import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SettingUp from './components/includes/SettingUp';
import SettingUpNew from './components/includes/SettingUpNew';
import Home from './components/portal/Home';
import BusinessInformation from './components/portal/BusinessInformation';
import Website from './components/portal/Website';
import Guide from './components/portal/Guide';
import Reviews from './components/portal/Reviews';
import Incentive from './components/portal/Incentive';
import Services from './components/portal/Services';
import Menu from './components/portal/Menu';
import Events from './components/portal/Events';
import GiftCards from './components/portal/GiftCards';
import Messaging from './components/portal/Messaging';
import Earnings from './components/portal/Earnings';
import Billing from './components/portal/Billing';
import Loyalty from './components/portal/Loyalty';
import Employees from './components/portal/Employees';
import PhotosAndVideos from './components/portal/PhotosAndVideos';
import WelcomePage from './components/onboarding/WelcomePage';
import Onboardingg from './components/onboarding/Onboardingg';
import NewOnboarding from './components/onboarding/NewOnboarding';
import NotFound from './components/portal/NotFound';
import LandingPage from './components/landing/LandingPage';
import Logout from './components/Logout';

class Routes extends React.Component {
  render(){
    return (
      <Router>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={LandingPage} />
          <Route exact path={`${process.env.PUBLIC_URL + "/setting-up"}`} component={SettingUp} />
          <Route exact path={`${process.env.PUBLIC_URL + "/setting-up-business"}`} component={SettingUpNew} />
          <Route exact path={`${process.env.PUBLIC_URL + "/home"}`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL + "/business-information"}`} component={BusinessInformation} />
          <Route exact path={`${process.env.PUBLIC_URL + "/my-website"}`} component={Website} />
          <Route exact path={`${process.env.PUBLIC_URL + "/guide"}`} component={Guide} />
          <Route exact path={`${process.env.PUBLIC_URL + "/reviews"}`} component={Reviews} />
          <Route exact path={`${process.env.PUBLIC_URL + "/incentive"}`} component={Incentive} />
          <Route exact path={`${process.env.PUBLIC_URL + "/services"}`} component={Services} />
          <Route exact path={`${process.env.PUBLIC_URL + "/menu"}`} component={Menu} />
          <Route exact path={`${process.env.PUBLIC_URL + "/events"}`} component={Events} />
          <Route exact path={`${process.env.PUBLIC_URL + "/gift-cards"}`} component={GiftCards} />
          <Route exact path={`${process.env.PUBLIC_URL + "/messaging"}`} component={Messaging} />
          <Route exact path={`${process.env.PUBLIC_URL + "/earnings"}`} component={Earnings} />
          <Route exact path={`${process.env.PUBLIC_URL + "/billing"}`} component={Billing} />
          <Route exact path={`${process.env.PUBLIC_URL + "/loyalty"}`} component={Loyalty} />
          <Route exact path={`${process.env.PUBLIC_URL + "/employees"}`} component={Employees} />
          <Route exact path={`${process.env.PUBLIC_URL + "/welcome-page"}`} component={WelcomePage} />
          <Route exact path={`${process.env.PUBLIC_URL + "/onboarding"}`} component={NewOnboarding} />
          <Route exact path={`${process.env.PUBLIC_URL + "/photos-videos"}`} component={PhotosAndVideos} />
          <Route exact path={`${process.env.PUBLIC_URL + "/logout"}`} component={Logout} />
          {/* Client Routes */}
          <Route exact path={`${process.env.PUBLIC_URL + "/onboardingg"}`} component={Onboardingg} />
          <Route path={`${process.env.PUBLIC_URL + "/not-found"}`} component={NotFound} />
          {/* <Route exact component={NotFound} /> */}
        </Switch>
      </Router>

    );
  }
}

export default Routes;
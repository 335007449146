import React from 'react';
import AuthService from '../misc/AuthService';
import { Modal } from 'antd';
import Iframe from 'react-iframe';
import CONSTANTS from '../misc/Constants';
import { WbIncandescentTwoTone } from '@mui/icons-material';

class HeaderNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      giveUsFeedbackModal: false,
      contactUsModal: false,
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
  }

  preventRefresh = (e) => {
    e.preventDefault();
  };

  giveUsfeedback = (e) => {
    e.preventDefault();
    this.setState({
      giveUsFeedbackModal: true,
    });
  };

  contactUs = (e) => {
    e.preventDefault();
    this.setState({
      contactUsModal: true,
    });
  };

  render() {
    return (
      <div
        style={{ zIndex: '1' }}
        className="nk-header nk-header-fixed is-light"
      >
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ml-n1"
             onClick={() => {
              if(window.sideNavTrigger == "nk-sidebar nk-sidebar-fixed is-light"){
                window.sideNavTrigger = "nk-sidebar nk-sidebar-fixed is-light nk-sidebar-active";
              }
              else{
                window.sideNavTrigger = "nk-sidebar nk-sidebar-fixed is-light";
              }
              
            }}
            >
              <a
                href="#/"
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="sidebarMenu"
              >
                <em className="icon ni ni-menu" />
              </a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href="#" className="logo-link">
                <img
                  style={{ maxHeight: '25px' }}
                  className="logo-light logo-img"
                  src="assets/images/logo.png"
                  srcSet="assets/images/logo.png 2x"
                  alt="logo"
                />
                <img
                  style={{ maxHeight: '25px' }}
                  className="logo-dark logo-img"
                  src="assets/images/logo.png"
                  srcSet="assets/images/logo.png 2x"
                  alt="logo-dark"
                />
              </a>
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li className="dropdown chats-dropdown hide-mb-xs">
                  <a
                    href="/"
                    className="dropdown-toggle nk-quick-nav-icon"
                    data-toggle="dropdown"
                  ></a>
                </li>
                
                <li className="dropdown user-dropdown">
                  <a
                    href="/"
                    className="dropdown-toggle mr-n1"
                    data-toggle="dropdown"
                  >
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        {this.state.userInfo.avatar === undefined ? (
                          <em className="icon ni ni-user-alt" />
                        ) : (
                          <img src={this.state.userInfo.avatar} alt="" />
                        )}
                      </div>
                      <div className="user-info d-none d-xl-block">
                        <div className="user-name dropdown-indicator">
                          {this.state.userInfo.email}
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li
                          onClick={(e) => {
                            this.giveUsfeedback(e);
                          }}
                        >
                          <a href="#">
                            <em className="icon ni ni-setting-alt" />
                            <span>Give us feedback</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li
                          onClick={(e) => {
                            this.contactUs(e);
                          }}
                        >
                          <a href="#">
                            <em className="icon ni ni-setting-alt" />
                            <span>Contact us</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li>
                          <a href={`${CONSTANTS.APP_URL}/logout`}>
                            <em className="icon ni ni-signout" />
                            <span>Sign out</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Give us feedback modal start */}

        <Modal
          visible={this.state.giveUsFeedbackModal}
          title="Give Us Feedback"
          footer={null}
          onCancel={() => {
            this.setState({
              giveUsFeedbackModal: false,
            });
          }}
        >
          <div>
            <iframe
              src={`https://forms.unstuq.com/embed.php?id=19148&element_1=${this.state.businessInfo.id}`}
              frameborder="0"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Modal>

        {/* Give us feedback modal end */}

        {/* Give us feedback modal start */}

        <Modal
          visible={this.state.contactUsModal}
          title="Contact Us"
          footer={null}
          onCancel={() => {
            this.setState({
              contactUsModal: false,
            });
          }}
        >
          <div>
            <iframe
              src={`https://forms.unstuq.com/embed.php?id=19461&element_1=${this.state.businessInfo.id}`}
              frameborder="0"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Modal>

        {/* Give us feedback modal end */}
      </div>
    );
  }
}

export default HeaderNav;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import AuthService from '../misc/AuthService';
import moment from 'moment';
import { Checkbox } from 'antd';


class NewOnboarding extends React.Component {
  constructor(props) {
    super(props);
    window.businessCheckerGlobal = this;
    this.state = {
      isLoading: true,
      stripeArray: [],
      nameOnCard: '',
      certify_business: false,
      certify_business_value: 0,
      code: '',
      dompe: false,
      disableCertify: true,
      showBusinessCheckerLaoder: false,
      showBusinessCheckerSuccess: false,
      setError: '',
      isRequesting: false,
      nameOnCard: '',
      couponCodeTest: "50OFF",
      couponCodeValue: '',
      couponCodeData: [],
      disableStartTrialButton: true,
      setCheckboxClassNameOne: 'SignUpForm-checkboxContainer',
      setCheckboxClassNameTwo: 'CheckboxField',
      plusFourteenDays: "",
      showCouponSection: false,
      showCouponCodeLoader: false,
      hideCouponCodeLaoder: false,
      couponCodeWriteup: "",
      couponCodeWriteupColor: "",
      userDetails: {
        sub: '',
        nickname: '',
        access_token: '',
        name: '',
        updated_at: '',
        email_verified: '',
        avatar: '',
        email: '',
        referred_by: ''
      },
      paymentDetails: {
        card_number: '',
        expiration_date: '',
        email: "",
        month: '',
        year: '',
        cvc: '',
        zip_code: '',
        first_name: '',
        last_name: '',
        coupon_code: ''
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    let startdate = moment();
    let tt = startdate.add(14, "days");
    let ttt = moment(tt).format('ll');
    setTimeout(
      function () {
        this.setState({
          isLoading: false,
          plusFourteenDays: ttt,
        });
      }.bind(this),
      2000
    );

    this.getCouponCodes();

    var userObj = reactLocalStorage.getObject('user_object');
    if (JSON.stringify(userObj) != '{}') {
      this.setState({
        dompe: true,
      });
    }

    if (window.sessionStorage.getItem('claim_another') != 'active') {
      if (window.sessionStorage.getItem('onboarding') === null) {
        window.location.replace(`${CONSTANTS.APP_URL}/logout`);
      }

      let userDetails = JSON.parse(window.sessionStorage.getItem('onboarding'));
      var oldData = this.state.userDetails;
      oldData.sub = userDetails.sub;
      oldData.nickname = userDetails.nickname;
      oldData.access_token = userDetails.access_token;
      oldData.name = userDetails.name;
      oldData.updated_at = userDetails.updated_at;
      oldData.email_verified = userDetails.email_verified;
      oldData.avatar = userDetails.picture;
      oldData.email = userDetails.email;
      this.setState({
        userDetails: oldData,
        // isLoading: false,
      });
      console.log(this.state.userDetails);

      let { stripeArray } = this.state;
      stripeArray.push(userDetails.email);

      let expirationDate = new Date();
      let validDays = 14;
      expirationDate.setTime(
        expirationDate.getTime() + validDays * 24 * 60 * 60 * 1000
      );
      // cookie.save('access_token', this.state.userDetails.access_token, {
      //   path: `${CONSTANTS.APP_URL}`,
      //   expires: expirationDate,
      // });
      reactLocalStorage.set('access_token', this.state.userDetails.access_token);
    } else {
      var userObj = reactLocalStorage.getObject('user_object');
      if (JSON.stringify(userObj) === '{}') {
        window.location.replace(`${CONSTANTS.APP_URL}/logout`);
      }

      let cann = JSON.parse(reactLocalStorage.getObject('user_object'));
      var oldData = this.state.userDetails;
      oldData.email = cann.email;
      this.setState({
        userDetails: oldData,
        // isLoading: false,
      });
      let { stripeArray } = this.state;
      stripeArray.push(cann.email);
    }
  }

  getCouponCodes() {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/coupon/codes',
        // AuthService.getAxiosHeaders(business_id)
      )
      .then((response) => {
        let result = response.data;
        console.log(result.data);
        // let coupon_codes = JSON.stringify(result.data.coupon_codes);
        let coupon_codes = result.data.coupon_codes;
        console.log(coupon_codes.length);
        this.setState({
          couponCodeData: result.data.coupon_codes,
        });

      })
      .catch((error) => {
      });
  }

  submitPaymentDetailsForm = (e) => {
    e.preventDefault();
    let old_paymentDetails = this.state.paymentDetails;
    old_paymentDetails.email = this.state.userDetails.email;
    this.setState({
      isRequesting: true,
      paymentDetails: old_paymentDetails,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/subscription',
        { payment_details: JSON.stringify(this.state.paymentDetails) }
      )
      .then((response) => {
        // let result = response.data;
        // let final_data = JSON.parse(result.data);
        // if(final_data['status'] == "failed"){
        //   this.setState({
        //     isRequesting: false,
        //   });
        //   AuthService.Prompts('error', final_data['message']);
        //   return;
        // }

        // sessionStorage.setItem('payment_information', JSON.stringify(final_data['data']));
        window.location.replace(`${CONSTANTS.APP_URL}/business-information`);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isRequesting: false
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  goBack() {
    window.sessionStorage.removeItem('onboarding');
    window.sessionStorage.removeItem('business_information');
    window.sessionStorage.removeItem('claim_another');
    window.location.replace(`${CONSTANTS.APP_URL}/business-information`);
  }


  handlePaymentDetails = (event, name) => {
    // if (name.toString() === 'card_number') {
    //   let old_paymentDetails = this.state.paymentDetails;
    //   old_paymentDetails.card_number = event.target.value;
    //   this.setState({
    //     paymentDetails: old_paymentDetails,
    //   });
    // }
    // if (name.toString() === 'month') {
    //   let old_paymentDetails = this.state.paymentDetails;
    //   old_paymentDetails.month = event.target.value;
    //   this.setState({
    //     paymentDetails: old_paymentDetails,
    //   });
    // }
    // if (name.toString() === 'year') {
    //   let old_paymentDetails = this.state.paymentDetails;
    //   old_paymentDetails.year = event.target.value;
    //   this.setState({
    //     paymentDetails: old_paymentDetails,
    //   });
    // }
    // if (name.toString() === 'cvc') {
    //   let old_paymentDetails = this.state.paymentDetails;
    //   old_paymentDetails.cvc = event.target.value;
    //   this.setState({
    //     paymentDetails: old_paymentDetails,
    //   });
    // }
    // if (name.toString() === 'zip_code') {
    //   let old_paymentDetails = this.state.paymentDetails;
    //   old_paymentDetails.zip_code = event.target.value;
    //   this.setState({
    //     paymentDetails: old_paymentDetails,
    //   });
    // }
    // if (name.toString() === 'first_name') {
    //   let old_paymentDetails = this.state.paymentDetails;
    //   old_paymentDetails.first_name = event.target.value;
    //   this.setState({
    //     paymentDetails: old_paymentDetails,
    //   });
    // }
    // if (name.toString() === 'last_name') {
    //   let old_paymentDetails = this.state.paymentDetails;
    //   old_paymentDetails.last_name = event.target.value;
    //   this.setState({
    //     paymentDetails: old_paymentDetails,
    //   });
    // }

    if (name.toString() === 'coupon_code') {
      let old_paymentDetails = this.state.paymentDetails;
      old_paymentDetails.coupon_code = event.target.value;
      this.setState({
        paymentDetails: old_paymentDetails,
        showCouponCodeLoader: true
      });
      console.log(event.target.value.length);

      for (var index = 0; index < this.state.couponCodeData.length; ++index) {
        var coupon_codes_result = this.state.couponCodeData[index];
        if(coupon_codes_result['code'] == this.state.paymentDetails.coupon_code.toUpperCase()){
          this.setState({
            couponCodeValue: coupon_codes_result['value'],
            showCouponCodeLoader: false,
            hideCouponCodeLaoder: true,
            couponCodeWriteupColor: 'green',
            couponCodeWriteup: 'Coupon code for $' + coupon_codes_result['value'] + ' applied'
          });
        }
        else if(event.target.value.length === 5 && coupon_codes_result['code'] != this.state.paymentDetails.coupon_code){
          this.setState({
            showCouponCodeLoader: false,
            hideCouponCodeLaoder: true,
            couponCodeWriteupColor: 'red',
            couponCodeWriteup: 'Invalid/Expired coupon code'
          });
        }
        else if(event.target.value.length === 0){
          this.setState({
            showCouponCodeLoader: false,
            hideCouponCodeLaoder: true,
            couponCodeWriteup: ''
          });
        }
        else{
          this.setState({
            showCouponCodeLoader: true,
            hideCouponCodeLaoder: false,
            couponCodeWriteup: ''
          });
        }
      }

      // let old_paymentDetails = this.state.paymentDetails;
      // old_paymentDetails.coupon_code = event.target.value;
      // this.setState({
      //   paymentDetails: old_paymentDetails,
      // });

    }
  };

  verifyCouponCode = (e) => {
    for (var index = 0; index < this.state.couponCodeData.length; ++index) {
      var coupon_codes_result = this.state.couponCodeData[index];
      if(coupon_codes_result['code'] == this.state.paymentDetails.coupon_code){
        this.setState({
          couponCodeValue: coupon_codes_result['value'],
        });
        AuthService.Prompts('success', 'Coupon code for $' + coupon_codes_result['value'] + ' successfully applied');
      }
      else{
        AuthService.Prompts('error', 'Invalid/Expired coupon code');
      }
    }
  }

  businessCheckerGlobal = (e) => {
    let businessDetails = JSON.parse(
      window.sessionStorage.getItem('business_information')
    );
    this.setState({
      showBusinessCheckerLaoder: true,
      showBusinessCheckerSuccess: false,
      showBusinessChckerError: '',
    });

    axios
      .post(CONSTANTS.API_BASE_URL + '/business/checker', {
        business_details: JSON.stringify(businessDetails),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.message == 'duplicate') {
          this.setState({
            showBusinessChckerError: 'This Business has already been claimed',
            showBusinessCheckerLaoder: false,
            showBusinessCheckerSuccess: false,
          });
        } else if (response.data.message == 'not a restaurant') {
          this.setState({
            showBusinessChckerError: 'This Business is not a restaurant',
            showBusinessCheckerLaoder: false,
            showBusinessCheckerSuccess: false,
          });
        } else if (response.data.message == 'not working') {
          this.setState({
            showBusinessChckerError: 'Unable to claim this Business',
            showBusinessCheckerLaoder: false,
            showBusinessCheckerSuccess: false,
          });
        } else {
          this.setState({
            showBusinessChckerError: '',
            disableCertify: false,
            showBusinessCheckerLaoder: false,
            showBusinessCheckerSuccess: true,
          });
        }
        //console.log(response.data.message);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  onChangeCertifyBusinessnCheckbox = (e) => {
    //console.log('checked = ', e.target.checked);
    let { stripeArray } = this.state;
    if (e.target.checked) {
      this.setState({
        checked: e.target.checked,
        certify_business: true,
        certify_business_value: 1,
        // disableStartTrialButton: false,
        setCheckboxClassNameOne:
          'SignUpForm-checkboxContainer SignUpForm-checkboxContainer--checked',
        setCheckboxClassNameTwo: 'CheckboxField CheckboxField--checked',
      });
      let toRemove = 0;
      let index = stripeArray.indexOf(toRemove);
      if (index > -1) {
        this.state.stripeArray.splice(index, 1);
      }
      stripeArray.push(1);
      //console.log(this.state.stripeArray);
    } else {
      this.setState({
        certify_business_value: 0,
        certify_business: false,
        // disableStartTrialButton: true,
        setCheckboxClassNameOne: 'SignUpForm-checkboxContainer',
        setCheckboxClassNameTwo: 'CheckboxField',
      });
      let toRemove = 1;
      let index = stripeArray.indexOf(toRemove);
      if (index > -1) {
        this.state.stripeArray.splice(index, 1);
        stripeArray.push(0);
      }
    }
  };

  onChangeHaveACouponCodeCheckbox = (e) => {
    if (e.target.checked) {
      this.setState({
        checked: e.target.checked,
        showCouponSection: true
      });
  }
  else{
    let old_paymentDetails = this.state.paymentDetails;
    old_paymentDetails.coupon_code = "";
    this.setState({
      paymentDetails: old_paymentDetails,
      showCouponSection: false
    });
  }
}

  render() {
    return (
      // isAuthenticated && (
      <Fragment>
        <MetaTags>
          <title>UnstuQ Business</title>
        </MetaTags>
        {this.state.isLoading ? (
          <>
            <img
              className="preloader"
              src="../assets/images/logo_small.png"
              alt="image"
            />
          </>
        ) : (
          <div
            style={{ backgroundColor: 'white', margin: '0', height: '100%' }}
            className="App-Container is-noBackground flex-container justify-content-center"
          >
            <div className="App App--multiItem" style={{ paddingTop: '10px' }}>
              {/* left side start */}
              <div className="App-Overview">
                <header>
                  <div className="Header-Content flex-container justify-content-space-between align-items-stretch">
                    <div className="Header-business flex-item width-grow flex-container align-items-center">
                      <div>
                        <div className="Header-merchantLogoWithLabel flex-item width-grow">
                          <div className="HeaderImage HeaderImage--icon HeaderImage--iconFallback flex-item width-fixed flex-container justify-content-center align-items-center width-fixed">
                            <svg
                              className="InlineSVG Icon HeaderImage-fallbackIcon Icon--sm"
                              focusable="false"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M3 7.5V12h10V7.5c.718 0 1.398-.168 2-.468V15a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7.032c.602.3 1.282.468 2 .468zM0 3L1.703.445A1 1 0 0 1 2.535 0h10.93a1 1 0 0 1 .832.445L16 3a3 3 0 0 1-5.5 1.659C9.963 5.467 9.043 6 8 6s-1.963-.533-2.5-1.341A3 3 0 0 1 0 3z"
                                fillRule="evenodd"
                              />
                            </svg>
                          </div>
                          <span className="Header-businessLink-label Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500">
                            Back
                          </span>
                          <h1 className="ProductSummary-name Text Text-color--gray500 Text-fontSize--16 Text-fontWeight--500">
                            UnstuQ Inc
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
                <div className="App-productSummaryContainer">
                  <div className="ProductSummary">
                    <div className="ProductSummary-productImageContainer is-clickable">
                      <div className="ProductImage-container">
                        <img
                          src="https://d1wqzb5bdbcre6.cloudfront.net/49e174fdaa2fb10a0fa890b8e01d7759815002fc17167489f80e754d1fa78331/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a644638785255644262486c496457567657555a795155645266475a735833526c6333526653445273646d52354e464a4e546d5644513370724e485a784f474a4e646d6c683030364f776e4c7a6775"
                          alt="Product"
                          className="ProductImage-image"
                        />
                      </div>
                    </div>
                    <div className="ProductSummary-info is-clickable">
                      <span className="ProductSummary-name Text Text-color--gray500 Text-fontSize--16 Text-fontWeight--500">
                        Try UnstuQ Business Basic
                      </span>
                      <span
                        className="ProductSummary-totalAmount Text Text-color--default Text-fontWeight--600 Text--tabularNumbers"
                        id="ProductSummary-totalAmount"
                      >
                        14 days free
                      </span>
                      <span
                        className="ProductSummary-description Text Text-color--gray500 Text-fontSize--14 Text-fontWeight--500"
                        id="ProductSummary-description"
                      >
                        <div className="ProductSummaryDescription">
                          Then ${this.state.couponCodeValue == "" ? 99 : 99 - this.state.couponCodeValue}.00 per month
                        </div>
                      </span>
                    </div>
                  </div>
                  <section className="OrderDetails App-Overview-OrderDetails my6 is-overflowing">
                    <ul className="OrderDetails-items">
                      <div
                        className="OrderDetails-item"
                        style={{
                          opacity: 1,
                          transform: 'scale(1)',
                          height: 'auto',
                          marginBottom: '16px',
                        }}
                      >
                        <div className="LineItem flex-container align-items-flex-start">
                          <div className="LineItem-imageContainer mr4 flex-item width-fixed">
                            <img
                              src="https://d1wqzb5bdbcre6.cloudfront.net/49e174fdaa2fb10a0fa890b8e01d7759815002fc17167489f80e754d1fa78331/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a644638785255644262486c496457567657555a795155645266475a735833526c6333526653445273646d52354e464a4e546d5644513370724e485a784f474a4e646d6c683030364f776e4c7a6775"
                              alt=""
                              className="LineItem-image"
                            />
                          </div>
                          <div className="flex-item width-grow">
                            <div className="flex-container justify-content-space-between align-items-baseline wrap-wrap">
                              <div
                                className="flex-column-break"
                                style={{ order: 2 }}
                              />
                              <div
                                className="flex-column-break"
                                style={{ order: 6 }}
                              />
                              <div className="flex-item width-12 flex-container justify-content-space-between width-12">
                                <div
                                  className="LineItem-productName flex-item width-auto"
                                  style={{ order: 0 }}
                                >
                                  <span className="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">
                                    UnstuQ Business Basic
                                  </span>
                                </div>
                                <div
                                  className="ml2 flex-item width-fixed"
                                  style={{ order: 1 }}
                                >
                                  <span className="Text Text-color--default Text-fontSize--14 Text-fontWeight--500 Text--tabularNumbers">
                                    14 days free
                                  </span>
                                </div>
                              </div>
                              <div
                                className="flex-item width-auto flex-item-align-left"
                                style={{ order: 4 }}
                              >
                                <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400" />
                              </div>
                              <div
                                className="flex-item width-auto flex-item-align-right"
                                style={{ order: 5 }}
                              >
                                <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                                  ${this.state.couponCodeValue == "" ? 99 : 99 - this.state.couponCodeValue}.00 / month after
                                </span>
                              </div>
                              <div
                                className="LineItem-tierBreakdown flex-item width-auto flex-item-align-left"
                                style={{ order: 7 }}
                              >
                                <div className="flex-container" />
                              </div>
                              <div
                                className="flex-item width-auto"
                                style={{ order: 3 }}
                              >
                                <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                    <div
                      className="OrderDetails-footer"
                      style={{ marginBottom: '0px' }}
                    >
                      <div className="OrderDetailsFooter-subtotal OrderDetailsFooter-subtotal--indented flex-container justify-content-space-between">
                        <span className="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">
                          Subtotal
                        </span>
                        <span
                          className="Text Text-color--default Text-fontSize--14 Text-fontWeight--600 Text--tabularNumbers"
                          id="OrderDetailsFooter-SubtotalAmount"
                        >
                          ${this.state.couponCodeValue == "" ? 99 : 99 - this.state.couponCodeValue}.00
                        </span>
                      </div>
                      <div className="OrderDetails-total OrderDetails-total--indented flex-container justify-content-space-between">
                        <span>
                          <span className="Text Text-color--default Text-fontSize--14 Text-fontWeight--400">
                            Total after trial
                          </span>
                        </span>
                        <span
                          className="Text Text-color--default Text-fontSize--14 Text-fontWeight--400 Text--tabularNumbers"
                          id="OrderDetails-TotalAmount"
                        >
                          ${this.state.couponCodeValue == "" ? 99 : 99 - this.state.couponCodeValue}.00
                        </span>
                      </div>
                      <div className="OrderDetailsFooter-trialTotal OrderDetailsFooter-trialTotal--indented flex-container justify-content-space-between">
                        <span>
                          <span className="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">
                            Total due today
                          </span>
                        </span>
                        <span
                          className="Text Text-color--default Text-fontSize--16 Text-fontWeight--600 Text--tabularNumbers"
                          id="OrderDetails-TrialAmount"
                        >
                          $0.00
                        </span>
                      </div>

                      <br />
                      <br />
                      {/* <br />
                      <br />
                      <br /> */}
                    </div>

                    {/* safe payment start */}
                    <div>
                      <div
                        className="form-group col-md-12 text-center"
                        style={{ marginLeft: '2rem', marginTop: '12px' }}
                      >
                        <div
                          style={{
                            margin: '0px 15px 15px',
                            padding: '0px',
                          }}
                        >
                          <div className>
                            <span>
                              <img
                                alt="card"
                                className="img-auto mw-220px border-0 m-b-10"
                                src="https://d1izqpt1s10trr.cloudfront.net/main/assets/images/checkout-card.png"
                              />
                            </span>
                          </div>
                          <div className="dl button-footer-image m-b-0">
                            <img
                              alt="secure"
                              className="img-center img-auto pull-left border-0 m-b-10 m-t-10"
                              src="https://d1izqpt1s10trr.cloudfront.net/main/assets/images/secure-checkout.png"
                            />
                          </div>
                          <div className="m-b-0">
                            <small
                              id="footerdesctextlabel"
                              className="db m-t-15 m-b-40 word-wrap-break"
                              style={{
                                fontSize: '12px !important',
                                color: 'rgb(33, 37, 41)',
                                fontFamily: '"Avenir Medium"',
                              }}
                            >
                              Your payment is always safe &amp; secure.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* safe payment end */}
                  </section>
                </div>
              </div>
              {/* left side end */}

              <div className="App-Payment">
                <div className="CheckoutPaymentForm">
                  <div
                    className="PaymentRequestOrHeader"
                    style={{ display: 'inherit' }}
                  >
                    <div
                      className="PaymentHeaderContainer"
                      style={{ display: 'none', opacity: 0 }}
                    >
                      <div className="PaymentHeader">
                        <div className="Text Text-color--default Text-fontSize--20 Text-fontWeight--500">
                          Enter payment details
                        </div>
                      </div>
                    </div>

                    {this.state.dompe && (
                      <div
                        className="ButtonAndDividerContainer"
                        style={{ opacity: 1, display: 'block' }}
                      >
                        <div className="PaymentRequestButtonContainer StripeElement"></div>
                        <div
                          className="Divider"
                          style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                          <hr />
                          <p
                            style={{ cursor: 'pointer', color: '#1890ff' }}
                            onClick={() => {
                              this.goBack();
                            }}
                            className="Divider-Text Text Text-color--gray400 Text-fontSize--14 Text-fontWeight--400"
                          >
                            {/* Claim Your Business */}
                            Back to portal
                          </p>
                        </div>
                      </div>
                    )}

                    <div
                      style={{ paddingBottom: '15px', paddingTop: '0px' }}
                      className="ProductSummary-info is-clickable"
                    >
                      <span className="ProductSummary-name Text Text-color--gray500 Text-fontSize--16 Text-fontWeight--500">
                        Claim Your Business
                      </span>
                    </div>
                  </div>
                  <div
                    className="PaymentFormFixedHeightContainer flex-container direction-column"
                    style={{ height: '529px' }}
                  >
                    <form onSubmit={this.submitPaymentDetailsForm}>
                      <div style={{ height: '460.883px' }}>
                        <div>
                          <div style={{ opacity: 1 }}>
                            {/* form goes here start */}

                            <div>
                              {/* <div className="App-Global-Fields flex-container direction-row wrap-wrap"> */}
                              <div>
                                {/* email for business input start */}
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label
                                      style={{ color: 'rgba(26,26,26,.4)' }}
                                      className="form-label Text-color--gray600"
                                      htmlFor="email-address"
                                    >
                                      Email
                                    </label>
                                  </div>
                                  <div className="form-control-wrap">
                                    <input
                                      disabled
                                      autoComplete="off"
                                      type="text"
                                      className="form-control form-control-lg"
                                      required
                                      value={this.state.userDetails.email}
                                    />
                                  </div>
                                </div>
                                {/* email for business input end */}

                                {/* search for business input start */}
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label
                                      style={{ color: 'rgba(26,26,26,.4)' }}
                                      className="form-label"
                                      htmlFor="password"
                                    >
                                      Search for your business below
                                    </label>
                                    {this.state.showBusinessCheckerLaoder && (
                                      <em className="icon ni ni-loader rotating" />
                                    )}
                                    {this.state.showBusinessCheckerSuccess && (
                                      <em
                                        className="icon ni ni-check-circle-fill"
                                        style={{ color: 'green' }}
                                      />
                                    )}
                                  </div>
                                  <div className="form-control-wrap">
                                    <input
                                      required
                                      onChange={() => {
                                        window.initAutocomplete();
                                      }}
                                      id="search-box-input"
                                      role="combobox"
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Enter the name of your business"
                                    />
                                  </div>
                                  {this.state.showBusinessChckerError != '' && (
                                    <div
                                      style={{ color: 'red' }}
                                      className="card-error"
                                      role="alert"
                                    >
                                      {this.state.showBusinessChckerError}
                                    </div>
                                  )}
                                </div>
                                {/* search for business input end */}

                                {/* certify input start */}

                                <div
                                  className="CardPayment-signUpFormContainer flex-item width-12"
                                  style={{ marginBottom: '15px' }}
                                >
                                  <div
                                    style={{
                                      pointerEvents: 'auto',
                                      height: 'auto',
                                      opacity: 1,
                                    }}
                                  >
                                    {/* <div className="SignUpForm-checkboxContainer SignUpForm-checkboxContainer--checked">
                                    <div className="FormFieldCheckbox SignUpForm-checkbox">
                                      <div className="CheckboxField CheckboxField--checked">
                                        <div className="Checkbox-InputContainer">
                                          <input
                                            id="enableStripePass"
                                            name="enableStripePass"
                                            type="checkbox"
                                            className="Checkbox-Input"
                                          />
                                          <span className="Checkbox-StyledInput" />
                                        </div>
                                        <label htmlFor="enableStripePass">
                                          <span className="Checkbox-Label Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">
                                            Save my info for secure 1-click
                                            checkout
                                            <div className="SignUpForm-subLabel">
                                              Pay faster on UnstuQ Inc and
                                              thousands of sites.
                                            </div>
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div> */}

                                    <div className="SignUpForm">
                                      <div
                                        className={
                                          this.state.setCheckboxClassNameOne
                                        }
                                      >
                                        <div className="FormFieldCheckbox SignUpForm-checkbox">
                                          <div
                                            className={
                                              this.state.setCheckboxClassNameTwo
                                            }
                                          >
                                            <div className="Checkbox-InputContainer">
                                              <input
                                                id="enableStripePass"
                                                name="enableStripePass"
                                                type="checkbox"
                                                className="Checkbox-Input"
                                                disabled={
                                                  this.state.disableCertify
                                                }
                                                required
                                                onChange={(e) => {
                                                  this.onChangeCertifyBusinessnCheckbox(
                                                    e
                                                  );
                                                }}
                                                checked={
                                                  this.state.certify_business
                                                }
                                              />
                                              <span className="Checkbox-StyledInput" />
                                            </div>
                                            <span className="Checkbox-Label Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">
                                              <div
                                                className="SignUpForm-subLabel"
                                                style={{
                                                  fontSize: '13px',
                                                  color: 'black',
                                                }}
                                              >
                                                I certify that I am an
                                                authorised representative of the
                                                above Business
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* card details start */}

                                {/* <div className="form-group">
                                  <div className="form-label-group">
                                    <label
                                      style={{
                                        color: 'rgba(26,26,26,.4)',
                                      }}
                                      className="form-label Text-color--gray600"
                                    >
                                      Card Details
                                    </label>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 col-12">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="First name"
                                          value={
                                            this.state.paymentDetails.first_name
                                          }
                                          onChange={(e) => {
                                            this.handlePaymentDetails(
                                              e,
                                              'first_name'
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Last name"
                                          value={
                                            this.state.paymentDetails.last_name
                                          }
                                          onChange={(e) => {
                                            this.handlePaymentDetails(
                                              e,
                                              'last_name'
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                <div>
                                  {/* <div
                                    className="row"
                                    style={{ marginBottom: '0.5rem' }}
                                  >
                                    <div className="col-md-6 col-12">
                                      <div
                                        className="form-group"
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <div className="input-group">
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Card Number"
                                            maxlength="16"
                                            size="16"
                                            value={
                                              this.state.paymentDetails
                                                .card_number
                                            }
                                            onChange={(e) => {
                                              this.handlePaymentDetails(
                                                e,
                                                'card_number'
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-3 col-12">
                                      <div className="form-group">
                                        <input
                                          type="number"
                                          className="form-control"
                                          maxlength="2"
                                          size="2"
                                          placeholder="MM"
                                          value={
                                            this.state.paymentDetails.month
                                          }
                                          onChange={(e) => {
                                            this.handlePaymentDetails(
                                              e,
                                              'month'
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-3 col-12">
                                      <div className="form-group">
                                        <input
                                          type="number"
                                          className="form-control"
                                          maxlength="2"
                                          size="2"
                                          placeholder="YY"
                                          value={this.state.paymentDetails.year}
                                          onChange={(e) => {
                                            this.handlePaymentDetails(
                                              e,
                                              'year'
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div
                                    className="row"
                                    style={{ marginBottom: '0.5rem' }}
                                  >
                                    <div className="col-md-6 col-12">
                                      <div className="form-group">
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="CVC"
                                          maxlength="4"
                                          size="4"
                                          value={this.state.paymentDetails.cvc}
                                          onChange={(e) => {
                                            this.handlePaymentDetails(e, 'cvc');
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                      <div className="form-group">
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Zip Code"
                                          maxlength="5"
                                          size="5"
                                          value={
                                            this.state.paymentDetails.zip_code
                                          }
                                          onChange={(e) => {
                                            this.handlePaymentDetails(
                                              e,
                                              'zip_code'
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div> */}

                                  {/* card details end */}

                                  <hr/>

                                  <Checkbox 
                                    onChange={(e) => {
                                      this.onChangeHaveACouponCodeCheckbox(
                                        e
                                      );
                                    }}
                                  >I have a coupon code</Checkbox>

                                 {this.state.showCouponSection && <div
                                    className="row"
                                    style={{ marginBottom: '0.5rem' }}
                                  >
                                    <div className="col-md-12 col-12">
                                      <div className="form-group">
                                      <div className="form-label-group">
                                    <label
                                      style={{
                                        color: 'rgba(26,26,26,.4)',
                                      }}
                                      className="form-label Text-color--gray600"
                                    >
                                      Coupon Code
                                    </label>

                                    {this.state.showCouponCodeLoader && (
                                      <em className="icon ni ni-loader rotating" />
                                    )}
                                    {this.state.hideCouponCodeLaoder && (
                                      <span style={{ color: this.state.couponCodeWriteupColor }}>{this.state.couponCodeWriteup}</span>
                                      // <em
                                      //   className="icon ni ni-check-circle-fill"
                                      //   style={{ color: 'green' }}
                                      // />
                                    )}

                                  </div>
                                  </div>
                                  </div>
                                  <div className="col-md-12 col-12">
                                      <div className="form-group">
                                        <input
                                          className="form-control"
                                          placeholder="Coupon Code"
                                          maxlength="5"
                                          size="5"
                                          value={this.state.paymentDetails.coupon_code}
                                          onChange={(e) => {
                                            this.handlePaymentDetails(e, 'coupon_code');
                                          }}
                                        />
                                      </div>

                                      {/* <span>{this.state.couponCodeWriteup}</span> */}
                                    </div>

                                    

                                    {/* coupon code apply button start */}

                                    {/* <div className="col-md-6 col-12">
                                      <div className="form-group">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-block"
                                          onClick={() => {this.verifyCouponCode()}}
                                          >
                                          <span>Apply</span>
                                        </button>
                                      </div>
                                    </div> */}

                                    {/* coupon code apply button end */}

                                  </div> }

                                  <hr/>

                                </div>

                                <div className="form-row" style={{ marginBottom: '0.5rem' }}>
                                  <div className="form-group col-md-12 m-b-0">
                                    <div
                                      className="position-relative"
                                      style={{
                                        // margin: '0px 10px',
                                        padding: '0px',
                                      }}
                                    >
                                      <p
                                        className
                                        style={{
                                          fontSize: '14px',
                                          // color: 'rgba(26, 26, 26, 0.4)',
                                          // fontFamily: '"Avenir Medium"',
                                        }}
                                      >
                                        {/* Your trial period ends on{" " + this.state.plusFourteenDays + " "}
                                        after which your card will be charged.
                                        However, you will not be charged if you
                                        cancel before the end of the trial period. */}

                                        Your trial period ends on{" " + this.state.plusFourteenDays + ". "}
                                        You will receive an email with payment instructions 3 days before your trial expires.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {!this.state.isRequesting ? (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary btn-block"
                                  >
                                    <span>Submit</span>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    disabled
                                    className="btn btn-lg btn-primary btn-block"
                                  >
                                    <div
                                      className="spinner-border"
                                      role="status"
                                      style={{ margin: '-6px' }}
                                    >
                                      {' '}
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>

                            {/* form goes here end */}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default NewOnboarding;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import CONSTANTS from '../misc/Constants';
import CookieConsent from "react-cookie-consent";
import { reactLocalStorage } from 'reactjs-localstorage';
import { OnboardingLoader } from '../includes/Loaders';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    window.landing = this;
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    window.switchTheme("landing");
    
    // setTimeout(
    //     function () {
    //     this.setState({
    //       isLoading: false,
    //     });
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('affiliate');
        if (code) {
          let expirationDate = new Date();
          let validDays = 90;
          expirationDate.setTime(
            expirationDate.getTime() + validDays * 24 * 60 * 60 * 1000
          );
          //   cookie.save('unstuq_affiliate_code', code, {
          //   path: `${CONSTANTS.APP_URL}`,
          //   expires: expirationDate,
          // });
          reactLocalStorage.set('unstuq_affiliate_code', code);
        }
    //   }.bind(this),
    //   2000
    // );
    // window.sessionStorage.removeItem('business_information');
  }

  landing = () => {
    this.setState({
      isLoading: false,
    });
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          {/* <link
            rel="stylesheet"
            // href="https://unstuq.com/assets/css/landingplugin.css"
            href="%PUBLIC_URL%/assets/css/landingplugin.css"
          />
          <link
            rel="stylesheet"
            href="%PUBLIC_URL%/assets/css/landingstyle.css"
            // href="https://unstuq.com/assets/css/landingstyle.css"
          /> */}
          <title>UnstuQ Business</title>
        </MetaTags>
        {this.state.isLoading ? (
          <>
          <img className="preloader" src="assets/images/logo_small.png" alt="image" />
          </>
        ) : (
          <div>
            <CookieConsent
              location="bottom"
              buttonText="I understand"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}
              {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>
            <div className="content-wrapper">
              <header className="wrapper bg-gray">
                <nav className="navbar navbar-expand-lg center-logo transparent navbar-light">
                  <div className="container flex-lg-row flex-nowrap align-items-center">
                    <div className="navbar-brand w-100">
                      <a href="./">
                        <img
                          src="./landing/assets/img/unstuq_logo@1x.png"
                          srcSet="./landing/assets/img/unstuq_logo@2x.png 2x"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="navbar-collapse offcanvas-nav">
                      <div className="offcanvas-header d-lg-none d-xl-none">
                        <a href="./">
                          <img
                            src="./landing/assets/img/unstuq_logo@1x.png"
                            srcSet="./landing/assets/img/unstuq_logo@2x.png 2x"
                            alt=""
                          />
                        </a>
                        <button
                          type="button"
                          className="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                          aria-label="Close"
                        />
                      </div>
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <a className="nav-link scroll" href="#home">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link scroll" href="#services">
                            Services
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link scroll" href="#howitworks">
                            How It Works
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="https://affiliate.unstuq.com">
                            Become an Affiliate
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link scroll" href="#contact">
                            Contact
                          </a>
                        </li>
                      </ul>
                      {/* /.navbar-nav */}
                    </div>
                    {/* /.navbar-collapse */}
                    <div className="navbar-other w-100 d-flex ms-auto">
                      <ul
                        className="navbar-nav flex-row align-items-center ms-auto"
                        data-sm-skip="true"
                      >
                        <li
                          className="nav-item d-none d-md-block"
                          id="logoutButton"
                        >
                          <a
                            href={`https://unstuq-business.us.auth0.com/authorize?response_type=code&client_id=pcOIjQNto4L64i9gRDAydggNa3vhEbDS&redirect_uri=${CONSTANTS.APP_URL}/setting-up&scope=openid%20profile%20email`}
                            className="btn btn-sm btn-primary rounded-pill"
                          >
                            Business Login
                          </a>
                        </li>
                        <li className="nav-item d-lg-none">
                          <div className="navbar-hamburger">
                            <button
                              className="hamburger animate plain"
                              data-toggle="offcanvas-nav"
                            >
                              <span />
                            </button>
                          </div>
                        </li>
                      </ul>
                      {/* /.navbar-nav */}
                    </div>
                    {/* /.navbar-other */}
                  </div>
                  {/* /.container */}
                </nav>
                {/* /.navbar */}
              </header>
              {/* /header */}
              <section id="home" className="wrapper bg-gradient-primary">
                <div className="container pt-10 pt-md-14 pb-8 text-center">
                  <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                    <div className="col-lg-7">
                      <figure>
                        <img
                          className="w-auto"
                          src="./landing/assets/img/shop.png"
                          alt=""
                        />
                      </figure>
                    </div>
                    {/* /column */}
                    <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
                      <h1 className="display-1 mb-5 mx-md-n5 mx-lg-0">
                        Grow Your Business with UnstuQ
                      </h1>
                      <p className="lead fs-lg mb-7">
                        We help small businesses consolidate marketing activities to <strong>significantly increase</strong> online discoverability and <strong>boost walk-in business</strong>.
                      </p>
                      <span>
                        <a
                          className="btn btn-primary rounded-pill me-2 nav-link scroll"
                          href={`https://unstuq-business.us.auth0.com/authorize?response_type=code&client_id=pcOIjQNto4L64i9gRDAydggNa3vhEbDS&redirect_uri=${CONSTANTS.APP_URL}/setting-up&scope=openid%20profile%20email`}
                        >
                          Claim my Business Listing
                        </a>
                      </span>
                    </div>
                    {/* /column */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container */}
              </section>
              <section id="services">
                <div className="wrapper bg-light">
                  <div className="container py-7 py-md-7">
                  {/*/.row */}
                    <div className="row gx-lg-8 gx-xl-12 gy-6 gy-md-0 text-center">
                      <h1 className="display-1 mb-10 mx-md-n5 mx-lg-0">
                        Our Services<br></br><span style={{fontSize: "1.0rem"}}>Our all-inclusive plan gives you access to ALL our services for a flat monthly rate.</span>
                      </h1>
                      <div className="col-md-6 col-lg-3 col-sm-12">
                        <img
                          src="./landing/assets/img/analysis.png"
                          className="svg-inject icon-svg icon-svg-md text-blue mb-3"
                          alt=""
                        />
                        <h4 style={{color:"#3f78e0"}}>Marketing Analytics</h4>
                        <p className="mb-2">
                          Access all your marketing and demand analytics in a single platform in near real time.
                        </p>
                      </div>
                      {/*/column */}
                      <div className="col-md-6 col-lg-3 col-sm-12">
                        <img
                          src="./landing/assets/img/love.png"
                          className="svg-inject icon-svg icon-svg-md text-green mb-3"
                          alt=""
                        />
                        <h4 style={{color:"#3f78e0"}}>Loyalty Program</h4>
                        <p className="mb-2">
                          Activate and manage a full-featured loyalty program within minutes of signing up.
                        </p>
                      </div>
                      {/*/column */}
                      <div className="col-md-6 col-lg-3 col-sm-12">
                        <img
                          src="./landing/assets/img/gift-card.png"
                          className="svg-inject icon-svg icon-svg-md text-yellow mb-3"
                          alt=""
                        />
                        <h4 style={{color:"#3f78e0"}}>Gift Cards Sales</h4>
                        <p className="mb-2">
                          Increase revenue and guarantee patronage with gift card sales that don't eat into your margins.
                        </p>
                      </div>
                      {/*/column */}
                      <div className="col-md-6 col-lg-3 col-sm-12">
                        <img
                          src="./landing/assets/img/dollar-coin.png"
                          className="svg-inject icon-svg icon-svg-md text-orange mb-3"
                          alt=""
                        />
                        <h4 style={{color:"#3f78e0"}}>Incentive Program</h4>
                        <p className="mb-2">
                          Offer coupons, discounts and more to your customers to drive foot traffic and increase retention.
                        </p>
                      </div>
                      
                      {/*/column */}
                    </div>
                    {/*/.row */}
                    {/*/.row */}
                    <div className="row gx-lg-8 gx-xl-12 mt-10 gy-6 gy-md-0 text-center">
                      <div className="col-md-6 col-lg-3 col-sm-12">
                        <img
                          src="./landing/assets/img/message.png"
                          className="svg-inject icon-svg icon-svg-md text-blue mb-3"
                          alt=""
                        />
                        <h4 style={{color:"#3f78e0"}}>Messaging</h4>
                        <p className="mb-2">
                          Connect with your customers through targeted messaging that is guaranteed to not end up in SPAM.
                        </p>
                      </div>
                      {/*/column */}
                      <div className="col-md-6 col-lg-3 col-sm-12">
                        <img
                          src="./landing/assets/img/tickets.png"
                          className="svg-inject icon-svg icon-svg-md text-green mb-3"
                          alt=""
                        />
                        <h4 style={{color:"#3f78e0"}}>Event Management</h4>
                        <p className="mb-2">
                          Publish and manage promotional events and track registrations and attendance. 
                        </p>
                      </div>
                      {/*/column */}
                      <div className="col-md-6 col-lg-3 col-sm-12">
                        <img
                          src="./landing/assets/img/note.png"
                          className="svg-inject icon-svg icon-svg-md text-yellow mb-3"
                          alt=""
                        />
                        <h4 style={{color:"#3f78e0"}}>Service Management</h4>
                        <p className="mb-2">
                          Publish and manage your business' menu or service offerings to keep your customers updated.
                        </p>
                      </div>
                      {/*/column */}
                      <div className="col-md-6 col-lg-3 col-sm-12">
                        <img
                          src="./landing/assets/img/computer.png"
                          className="svg-inject icon-svg icon-svg-md text-orange mb-3"
                          alt=""
                        />
                        <h4 style={{color:"#3f78e0"}}>Custom Website</h4>
                        <p className="mb-2">
                          Receive a custom website populated with all your business' information within minutes.
                        </p>
                      </div>
                      
                      {/*/column */}
                    </div>
                    {/*/.row */}
                  </div>
                  {/* /.container */}
                </div>
                {/* /.wrapper */}
              </section>
              {/* /section */}
              <section id="howitworks">
                <div className="wrapper bg-gray">
                  <div className="container py-7 py-md-7">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-7 mb-md-7 align-items-center">
                      <div className="col-lg-6 col-md-12">
                        <figure>
                          <img
                            className="w-auto"
                            src="./landing/assets/img/works.png"
                            alt=""
                          />
                        </figure>
                      </div>
                      {/*/column */}
                      <div className="col-lg-6 col-md-12">
                        <h2 className="fs-15 text-uppercase text-line text-primary mb-3">
                          How It Works?
                        </h2>
                        <h3 className="display-5 mb-7 pe-xxl-5">
                          UnstuQ Business gives you everything you need to manage and grow your business without taking a bite out of your bottom-line.
                        </h3>
                        <div className="d-flex flex-row mb-4">
                          <div>
                            <img
                              src="./landing/assets/img/icons/light-bulb.svg"
                              className="svg-inject icon-svg icon-svg-sm text-blue me-4"
                              alt=""
                            />
                          </div>
                          <div>
                            <h4 className="mb-1">Subscribe</h4>
                            <p className="mb-1">
                              Subscribe and try-out UnstuQ Business for free for 14 days.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-4">
                          <div>
                            <img
                              src="./landing/assets/img/icons/design.svg"
                              className="svg-inject icon-svg icon-svg-sm text-yellow me-4"
                              alt=""
                            />
                          </div>
                          <div>
                            <h4 className="mb-1">Configure</h4>
                            <p className="mb-1">
                              Configure your business listing by completing a list of tasks.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div>
                            <img
                              src="./landing/assets/img/icons/pie-chart-2.svg"
                              className="svg-inject icon-svg icon-svg-sm text-green me-4"
                              alt=""
                            />
                          </div>
                          <div>
                            <h4 className="mb-1">Grow</h4>
                            <p className="mb-0">
                              Monitor your business growth and performance regularly.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*/column */}
                    </div>
                    {/*/.row */}
                    {/*/.row */}
                  </div>
                  {/* /.container */}
                </div>
                {/* /.wrapper */}
              </section>
              {/* /section */}
              {/* /section */}
              <section id="contact">
                <div className="wrapper bg-light">
                  <div className="container py-7 py-md-7">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                      <div className="col-lg-5">
                        <h2 className="fs-15 text-uppercase text-line text-primary text-center mb-3">
                          Get In Touch
                        </h2>
                        <h3 className="display-5 mb-7">
                          Got any questions? Don't hesitate to get in touch.
                        </h3>
                        <div className="d-flex flex-row">
                          <div>
                            <h5 className="mb-1">Address</h5>
                            <address>
                              Saratoga Springs, UT
                            </address>
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div>
                            <h5 className="mb-1">Phone</h5>
                            <p>+1 (801) 899-9007</p>
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div>
                            <h5 className="mb-1">E-mail</h5>
                            <p className="mb-0">
                              <a
                                href="mailto:business@unstuq.com"
                                className="link-body"
                              >
                                business@unstuq.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*/column */}
                      <div className="col-lg-7">
                        <figure>
                          <img
                            className="w-auto"
                            src="./landing/assets/img/contact.png"
                            alt=""
                          />
                        </figure>
                      </div>
                      {/*/column */}
                    </div>
                    {/*/.row */}
                  </div>
                  {/* /.container */}
                </div>
                {/* /.wrapper */}
              </section>
              {/* /section */}
            </div>
            {/* /.content-wrapper */}
            <footer className="bg-dark text-inverse">
              <div className="container py-7 py-md-7">
                <div className="row gy-6 gy-lg-0">
                  <div className="col-md-12 col-lg-12">
                    <p className="mb-4">
                        © 2022 UnstuQ Business.{' '}
                        <br className="d-none d-lg-block" />
                        All rights reserved.
                      </p>
                  </div>
                </div>
              </div>
            </footer>
            <div className="progress-wrap">
              <svg
                className="progress-circle svg-content"
                width="100%"
                height="100%"
                viewBox="-1 -1 102 102"
              >
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
              </svg>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default LandingPage;
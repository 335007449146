import React from "react";
import {Redirect} from "react-router-dom";
import CONSTANTS from './misc/Constants';
import { reactLocalStorage } from 'reactjs-localstorage';

class Logout extends React.Component {
    
  render(){
      // on local domain
      // cookie.remove('access_token', { path: `${CONSTANTS.APP_URL}` });
      window.sessionStorage.clear();
      reactLocalStorage.clear();
      
      return (
          window.location.replace(`${CONSTANTS.APP_URL}/`)
          // <Redirect
          //   to={{
          //     // pathname: `${CONSTANTS.APP_URL}`
          //     pathname: '/unstuq-business'
          //   }}
          // />
      );
  }

}

export default Logout;
const Production = {
  Constants: {
      // API_BASE_URL: "https://cors-anywhere.herokuapp.com/http://api-dev.unstuq.com/api/v2",
      API_BASE_URL: "https://api.unstuq.com/api/v2",
      APP_URL: "https://business.unstuq.com",
      STRIPE_PK: "pk_live_gwtYi3mgW5Dw0UekHMKuRBt800zmuXAwmm",
      STRIPE_SK: "sk_live_88fug0XUxq80dHmzENgJ9LpC00VU8EnWJx",
  }
}

const Development = {
  Constants: {
    API_BASE_URL: "http://localhost:8000/api/v2",
    APP_URL: "http://localhost:3000",
    STRIPE_PK: "pk_test_BNVt9ckGG79TLwDFNEHs3wia00COwp2NKr",
    STRIPE_SK: "sk_test_EQAhsWpMykP32iWmidJ8WQA800ZqoEFiku",
  }
}

export default window.location.hostname.indexOf('localhost') !== -1 ? Development.Constants : Production.Constants;
import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import moment from 'moment';
import theme from '../../theme/black';
import CSVReader from 'react-csv-reader';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import SideNav from '../includes/SideNav';
import PhoneInput from 'react-phone-number-input/max';
import HeaderNav from '../includes/HeaderNav';
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Tour from "reactour";
// import {messagingTourConfig} from '../includes/tourConfig';
import Footer from '../includes/Footer';
import { BusinessPortalLoader } from '../includes/Loaders';
import { reactLocalStorage } from 'reactjs-localstorage';
import EllipsisText from 'react-ellipsis-text';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { DatePicker, Modal, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const the_data = [];

const handleForce = (data, fileInfo) => {
  //console.log(data, fileInfo);
  the_data.push(data);
};

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
};

class Messaging extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      nothingToShow: true,
      isRequesting: false,
      messageVisible: false,
      showGiftCard: false,
      loadGiftCardSwitch: false,
      deleteMessageModal: false,
      rejectionTextStatus: false,
      showSubmitButton: true,
      uploadContactsVisible: false,
      uploadContactsManuallyVisible: false,
      manualContactsUploadForm: false,
      isTourOpen: false,
      isShowingMore: false,
      subjectDisabled: false,
      textDisabled: false,
      scheduleDiabled: false,
      favoriteDisabled: false,
      matchedDisabled: false,
      purchasedDisabled: false,
      contactsDisabled: false,
      messageTitle: '',
      countryFlag: '',
      fileList: [],
      uploading: false,
      modalStatusClassName: '',
      theActive: '',
      theDisplay: '',
      modalStatus: '',
      recurringList: [],
      totalMessages: 0,
      totalMessagesDelivered: 0,
      totalMessagesPending: 0,
      totalMessagesInReview: 0,
      showMessagingDeleteButton: true,
      messagingTransactionsArray: [],
      manualCsvArray: [],
      viewContactsArray: [],
      business_details: {
        name: '',
      },
      messaging: {
        id: '',
        subject: '',
        text: '',
        deliveryDate: '',
        status: '',
        favoritedBusinessCheckbox: 0,
        matchedOnBusinessCheckbox: 0,
        purchasedGiftcardCheckbox: 0,
        inMyContactsCheckbox: 0,
        rejectionText: '',
      },
      messaging_preview: {
        subject: '',
        text: '',
        favoritedBusinessCheckbox: 0,
        matchedOnBusinessCheckbox: 0,
        purchasedGiftcardCheckbox: 0,
        inMyContactsCheckbox: 0,
      },
      manualContactsForm: {
        name: '',
        email: '',
        phone: '',
      },
      manualContactsFormPreview: {
        id: '',
        name: '',
        email: '',
        phone: '',
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getMessagingTransactions("");
      this.getCountryFlag();
      this.viewContacts("");
      // if(this.state.userInfo.messaging_tour === 0){
      //   this.openTour();
      // }
    }
  }

  getCountryFlag = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        this.setState({
          countryFlag: data.country_code,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // disableBody = target => disableBodyScroll(target);
  // enableBody = target => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //   .post(
  //     CONSTANTS.API_BASE_URL + '/business/tour/update',
  //     { column_name: "messaging_tour" },
  //     AuthService.getAxiosHeaders(
  //       window.sessionStorage.getItem('new_business')
  //     )
  //   )
  //   .then((response) => {
  //     let result = response.data;
  //     reactLocalStorage.remove(
  //       'user_object'
  //     );
  //     reactLocalStorage.setObject(
  //       'user_object',
  //       JSON.stringify(result.data.user_details)
  //     );
  //   })
  //   .catch((error) => {
  //   });
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  getMuiTheme = () => createTheme({
    components: {
        MUIDataTableHeadCell: {
          styleOverrides:{
          fixedHeader: {
            zIndex: "0"
          }
        }
        },
        MUIDataTableSelectCell: {
          styleOverrides:{
          headerCell: {
            zIndex: "0"
          }
        }
        },
    }
})

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  handleCsvUpload = () => {
    this.setState({
      uploading: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/upload/csv',
        { file: JSON.stringify(the_data) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.viewContacts("csv add");
        // AuthService.Prompts('success', 'Uploaded Successfully');
      })
      .catch((error) => {
        this.setState({
          uploading: false,
          //   isRequesting: false,
          //   businessTagsModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  handleManualContactsForm = (event, name) => {
    if (name.toString() === 'name') {
      let value = event.target.value;
      let old_manual_contacts_form = this.state.manualContactsForm;
      old_manual_contacts_form.name = value;
      this.setState({
        manualContactsForm: old_manual_contacts_form,
      });
    }
    if (name.toString() === 'email') {
      let value = event.target.value;
      let old_manual_contacts_form = this.state.manualContactsForm;
      old_manual_contacts_form.email = value;
      this.setState({
        manualContactsForm: old_manual_contacts_form,
      });
    }
    if (name.toString() === 'phone') {
      let value = event;
      let old_manual_contacts_form = this.state.manualContactsForm;
      old_manual_contacts_form.phone = value;
      this.setState({
        manualContactsForm: old_manual_contacts_form,
      });
    }
  };

  getMessagingTransactions = (request) => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/messages',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let messaging_transactions = result.data;
        let UsersArray = [];

        let sumTotalMessagesDelivered = 0;
        let sumTotalMessagesPending = 0;
        let sumTotalMessagesInReview = 0;

        for (let i = 0; i < messaging_transactions.length; i++) {
          let the_id = messaging_transactions[i].id;
          let created_at = moment(messaging_transactions[i].created_at).format(
            'll'
          );
          let favorited_business_group =
            messaging_transactions[i].favorited_business_group;
          let matched_on_business_group =
            messaging_transactions[i].matched_on_business_group;
          let purchased_giftcard_group =
            messaging_transactions[i].purchased_giftcard_group;
            let in_my_contacts_group =
            messaging_transactions[i].in_my_contacts_group;
          let delivery_date =
            messaging_transactions[i].delivery_date != ''
              ? moment(messaging_transactions[i].delivery_date).format('ll')
              : '';
          let status = messaging_transactions[i].status;
          let subject = messaging_transactions[i].subject;
          let text = messaging_transactions[i].text;
          let rejection_text = messaging_transactions[i].rejection_text;

          if (messaging_transactions[i].status == 'delivered') {
            sumTotalMessagesDelivered++;
            this.setState({
              showSubmitButton: false,
            });
          }

          messaging_transactions[i].status == 'delivered' &&
            sumTotalMessagesDelivered++;
          messaging_transactions[i].status == 'pending' &&
            sumTotalMessagesPending++;
          messaging_transactions[i].status == 'in review' &&
            sumTotalMessagesInReview++;

          let UserArray = [];
          UserArray.push(created_at);
          UserArray.push(favorited_business_group);
          UserArray.push(matched_on_business_group);
          UserArray.push(purchased_giftcard_group);
          UserArray.push(delivery_date);
          UserArray.push(status);
          UserArray.push(subject);
          UserArray.push(text);
          UserArray.push(the_id);
          UserArray.push(rejection_text);
          UserArray.push(in_my_contacts_group);

          UsersArray.push(UserArray);
        }

        if(request == "add"){
          this.setState({
            messagingTransactionsArray: UsersArray,
            totalMessages: messaging_transactions.length,
            totalMessagesDelivered: sumTotalMessagesDelivered,
            totalMessagesPending: sumTotalMessagesPending,
            totalMessagesInReview: sumTotalMessagesInReview,
            isRequesting: false,
            messageVisible: false,
          });
          AuthService.Prompts('success', 'Updated Successfully');
        }
        if(request == "delete"){
          this.setState({
            messagingTransactionsArray: UsersArray,
            totalMessages: messaging_transactions.length,
            totalMessagesDelivered: sumTotalMessagesDelivered,
            totalMessagesPending: sumTotalMessagesPending,
            totalMessagesInReview: sumTotalMessagesInReview,
            isRequesting: false,
            deleteMessageModal: false,
          });
          AuthService.Prompts('success', 'Deleted Successfully');
        }

        this.setState({
          ...this.state,
          messagingTransactionsArray: UsersArray,
          totalMessages: messaging_transactions.length,
          totalMessagesDelivered: sumTotalMessagesDelivered,
          totalMessagesPending: sumTotalMessagesPending,
          totalMessagesInReview: sumTotalMessagesInReview,
          isLoading: false,
          // isRequesting: false,
          // messageVisible: false,
          // deleteMessageModal: false,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  addMessage = () => {
    let old_messaging = this.state.messaging;
    old_messaging.id = '';
    old_messaging.subject = '';
    old_messaging.text = '';
    old_messaging.deliveryDate = ''
    old_messaging.rejectionText = ''
    old_messaging.status = '';
    old_messaging.favoritedBusinessCheckbox = 0;
    old_messaging.matchedOnBusinessCheckbox = 0;
    old_messaging.purchasedGiftcardCheckbox = 0;
    old_messaging.inMyContactsCheckbox = 0;
    this.setState({
      messaging: old_messaging,
      rejectionTextStatus: false,
      messageVisible: true,
      messageTitle: 'Add Message',
      showSubmitButton: true,
      modalStatus: false,
      subjectDisabled: false,
      textDisabled: false,
      scheduleDiabled: false,
      favoriteDisabled: false,
      matchedDisabled: false,
      purchasedDisabled: false,
      contactsDisabled: false,
    });
  };

  deleteMessaging = (the_id, subject) => {
    let old_messaging = this.state.messaging;
    old_messaging.id = the_id;
    old_messaging.subject = subject;
    this.setState({
      messaging: old_messaging,
      deleteMessageModal: true,
    });
  };

  addManualContact = () => {
    this.setState({
      isRequesting: true,
    });

    // get manualContactsForm object
    // push content of manualContactsForm into manualCsvData
    // render manualCsvData data into datatable

    ////  or ////
    // submit data to DB and fetch data from DB everytime a new user is added
  };

  viewContacts = (request) => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/contacts',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let contacts = result.data;
        let UsersArray = [];

        for (let i = 0; i < contacts.length; i++) {
          let the_id = contacts[i].id;
          let name = contacts[i].name;
          let email = contacts[i].email;
          let phone = contacts[i].phone;

          let UserArray = [];
          UserArray.push(the_id);
          UserArray.push(name);
          UserArray.push(email);
          UserArray.push(phone);

          UsersArray.push(UserArray);
        }
        if(request == "manual add"){
          this.setState({
            incentivesArray: UsersArray,
            isRequesting: false,
            uploading: false,
            manualContactsUploadForm: false,
          });
          AuthService.Prompts('success', 'Updated Successfully');
        }
        if(request == "csv add"){
          this.setState({
            incentivesArray: UsersArray,
            isRequesting: false,
            uploading: false,
            uploadContactsVisible: false,
          });
          AuthService.Prompts('success', 'Updated Successfully');
        }
        if(request == "delete contact"){
          this.setState({
            incentivesArray: UsersArray,
            isRequesting: false,
            deleteContactModal: false,
          });
          AuthService.Prompts('success', 'Deleted Successfully');
        }

        this.setState({
          ...this.state,
          viewContactsArray: UsersArray,
          manualCsvArray: UsersArray,
          // isRequesting: false,
          // uploading: false,
          // uploadContactsVisible: false,
          // manualContactsUploadForm: false,
          // deleteContactModal: false,
        });
        // AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        //console.log('error');
        //console.log(error);
        // AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  submitManualContactsForm = (e) => {
    this.setState({
      isRequesting: true,
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/add/manual/contact',
        { manual_contact: JSON.stringify(this.state.manualContactsForm) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.viewContacts("manual add");
        // AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  deleteContact = (e) => {
    this.setState({
      isRequesting: true,
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/delete/contact',
        { contact_id: JSON.stringify(this.state.manualContactsFormPreview.id) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.viewContacts("delete contact");
        // AuthService.Prompts('success', 'Deleted Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  deleteMessageRequest = (e) => {
    this.setState({
      isRequesting: true,
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/delete/message',
        { messaging: JSON.stringify(this.state.messaging) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.getMessagingTransactions("delete");
        // AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          deleteMessageModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  scheduleMeeting = (date, dateString) => {
    let old_messaging = this.state.messaging;
    old_messaging.deliveryDate = dateString;
    this.setState({
      messaging: old_messaging,
    });
  };

  onChangeFavoritedBusiness = (e, group) => {
    // if(this.state.favoriteDisabled == true){
    //   AuthService.Prompts('error', 'You can only target one catrgory of customers per message');
    //   return;
    // }
    if (e.target.checked && group == 'favoritedBusiness') {
      let old_messaging = this.state.messaging;
      old_messaging.favoritedBusinessCheckbox = 1;
      this.setState({
        messaging: old_messaging,
        favoritedBusinessCheckbox: 1,
        purchasedDisabled: true,
        matchedDisabled: true,
      });
    } else {
      if (group == 'favoritedBusiness') {
        let old_messaging = this.state.messaging;
        old_messaging.favoritedBusinessCheckbox = 0;
        this.setState({
          messaging: old_messaging,
          favoritedBusinessCheckbox: 0,
          purchasedDisabled: false,
          matchedDisabled: false,
        });
      }
    }
  };

  onChangeMatchedOnBusiness = (e, group) => {
    if (e.target.checked && group == 'matchedOnBusiness') {
      let old_messaging = this.state.messaging;
      old_messaging.matchedOnBusinessCheckbox = 1;
      this.setState({
        messaging: old_messaging,
        matchedOnBusinessCheckbox: 1,
        purchasedDisabled: true,
        favoriteDisabled: true,
      });
    } else {
      if (group == 'matchedOnBusiness') {
        let old_messaging = this.state.messaging;
        old_messaging.matchedOnBusinessCheckbox = 0;
        this.setState({
          messaging: old_messaging,
          matchedOnBusinessCheckbox: 0,
          purchasedDisabled: false,
          favoriteDisabled: false,
        });
      }
    }
  };

  onChangePurchasedGiftcard = (e, group) => {
    if (e.target.checked && group == 'purchasedGiftcard') {
      let old_messaging = this.state.messaging;
      old_messaging.purchasedGiftcardCheckbox = 1;
      this.setState({
        messaging: old_messaging,
        purchasedGiftcardCheckbox: 1,
        matchedDisabled: true,
        favoriteDisabled: true,
      });
    } else {
      if (group == 'purchasedGiftcard') {
        let old_messaging = this.state.messaging;
        old_messaging.purchasedGiftcardCheckbox = 0;
        this.setState({
          messaging: old_messaging,
          purchasedGiftcardCheckbox: 0,
          matchedDisabled: false,
          favoriteDisabled: false,
        });
      }
    }
  };

  onChangeInMyContacts = (e, group) => {
    if (e.target.checked && group == 'inMyContacts') {
      let old_messaging = this.state.messaging;
      old_messaging.inMyContactsCheckbox = 1;
      this.setState({
        messaging: old_messaging,
        inMyContactsCheckbox: 1,
      });
    } else {
      if (group == 'inMyContacts') {
        let old_messaging = this.state.messaging;
        old_messaging.inMyContactsCheckbox = 0;
        this.setState({
          messaging: old_messaging,
          inMyContactsCheckbox: 0
        });
      }
    }
  };

  handleAddMessaging = (event, name) => {
    if (name.toString() === 'subject') {
      let old_messaging = this.state.messaging;
      old_messaging.subject = event.target.value;
      this.setState({
        messaging: old_messaging,
      });
    }
    if (name.toString() === 'text') {
      let old_messaging = this.state.messaging;
      old_messaging.text = event.target.value;
      this.setState({
        messaging: old_messaging,
      });
    }
  };

  manageMessaging = (
    created_at,
    favorited_business_group,
    matched_on_business_group,
    purchased_giftcard_group,
    in_my_contacts_group,
    delivery_date,
    status,
    subject,
    text,
    the_id,
    rejection_text
  ) => {
    if (
      rejection_text == '' ||
      rejection_text === undefined ||
      rejection_text === null
    ) {
      let old_messaging = this.state.messaging;
      old_messaging.rejectionText = '';
      this.setState({
        messaging: old_messaging,
        rejectionTextStatus: false,
      });
    } else {
      this.setState({
        rejectionTextStatus: true,
      });
    }

    if (status == 'delivered') {
      this.setState({
        showSubmitButton: false,
        modalStatusClassName: 'alert alert-success',
        modalStatus: true,
        subjectDisabled: false,
        textDisabled: false,
        scheduleDiabled: false,
        favoriteDisabled: false,
        matchedDisabled: false,
        purchasedDisabled: false,
        contactsDisabled: false,
      });
    } else if (status == 'in review') {
      this.setState({
        showSubmitButton: false,
        modalStatusClassName: 'alert alert-warning',
        modalStatus: true,
        subjectDisabled: true,
        textDisabled: true,
        scheduleDiabled: true,
        favoriteDisabled: true,
        matchedDisabled: true,
        purchasedDisabled: true,
        contactsDisabled: true,
      });
    } else if (status == 'pending') {
      this.setState({
        showSubmitButton: false,
        modalStatusClassName: 'alert alert-primary',
        modalStatus: true,
        subjectDisabled: true,
        textDisabled: true,
        scheduleDiabled: true,
        favoriteDisabled: true,
        matchedDisabled: true,
        purchasedDisabled: true,
        contactsDisabled: true,
      });
    } else {
      this.setState({
        showSubmitButton: true,
        modalStatus: false,
        subjectDisabled: false,
        textDisabled: false,
        scheduleDiabled: false,
        favoriteDisabled: false,
        matchedDisabled: false,
        purchasedDisabled: false,
        contactsDisabled: false,
        // modalStatusClassName: "alert alert-danger"
      });
    }

    // let old_messaging = this.state.messaging_preview;
    // old_messaging.subject = subject;
    // old_messaging.text = text;
    // old_messaging.favoritedBusinessCheckbox = favorited_business_group;
    // old_messaging.matchedOnBusinessCheckbox = matched_on_business_group;
    // old_messaging.purchasedGiftcardCheckbox = purchased_giftcard_group;

    let old_messaging = this.state.messaging;
    old_messaging.id = the_id;
    old_messaging.subject = subject;
    old_messaging.text = text;
    old_messaging.status = status;
    old_messaging.deliveryDate = delivery_date;
    old_messaging.rejectionText = rejection_text;
    old_messaging.favoritedBusinessCheckbox = favorited_business_group;
    old_messaging.matchedOnBusinessCheckbox = matched_on_business_group;
    old_messaging.purchasedGiftcardCheckbox = purchased_giftcard_group;
    old_messaging.inMyContactsCheckbox = in_my_contacts_group;
    this.setState({
      messaging: old_messaging,
      messageVisible: true,
      messageTitle: 'Manage Message',
    });
  };

  submitMessageForm = (e) => {
    e.preventDefault();
    this.setState({
      isRequesting: true,
    });

    //console.log(this.state.messaging);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/add/message',
        { messaging: JSON.stringify(this.state.messaging) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.getMessagingTransactions("add");
        // AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        let result = error.response;
        this.setState({
          isRequesting: false,
          messageVisible: false,
        });
        AuthService.Prompts('error', result.data.message);
      });
  };

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();

    fileList.forEach((file) => {
      //console.log(file);
      formData.append('files[]', file);
    });

    this.setState({
      uploading: true,
    });

    //console.log(formData);
    //console.log(this.state.fileList[0]);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/upload/csv',
        { file: formData },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.setState({
          uploading: false,
        });
        AuthService.Prompts('success', 'Updated Successfully');
        this.getIncentives();
      })
      .catch((error) => {
        this.setState({
          uploading: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });

    // ends here

    // You can use any AJAX library you like
    // fetch('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
    //   method: 'POST',
    //   body: formData,
    // })
    //   .then(res => res.json())
    //   .then(() => {
    //     this.setState({
    //       fileList: [],
    //     });
    //     message.success('upload successfully.');
    //   })
    //   .catch(() => {
    //     message.error('upload failed.');
    //   })
    //   .finally(() => {
    //     this.setState({
    //       uploading: false,
    //     });
    //   });
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";

    const { uploading, fileList } = this.state;
    // const props = {
    //   onRemove: file => {
    //     this.setState(state => {
    //       const index = state.fileList.indexOf(file);
    //       const newFileList = state.fileList.slice();
    //       newFileList.splice(index, 1);
    //       return {
    //         fileList: newFileList,
    //       };
    //     });
    //   },
    //   beforeUpload: file => {
    //     this.setState(state => ({
    //       fileList: [...state.fileList, file],
    //     }));
    //     return false;
    //   },
    //   fileList,
    // };

    const data = this.state.messagingTransactionsArray;
    const manualCsvData = this.state.manualCsvArray;
    const viewContactsData = this.state.viewContactsArray;

    const columns = [
      {
        // label: "Name",
        name: 'Date Created',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][0];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Messaging Group',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let favorited_business_group = data[dataIndex][1];
            let matched_on_business_group = data[dataIndex][2];
            let purchased_giftcard_group = data[dataIndex][3];
            let in_my_contacts_group = data[dataIndex][10];
            // if (value == '' || value === undefined || value === null) {
            //   return 'N/A';
            // } else {
              if (
                favorited_business_group === 1 &&
                matched_on_business_group === 1 &&
                purchased_giftcard_group === 1 &&
                in_my_contacts_group === 1
              ) {
                return (
                  <div className="row">
                    <li style={{ padding: '0px' }} className="preview-item">
                      <span
                        style={{ fontWeight: '700' }}
                        className="badge badge-success"
                      >
                        Favorite
                      </span>
                    </li>
                    <li
                      style={{ padding: '0px', marginLeft: '4px' }}
                      className="preview-item"
                    >
                      <span
                        style={{ fontWeight: '700' }}
                        className="badge badge-success"
                      >
                        Matched
                      </span>
                    </li>
                    <li
                      style={{ padding: '0px', marginLeft: '4px' }}
                      className="preview-item"
                    >
                      <span
                        style={{ fontWeight: '700' }}
                        className="badge badge-success"
                      >
                        Gift Card
                      </span>
                    </li>
                    <li
                      style={{ padding: '0px', marginLeft: '4px' }}
                      className="preview-item"
                    >
                      <span
                        style={{ fontWeight: '700' }}
                        className="badge badge-success"
                      >
                        Contacts
                      </span>
                    </li>
                  </div>
                );
              }
            else if (
              favorited_business_group === 1 &&
              matched_on_business_group === 1 &&
              purchased_giftcard_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Favorite
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Matched
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Gift Card
                    </span>
                  </li>
                </div>
              );
            } else if (
              favorited_business_group === 1 &&
              matched_on_business_group === 1 &&
              in_my_contacts_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Favorite
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Matched
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Contacts
                    </span>
                  </li>
                </div>
              );
            } else if (
              favorited_business_group === 1 &&
              purchased_giftcard_group === 1 &&
              in_my_contacts_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Favorite
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Contacts
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Gift Card
                    </span>
                  </li>
                </div>
              );
            } else if (
              matched_on_business_group === 1 &&
              purchased_giftcard_group === 1 &&
              in_my_contacts_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Contacts
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Matched
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Gift Card
                    </span>
                  </li>
                </div>
              );
            } else if (
              favorited_business_group === 1 &&
              matched_on_business_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Favorite
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Matched
                    </span>
                  </li>
                </div>
              );
            } else if (
              favorited_business_group === 1 &&
              purchased_giftcard_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Favorite
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Gift Card
                    </span>
                  </li>
                </div>
              );
            } else if (
              matched_on_business_group === 1 &&
              purchased_giftcard_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Matched
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Gift Card
                    </span>
                  </li>
                </div>
              );
            } else if (
              in_my_contacts_group === 1 &&
              favorited_business_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Contacts
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Favorite
                    </span>
                  </li>
                </div>
              );
            } else if (
              in_my_contacts_group === 1 &&
              matched_on_business_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Matched
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Contacts
                    </span>
                  </li>
                </div>
              );
            } else if (
              in_my_contacts_group === 1 &&
              purchased_giftcard_group === 1
            ) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Contacts
                    </span>
                  </li>
                  <li
                    style={{ padding: '0px', marginLeft: '4px' }}
                    className="preview-item"
                  >
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Gift Card
                    </span>
                  </li>
                </div>
              );
            } else if (favorited_business_group === 1) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Favorite
                    </span>
                  </li>
                </div>
              );
            } else if (matched_on_business_group === 1) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Matched
                    </span>
                  </li>
                </div>
              );
            } else if (purchased_giftcard_group === 1) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Gift Card
                    </span>
                  </li>
                </div>
              );
            }
            else if (in_my_contacts_group === 1) {
              return (
                <div className="row">
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Contacts
                    </span>
                  </li>
                </div>
              );
            }
            // }
          },
        },
      },
      {
        name: 'Delivery Date',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][4];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Status',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][5];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              if (value == 'in review') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                      <span style={{ fontWeight: '700' }} className="dot bg-warning d-mb-none" />
                      <span style={{ fontWeight: '700' }} className="badge badge-sm badge-dot has-bg badge-warning d-none d-mb-inline-flex">
                        In Review
                      </span>
                  </li>
                );
              } else if (value == 'pending') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span style={{ fontWeight: '700' }} className="dot bg-primary d-mb-none" />
                      <span style={{ fontWeight: '700' }} className="badge badge-sm badge-dot has-bg badge-primary d-none d-mb-inline-flex">
                        Pending
                      </span>
                  </li>
                );
              } else if (value == 'delivered') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span style={{ fontWeight: '700' }} className="dot bg-success d-mb-none" />
                      <span style={{ fontWeight: '700' }} className="badge badge-sm badge-dot has-bg badge-success d-none d-mb-inline-flex">
                        Delivered
                      </span>
                  </li>
                );
              } else {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span style={{ fontWeight: '700' }} className="dot bg-danger d-mb-none" />
                      <span style={{ fontWeight: '700' }} className="badge badge-sm badge-dot has-bg badge-danger d-none d-mb-inline-flex">
                      Rejected
                      </span>
                  </li>
                );
              }
            }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let created_at = data[dataIndex][0];
            let favorited_business_group = data[dataIndex][1];
            let matched_on_business_group = data[dataIndex][2];
            let purchased_giftcard_group = data[dataIndex][3];
            let delivery_date = data[dataIndex][4];
            let status = data[dataIndex][5];
            let subject = data[dataIndex][6];
            let text = data[dataIndex][7];
            let the_id = data[dataIndex][8];
            let rejection_text = data[dataIndex][9];
            let in_my_contacts_group = data[dataIndex][10];

            if (status == 'delivered') {
              return (
                <div>
                  <button
                    onClick={() => {
                      this.manageMessaging(
                        created_at,
                        favorited_business_group,
                        matched_on_business_group,
                        purchased_giftcard_group,
                        in_my_contacts_group,
                        delivery_date,
                        status,
                        subject,
                        text,
                        the_id,
                        rejection_text,
                      );
                    }}
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: '10px' }}
                  >
                    Manage
                  </button>
                </div>
              );
            } else {
              return (
                <div>
                  <button
                    onClick={() => {
                      this.manageMessaging(
                        created_at,
                        favorited_business_group,
                        matched_on_business_group,
                        purchased_giftcard_group,
                        in_my_contacts_group,
                        delivery_date,
                        status,
                        subject,
                        text,
                        the_id,
                        rejection_text
                      );
                    }}
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: '10px' }}
                  >
                    Manage
                  </button>
                  <button
                    onClick={() => {
                      this.deleteMessaging(the_id, subject);
                    }}
                    className="btn btn-danger btn-sm"
                  >
                    Delete
                  </button>
                </div>
              );
            }
          },
        },
      },
    ];

    const manualCsvColumns = [
      {
        name: 'Name',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = manualCsvData[dataIndex][1];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Email',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = manualCsvData[dataIndex][2];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Phone',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = manualCsvData[dataIndex][3];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let the_id = manualCsvData[dataIndex][0];
            let name = manualCsvData[dataIndex][1];
            let email = manualCsvData[dataIndex][2];
            let phone = manualCsvData[dataIndex][3];
            return (
              <div>
                <button
                  onClick={() => {
                    // this.deleteContact(
                    //   the_id,
                    // );
                    let old_contacts_form =
                      this.state.manualContactsFormPreview;
                    old_contacts_form.id = the_id;
                    old_contacts_form.name = name;
                    old_contacts_form.email = email;
                    old_contacts_form.phone = phone;
                    this.setState({
                      manualContactsFormPreview: old_contacts_form,
                      deleteContactModal: true,
                    });
                  }}
                  className="btn btn-danger btn-sm"
                  style={{ marginRight: '10px' }}
                >
                  Delete
                </button>
              </div>
            );
          },
        },
      },
    ];

    const viewContactsColumns = [
      {
        name: 'Name',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = viewContactsData[dataIndex][1];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Email',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = viewContactsData[dataIndex][2];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Phone',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = viewContactsData[dataIndex][3];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let the_id = viewContactsData[dataIndex][0];
            let name = viewContactsData[dataIndex][1];
            let email = viewContactsData[dataIndex][2];
            let phone = viewContactsData[dataIndex][3];
            return (
              <div>
                <button
                  onClick={() => {
                    // this.deleteContact(
                    //   the_id,
                    // );
                    let old_contacts_form =
                      this.state.manualContactsFormPreview;
                    old_contacts_form.id = the_id;
                    old_contacts_form.name = name;
                    old_contacts_form.email = email;
                    old_contacts_form.phone = phone;
                    this.setState({
                      manualContactsFormPreview: old_contacts_form,
                      deleteContactModal: true,
                    });
                  }}
                  className="btn btn-danger btn-sm"
                  style={{ marginRight: '10px' }}
                >
                  Delete
                </button>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      filter: false,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
            noMatch: "No messages have been created for this business"
        },
      },
    };

    const manualCsvOptions = {
      filterType: 'dropdown',
      responsive: 'standard',
      filter: false,
      print: false,
      download: false,
      search: false,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
          //   noMatch: this.props.isLoading ?
          //   'Sorry, there is no matching data to display' :
          //   <Loader />,
        },
      },
    };

    const viewContactsOptions = {
      filterType: 'dropdown',
      responsive: 'standard',
      filter: false,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
          //   noMatch: this.props.isLoading ?
          //   'Sorry, there is no matching data to display' :
          //   <Loader />,
        },
      },
    };
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
            <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="row g-gs">
                              <div className="col-md-12">

                                <div className="nk-block-head nk-block-head-sm mb-2">
                                  <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                      <h4
                                        className="nk-block-title page-title"
                                        style={{ color: '#364a63' }}
                                      >
                                        {this.state.businessInfo.name.toString()}
                                      </h4>
                                    </div>
                                    <div className="nk-block-head-content">
                                      <div className="toggle-wrap nk-block-tools-toggle">
                                        <a
                                          href="#"
                                          className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                          data-target="pageMenu"
                                        >
                                          <em
                                            onClick={() => {
                                              this.pageMenu();
                                            }}
                                            className="icon ni ni-more-v"
                                          />
                                        </a>
                                        <div
                                          className="toggle-expand-content"
                                          data-content="pageMenu"
                                          style={{
                                            display: `${this.state.theDisplay}`,
                                          }}
                                        >
                                          <ul className="nk-block-tools g-3">
                                            {/* <li>
                                            <a
                                                href="#"
                                                className="btn btn-primary"
                                              >
                                                <span>View Contacts</span>
                                              </a>
                                            </li> */}
                                            <li data-tut="reactour__addMessageButton">
                                              <button
                                                type="submit"
                                                className="btn btn-md btn-primary btn-block"
                                                onClick={() => {
                                                  this.addMessage();
                                                }}
                                              >
                                                <span>Add Message</span>
                                              </button>
                                              {/* <a
                                                href="#"
                                                className="btn btn-primary"
                                              >
                                                <span>View Contacts</span>
                                              </a> */}
                                            </li>
                                            {/* <li
                                            data-tut="reactour__addContactsButton"
                                            >
                                              <button
                                                type="submit"
                                                className="btn btn-md btn-primary btn-block"
                                                onClick={() => {
                                                  this.setState({
                                                    viewContactsVisible: true,
                                                  });
                                                }}
                                              >
                                                <span>Add/View Contacts</span>
                                              </button>
                                            </li> */}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div data-tut="reactour__messagingDataTable">
                            <div className="row mb-3">
                              <div className="col-xxl-3 col-sm-3">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">
                                            Total Messages Created
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {this.state.totalMessages}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xxl-3 col-sm-3">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">Delivered</h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {this.state.totalMessagesDelivered}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xxl-3 col-sm-3">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">
                                            Pending Delivery
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {this.state.totalMessagesPending}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xxl-3 col-sm-3">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">In Review</h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {this.state.totalMessagesInReview}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <MUIDataTable
                              title={'Messaging'}
                              data={data}
                              columns={columns}
                              options={options}
                            /> */}

                                <ThemeProvider theme={this.getMuiTheme()}>
                                  <MUIDataTable title={'Messaging'} data={data} columns={columns} options={options} />
                                </ThemeProvider>
                            </div>

                            <Modal
                              visible={this.state.messageVisible}
                              title={this.state.messageTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  messageVisible: false,
                                });
                              }}
                            >
                              <form onSubmit={this.submitMessageForm}>
                                {this.state.rejectionTextStatus && (
                                  <div className="col-md-12 mb-2">
                                    <div className="form-group">
                                      <div className="example-alert">
                                        <div className="alert alert-danger alert-icon">
                                          <em className="icon ni ni-cross-circle" />{' '}
                                          <strong>Review Failed</strong>!{' '}
                                          {' ' +
                                            this.state.messaging.rejectionText}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {this.state.modalStatus && (
                                  <div className="col-md-12 mb-2">
                                    <div className="form-group">
                                      <div className="example-alert">
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={
                                            this.state.modalStatusClassName
                                          }
                                        >
                                          {/* <em className="icon ni ni-cross-circle" />{' '} */}
                                          <strong>Message Status: </strong>{' '}
                                          {' ' +
                                            this.state.messaging.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            this.state.messaging.status.slice(
                                              1
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="fv-phone"
                                    >
                                      Subject
                                      {/* { 'ID: ' + this.state.messaging.id} */}
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <input
                                          className="form-control form-control-md"
                                          placeholder="Subject"
                                          spellCheck="false"
                                          required
                                          disabled={this.state.subjectDisabled}
                                          value={this.state.messaging.subject}
                                          onChange={(e) => {
                                            this.handleAddMessaging(
                                              e,
                                              'subject'
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="fv-phone"
                                    >
                                      Text
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <textarea
                                          className="form-control form-control-md"
                                          placeholder="Text"
                                          spellCheck="false"
                                          required
                                          disabled={this.state.textDisabled}
                                          rows="2"
                                          style={{
                                            fontSize: '14px',
                                            minHeight: 'auto',
                                          }}
                                          value={this.state.messaging.text}
                                          onChange={(e) => {
                                            this.handleAddMessaging(e, 'text');
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-2">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="fv-phone"
                                    >
                                      Schedule message for
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <DatePicker
                                          disabled={this.state.scheduleDiabled}
                                          onChange={this.scheduleMeeting}
                                          disabledDate={(current) => {
                                            return (
                                              moment().add(0, 'days') >=
                                                current ||
                                              moment().add(1, 'month') <=
                                                current
                                            );
                                          }}
                                          value={
                                            this.state.messaging.deliveryDate !=
                                              '' &&
                                            moment(
                                              this.state.messaging.deliveryDate
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-2">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="fv-phone"
                                    >
                                      I am targetting consumers who:
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <div className="preview-block">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="favoritedBusiness"
                                              disabled={this.state.favoriteDisabled}
                                              checked={
                                                this.state.messaging
                                                  .favoritedBusinessCheckbox ===
                                                  1 ? true : false
                                              }
                                              onChange={(e) => {
                                                this.onChangeFavoritedBusiness(
                                                  e,
                                                  'favoritedBusiness'
                                                );
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="favoritedBusiness"
                                            >
                                              Added my business to their
                                              favorite
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-2">
                                  <div className="form-group">
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <div className="preview-block">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="matchedOnBusiness"
                                              disabled={this.state.matchedDisabled}
                                              checked={
                                                this.state.messaging
                                                  .matchedOnBusinessCheckbox ===
                                                  1 ? true : false
                                              }
                                              onChange={(e) => {
                                                this.onChangeMatchedOnBusiness(
                                                  e,
                                                  'matchedOnBusiness'
                                                );
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="matchedOnBusiness"
                                            >
                                              Matched on my business
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-2">
                                  <div className="form-group">
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <div className="preview-block">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="purchasedGiftcard"
                                              disabled={this.state.purchasedDisabled}
                                              checked={
                                                this.state.messaging
                                                  .purchasedGiftcardCheckbox ===
                                                  1 ? true : false
                                              }
                                              onChange={(e) => {
                                                this.onChangePurchasedGiftcard(
                                                  e,
                                                  'purchasedGiftcard'
                                                );
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="purchasedGiftcard"
                                            >
                                              Purchased a gift card
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="col-md-12 mt-2">
                                  <div className="form-group">
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <div className="preview-block">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="inMyContacts"
                                              disabled={this.state.contactsDisabled}
                                              checked={
                                                this.state.messaging
                                                  .inMyContactsCheckbox ===
                                                  1 ? true : false
                                              }
                                              onChange={(e) => {
                                                this.onChangeInMyContacts(
                                                  e,
                                                  'inMyContacts'
                                                );
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="inMyContacts"
                                            >
                                              People in my contacts
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                {this.state.showSubmitButton === true && (
                                  <div className="mt-4">
                                    {!this.state.isRequesting ? (
                                      <button
                                        type="submit"
                                        className="btn btn-lg btn-primary btn-block"
                                      >
                                        <span>Submit</span>
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        disabled
                                        className="btn btn-lg btn-primary btn-block"
                                      >
                                        <div
                                          className="spinner-border"
                                          role="status"
                                          style={{ margin: '-6px' }}
                                        >
                                          {' '}
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                )}
                              </form>
                            </Modal>

                            <Modal
                              title="Delete Message"
                              visible={this.state.deleteMessageModal}
                              footer={null}
                              // onOk={() => {
                              //   this.deleteMessageRequest();
                              // }}
                              // confirmLoading={confirmLoading}
                              onCancel={() => {
                                this.setState({
                                  deleteMessageModal: false,
                                });
                              }}
                            >

                              <form onSubmit={this.deleteMessageRequest}>
                              <p>
                                Delete{' '}
                                {`${'"' + this.state.messaging.subject + '"'}`}{' '}
                                Message
                              </p>

                              <div style={{ marginTop: '12px' }}>
                                  {!this.state.isRequesting ? (
                                    <button
                                      type="submit"
                                      className="btn btn-md btn-primary btn-block"
                                    >
                                      <span>Submit</span>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="btn btn-lg btn-primary btn-block"
                                    >
                                      <div
                                        className="spinner-border"
                                        role="status"
                                        style={{ margin: '-6px' }}
                                      >
                                        {' '}
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </form>
                            </Modal>

                            {/* upload contacts modal start */}

                            <Modal
                              visible={this.state.uploadContactsVisible}
                              title={'Add Contacts'}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  uploadContactsVisible: false,
                                });
                              }}
                            >
                              {/* <form onSubmit={this.submitMessageForm} enctype="multipart/form-data"> */}

                              <div className="col-md-12">
                                  <label
                                    className="form-label"
                                    htmlFor="fv-phone"
                                  >
                                    Expected Columns for CVS file: <span style={{color: "red", fontWeight: "800"}}>name • email • phone</span>
                                  </label>
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="fv-phone"
                                  >
                                    CSV Upload
                                  </label>

                                  {/* <CSVReader
                                    cssClass="react-csv-input"
                                    label="Select CSV with secret Death Star statistics"
                                    onFileLoaded={handleForce}
                                    parserOptions={papaparseOptions}
                                  /> */}

                                  <div className="form-control-wrap">
                                    <div className="row g-gs">
                                      <div className="col-md-6">
                                        {/* <Upload {...props}>
                                          <Button icon={<UploadOutlined />}>
                                            Select File
                                          </Button>
                                        </Upload> */}

                                        <CSVReader
                                          cssClass="react-csv-input"
                                          // label="Select CSV with secret Death Star statistics"
                                          onFileLoaded={handleForce}
                                          parserOptions={papaparseOptions}
                                        />
                                      </div>
                                      <div
                                        className="col-md-6 text-right"
                                        style={{ marginTop: '-15px' }}
                                      >
                                        <Button
                                          type="primary"
                                          onClick={this.handleCsvUpload}
                                          // disabled={the_data.length === 0}
                                          loading={uploading}
                                          style={{ marginTop: 16 }}
                                        >
                                          {uploading
                                            ? 'Uploading'
                                            : 'Start Upload'}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="mt-4">
                                      <button
                                        type="submit"
                                        className="btn btn-lg btn-primary btn-block"
                                      >
                                        <span>CSV Upload</span>
                                      </button>
                                  </div> */}

                              {/* <div className="mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary btn-block"
                                  onClick={() => {
                                    this.setState({
                                      uploadContactsManuallyVisible: true,
                                      uploadContactsVisible: false,
                                    });
                                  }}
                                >
                                  <span>Manual Upload</span>
                                </button>
                              </div> */}

                              {/* </form> */}
                            </Modal>

                            {/* upload contacts modal end */}

                            {/* upload manual contacts modal start */}

                            <Modal
                              visible={this.state.uploadContactsManuallyVisible}
                              title={'Add Contacts'}
                              footer={null}
                              width={1000}
                              onCancel={() => {
                                this.setState({
                                  uploadContactsManuallyVisible: false,
                                });
                              }}
                            >
                              <div className="col-md-3">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary btn-block"
                                  onClick={() => {
                                    this.setState({
                                      manualContactsUploadForm: true,
                                    });
                                  }}
                                >
                                  <span>Upload</span>
                                </button>
                              </div>

                              {/* <MUIDataTable
                                title={'Contacts'}
                                data={manualCsvData}
                                columns={manualCsvColumns}
                                options={manualCsvOptions}
                              /> */}

                                <ThemeProvider theme={this.getMuiTheme()}>
                                  <MUIDataTable title={'Contacts'} data={manualCsvData} columns={manualCsvColumns} options={manualCsvOptions} />
                                </ThemeProvider>
                            </Modal>

                            {/* upload manual contacts modal end */}

                            {/* view contacts modal start */}

                            <Modal
                              visible={this.state.viewContactsVisible}
                              title={'Contacts'}
                              footer={null}
                              width={1000}
                              onCancel={() => {
                                this.setState({
                                  viewContactsVisible: false,
                                });
                              }}
                            >
                              <div className="row col-md-12">
                                <div className="col-md-3">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary btn-block"
                                    onClick={() => {
                                      this.setState({
                                        manualContactsUploadForm: true,
                                      });
                                    }}
                                  >
                                    <span>Manual Upload</span>
                                  </button>
                                </div>

                                <div className="col-md-3">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary btn-block"
                                    onClick={() => {
                                      this.setState({
                                        uploadContactsVisible: true,
                                      });
                                    }}
                                  >
                                    <span>CSV Upload</span>
                                  </button>
                                </div>
                              </div>

                              {/* <MUIDataTable
                                // title={'Contacts'}
                                data={viewContactsData}
                                columns={viewContactsColumns}
                                options={viewContactsOptions}
                              /> */}

                                <ThemeProvider theme={this.getMuiTheme()}>
                                  <MUIDataTable data={viewContactsData} columns={viewContactsColumns} options={viewContactsOptions} />
                                </ThemeProvider>
                            </Modal>

                            {/* view contacts modal end */}

                            {/* upload manual contacts form modal end */}

                            <Modal
                              visible={this.state.manualContactsUploadForm}
                              title={'Add Contact'}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  manualContactsUploadForm: false,
                                });
                              }}
                            >
                              <form onSubmit={this.submitManualContactsForm}>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="fv-phone"
                                    >
                                      Name
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <input
                                          className="form-control form-control-md"
                                          placeholder="Name"
                                          spellCheck="false"
                                          required
                                          value={
                                            this.state.manualContactsForm.name
                                          }
                                          onChange={(e) => {
                                            this.handleManualContactsForm(
                                              e,
                                              'name'
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-2">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="fv-phone"
                                    >
                                      Email
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <input
                                          className="form-control form-control-md"
                                          placeholder="Email"
                                          spellCheck="false"
                                          type="email"
                                          required
                                          value={
                                            this.state.manualContactsForm.email
                                          }
                                          onChange={(e) => {
                                            this.handleManualContactsForm(
                                              e,
                                              'email'
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-2">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="fv-phone"
                                    >
                                      Phone
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="input-group">
                                        <PhoneInput
                                          class="form-control"
                                          international
                                          countryCallingCodeEditable={false}
                                          defaultCountry={
                                            this.state.countryFlag
                                          }
                                          style={{
                                            fontSize: '14px',
                                            height: '35px',
                                            width: '100%',
                                          }}
                                          value={
                                            this.state.manualContactsForm.phone
                                          }
                                          onChange={(e) => {
                                            this.handleManualContactsForm(
                                              e,
                                              'phone'
                                            );
                                          }}
                                          // error={this.state.regForm.phoneNumber ? (isValidPhoneNumber(this.state.regForm.phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                        {/* <input
                                          className="form-control form-control-md"
                                          placeholder="Subject"
                                          spellCheck="false"
                                          required
                                          value={this.state.messaging.subject}
                                          onChange={(e) => {
                                            this.handleManualContactsForm(
                                              e,
                                              'phone'
                                            );
                                          }}
                                        /> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* {this.state.showSubmitButton === true && ( */}
                                <div className="mt-4">
                                  {!this.state.isRequesting ? (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary btn-block"
                                      // onClick={() => {
                                      //   this.addManualContact();
                                      // }}
                                    >
                                      <span>Submit</span>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="btn btn-lg btn-primary btn-block"
                                    >
                                      <div
                                        className="spinner-border"
                                        role="status"
                                        style={{ margin: '-6px' }}
                                      >
                                        {' '}
                                      </div>
                                    </button>
                                  )}
                                </div>
                                {/* )} */}
                              </form>
                            </Modal>

                            {/* delete contact modal start */}

                            <Modal
                              title="Delete Contact"
                              visible={this.state.deleteContactModal}
                              footer={null}
                              // onOk={() => {
                              //   this.deleteContact();
                              // }}
                              // confirmLoading={confirmLoading}
                              onCancel={() => {
                                this.setState({
                                  deleteContactModal: false,
                                });
                              }}
                            >
                              <form onSubmit={this.deleteContact}>
                                <p>
                                  Delete{' '}
                                  {`${
                                    '"' +
                                    this.state.manualContactsFormPreview.name +
                                    '"'
                                  }`}{' '}
                                  {/* Message */}
                                </p>

                                <div style={{ marginTop: '12px' }}>
                                  {!this.state.isRequesting ? (
                                    <button
                                      type="submit"
                                      className="btn btn-md btn-primary btn-block"
                                    >
                                      <span>Submit</span>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="btn btn-lg btn-primary btn-block"
                                    >
                                      <div
                                        className="spinner-border"
                                        role="status"
                                        style={{ margin: '-6px' }}
                                      >
                                        {' '}
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </form>
                            </Modal>

                            {/* delete contact modal end */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer
                      infoForFooter={reactLocalStorage.getObject('free_trial')}
                    />
                  </div>
                </div>
              </div>
              {/* <Tour
                onRequestClose={this.closeTour}
                disableInteraction={true}
                steps={messagingTourConfig}
                isOpen={isTourOpen}
                className="helper"
                rounded={5}
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
              /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Messaging;

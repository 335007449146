import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import theme from '../../theme/black';
import { ThemeProvider } from '@mui/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import moment from 'moment';
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Tour from "reactour";
// import {servicesTourConfig} from '../includes/tourConfig';
import { Upload, Modal } from 'antd';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { BusinessPortalLoader } from '../includes/Loaders';
import { reactLocalStorage } from 'reactjs-localstorage';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { DatePicker, message } from 'antd';
const { RangePicker } = DatePicker;
// var Loader = require('react-loader');

// let theme = createTheme();
// theme = responsiveFontSizes(theme);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class Services extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      nothingToShow: true,
      isRequesting: false,
      showServiceModal: false,
      previewVisible: false,
      allowEmptyStartDate: false,
      allowEmptyEndDate: false,
      deleteServiceModal: false,
      giftCardVisible: false,
      showGiftCard: false,
      loadGiftCardSwitch: false,
      deleteServiceId: '',
      giftCardTitle: '',
      deleteServiceTitle: '',
      previewImage: '',
      previewTitle: '',
      theDisplay: '',
      serviceModalTitle: '',
      isTourOpen: false,
      isShowingMore: false,
      fileListt: [],
      getImageUrl: '',
      recurringList: [],
      servicesArray: [],
      detailedRecurringList: [],
      business_details: {
        name: '',
      },
      service: {
        id: '',
        title: '',
        description: '',
        fileList: [],
        price: '',
        frequency: '',
        type: '',
        status: 0,
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getServices("");
      // if(this.state.userInfo.services_tour === 0){
      //   this.openTour();
      // }
    }
  }

  getServices = (request) => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/services',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let business_services = result.data.business_services;
        let UsersArray = [];

        for (let i = 0; i < business_services.length; i++) {
          let the_id = business_services[i].id;
          let title = business_services[i].title;
          let description = business_services[i].description;
          let image = business_services[i].image;
          let image_content = business_services[i].image_content;
          let price = business_services[i].price;
          let status = business_services[i].status;
          let frequency = business_services[i].frequency;
          let type = business_services[i].type;

          let UserArray = [];
          UserArray.push(title);
          UserArray.push(description);
          UserArray.push(the_id);
          UserArray.push(image_content);
          UserArray.push(image);
          UserArray.push(price);
          UserArray.push(status);
          UserArray.push(frequency);
          UserArray.push(image);
          UserArray.push(type);

          UsersArray.push(UserArray);
        }
        if(request == "add"){
          this.setState({
            serviceArray: UsersArray,
            isRequesting: false,
            showServiceModal: false,
          });
          AuthService.Prompts('success', 'Updated Successfully');
        }
        if(request == "delete"){
          this.setState({
            serviceArray: UsersArray,
            isRequesting: false,
            deleteServiceModal: false,
          });
          AuthService.Prompts('success', 'Deleted Successfully');
        }
        this.setState({
          ...this.state,
          servicesArray: UsersArray,
          isLoading: false,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  getMuiTheme = () => createTheme({
    components: {
        MUIDataTableHeadCell: {
          styleOverrides:{
          fixedHeader: {
            zIndex: "0"
          }
        }
        },
        MUIDataTableSelectCell: {
          styleOverrides:{
          headerCell: {
            zIndex: "0"
          }
        }
        },
    }
})

  // disableBody = target => disableBodyScroll(target);
  // enableBody = target => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //   .post(
  //     CONSTANTS.API_BASE_URL + '/business/tour/update',
  //     { column_name: "services_tour" },
  //     AuthService.getAxiosHeaders(
  //       window.sessionStorage.getItem('new_business')
  //     )
  //   )
  //   .then((response) => {
  //     let result = response.data;
  //     reactLocalStorage.remove(
  //       'user_object'
  //     );
  //     reactLocalStorage.setObject(
  //       'user_object',
  //       JSON.stringify(result.data.user_details)
  //     );
  //   })
  //   .catch((error) => {
  //   });
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  deleteService = (the_id, title) => {
    this.setState({
      deleteServiceModal: true,
      deleteServiceId: the_id,
      deleteServiceTitle: title,
    });
  };

  editService = (
    the_id,
    title,
    description,
    image_content,
    image,
    price,
    status,
    frequency,
    type
  ) => {
    let theArray = [];
    this.state.fileListt.length = 0;
    this.setState({
      serviceModalTitle: 'Edit Service',
    });

    let old_file = [];
    if (image != '') {
      old_file = [
        {
          action: 'edit',
          url: image,
        },
      ];
    }

    let old_service = this.state.service;
    old_service.id = the_id;
    old_service.title = title;
    old_service.description = description;
    old_service.price = price;
    old_service.status = status;
    old_service.frequency = frequency;
    old_service.type = type;
    old_service.fileList = old_file;
    this.setState({
      service: old_service,
      showServiceModal: true,
    });
    //console.log(this.state.fileListt);
  };

  onChangeStatus = (e, day) => {
    if (e.target.checked && day == 'status') {
      let old_service = this.state.service;
      old_service.status = 1;
      this.setState({
        service: old_service,
      });
    } else {
      if (day == 'status') {
        let old_service = this.state.service;
        old_service.status = 0;
        this.setState({
          service: old_service,
        });
      }
    }
  };

  deleteServiceRequest = (e) => {
    this.setState({
      isRequesting: true,
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/delete/service',
        { service_id: JSON.stringify(this.state.deleteServiceId) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.getServices("delete");
        // this.setState({
        //   isRequesting: false,
        //   deleteServiceModal: false,
        // });
        // AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          deleteServiceModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  addService = () => {
    let old_service = this.state.service;
    old_service.id = '';
    old_service.title = '';
    old_service.description = '';
    old_service.fileList = [];
    old_service.price = '';
    old_service.status = 1;

    this.setState({
      showServiceModal: true,
      service: old_service,
      serviceModalTitle: 'Add a Service',
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  frequency = (event) => {
    let old_fileList = this.state.service;
    old_fileList.frequency = event.target.value;
    this.setState({
      service: old_fileList,
    });
  }

  type = (event) => {
    let old_fileList = this.state.service;
    old_fileList.type = event.target.value;
    this.setState({
      service: old_fileList,
    });
  }

  handleAddService = (event, name) => {
    if (name.toString() === 'title') {
      let old_fileList = this.state.service;
      old_fileList.title = event.target.value;
      this.setState({
        service: old_fileList,
      });
    }
    if (name.toString() === 'description') {
      let old_fileList = this.state.service;
      old_fileList.description = event.target.value;
      this.setState({
        service: old_fileList,
      });
    }
    if (name.toString() === 'price') {
      let old_fileList = this.state.service;
      old_fileList.price = event.target.value;
      this.setState({
        service: old_fileList,
      });
    }
  };

  handleChange = ({ fileList }) => {
    let old_fileList = this.state.service;
    old_fileList.fileList = [];
    old_fileList.fileList = fileList;
    this.setState({
      service: old_fileList,
    });
    //console.log(this.state.service.fileList);
  };

  submitServicesForm = (e) => {
    e.preventDefault();
    if(this.state.service.frequency == ""){
      AuthService.Prompts('error', 'Price type is required');
      return
    }
    // if(this.state.service.type == ""){
    //   AuthService.Prompts('error', 'Service type is required');
    //   return
    // }
    //console.log(this.state.service);

    this.setState({
      isRequesting: true,
    });

    if (this.state.service.fileList.length != 0) {
      //console.log(Object.keys(this.state.service.fileList[0]).length);
      if (Object.keys(this.state.service.fileList[0]).length === 3) {
        let old_service = this.state.service;
        old_service.fileList = [];
        this.setState({
          service: old_service,
        });
      }
    }
    let data = this.state.fileListt;
    data = this.state.fileListt.filter(function (element) {
      return element !== undefined;
    });

    let old_service = this.state.service;
    old_service.finalRepeatList = [...new Set(data)];
    this.setState({
      service: old_service,
    });

    //console.log(this.state.service.fileListt);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/services',
        { services: JSON.stringify(this.state.service) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.getServices("add");
        // this.setState({
        //   isRequesting: false,
        //   showServiceModal: false,
        // });
        // AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          showServiceModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  onChangeGiftCardStatus = (e) => {
    //console.log(`switch to ${e}`);
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";

    const columns = [
      {
        name: 'Image',
        options: {
          print: true,
          filter: true,
          sort: false,
          filterType: 'multiselect',
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][8];
            if (value == '' || value === undefined || value === null) {
              return <div className="user-toggle">
              <div className="sm" style={{width: "75px"}}>
                <img src='../assets/images/image.png' alt="" />
              </div>
            </div>;
            } else {
              return <div className="user-toggle">
              <div className="sm" style={{width: "75px"}}>
                <img src={value} alt="" />
              </div>
            </div>;
            }
          },
        },
      },
      {
        name: 'Title',
        options: {
          print: true,
          filter: true,
          sort: false,
          filterType: 'multiselect',
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][0];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      // {
      //   name: 'Type',
      //   options: {
      //     print: true,
      //     filter: true,
      //     sort: false,
      //     filterType: 'multiselect',
      //     empty: true,
      //     customBodyRenderLite: (dataIndex) => {
      //       let value = data[dataIndex][9];
      //       if (value == '' || value === undefined || value === null) {
      //         return 'N/A';
      //       } else {
      //         return value;
      //       }
      //     },
      //   },
      // },
      {
        name: 'Price',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][5];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return '$' + value;
            }
          },
        },
      },
      {
        name: 'Price Per',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][7];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Status',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][6];
            // if (value == '' || value === undefined || value === null) {
            //   return 'N/A';
            // } else {
            //   return '$' + value;
            if (value === 1) {
              return (
                <li style={{ padding: '0px' }} className="preview-item">
                  <span
                    style={{ fontWeight: '700' }}
                    className="badge badge-success"
                  >
                    Active
                  </span>
                </li>
              );
            } else {
              return (
                <li style={{ padding: '0px' }} className="preview-item">
                  <span
                    style={{ fontWeight: '700' }}
                    className="badge badge-danger"
                  >
                    Inactive
                  </span>
                </li>
              );
            }
            // }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let title = data[dataIndex][0];
            let description = data[dataIndex][1];
            let the_id = data[dataIndex][2];
            let image_content = data[dataIndex][3];
            let image = data[dataIndex][4];
            let price = data[dataIndex][5];
            let status = data[dataIndex][6];
            let frequency = data[dataIndex][7];
            let type = data[dataIndex][9];
            return (
              <div>
                <button
                  onClick={() => {
                    this.editService(
                      the_id,
                      title,
                      description,
                      image_content,
                      image,
                      price,
                      status,
                      frequency,
                      type
                    );
                  }}
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: '10px' }}
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    this.deleteService(the_id, title);
                  }}
                  className="btn btn-danger btn-sm"
                >
                  Delete
                </button>
              </div>
            );
          },
        },
      },
    ];

    const data = this.state.servicesArray;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      // resizableColumns: true,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
              noMatch: 'No services or menu items have been added for this business'
        },
      },
    };
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div>Add Image</div>
      </div>
    );
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
             <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h4
                                      className="nk-block-title page-title"
                                      style={{ color: '#364a63' }}
                                    >
                                      {this.state.businessInfo.name.toString()}
                                    </h4>
                                  </div>
                                  <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em
                                          onClick={() => {
                                            this.pageMenu();
                                          }}
                                          className="icon ni ni-more-v"
                                        />
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                        style={{
                                          display: `${this.state.theDisplay}`,
                                        }}
                                      >
                                        <ul className="nk-block-tools g-3">
                                          <li data-tut="reactour__addAServiceButton">
                                            <button
                                              type="submit"
                                              className="btn btn-md btn-primary btn-block"
                                              onClick={() => {
                                                this.addService();
                                              }}
                                            >
                                              <span>Add a Service</span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            {/* <div className="row">
                              <div className="col-md-3">
                                <header
                                  className="kanban-board-header"
                                  style={{ borderTop: '0px' }}
                                >
                                  <div
                                    className="kanban-title-board"
                                    style={{ justifyContent: 'flex-start' }}
                                  >
                                    <div className="kanban-title-content">
                                      <div className="drodown">
                                        <a
                                          href="/#"
                                          className="dropdown-toggle btn btn-sm btn-icon btn-trigger mr-n1"
                                          data-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <em className="icon ni ni-happy-fill" />
                                        </a>
                                      </div>
                                    </div>
                                    <div className="kanban-title-content ml-2">
                                      <h6
                                        className="title"
                                        onClick={() => {
                                          this.addService();
                                        }}
                                        style={{
                                          color: '#017afe',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        Add Service
                                      </h6>
                                    </div>
                                  </div>
                                </header>
                              </div>
                            </div> */}

                            <Modal
                              visible={this.state.showServiceModal}
                              title={this.state.serviceModalTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  showServiceModal: false,
                                });
                              }}
                            >
                              <div className="form-group">
                                <div className="form-control-wrap">
                                  <form onSubmit={this.submitServicesForm}>
                                    <div>
                                      <div
                                        className="row"
                                        style={{ alignItems: 'normal' }}
                                      >
                                        <div className="col-3">
                                          <Upload
                                            action={`${
                                              CONSTANTS.API_BASE_URL +
                                              '/business/photo/update'
                                            }`}
                                            listType="picture-card"
                                            fileList={
                                              this.state.service.fileList
                                            }
                                            onPreview={this.handlePreview}
                                            onChange={this.handleChange}
                                          >
                                            {this.state.service.fileList
                                              .length === 1
                                              ? null
                                              : uploadButton}
                                          </Upload>
                                          <Modal
                                            visible={this.state.previewVisible}
                                            title={this.state.previewTitle}
                                            footer={null}
                                            onCancel={() => {
                                              this.setState({
                                                previewVisible: false,
                                              });
                                            }}
                                          >
                                            <img
                                              alt="example"
                                              style={{ width: '100%' }}
                                              src={this.state.previewImage}
                                            />
                                          </Modal>
                                        </div>
                                        <div className="col-9">
                                          <div className="project-info">
                                            <input
                                              className="form-control form-control-sm"
                                              placeholder="Title"
                                              spellCheck="false"
                                              required
                                              style={{
                                                fontSize: '14px',
                                                height: '35px',
                                              }}
                                              value={this.state.service.title}
                                              onChange={(e) => {
                                                this.handleAddService(
                                                  e,
                                                  'title'
                                                );
                                              }}
                                            />
                                                  {/* <select
                                                    value={this.state.service.type}
                                                    onChange={(e) => {
                                                      this.type(e);
                                                    }}
                                                    className="form-control mt-2"
                                                    id="default-06"
                                                  >
                                                    <option selected="selected" value="">
                                                      Type
                                                    </option>
                                                    <option value="Menu Item">
                                                      Menu Item
                                                    </option>
                                                    <option value="Other Service">
                                                      Other Service
                                                    </option>
                                                  </select> */}
                                            <textarea
                                              className="form-control form-control-sm mt-2"
                                              placeholder="Description"
                                              spellCheck="false"
                                              required
                                              rows="2"
                                              style={{
                                                fontSize: '14px',
                                                minHeight: 'auto',
                                              }}
                                              value={
                                                this.state.service.description
                                              }
                                              onChange={(e) => {
                                                this.handleAddService(
                                                  e,
                                                  'description'
                                                );
                                              }}
                                            />

                                            <div
                                              className="row"
                                              // style={{ alignItems: 'normal' }}
                                            >
                                              <div className="col-6 form-control-wrap mt-2">
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span
                                                      className="input-group-text"
                                                      id="fv-phone"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Price"
                                                    value={
                                                      this.state.service.price
                                                    }
                                                    onChange={(e) => {
                                                      this.handleAddService(
                                                        e,
                                                        'price'
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="form-group mt-2">
                                                  <div className="form-control-wrap">
                                                    <div className="row">
                                                    <div className="col-md-3">
                                                <div className="input-group-prepend">
                                                    <span
                                                      className="input-group-text"
                                                      id="fv-phone"
                                                    >
                                                      Per
                                                    </span>
                                                  </div>
                                                  </div>
                                                  <div className="col-md-9">
                                                  <select
                                                    value={this.state.service.frequency}
                                                    onChange={(e) => {
                                                      this.frequency(e);
                                                    }}
                                                    className="form-control"
                                                    id="default-06"
                                                  >
                                                    <option value="Not Applicable">
                                                      N/A
                                                    </option>
                                                    <option value="Per Item">
                                                      Item
                                                    </option>
                                                    <option value="Per Day">
                                                      Day
                                                    </option>
                                                    <option value="Per Week">
                                                      Week
                                                    </option>
                                                    <option value="Per Month">
                                                      Month
                                                    </option>
                                                    <option value="Per Session">
                                                      Session
                                                    </option>
                                                  </select>
                                                  </div>
                                                  </div>
                                              </div>
                                              {/* </div> */}
                                              </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="form-group mt-3">
                                                  <div className="form-control-wrap">
                                                    {/* <div className="col-md-3 col-sm-6"> */}
                                                      <div className="preview-block">
                                                        <div className="custom-control custom-switch checked">
                                                          <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                              this.onChangeStatus(
                                                                e,
                                                                'status'
                                                              );
                                                            }}
                                                            className="custom-control-input"
                                                            // defaultChecked
                                                            checked={
                                                              this.state.service
                                                                .status === 1
                                                                ? true
                                                                : false
                                                            }
                                                            id="status"
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor="status"
                                                          >
                                                            Active
                                                          </label>
                                                        </div>
                                                      {/* </div> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{ marginTop: '12px' }}>
                                      {!this.state.isRequesting ? (
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <span>Submit</span>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          disabled
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <div
                                            className="spinner-border"
                                            role="status"
                                            style={{ margin: '-6px' }}
                                          >
                                            {' '}
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Modal>

                            <Modal
                              title="Delete Service"
                              visible={this.state.deleteServiceModal}
                              footer={null}
                              // onOk={() => {
                              //   this.deleteServiceRequest();
                              // }}
                              // confirmLoading={confirmLoading}
                              onCancel={() => {
                                this.setState({
                                  deleteServiceModal: false,
                                });
                              }}
                            >
                              <form onSubmit={this.deleteServiceRequest}>
                                <p>
                                  Delete{' '}
                                  {`${
                                    '"' + this.state.deleteServiceTitle + '"'
                                  }`}{' '}
                                  Service
                                </p>

                                <div style={{ marginTop: '12px' }}>
                                  {!this.state.isRequesting ? (
                                    <button
                                      type="submit"
                                      className="btn btn-md btn-primary btn-block"
                                    >
                                      <span>Submit</span>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="btn btn-lg btn-primary btn-block"
                                    >
                                      <div
                                        className="spinner-border"
                                        role="status"
                                        style={{ margin: '-6px' }}
                                      >
                                        {' '}
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </form>
                            </Modal>

                            <div data-tut="reactour__serviceDataTable">
                              {/* <MUIDataTable
                                title={'Services'}
                                data={data}
                                columns={columns}
                                options={options}
                              /> */}

                                <ThemeProvider theme={this.getMuiTheme()}>
                                  <MUIDataTable title={'Services'} data={data} columns={columns} options={options} />
                                </ThemeProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer
                      infoForFooter={reactLocalStorage.getObject('free_trial')}
                    />
                  </div>
                </div>
              </div>
              {/* <Tour
                onRequestClose={this.closeTour}
                disableInteraction={true}
                steps={servicesTourConfig}
                isOpen={isTourOpen}
                className="helper"
                rounded={5}
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
              /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Services;

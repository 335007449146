import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import moment from 'moment';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import SideNav from '../includes/SideNav';
import { Modal } from 'antd';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import Iframe from 'react-iframe';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BusinessPortalLoader } from '../includes/Loaders';
import theme from '../../theme/black';
import CheckoutForm from '../includes/CheckoutForm';

class Billing extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    // window.helloComponent = this;
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      subscriptionModal: false,
      cancelSubscriptionModal: false,
      isTourOpen: false,
      isShowingMore: false,
      subscriptionModalBusinessId: '',
      subscriptionModalBusinessPaymentId: '',
      subscriptionModalBusinessName: '',
      subscriptionModalBusinessOwnerEmail: '',
      setError: '',
      isRequesting: false,
      setDisabled: true,
      billingArray: [],
      stripeArray: [],
      CheckoutFormList: [],
      CheckoutFormModal: false,
      paymentDetails: {
        business_id: '',
        card_number: '',
        expiration_date: '',
        email: '',
        month: '',
        year: '',
        cvc: '',
        zip_code: '',
        first_name: '',
        last_name: '',
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    this.getBillings();
    let { stripeArray } = this.state;
    stripeArray.push(this.state.userInfo.email);
    this.setState({
      stripeArray: stripeArray,
    });
  }

  getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              zIndex: '0',
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              zIndex: '0',
            },
          },
        },
      },
    });

  manageBilling = async () => {
    this.setState({
      subscriptionModal: true,
    });
  };

  cancelBilling = () => {
    this.setState({
      cancelSubscriptionModal: true,
    });
  };

  toggleShowMore = () => {
    this.setState((prevState) => ({
      isShowingMore: !prevState.isShowingMore,
    }));
  };

  cancelSubscription = (e) => {
    this.setState({
      isRequesting: true,
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/cancel/stripe/subscription',
        {
          payment_id: this.state.subscriptionModalBusinessPaymentId,
          business_payment_id: this.state.subscriptionModalBusinessId,
        },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let billing = result.data;
        let UsersArray = [];

        for (let i = 0; i < billing.length; i++) {
          let the_id = billing[i].id;
          let name = billing[i].name;
          let email = billing[i].email;
          let nextBillingDate =
            billing[i].next_billing_date != ''
              ? moment(billing[i].next_billing_date).format('ll')
              : '';
          let status = billing[i].status;
          let paymentId = billing[i].payment_id;

          let UserArray = [];
          UserArray.push(name);
          UserArray.push(status);
          UserArray.push(the_id);
          UserArray.push(email);
          UserArray.push(nextBillingDate);
          UserArray.push(paymentId);

          UsersArray.push(UserArray);
        }
        this.setState({
          billingArray: UsersArray,
          isRequesting: false,
          cancelSubscriptionModal: false,
        });
        AuthService.Prompts(
          'success',
          'Subscription cancelled. Redirecting...'
        );
        //redirect to business information page
        window.location.replace(`${CONSTANTS.APP_URL}/business-information`);
      })
      .catch((error) => {
        //console.log(error);
        this.setState({
          isRequesting: false,
        });
      });
  };

  submitPaymentDetailsForm = (e) => {
    e.preventDefault();
    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/re/subscribe',
        { payment_details: JSON.stringify(this.state.paymentDetails) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let billing = result.data;
        let UsersArray = [];

        for (let i = 0; i < billing.length; i++) {
          let the_id = billing[i].id;
          let name = billing[i].name;
          let email = billing[i].email;
          let nextBillingDate =
            billing[i].next_billing_date != ''
              ? moment(billing[i].next_billing_date).format('ll')
              : '';
          let status = billing[i].status;
          let paymentId = billing[i].payment_id;

          let UserArray = [];
          UserArray.push(name);
          UserArray.push(status);
          UserArray.push(the_id);
          UserArray.push(email);
          UserArray.push(nextBillingDate);
          UserArray.push(paymentId);

          UsersArray.push(UserArray);
        }
        this.setState({
          billingArray: UsersArray,
          subscriptionModal: false,
          isRequesting: false,
        });

        AuthService.Prompts('success', 'Subscribed successfully');

      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  getBillings = () => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/stripe/subscription',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let billing = result.data;
        let UsersArray = [];

        for (let i = 0; i < billing.length; i++) {
          let the_id = billing[i].id;
          let name = billing[i].name;
          let email = billing[i].email;
          let nextBillingDate =
            billing[i].next_billing_date != ''
              ? moment(billing[i].next_billing_date).format('ll')
              : '';
          let status = billing[i].status;
          let paymentId = billing[i].payment_id;

          let UserArray = [];
          UserArray.push(name);
          UserArray.push(status);
          UserArray.push(the_id);
          UserArray.push(email);
          UserArray.push(nextBillingDate);
          UserArray.push(paymentId);

          UsersArray.push(UserArray);
        }
        this.setState({
          billingArray: UsersArray,
          isLoading: false,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  handlePaymentDetails = (event, name) => {
    if (name.toString() === 'card_number') {
      let old_paymentDetails = this.state.paymentDetails;
      old_paymentDetails.card_number = event.target.value;
      this.setState({
        paymentDetails: old_paymentDetails,
      });
    }
    if (name.toString() === 'month') {
      let old_paymentDetails = this.state.paymentDetails;
      old_paymentDetails.month = event.target.value;
      this.setState({
        paymentDetails: old_paymentDetails,
      });
    }
    if (name.toString() === 'year') {
      let old_paymentDetails = this.state.paymentDetails;
      old_paymentDetails.year = event.target.value;
      this.setState({
        paymentDetails: old_paymentDetails,
      });
    }
    if (name.toString() === 'cvc') {
      let old_paymentDetails = this.state.paymentDetails;
      old_paymentDetails.cvc = event.target.value;
      this.setState({
        paymentDetails: old_paymentDetails,
      });
    }
    if (name.toString() === 'zip_code') {
      let old_paymentDetails = this.state.paymentDetails;
      old_paymentDetails.zip_code = event.target.value;
      this.setState({
        paymentDetails: old_paymentDetails,
      });
    }
    if (name.toString() === 'first_name') {
      let old_paymentDetails = this.state.paymentDetails;
      old_paymentDetails.first_name = event.target.value;
      this.setState({
        paymentDetails: old_paymentDetails,
      });
    }
    if (name.toString() === 'last_name') {
      let old_paymentDetails = this.state.paymentDetails;
      old_paymentDetails.last_name = event.target.value;
      this.setState({
        paymentDetails: old_paymentDetails,
      });
    }
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = '#1890ff';

    const columns = [
      {
        // label: "Name",
        name: 'Business Name',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][0];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Status',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][1];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              if (value == 'trialing') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-primary"
                    >
                      Free Trial
                    </span>
                  </li>
                );
              } else if (value == 'canceled') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-danger"
                    >
                      Cancelled
                    </span>
                  </li>
                );
              } else {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Active
                    </span>
                  </li>
                );
              }
            }
          },
        },
      },
      {
        name: 'Next Billing Date',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let status = data[dataIndex][1];
            let value = data[dataIndex][4];

            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return status == 'canceled' ? 'N/A' : '$99 on ' + value;
            }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let name = data[dataIndex][0];
            let status = data[dataIndex][1];
            let the_id = data[dataIndex][2];
            let email = data[dataIndex][3];
            let payment_id = data[dataIndex][5];

            if (status == 'trialing') {
              return (
                // <div>
                //   <button
                //     onClick={() => {
                //       this.cancelBilling(name, the_id, email, payment_id);
                //       this.setState({
                //         subscriptionModalBusinessId: the_id,
                //         subscriptionModalBusinessPaymentId: payment_id,
                //         subscriptionModalBusinessName: name,
                //         subscriptionModalBusinessOwnerEmail: email,
                //       });
                //     }}
                //     className="btn btn-danger btn-sm"
                //     style={{ marginRight: '10px' }}
                //   >
                //     Cancel Subscription
                //   </button>
                // </div>

                <div>
                <button
                  onClick={() => {
                    this.manageBilling(name, the_id, email, payment_id);
                    let old_paymentDetails = this.state.paymentDetails;
                    old_paymentDetails.business_id = the_id;
                    old_paymentDetails.email = email;
                    this.setState({
                      paymentDetails: old_paymentDetails,
                      subscriptionModalBusinessId: the_id,
                      subscriptionModalBusinessPaymentId: payment_id,
                      subscriptionModalBusinessName: name,
                      subscriptionModalBusinessOwnerEmail: email,
                    });
                  }}
                  className="btn btn-success btn-sm"
                  style={{ marginRight: '10px' }}
                >
                  Subscribe
                </button>
              </div>
              );
            } else if (status == 'canceled') {
              return (
                <div>
                  <button
                    onClick={() => {
                      this.manageBilling(name, the_id, email, payment_id);
                      let old_paymentDetails = this.state.paymentDetails;
                      old_paymentDetails.business_id = the_id;
                      old_paymentDetails.email = email;
                      this.setState({
                        paymentDetails: old_paymentDetails,
                        subscriptionModalBusinessId: the_id,
                        subscriptionModalBusinessPaymentId: payment_id,
                        subscriptionModalBusinessName: name,
                        subscriptionModalBusinessOwnerEmail: email,
                      });
                    }}
                    className="btn btn-success btn-sm"
                    style={{ marginRight: '10px' }}
                  >
                    Subscribe
                  </button>
                </div>
              );
            } else {
              return (
                <div>
                  <button
                    onClick={() => {
                      this.cancelBilling(name, the_id, email, payment_id);
                      this.setState({
                        subscriptionModalBusinessId: the_id,
                        subscriptionModalBusinessPaymentId: payment_id,
                        subscriptionModalBusinessName: name,
                        subscriptionModalBusinessOwnerEmail: email,
                      });
                    }}
                    className="btn btn-danger btn-sm"
                    style={{ marginRight: '10px' }}
                  >
                    Cancel Subscription
                  </button>
                </div>
              );
            }
          },
        },
      },
    ];

    const data = this.state.billingArray;

    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      filter: false,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
          //   noMatch: this.props.isLoading ?
          //   'Sorry, there is no matching data to display' :
          //   <Loader />,
        },
      },
    };
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
            <>
              <img
                className="preloader"
                src="assets/images/logo_small.png"
                alt="image"
              />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                            </div>

                            <div className="row g-gs">
                              <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="card card-full">
                                  <div
                                    className="card-inner-group"
                                  >
                                    <div data-tut="reactour__billingDataTable">

                                      <ThemeProvider theme={this.getMuiTheme()}>
                                        <MUIDataTable
                                          title={'Billing'}
                                          data={data}
                                          columns={columns}
                                          options={options}
                                        />
                                      </ThemeProvider>
                                    </div>
                                    {/*   </>

                                    subscription modal start */}

                                    <Modal
                                      visible={this.state.subscriptionModal}
                                      title="Subscribe"
                                      footer={null}
                                      onCancel={() => {
                                        this.setState({
                                          subscriptionModal: false,
                                          subscriptionModalBusinessId: '',
                                          subscriptionModalBusinessName: '',
                                          subscriptionModalBusinessOwnerEmail:
                                            '',
                                        });
                                      }}
                                    >
                                      {/* <Elements stripe={promise}>
                                                <CheckoutForm
                                                disableStartTrialButton={false}
                                                stripeArray={this.state.stripeArray}
                                                />
                                            </Elements> */}

                                      <form
                                        onSubmit={this.submitPaymentDetailsForm}
                                      >
                                        <div>
                                          <div>
                                            <div style={{ opacity: 1 }}>
                                              {/* form goes here start */}

                                              <div>
                                                {/* <div className="App-Global-Fields flex-container direction-row wrap-wrap"> */}
                                                <div>
                                                  <div className="form-group">
                                                    <div className="form-label-group">
                                                      <label
                                                        style={{
                                                          color:
                                                            'rgba(26,26,26,.4)',
                                                        }}
                                                        className="form-label Text-color--gray600"
                                                        // htmlFor="first_name"
                                                      >
                                                        Card Details
                                                      </label>
                                                    </div>
                                                    <div className="row">
                                                      <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            // id="exampleInputCvcCode"
                                                            placeholder="First name"
                                                            value={
                                                              this.state
                                                                .paymentDetails
                                                                .first_name
                                                            }
                                                            onChange={(e) => {
                                                              this.handlePaymentDetails(
                                                                e,
                                                                'first_name'
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            // id="exampleInputCvcCode"
                                                            placeholder="Last name"
                                                            value={
                                                              this.state
                                                                .paymentDetails
                                                                .last_name
                                                            }
                                                            onChange={(e) => {
                                                              this.handlePaymentDetails(
                                                                e,
                                                                'last_name'
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div>
                                                    <div
                                                      className="row"
                                                      style={{
                                                        marginBottom: '0.5rem',
                                                      }}
                                                    >
                                                      <div className="col-md-6 col-12">
                                                        <div
                                                          className="form-group"
                                                          style={{
                                                            marginBottom:
                                                              '10px',
                                                          }}
                                                        >
                                                          <div className="input-group">
                                                            <input
                                                              type="number"
                                                              className="form-control"
                                                              placeholder="Card Number"
                                                              maxlength="16"
                                                              size="16"
                                                              value={
                                                                this.state
                                                                  .paymentDetails
                                                                  .card_number
                                                              }
                                                              onChange={(e) => {
                                                                this.handlePaymentDetails(
                                                                  e,
                                                                  'card_number'
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-md-3 col-12">
                                                        <div className="form-group">
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            maxlength="2"
                                                            size="2"
                                                            placeholder="MM"
                                                            value={
                                                              this.state
                                                                .paymentDetails
                                                                .month
                                                            }
                                                            onChange={(e) => {
                                                              this.handlePaymentDetails(
                                                                e,
                                                                'month'
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="col-md-3 col-12">
                                                        <div className="form-group">
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            maxlength="2"
                                                            size="2"
                                                            placeholder="YY"
                                                            value={
                                                              this.state
                                                                .paymentDetails
                                                                .year
                                                            }
                                                            onChange={(e) => {
                                                              this.handlePaymentDetails(
                                                                e,
                                                                'year'
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="row"
                                                      style={{
                                                        marginBottom: '0.5rem',
                                                      }}
                                                    >
                                                      <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="CVC"
                                                            maxlength="4"
                                                            size="4"
                                                            value={
                                                              this.state
                                                                .paymentDetails
                                                                .cvc
                                                            }
                                                            onChange={(e) => {
                                                              this.handlePaymentDetails(
                                                                e,
                                                                'cvc'
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Zip Code"
                                                            maxlength="5"
                                                            size="5"
                                                            value={
                                                              this.state
                                                                .paymentDetails
                                                                .zip_code
                                                            }
                                                            onChange={(e) => {
                                                              this.handlePaymentDetails(
                                                                e,
                                                                'zip_code'
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <br />

                                                  {!this.state.isRequesting ? (
                                                    <button
                                                      type="submit"
                                                      className="btn btn-lg btn-primary btn-block"
                                                    >
                                                      <span>Submit</span>
                                                    </button>
                                                  ) : (
                                                    <button
                                                      type="button"
                                                      disabled
                                                      className="btn btn-lg btn-primary btn-block"
                                                    >
                                                      <div
                                                        className="spinner-border"
                                                        role="status"
                                                        style={{
                                                          margin: '-6px',
                                                        }}
                                                      >
                                                        {' '}
                                                      </div>
                                                    </button>
                                                  )}
                                                  {/* stripe checkout end */}
                                                </div>
                                              </div>

                                              {/* form goes here end */}
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </Modal>

                                    {/* subscription modal end */}

                                    {/* :

                                    <>
                                      <div style={{backgroundColor: '#f5f6fa'}} className="nk-block-content nk-error-ld text-center">
                                        <em
                                         className="icon ni ni-cc-alt-fill"
                                         style={{
                                           fontSize: '40vh',
                                           color: '#8094ae',
                                         }}
                                       ></em>
                                        <h4
                                        style={{
                                           color: '#1890ff',
                                           cursor: "pointer"
                                         }} className="nk-error-title">
                                          Subscribe
                                        </h4>
                                      </div>
                                      </>

                                        } */}

                                    {/* cancel subscription modal start */}

                                    <Modal
                                      visible={
                                        this.state.cancelSubscriptionModal
                                      }
                                      title="Cancel Subscription"
                                      footer={null}
                                      // width={600}
                                      onCancel={() => {
                                        this.setState({
                                          cancelSubscriptionModal: false,
                                        });
                                      }}
                                    >
                                      <div>
                                        <label className="form-group mb-1">
                                          <span>
                                            Cancel Subscription for{' '}
                                            {
                                              this.state
                                                .subscriptionModalBusinessName
                                            }
                                          </span>
                                        </label>
                                        {/* <button
                                            type="submit"
                                            className="btn btn-md btn-primary btn-block mb-4"
                                            onClick={() => {
                                                this.cancelSubscription();
                                            }}
                                            >
                                            <span>Cancel Subscription</span>
                                            </button> */}
                                        <form
                                          onSubmit={this.cancelSubscription}
                                        >
                                          <div style={{ marginTop: '12px' }}>
                                            {!this.state.isRequesting ? (
                                              <button
                                                type="submit"
                                                className="btn btn-lg btn-primary btn-block"
                                              >
                                                <span>Cancel Subscription</span>
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                disabled
                                                className="btn btn-lg btn-primary btn-block"
                                              >
                                                <div
                                                  className="spinner-border"
                                                  role="status"
                                                  style={{ margin: '-6px' }}
                                                >
                                                  {' '}
                                                </div>
                                              </button>
                                            )}
                                          </div>
                                        </form>
                                      </div>
                                    </Modal>

                                    {/* cancel subscription modal end */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer />
                  </div>
                </div>
              </div>
              {/* <Tour
              onRequestClose={this.closeTour}
              disableInteraction={true}
              steps={billingTourConfig}
              isOpen={isTourOpen}
              className="helper"
              rounded={5}
              onAfterOpen={this.disableBody}
              onBeforeClose={this.enableBody}
            /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Billing;

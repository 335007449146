import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { Modal } from 'antd';
import Iframe from 'react-iframe';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterIcon,
  FacebookIcon,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramIcon,
  WhatsappIcon,
  TelegramShareButton,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BusinessPortalLoader } from '../includes/Loaders';

class Website extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      allReviewsModal: false,
      websiteShareModal: false,
      business_reviews: [],
      theDisplay: '',
      // shareUrl: `https://unstuq.com/${this.state.businessInfo.place_id}`,
      title: '',
      business_details: {
        place_id: '',
        bio: '',
        name: '',
        formatted_address: '',
        business_status: '',
        icon: '',
        icon_background_color: '',
        icon_mask_base_uri: '',
        phone_number: '',
        rating: '',
        url: '',
        user_ratings_total: '',
        vicinity: '',
        website: '',
        reference: '',
        lat: '',
        lng: '',
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    this.setState({
      isLoading: false,
    });
  }

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>UnstuQ Business</title>
        </MetaTags>
        {this.state.isLoading ? (
           <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
        ) : (
          <div>
            <div className="nk-app-root bg-lighter">
              <div className="nk-main ">
                <SideNav />
                <div className="nk-wrap ">
                  <HeaderNav />

                  <div className="nk-content ">
                    <div className="container-fluid">
                      <div className="nk-content-inner">
                        <div className="nk-content-body">
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h4
                                  className="nk-block-title page-title"
                                  style={{ color: '#364a63' }}
                                >
                                  {this.state.business_details.name.toString()}
                                </h4>
                              </div>
                              <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                  <a
                                    href="#"
                                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                    data-target="pageMenu"
                                  >
                                    <em
                                      onClick={() => {
                                        this.pageMenu();
                                      }}
                                      className="icon ni ni-more-v"
                                    />
                                  </a>
                                  <div
                                    className="toggle-expand-content"
                                    data-content="pageMenu"
                                    style={{
                                      display: `${this.state.theDisplay}`,
                                    }}
                                  >
                                    <ul className="nk-block-tools g-3">
                                      <li data-toggle="tooltip" data-placement="top" title="Open in new tab">
                                        <a
                                          href={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                                          target="_blank"
                                        >
                                          <div>
                                            <span className="nk-menu-icon me-4">
                                              <em
                                                className="icon ni ni-external"
                                                style={{
                                                  fontSize: '35px',
                                                  cursor: 'pointer',
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </a>
                                      </li>

                                      <li data-toggle="tooltip" data-placement="top" title="Share link">
                                        <div>
                                          <span className="nk-menu-icon me-4">
                                            <em
                                              className="icon ni ni-share-fill"
                                              style={{
                                                fontSize: '35px',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  websiteShareModal: true,
                                                });
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </li>

                                      <li data-toggle="tooltip" data-placement="top" title="Copy to clipboard">
                                        <div>
                                          <span className="nk-menu-icon me-4">
                                            <em
                                              className="icon ni ni-copy-fill"
                                              style={{
                                                fontSize: '35px',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  `https://unstuq.com/${this.state.businessInfo.place_id}`
                                                );
                                                AuthService.Prompts(
                                                  'success',
                                                  'Copied to clipboard'
                                                );
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row g-gs">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                              <div className="card card-full">
                                <div
                                  className="card-inner-group"
                                  //   style={{
                                  //     maxHeight: '380px',
                                  //     overflow: 'scroll',
                                  //   }}
                                >
                                  {/* {this.state.business_reviews.length < 1 ? (
                                    <>
                                      <div
                                        style={{ backgroundColor: '#f5f6fa' }}
                                        className="nk-block-content nk-error-ld text-center"
                                      >
                                        <em
                                          className="icon ni ni-na fa-lg"
                                          style={{
                                            fontSize: '40vh',
                                            color: '#8094ae',
                                          }}
                                        ></em>
                                        <h3
                                          style={{
                                            color: '#8094ae',
                                          }}
                                          className="nk-error-title"
                                        >
                                          There are no reviews for this business
                                        </h3>
                                      </div>
                                    </>
                                  ) : (
                                    <> */}

                                  <div
                                    className="holds-the-iframe"
                                    style={{ padding: '8px' }}
                                  >
                                    <iframe
                                        src={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                                        frameborder="0"
                                        width="100%"
                                        height="2000"
                                    >
                                    </iframe>
                                </div>

                                  {/* </>
                                   )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* share modal start */}

                  <Modal
                    visible={this.state.websiteShareModal}
                    title="Share website link"
                    footer={null}
                    onCancel={() => {
                      this.setState({
                        websiteShareModal: false,
                      });
                    }}
                  >
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="cf-default-textarea"
                      >
                        Click on any of the social media handles to share website link
                      </label>
                    </div>

                    <ul className="nk-block-tools g-3">
                      <li>
                        <div className="Demo__some-network">
                          <FacebookShareButton
                            url={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                            quote={this.state.title}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                        </div>
                      </li>

                      <li>
                        <div className="Demo__some-network">
                          <FacebookMessengerShareButton
                            url={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                            appId="521270401588372"
                            className="Demo__some-network__share-button"
                          >
                            <FacebookMessengerIcon size={32} round />
                          </FacebookMessengerShareButton>
                        </div>
                      </li>

                      <li>
                        <div className="Demo__some-network">
                          <TwitterShareButton
                            url={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                            title={this.state.title}
                            className="Demo__some-network__share-button"
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                        </div>
                      </li>

                      <li>
                        <div className="Demo__some-network">
                          <TelegramShareButton
                            url={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                            title={this.state.title}
                            className="Demo__some-network__share-button"
                          >
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>
                        </div>
                      </li>

                      <li>
                        <div className="Demo__some-network">
                          <WhatsappShareButton
                            url={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                            title={this.state.title}
                            separator=":: "
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                        </div>
                      </li>

                      <li>
                        <div className="Demo__some-network">
                          <LinkedinShareButton
                            url={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                        </div>
                      </li>

                      <li>
                        <div className="Demo__some-network">
                          <EmailShareButton
                            url={`https://unstuq.com/${this.state.businessInfo.place_id}`}
                            subject={this.state.title}
                            body="body"
                            className="Demo__some-network__share-button"
                          >
                            <EmailIcon size={32} round />
                          </EmailShareButton>
                        </div>
                      </li>
                    </ul>
                  </Modal>

                  {/* share modal end */}

                  <Footer
                    infoForFooter={reactLocalStorage.getObject('free_trial')}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Website;

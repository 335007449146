import React, { Fragment } from 'react';
import CONSTANTS from '../misc/Constants';
import { reactLocalStorage } from 'reactjs-localstorage';
import { SettingUpLoader } from '../includes/Loaders';

class SettingUpNew extends React.Component {

  componentDidMount() {
        window.setTimeout(function(){
        reactLocalStorage.setObject('business_information_status', 'active');
        window.location.replace(`${CONSTANTS.APP_URL}/business-information`);
    }, 2000);
  }

  render() {
    return (
      <Fragment>
        <SettingUpLoader></SettingUpLoader>
      </Fragment>
    );
  }
}

export default SettingUpNew;

import React, { Fragment } from 'react';

class Onboardingg extends React.Component {

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
  }

  render () {
    return (
      <Fragment>
      <div id="hello" data-sp-next="#step-2" data-sp-text="This is the first step.">Hello! Ready to start? <strong>Click here!</strong></div>

      <div id="step-2" data-sp-next="#step-3" data-sp-text="Just a few more steps...">This is a small plugin that allows the  easy creation of spotlights that could be useful for small presentations, demos, or instructions.</div>

      <div id="step-3" data-sp-next="#bye" data-sp-text="Almost there..." data-sp-shape="round">It is customizable. User can select shapes, colors, messages to be displayed...</div>

      <div id="bye" data-sp-text="You made it till the end!">Bye! This was the end of the presentation</div>
      </Fragment>
    );
  }
}

export default Onboardingg
import React from "react"
import ContentLoader from "react-content-loader"

const OnboardingLoader = (props) => (
  <ContentLoader 
    speed={2}
    width="100%"
    height="100%"
    viewBox="0 10 500 230"
    backgroundColor="#d9d9d9"
    foregroundColor="#ededed"
    {...props}
  >
    <rect x="5" y="15" rx="4" ry="4" width="98%" height="20" />
    <rect x="5" y="50" rx="4" ry="4" width="98%" height="100%" />
  </ContentLoader>
)

const BusinessPortalLoader = (props) => (
  <ContentLoader 
    speed={2}
    width="100%"
    height={500}
    viewBox="0 10 500 230"
    backgroundColor="#d9d9d9"
    foregroundColor="#ededed"
    {...props}
  >
    <rect x="5" y="15" rx="2" ry="2" width="25%" height="100%" />
    <rect x="29%" y="15" rx="4" ry="4" width="70%" height="20" />
    <rect x="29%" y="50" rx="2" ry="2" width="70%" height="100%" />
  </ContentLoader>
)

const SettingUpLoader = (props) => (
  <ContentLoader
  speed={2}
  width="100%"
  height={500}
  viewBox="0 10 500 230"
  backgroundColor="#d9d9d9"
  foregroundColor="#ededed"
  {...props}
>
  <circle cx="200" cy="125" r="8" />
  <circle cx="250" cy="125" r="8" />
  <circle cx="300" cy="125" r="8" />
</ContentLoader>
)

export { OnboardingLoader, 
  BusinessPortalLoader,
  SettingUpLoader
}
import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import moment from 'moment';
import theme from '../../theme/black';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { TableFooter, TableCell, TableRow } from '@material-ui/core';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// import Tour from 'reactour';
import PhoneInput from 'react-phone-number-input/max';
// import { earningsTourConfig } from '../includes/tourConfig';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BusinessPortalLoader } from '../includes/Loaders';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Switch, Modal } from 'antd';

class Earnings extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      nothingToShow: true,
      isRequesting: false,
      viewEarningsVisible: false,
      showGiftCard: false,
      loadGiftCardSwitch: false,
      isTourOpen: false,
      isShowingMore: false,
      showPaypalInfo: false,
      showVenmoInfo: false,
      showBankTransferInfo: false,
      checkRoutingNumber: false,
      viewEarningsTitle: '',
      theDisplay: '',
      recurringList: [],
      totalTransactions: 0,
      totalEarnings: 0,
      countryFlag: '',
      withdrawn: 0,
      availableBalance: 0,
      earningsTransactionsArray: [],
      business_details: {
        name: '',
      },
      previewEarnings: {
        date: '',
        withdrawalMethod: '',
        status: '',
        amount: 0,
        transaction_id: '',
      },
      requestPayment: {
        amount: 100,
        method: '',
        paypalEmail: '',
        venmoPhoneNumber: '',
        venmoUsername: '',
        accountNumber: '',
        routingNumber: '',
        accountType: '',
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getEarnings();
      // if (this.state.userInfo.earnings_tour === 0) {
      //   this.openTour();
      // }
    }
    // this.getCountryFlag();
  }

  getEarnings = (request) => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/earnings',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let gift_card_transactions = result.data.gift_card_transactions;
        let earnings_transactions = result.data.earnings_result;
        let UsersArray = [];
        let totalEarnings = 0;
        let withdrawn = 0;
        let availableBalance = 0;

        for (let i = 0; i < earnings_transactions.length; i++) {
          let the_id = earnings_transactions[i].id;
          let date = moment(earnings_transactions[i].created_at).format('ll');
          let withdrawal_method = earnings_transactions[i].withdrawal_method;
          let transaction_id = earnings_transactions[i].transaction_id;
          let amount = earnings_transactions[i].amount;
          let status = earnings_transactions[i].status;
          //   if(type == 'deposit'){
          //     sumTotalDeposit += parseInt(gift_card_transactions[i].amount);
          //   }
          //   else{
          //     sumTotalRedemptions += parseInt(gift_card_transactions[i].amount);
          //   }

          let UserArray = [];
          // UserArray.push(the_id);
          UserArray.push(date);
          UserArray.push(withdrawal_method);
          UserArray.push(status);
          UserArray.push(amount);
          UserArray.push(transaction_id);

          UsersArray.push(UserArray);
        }

          if(request == "add"){
            this.setState({
              isRequesting: false,
              showRequestPaymentModal: false,
            });
            AuthService.Prompts('success', 'Updated Successfully');
          }

        this.setState({
          ...this.state,
          isLoading: false,
          earningsTransactionsArray: UsersArray,
          totalTransactions: earnings_transactions.length,
          //   totalPurchases: sumTotalDeposit,
          //   totalRedemptions: sumTotalRedemptions,
          //   outstanding: sumTotalOutstanding,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              zIndex: '0',
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              zIndex: '0',
            },
          },
        },
      },
    });

  // disableBody = (target) => disableBodyScroll(target);
  // enableBody = (target) => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState((prevState) => ({
      isShowingMore: !prevState.isShowingMore,
    }));
  };

  submitPaymentRequestForm = (e) => {
    e.preventDefault();
    //console.log(this.state.requestPayment);
    // if(this.state.checkRoutingNumber === false){
    //   AuthService.Prompts('error', 'Invalid routing number');
    //   return;
    // }
    if(this.state.requestPayment.method === ""){
      AuthService.Prompts('error', 'Invalid payment method');
      return;
    }
    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/payment/request',
        { request_payment: JSON.stringify(this.state.requestPayment) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getEarnings("add");
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          businessTagsModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  getCountryFlag = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        let old_requestPayment = this.state.requestPayment;
        old_requestPayment.venmoPhoneNumber = data.country_code;
        this.setState({
          requestPayment: old_requestPayment,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
      alert(this.state.requestPayment.venmoPhoneNumber);
  };

  handlePaymentRequest = (event, name) => {
    if (name.toString() === 'amount') {
      let old_requestPayment = this.state.requestPayment;
      old_requestPayment.title = event.target.value;
      this.setState({
        requestPayment: old_requestPayment,
      });
    }
    if (name.toString() === 'paypal_email') {
      let old_requestPayment = this.state.requestPayment;
      old_requestPayment.paypalEmail = event.target.value;
      this.setState({
        requestPayment: old_requestPayment,
      });
    }
    if (name.toString() === 'venmo_phone_number') {
      let old_requestPayment = this.state.requestPayment;
      old_requestPayment.venmoPhoneNumber = event.target.value;
      this.setState({
        requestPayment: old_requestPayment,
      });
    }
    if (name.toString() === 'venmo_username') {
      let old_requestPayment = this.state.requestPayment;
      old_requestPayment.venmoUsername = event.target.value;
      this.setState({
        requestPayment: old_requestPayment,
      });
    }
    if (name.toString() === 'account_number') {
      let old_requestPayment = this.state.requestPayment;
      old_requestPayment.accountNumber = event.target.value;
      this.setState({
        requestPayment: old_requestPayment,
      });
    }
    if (name.toString() === 'routing_number') {
      let finalValue = event.target.value;
      const isInputValid = !finalValue || this.regexRouting(finalValue);
      let old_requestPayment = this.state.requestPayment;
      old_requestPayment.routingNumber = event.target.value;
      this.setState({
        requestPayment: old_requestPayment,
        checkRoutingNumber: isInputValid === true ? true : false,
      });
      //console.log(isInputValid);
    }
  };

  regexRouting = URL => {
    const regex = new RegExp('/^[+-]?\d*(?:[.,]\d*)?$/');    
    return regex.test(URL);
  };

  payoutMethod = (event) => {
    let old_requestPayment = this.state.requestPayment;
    old_requestPayment.method = event.target.value;
    this.setState({
      requestPayment: old_requestPayment,
    });
    if(event.target.value == 'Paypal'){
      this.setState({
        showPaypalInfo: true,
        showVenmoInfo: false,
        showBankTransferInfo: false,
      });
    }
    if(event.target.value == 'Venmo'){
      this.setState({
        showPaypalInfo: false,
        showVenmoInfo: true,
        showBankTransferInfo: false,
      });
    }
    if(event.target.value == 'Bank Transfer'){
      this.setState({
        showPaypalInfo: false,
        showVenmoInfo: false,
        showBankTransferInfo: true,
      });
    }
  };

  accountType = (event) => {
    let old_requestPayment = this.state.requestPayment;
    old_requestPayment.accountType = event.target.value;
    this.setState({
      requestPayment: old_requestPayment,
    });
  };

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //     .post(
  //       CONSTANTS.API_BASE_URL + '/business/tour/update',
  //       { column_name: 'earnings_tour' },
  //       AuthService.getAxiosHeaders(
  //         window.sessionStorage.getItem('new_business')
  //       )
  //     )
  //     .then((response) => {
  //       let result = response.data;
  //       reactLocalStorage.remove('user_object');
  //       reactLocalStorage.setObject(
  //         'user_object',
  //         JSON.stringify(result.data.user_details)
  //       );
  //     })
  //     .catch((error) => {});
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  viewTransaction = (
    date,
    withdrawal_method,
    status,
    amount,
    transaction_id
  ) => {
    var old_earnings = this.state.previewEarnings;
    old_earnings.date = date;
    old_earnings.withdrawalMethod = withdrawal_method;
    old_earnings.status = status;
    old_earnings.amount = amount;
    old_earnings.transaction_id = transaction_id;

    this.setState({
      isLoading: false,
      previewEarnings: old_earnings,
    });

    this.setState({
      viewEarningsVisible: true,
      viewEarningsTitle: 'View Transaction',
    });
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = '#5cb7b7';

    const columns = [
      {
        // label: "Name",
        name: 'Date',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][0];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Withdrawal Method',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][1];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
          },
        },
      },
      {
        name: 'Status',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][2];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              if (value == 'pending') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-warning"
                    >
                      Pending
                    </span>
                  </li>
                );
              } else if (value == 'paid') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Paid
                    </span>
                  </li>
                );
              } else {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-danger"
                    >
                      Declined
                    </span>
                  </li>
                );
              }
            }
          },
        },
      },
      {
        name: 'Amount',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][3];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return '$' + value;
            }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let date = data[dataIndex][0];
            let withdrawal_method = data[dataIndex][1];
            let status = data[dataIndex][2];
            let amount = data[dataIndex][3];
            let transaction_id = data[dataIndex][4];

            return (
              <div>
                <button
                  onClick={() => {
                    this.viewTransaction(
                      date,
                      withdrawal_method,
                      status,
                      amount,
                      transaction_id
                    );
                  }}
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: '10px' }}
                >
                  View
                </button>
              </div>
            );
          },
        },
      },
    ];

    const data = this.state.earningsTransactionsArray;

    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      filter: false,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
            noMatch: 'This business has not earned any income yet'
        },
      },
    };
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
             <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                  <h4
                                    className="nk-block-title page-title"
                                    style={{ color: '#364a63' }}
                                  >
                                    {this.state.businessInfo.name.toString()}
                                  </h4>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                      href="#"
                                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                      data-target="pageMenu"
                                    >
                                      <em
                                        onClick={() => {
                                          this.pageMenu();
                                        }}
                                        className="icon ni ni-more-v"
                                      />
                                    </a>
                                    <div
                                      className="toggle-expand-content"
                                      data-content="pageMenu"
                                      style={{
                                        display: `${this.state.theDisplay}`,
                                      }}
                                    >
                                      <ul className="nk-block-tools g-3">
                                        {this.state.availableBalance >= 100 &&
                                        <li data-tut="reactour__addAServiceButton">
                                          <button
                                            type="submit"
                                            className="btn btn-md btn-primary btn-block"
                                            onClick={() => {
                                              let old_requestPayment = this.state.requestPayment;
                                              old_requestPayment.method = "";
                                              this.setState({
                                                showRequestPaymentModal: true,
                                                requestPayment: old_requestPayment,
                                                showPaypalInfo: false,
                                                showVenmoInfo: false,
                                                showBankTransferInfo: false,
                                              });
                                            }}
                                          >
                                            <span>Request Payment</span>
                                          </button>
                                        </li> 
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div data-tut="reactour__earningsDataTable">
                              <div className="row mb-3">
                                <div className="col-xxl-3 col-sm-3">
                                  <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              Total Transactions
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="data">
                                          <div className="data-group">
                                            <div className="amount">
                                              {this.state.totalTransactions}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xxl-3 col-sm-3">
                                  <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              Total Earnings
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="data">
                                          <div className="data-group">
                                            <div className="amount">
                                              {'$' + this.state.totalEarnings}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xxl-3 col-sm-3">
                                  <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">Withdrawn</h6>
                                          </div>
                                        </div>
                                        <div className="data">
                                          <div className="data-group">
                                            <div className="amount">
                                              {'$' + this.state.withdrawn}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xxl-3 col-sm-3">
                                  <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              Available Balance
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="data">
                                          <div className="data-group">
                                            <div className="amount">
                                              {'$' +
                                                this.state.availableBalance}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <MUIDataTable
                              title={'Earnings'}
                              data={data}
                              columns={columns}
                              options={options}
                            /> */}

                              <ThemeProvider theme={this.getMuiTheme()}>
                                <MUIDataTable
                                  title={'Earnings'}
                                  data={data}
                                  columns={columns}
                                  options={options}
                                />
                              </ThemeProvider>
                            </div>

                            <Modal
                              visible={this.state.viewEarningsVisible}
                              title={this.state.viewEarningsTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  viewEarningsVisible: false,
                                });
                              }}
                            >
                              <div className="col-md-12 col-xxl-6">
                                <div className="pricing-body">
                                  <ul className="pricing-features">
                                    <li>
                                      <span className="w-50">Date</span> -{' '}
                                      <span className="ml-auto">
                                        {this.state.previewEarnings.date}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">
                                        Withdrawal Method
                                      </span>{' '}
                                      -{' '}
                                      <span className="ml-auto">
                                        {this.state.previewEarnings.withdrawalMethod
                                          .charAt(0)
                                          .toUpperCase() +
                                          this.state.previewEarnings.withdrawalMethod.slice(
                                            1
                                          )}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">Status</span> -{' '}
                                      <span className="ml-auto">
                                        {this.state.previewEarnings.status
                                          .charAt(0)
                                          .toUpperCase() +
                                          this.state.previewEarnings.status.slice(
                                            1
                                          )}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">Amount</span> -{' '}
                                      <span className="ml-auto">
                                        {'$' +
                                          this.state.previewEarnings.amount}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">
                                        Transaction ID
                                      </span>{' '}
                                      -{' '}
                                      <span className="ml-auto">
                                        {
                                          this.state.previewEarnings
                                            .transaction_id
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Modal>

                            {/* request payment modal start */}

                            <Modal
                              visible={this.state.showRequestPaymentModal}
                              title={'Request Payment'}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  showRequestPaymentModal: false,
                                });
                              }}
                            >
                              <div className="form-group">
                                <div className="form-control-wrap">
                                  <form
                                    onSubmit={this.submitPaymentRequestForm}
                                  >
                                    <div>
                                      <div
                                        className="row"
                                        style={{ alignItems: 'normal' }}
                                      >
                                        <div className="col-12">
                                          <div className="project-info">
                                            <div
                                              className="row"
                                              // style={{ alignItems: 'normal' }}
                                            >
                                              <div className="col-sm-12 col-md-6 col-lg-6 form-control-wrap">
                                                <span
                                                  // htmlFor="status"
                                                >
                                                  Withdrawal Amount
                                                </span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span
                                                      className="input-group-text"
                                                      id="fv-phone"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Amount"
                                                    value={
                                                      this.state.requestPayment
                                                        .amount
                                                    }
                                                    onChange={(e) => {
                                                      this.handlePaymentRequest(
                                                        e,
                                                        'amount'
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                              <span
                                                  // htmlFor="status"
                                                >
                                                  Payout method
                                                </span>
                                                <div className="form-group">
                                                  <div className="form-control-wrap">
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                        <select
                                                          value={
                                                            this.state
                                                              .requestPayment
                                                              .method
                                                          }
                                                          onChange={(e) => {
                                                            this.payoutMethod(
                                                              e
                                                            );
                                                          }}
                                                          className="form-control"
                                                          id="default-06"
                                                        >
                                                          <option selected="selected" value="">
                                                            Select Payout method
                                                          </option>
                                                          <option value="Paypal">
                                                            Paypal
                                                          </option>
                                                          <option value="Venmo">
                                                            Venmo
                                                          </option>
                                                          <option value="Bank Transfer">
                                                            Bank Transfer
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* </div> */}
                                                </div>

                                              </div>
                                            </div>

                                            {this.state.showPaypalInfo && 
                                               <div className="mt-2">
                                                <span
                                                  // htmlFor="status"
                                                >
                                                  PayPal Email
                                                </span>
                                                <div className="input-group">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="PayPal Email"
                                                    value={
                                                      this.state.requestPayment
                                                        .paypalEmail
                                                    }
                                                    onChange={(e) => {
                                                      this.handlePaymentRequest(
                                                        e,
                                                        'paypal_email'
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>}

                                            {this.state.showVenmoInfo &&
                                            <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 form-control-wrap">
                                               <div className="mt-2">
                                                <span
                                                  // htmlFor="status"
                                                >
                                                  Venmo Phone Number
                                                </span>
                                                <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Venmo Phone Number"
                                                    value={
                                                      this.state.requestPayment
                                                        .venmoPhoneNumber
                                                    }
                                                    onChange={(e) => {
                                                      this.handlePaymentRequest(
                                                        e,
                                                        'venmo_phone_number'
                                                      );
                                                    }}
                                                  />

                                                {/* <PhoneInput
                                                    class="form-control"
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    style={{ fontSize: '14px', height: '35px' }}
                                                    value={this.state.requestPayment.venmoPhoneNumber}
                                                    onChange={(e) => {
                                                      this.handlePaymentRequest(e, 'venmo_phone_number');
                                                    }}
                                                  /> */}
                                                </div>
                                              </div>
                                              </div>

                                              <div className="col-sm-12 col-md-6 col-lg-6 form-control-wrap">
                                               <div className="mt-2">
                                                <span
                                                  // htmlFor="status"
                                                >
                                                  Venmo Username
                                                </span>
                                                <div className="input-group">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Venmo Username"
                                                    value={
                                                      this.state.requestPayment
                                                        .venmoUsername
                                                    }
                                                    onChange={(e) => {
                                                      this.handlePaymentRequest(
                                                        e,
                                                        'venmo_username'
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              </div>
                                              </div>}

                                          {this.state.showBankTransferInfo &&
                                            <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 form-control-wrap">
                                               <div className="mt-2">
                                                <span
                                                  // htmlFor="status"
                                                >
                                                  Account Number
                                                </span>
                                                <div className="input-group">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                    placeholder="Account Number"
                                                    value={
                                                      this.state.requestPayment
                                                        .accountNumber
                                                    }
                                                    onChange={(e) => {
                                                      this.handlePaymentRequest(
                                                        e,
                                                        'account_number'
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              </div>

                                              <div className="col-sm-12 col-md-6 col-lg-6 form-control-wrap">
                                               <div className="mt-2">
                                                <span>
                                                  Routing Number
                                                </span>
                                                <div className="input-group">
                                                  <input
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                    maxLength={9}
                                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                                    placeholder="Routing Number"
                                                    value={
                                                      this.state.requestPayment
                                                        .routingNumber
                                                    }
                                                    onChange={(e) => {
                                                      this.handlePaymentRequest(
                                                        e,
                                                        'routing_number'
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                {/* {this.state.checkRoutingNumber && 
                                                 <span style={{color: "red"}}>Invalid Routing Number</span>
                                                } */}
                                              </div>
                                              </div>

                                              <div className="col-md-12 mt-2">
                                              <span
                                                  // htmlFor="status"
                                                >
                                                  Account type
                                                </span>
                                                <div className="form-group">
                                                  <div className="form-control-wrap">
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                        <select
                                                          value={
                                                            this.state
                                                              .requestPayment
                                                              .accountType
                                                          }
                                                          onChange={(e) => {
                                                            this.accountType(
                                                              e
                                                            );
                                                          }}
                                                          className="form-control"
                                                          id="default-06"
                                                        >
                                                          <option selected="selected" value="">
                                                            Select account type
                                                          </option>
                                                          <option value="Personal checking">
                                                            Personal checking
                                                          </option>
                                                          <option value="Personal savings">
                                                            Personal savings
                                                          </option>
                                                          <option value="Business checking">
                                                            Business checking
                                                          </option>
                                                          <option value="Business savings">
                                                            Business savings
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* </div> */}
                                                </div>

                                              </div>
                                              </div>}

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{ marginTop: '12px' }}>
                                      {!this.state.isRequesting ? (
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <span>Submit</span>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          disabled
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <div
                                            className="spinner-border"
                                            role="status"
                                            style={{ margin: '-6px' }}
                                          >
                                            {' '}
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Modal>

                            {/* request payment modal end */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer
                      infoForFooter={reactLocalStorage.getObject('free_trial')}
                    />
                  </div>
                </div>
              </div>
              {/* <Tour
                onRequestClose={this.closeTour}
                disableInteraction={true}
                steps={earningsTourConfig}
                isOpen={isTourOpen}
                className="helper"
                rounded={5}
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
              /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Earnings;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import moment from 'moment';
import theme from '../../theme/black';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { TableFooter, TableCell, TableRow } from '@material-ui/core';
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Tour from "reactour";
// import {giftCardsTourConfig} from '../includes/tourConfig';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BusinessPortalLoader } from '../includes/Loaders';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Switch, Modal } from 'antd';
var numeral = require('numeral');

class Incentive extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      nothingToShow: true,
      isRequesting: false,
      giftCardVisible: false,
      showGiftCard: false,
      loadGiftCardSwitch: false,
      isTourOpen: false,
      isShowingMore: false,
      giftCardTitle: '',
      theDisplay: '',
      recurringList: [],
      totalTransactions: 0,
      totalPurchases: 0,
      totalRedemptions: 0,
      outstanding: 0,
      giftCardTransactionsArray: [],
      business_details: {
        name: '',
      },
      incentive: {
        id: '',
        title: '',
        description: '',
        fileList: [],
        finalRepeatList: [],
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
      },
      giftCard: {
        id: '',
        min: '',
        max: '',
        discount: '',
        status: 1,
      },
      previewGiftcard: {
        min: '',
        max: '',
        discount: '',
        status: 1,
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getGiftCard();
      this.getGiftCardTransactions();
      // if(this.state.userInfo.gift_cards_tour === 0){
      //   this.openTour();
      // }
    }
  }

  getGiftCard = () => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/gift/card',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        if (result.data.length > 0) {
          let old_preview_gift_card = this.state.previewGiftcard;
          old_preview_gift_card.max = result.data[0].maximum;
          old_preview_gift_card.min = result.data[0].minimum;
          old_preview_gift_card.discount = result.data[0].discount;
          old_preview_gift_card.status = result.data[0].status;

          let old_gift_card = this.state.giftCard;
          old_gift_card.id = result.data[0].id;
          old_gift_card.max = result.data[0].maximum;
          old_gift_card.min = result.data[0].minimum;
          old_gift_card.discount = result.data[0].discount;
          old_gift_card.status = result.data[0].status;
          this.setState({
            previewGiftcard: old_preview_gift_card,
            giftCard: old_gift_card,
            showGiftCard: true,
            // isLoading: false,
          });
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  getMuiTheme = () => createTheme({
    components: {
        MUIDataTableHeadCell: {
          styleOverrides:{
          fixedHeader: {
            zIndex: "0"
          }
        }
        },
        MUIDataTableSelectCell: {
          styleOverrides:{
          headerCell: {
            zIndex: "0"
          }
        }
        },
    }
})

  getGiftCardTransactions = () => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/gift/card/transactions',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let gift_card_transactions = result.data;
        let UsersArray = [];
        let sumTotalDeposit = 0;
        let sumTotalRedemptions = 0;
        let sumTotalOutstanding = 0;

        for (let i = 0; i < gift_card_transactions.length; i++) {
          let the_id = gift_card_transactions[i].id;
          let name = gift_card_transactions[i].name;
          let avatar = gift_card_transactions[i].avatar;
          let created_at = gift_card_transactions[i].created_at;
          // let type = gift_card_transactions[i].type;
          let gross_amount = gift_card_transactions[i].gross_amount;
          let net_amount = gift_card_transactions[i].net_amount;
          let discount_amount = gift_card_transactions[i].discount_amount;
          let transaction_id = gift_card_transactions[i].transaction_id;
          let transaction_status = gift_card_transactions[i].transaction_status;
          let timestamp_converted = moment(created_at).format('lll');
          if (transaction_status == 'paid') {
            sumTotalDeposit += parseFloat(gift_card_transactions[i].net_amount);
          } else {
            sumTotalRedemptions += parseFloat(gift_card_transactions[i].net_amount);
          }
          let employee_name = gift_card_transactions[i].employee_name;

          let UserArray = [];
          // UserArray.push(name);
          UserArray.push(gross_amount);
          UserArray.push(net_amount);
          UserArray.push(discount_amount);
          UserArray.push(transaction_id);
          UserArray.push(transaction_status);
          UserArray.push(timestamp_converted);
          UserArray.push(name);
          UserArray.push(avatar);
          UserArray.push(employee_name);
          // UserArray.push(type);

          UsersArray.push(UserArray);
        }

        sumTotalOutstanding =
          parseFloat(sumTotalDeposit) - parseFloat(sumTotalRedemptions);

        this.setState({
          ...this.state,
          giftCardTransactionsArray: UsersArray,
          totalTransactions: gift_card_transactions.length,
          totalPurchases: sumTotalDeposit,
          totalRedemptions: sumTotalRedemptions,
          outstanding: sumTotalOutstanding < 0 ? 0 : sumTotalOutstanding,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // disableBody = target => disableBodyScroll(target);
  // enableBody = target => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //   .post(
  //     CONSTANTS.API_BASE_URL + '/business/tour/update',
  //     { column_name: "gift_cards_tour" },
  //     AuthService.getAxiosHeaders(
  //       window.sessionStorage.getItem('new_business')
  //     )
  //   )
  //   .then((response) => {
  //     let result = response.data;
  //     reactLocalStorage.remove(
  //       'user_object'
  //     );
  //     reactLocalStorage.setObject(
  //       'user_object',
  //       JSON.stringify(result.data.user_details)
  //     );
  //   })
  //   .catch((error) => {
  //   });
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  addGiftCard = () => {
    let old_giftCard = this.state.giftCard;
    old_giftCard.min = 25;
    old_giftCard.max = 250;
    old_giftCard.discount = 0;
    this.setState({
      giftCardVisible: true,
      giftCardTitle: 'Edit Gift Card Settings',
    });
  };

  editGiftCard = () => {
    this.setState({
      giftCardVisible: true,
      giftCardTitle: 'Edit Gift Card Settings',
    });
  };

  handleGiftCardDetails = (event, name) => {
    if (name.toString() === 'min') {
      let old_giftCard = this.state.giftCard;
      old_giftCard.min = event.target.value;
      this.setState({
        giftCard: old_giftCard,
      });
    }
    if (name.toString() === 'max') {
      let old_giftCard = this.state.giftCard;
      old_giftCard.max = event.target.value;
      this.setState({
        giftCard: old_giftCard,
      });
    }
    if (name.toString() === 'discount') {
      let old_giftCard = this.state.giftCard;
      old_giftCard.discount = event.target.value;
      this.setState({
        giftCard: old_giftCard,
      });
    }
  };

  onChangeGiftCardStatus = (e) => {
    //console.log(`switch to ${e}`);
    if (e === true) {
      let old_giftCard = this.state.giftCard;
      old_giftCard.status = 1;
      this.setState({
        giftCard: old_giftCard,
      });
    } else {
      let old_giftCard = this.state.giftCard;
      old_giftCard.status = 0;
      this.setState({
        giftCard: old_giftCard,
      });
    }
  };

  submitGiftCardForm = (e) => {
    e.preventDefault();
    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/gift/card',
        { gift_card: JSON.stringify(this.state.giftCard) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        if (result.data.length > 0) {
          let old_preview_gift_card = this.state.previewGiftcard;
          old_preview_gift_card.max = result.data[0].maximum;
          old_preview_gift_card.min = result.data[0].minimum;
          old_preview_gift_card.discount = result.data[0].discount;

          let old_gift_card = this.state.giftCard;
          old_gift_card.id = result.data[0].id;
          old_gift_card.max = result.data[0].maximum;
          old_gift_card.min = result.data[0].minimum;
          old_gift_card.discount = result.data[0].discount;
          old_gift_card.status = result.data[0].status;
          this.setState({
            previewGiftcard: old_preview_gift_card,
            giftCard: old_gift_card,
            showGiftCard: true,
            isRequesting: false,
            giftCardVisible: false,
          });
        }
        AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          giftCardVisible: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";

    const columns = [
      {
        // label: "Name",
        name: 'Date/Time',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][5];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Customer',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let customer_name = data[dataIndex][6];
            let avatar = data[dataIndex][7];
            if (customer_name == '' || customer_name === undefined || customer_name === null) {
              return 'N/A';
            } else {
              return <div className="user-toggle">
              <div className="user-avatar sm">
                <img src={avatar} alt="" />
              </div>
              <div className="user-info d-none d-xl-block">
                <div className="user-name">
                  { customer_name }
                </div>
              </div>
            </div>;
            }
          },
        },
      },
      {
        name: 'Type',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][4];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value == 'paid' ? (
                <li style={{ padding: '0px' }} className="preview-item">
                  <span className="badge badge-success">Purchase</span>
                </li>
              ) : (
                <li style={{ padding: '0px' }} className="preview-item">
                  <span className="badge badge-danger">Redemption</span>
                </li>
              );
            }
          },
        },
      },
      {
        name: 'Redeemed By',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][8];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Value',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][0];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return numeral(value).format('$0.00a');
            }
          },
        },
      }
    ];

    const data = this.state.giftCardTransactionsArray;

    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      filter: false,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
            noMatch: "No gift card transactions have been recorded for this business"
        },
      },
    };
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
             <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h4
                                      className="nk-block-title page-title"
                                      style={{ color: '#364a63' }}
                                    >
                                      {this.state.businessInfo.name.toString()}
                                    </h4>
                                  </div>
                                              
                                  <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em
                                          onClick={() => {
                                            this.pageMenu();
                                          }}
                                          className="icon ni ni-more-v"
                                        />
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                        style={{
                                          display: `${this.state.theDisplay}`,
                                        }}
                                      >
                                        <ul className="nk-block-tools g-3">
                                        {this.state.giftCard.status === 1 ?
                                              <div className="nk-tb-col">
                                                <span className="badge badge-pill badge-success">Enabled</span>
                                              </div>
                                              : 
                                              <div className="nk-tb-col">
                                                <span className="badge badge-pill badge-danger">Disabled</span>
                                              </div>
                                              }
                                          {/*{this.state.showGiftCard === true ? (
                                            <li>
                                             
                                              <button
                                                type="submit"
                                                className="btn btn-md btn-primary btn-block"
                                                onClick={() => {
                                                  this.editGiftCard();
                                                }}
                                              >
                                                <span>
                                                  Edit Gift Card Settings
                                                </span>
                                              </button>
                                            </li>
                                          ) : (
                                            <li data-tut="reactour__addGiftCardButton">
                                              <button
                                                type="submit"
                                                className="btn btn-md btn-primary btn-block"
                                                onClick={() => {
                                                  this.addGiftCard();
                                                }}
                                              >
                                                <span>
                                                  Edit Gift Card Settings
                                                </span>
                                              </button>
                                            </li>
                                          )}*/}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div data-tut="reactour__giftCardsDataTable">
                              <div className="row mb-3">
                                <div className="col-xxl-3 col-sm-3">
                                  <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              Total Transactions
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="data">
                                          <div className="data-group">
                                            <div className="amount">
                                              {this.state.totalTransactions}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xxl-3 col-sm-3">
                                  <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              Total Revenue
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="data">
                                          <div className="data-group">
                                            <div className="amount">
                                              {numeral(this.state.totalPurchases).format('$0.00a')}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xxl-3 col-sm-3">
                                  <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              Total Redemptions
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="data">
                                          <div className="data-group">
                                            <div className="amount">
                                              {numeral(this.state.totalRedemptions).format('$0.00a')}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xxl-3 col-sm-3">
                                  <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              Outstanding
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="data">
                                          <div className="data-group">
                                            <div className="amount">
                                              {numeral(this.state.outstanding).format('$0.00a')}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                                <ThemeProvider theme={this.getMuiTheme()}>
                                  <MUIDataTable title={'Gift Card Transactions'} data={data} columns={columns} options={options} />
                                </ThemeProvider>
                            </div>

                            {/*<Modal
                              visible={this.state.giftCardVisible}
                              title={this.state.giftCardTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  giftCardVisible: false,
                                });
                              }}
                            >
                              <form onSubmit={this.submitGiftCardForm}>
                                <div className="row g-gs">
                                  <div className="col-md-8">
                                    <label
                                      className="form-label"
                                      htmlFor="fv-phone"
                                    >
                                      Allow Customers to purchase Gift Cards
                                    </label>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <div className="form-control-wrap">
                                        <div
                                          className="input-group"
                                          // style={{ marginTop: '6px' }}
                                        >
                                          <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                              <CloseOutlined />
                                            }
                                            defaultChecked
                                            size="large"
                                            defaultChecked={
                                              this.state.previewGiftcard
                                                .status === 1 && true
                                            }
                                            onChange={
                                              this.onChangeGiftCardStatus
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row g-gs">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="fv-phone"
                                      >
                                        Minimum Value
                                      </label>
                                      <div className="form-control-wrap">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text"
                                              id="fv-phone"
                                            >
                                              $
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            required
                                            value={this.state.giftCard.min}
                                            onChange={(e) => {
                                              this.handleGiftCardDetails(
                                                e,
                                                'min'
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="fv-phone"
                                      >
                                        Maximum Value
                                      </label>
                                      <div className="form-control-wrap">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text"
                                              id="fv-phone"
                                            >
                                              $
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            required
                                            value={this.state.giftCard.max}
                                            onChange={(e) => {
                                              this.handleGiftCardDetails(
                                                e,
                                                'max'
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="fv-phone"
                                      >
                                        Discount Amount
                                      </label>
                                      <div className="form-control-wrap">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text"
                                              id="fv-phone"
                                            >
                                              %
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            required
                                            value={this.state.giftCard.discount}
                                            onChange={(e) => {
                                              this.handleGiftCardDetails(
                                                e,
                                                'discount'
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-4">
                                  {!this.state.isRequesting ? (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary btn-block"
                                    >
                                      <span>Submit</span>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="btn btn-lg btn-primary btn-block"
                                    >
                                      <div
                                        className="spinner-border"
                                        role="status"
                                        style={{ margin: '-6px' }}
                                      >
                                        {' '}
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </form>
                                  </Modal>*/}
                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer
                      infoForFooter={reactLocalStorage.getObject('free_trial')}
                    />
                  </div>
                </div>
              </div>
              {/* <Tour
                onRequestClose={this.closeTour}
                disableInteraction={true}
                steps={giftCardsTourConfig}
                isOpen={isTourOpen}
                className="helper"
                rounded={5}
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
              /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Incentive;

import Cookie from 'universal-cookie';
import { reactLocalStorage } from 'reactjs-localstorage';
import CONSTANTS from './Constants';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
const notyf = new Notyf();
// const cookie = new Cookie();

const AuthService =  {
    checkProtected: () => {
        var access_token = reactLocalStorage.get('access_token');
				// var user_object = reactLocalStorage.getObject('user_object');
        if(access_token === undefined || access_token  === ""){
            window.location.replace(`${CONSTANTS.APP_URL}/logout`);
						return;
        }
				
    },

    getAuth: () => {
        var user_object = reactLocalStorage.getObject('user_object');
        var business_object = reactLocalStorage.getObject('business_object');
        var access_token = reactLocalStorage.get('access_token');
        if(JSON.stringify(user_object) === '{}' || access_token === ''){
            window.location.replace(`${CONSTANTS.APP_URL}/logout`);
						return;
        }
        return {
          "user": user_object,
          "business": business_object,
          "access_token": access_token
        }
    },

    getAxiosHeaders: (business_id) => {
        var access_token = reactLocalStorage.get('access_token');
        var business_object = JSON.parse(reactLocalStorage.getObject('business_object'));
        return { headers: {
          "Authorization": `Bearer ${access_token}`,
          "Business": `id ${business_id === null ? business_object.id : business_id}`,
        } }
    },

    getAxiosUploadHeaders: () => {
        var access_token = reactLocalStorage.get('access_token');
        return { 
            headers: {
                "Authorization" : `Bearer ${access_token}`, 
                "content-type": "multipart/form-data",
            } 
        }
    },

    Prompts: (type, message) => (
        notyf.open({
          type: type,
          message: message,
          duration: 3000,
          dismissible: true,
          icon: false,
          position: {
            x: 'center',
            y: 'top',
          },
        })
      )
};

export default AuthService;
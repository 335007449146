import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import moment from 'moment';
import theme from '../../theme/black';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Tour from "reactour";
// import {loyaltyTourConfig} from '../includes/tourConfig';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BusinessPortalLoader } from '../includes/Loaders';
import { Switch, Modal, Image, Divider, Input, Typography, Space } from 'antd';
var numeral = require('numeral');

class Loyalty extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.handleDefinePointsSubmit = this.handleDefinePointsSubmit.bind(this);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      post_business_define_points: [{ points: '', worth: '' }],
      business_define_points_preview: [{ points: '', worth: '' }],
      isLoading: true,
      nothingToShow: true,
      isRequesting: false,
      viewLoyaltyVisible: false,
      showGiftCard: false,
      loadGiftCardSwitch: false,
      isTourOpen: false,
      isShowingMore: false,
      editLoyaltyVisible: false,
      previewReceiptVisible: false,
      definePointsModal: false,
      viewLoyaltyTitle: '',
      theDisplay: '',
      post_business_define_points: [],
      recurringList: [],
      totalTransactions: 0,
      totalEarned: 0,
      totalRedeemed: 0,
      totalEarnings: 0,
      customersPerMonth: 0,
      pointsEarnedPerMonth: 0,
      pointsRedeemedPerMonth: 0,
      customersPerWeek: 0,
      pointsEarnedPerWeek: 0,
      pointsRedeemedPerWeek: 0,
      withdrawn: 0,
      availableBalance: 0,
      loyaltyTransactionsArray: [],
      editLoyaltyTitle: '',
      business_details: {
        name: '',
      },
      editLoyalty: {
        id: 0,
        user_name: '',
        employee_name: '',
        receipt_amount: 0,
        points: 0,
        date_time: '',
        status: '',
        url: '',
      },
      points: {
        points_threshold: '',
        points_worth: '',
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    this.getLoyalty();
    // if(this.state.userInfo.loyalty_tour === 0){
    //   this.openTour();
    // }
  }

  getLoyalty = (request) => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/loyalty',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let loyalty_transactions = result.data.loyalty_transactions;
        let UsersArray = [];
        let totalEarnings = [];
        let totalRedeemedd = [];

        for (let i = 0; i < loyalty_transactions.length; i++) {
          let the_id = loyalty_transactions[i].id;
          let user_id = loyalty_transactions[i].user_id;
          let employee_code = loyalty_transactions[i].employee_code;
          let receipt_amount = loyalty_transactions[i].receipt_amount;
          let points = loyalty_transactions[i].points;
          let status = loyalty_transactions[i].status;
          let created_at = moment(loyalty_transactions[i].created_at).format(
            'll hh:mm a'
          );
          let customer_name = loyalty_transactions[i].customer_name;
          let avatar = loyalty_transactions[i].avatar;
          let employee_name = loyalty_transactions[i].employee_name;
          let url = loyalty_transactions[i].receipt_image;

          if (status == 'earned') {
            totalEarnings.push(loyalty_transactions[i].status);
          }

          if (status == 'redeemed') {
            totalRedeemedd.push(loyalty_transactions[i].status);
          }

          let UserArray = [];
          UserArray.push(the_id);
          UserArray.push(user_id);
          UserArray.push(employee_code);
          UserArray.push(receipt_amount);
          UserArray.push(points);
          UserArray.push(status);
          UserArray.push(created_at);
          UserArray.push(customer_name);
          UserArray.push(avatar);
          UserArray.push(employee_name);
          UserArray.push(url);

          UsersArray.push(UserArray);
        }

        if (request == 'add') {
          this.setState({
            isRequesting: false,
            editLoyaltyVisible: false,
            definePointsModal: false,
          });
          AuthService.Prompts('success', 'Updated Successfully');
        }

        let old_points = this.state.points;
        old_points.points_threshold = result.data.business_define_points[0]['points'];
        old_points.points_worth = result.data.business_define_points[0]['worth'];
        this.setState({
          points: old_points,
        });

        this.setState({
          ...this.state,
          isLoading: false,
          loyaltyTransactionsArray: UsersArray,
          totalTransactions: loyalty_transactions.length,
          totalEarned: totalEarnings.length,
          totalRedeemed: totalRedeemedd.length,

          customersPerMonth: result.data.number_of_customers_per_month,
          pointsEarnedPerMonth: result.data.number_of_points_earned_per_month,
          pointsRedeemedPerMonth:
            result.data.number_of_points_redeemed_per_month,

          customersPerWeek: result.data.number_of_customers_per_week,
          pointsEarnedPerWeek: result.data.number_of_points_earned_per_week,
          pointsRedeemedPerWeek: result.data.number_of_points_redeemed_per_week,
        });

        if (result.data.business_define_points.length > 0) {
          for (let i = 0; i < result.data.business_define_points.length; i++) {
            this.setState({
              post_business_define_points: result.data.business_define_points,
              // business_delivery_options_preview: result.data.business_delivery_options,
            });
          }
        } else {
          this.setState({
            post_business_define_points: [{ points: '', worth: '' }],
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // disableBody = target => disableBodyScroll(target);
  // enableBody = target => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState((prevState) => ({
      isShowingMore: !prevState.isShowingMore,
    }));
  };

  handleReceiptAmount(e, n) {
    if (n.toString() === 'receipt_amount') {
      let old_editLoyalty = this.state.editLoyalty;
      old_editLoyalty.receipt_amount = e.target.value;
      this.setState({
        editLoyalty: old_editLoyalty,
      });
    }
    if (n.toString() === 'points') {
      let old_editLoyalty = this.state.editLoyalty;
      old_editLoyalty.points = e.target.value;
      this.setState({
        editLoyalty: old_editLoyalty,
      });
    }
  }

  removeDefinePointsFormFields(i) {
    let post_business_define_points = this.state.post_business_define_points;
    post_business_define_points.splice(i, 1);
    this.setState({ post_business_define_points });
  }

  addDefinePointsFields() {
    this.setState({
      post_business_define_points: [
        ...this.state.post_business_define_points,
        { points: '', worth: '' },
      ],
    });
  }

  handleDefinePoints(i, e, n) {
    let { post_business_define_points } = this.state;
    if (n == 'points') {
      let finalValue = e.target.value;
      post_business_define_points[i][n] = finalValue;
      this.setState({
        post_business_define_points,
      });
    } else {
      let finalValue = e.target.value;
      post_business_define_points[i][n] = finalValue;
      this.setState({
        post_business_define_points,
      });
    }
    //console.log(this.state.post_business_delivery_options);
  }

  submiteditLoyaltyStatusForm = (e) => {
    e.preventDefault();
    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/edit/loyalty/status',
        {
          loyalty_status: this.state.editLoyalty.status,
          loyalty_id: this.state.editLoyalty.id,
          receipt_amount: this.state.editLoyalty.receipt_amount,
          points: this.state.editLoyalty.points,
        },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getLoyalty('add');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          businessTagsModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              zIndex: '0',
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              zIndex: '0',
            },
          },
        },
      },
    });

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //   .post(
  //     CONSTANTS.API_BASE_URL + '/business/tour/update',
  //     { column_name: "loyalty_tour" },
  //     AuthService.getAxiosHeaders(
  //       window.sessionStorage.getItem('new_business')
  //     )
  //   )
  //   .then((response) => {
  //     let result = response.data;
  //     reactLocalStorage.remove(
  //       'user_object'
  //     );
  //     reactLocalStorage.setObject(
  //       'user_object',
  //       JSON.stringify(result.data.user_details)
  //     );
  //   })
  //   .catch((error) => {
  //   });
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  editLoyaltyStatus = (event) => {
    let old_edit_loyalty = this.state.editLoyalty;
    old_edit_loyalty.status = event.target.value;
    this.setState({
      editLoyalty: old_edit_loyalty,
    });
  };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  onChangePoints = (e, n) => {
    let { points } = this.state;
    if (n == 'points_threshold') {
      let old_points = this.state.points;
      old_points.points_threshold = e.target.value;
      this.setState({
        points: old_points,
      });
    } else {
      let old_points = this.state.points;
      old_points.points_worth = e.target.value;
      this.setState({
        points: old_points,
      });
    }
  };

  handleDefinePointsSubmit(event) {
    event.preventDefault();
    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/add/business/points/worth',
        { points: JSON.stringify(this.state.points) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let old_points = this.state.points;
        old_points.points_threshold = result.data.business_define_points[0]['points'];
        old_points.points_worth = result.data.business_define_points[0]['worth'];
        this.setState({
          points: old_points,
        });

        this.getLoyalty('add');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  }

  viewTransaction = (
    id,
    user_name,
    employee_name,
    receipt_amount,
    points,
    date_time,
    status,
    url
  ) => {
    var old_edit_loyalty = this.state.editLoyalty;
    old_edit_loyalty.id = id;
    old_edit_loyalty.user_name = user_name;
    old_edit_loyalty.employee_name = employee_name;
    old_edit_loyalty.receipt_amount = receipt_amount;
    old_edit_loyalty.points = points;
    old_edit_loyalty.date_time = date_time;
    old_edit_loyalty.status = status;
    old_edit_loyalty.url = url;

    this.setState({
      editLoyaltyVisible: true,
      editLoyalty: old_edit_loyalty,
      editLoyaltyTitle: 'View Transaction',
    });
  };

  // viewLoyalty = (
  //     date,
  //     withdrawal_method,
  //     status,
  //     amount,
  //     transaction_id) => {

  //     var old_earnings = this.state.editLoyalty;
  //     old_earnings.date = date;
  //     old_earnings.withdrawalMethod = withdrawal_method;
  //     old_earnings.status = status;
  //     old_earnings.amount = amount;
  //     old_earnings.transaction_id = transaction_id;

  //     this.setState({
  //         isLoading: false,
  //         editLoyalty: old_earnings,
  //     });

  // this.setState({
  //     viewLoyaltyVisible: true,
  //     viewLoyaltyTitle: 'View Loyalty',
  // });

  // }

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = '#5cb7b7';

    const columns = [
      {
        // label: "Name",
        name: 'User',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            // let value = data[dataIndex][1];
            let customer_name = data[dataIndex][7];
            let avatar = data[dataIndex][8];
            if (
              customer_name == '' ||
              customer_name === undefined ||
              customer_name === null
            ) {
              return 'N/A';
            } else {
              return (
                <div className="user-toggle">
                  <div className="user-avatar sm">
                    <img src={avatar} alt="" />
                  </div>
                  <div className="user-info d-none d-xl-block">
                    <div className="user-name">{customer_name}</div>
                  </div>
                </div>
              );
            }
          },
        },
      },
      {
        name: 'Employee',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][9];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Amount',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][3];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return numeral(value).format('$0.00a');
            }
          },
        },
      },
      {
        name: 'Points',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][4];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Date/Time',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][6];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Status',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][5];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              if (value == 'earned') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-success"
                    >
                      Earned
                    </span>
                  </li>
                );
              } else if (value == 'redeemed') {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-primary"
                    >
                      Redeemed
                    </span>
                  </li>
                );
              } else {
                return (
                  <li style={{ padding: '0px' }} className="preview-item">
                    <span
                      style={{ fontWeight: '700' }}
                      className="badge badge-danger"
                    >
                      Blocked
                    </span>
                  </li>
                );
              }
            }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let id = data[dataIndex][0];
            let user_name = data[dataIndex][7];
            let employee_name = data[dataIndex][9];
            let receipt_amount = data[dataIndex][3];
            let points = data[dataIndex][4];
            let date_time = data[dataIndex][6];
            let status = data[dataIndex][5];
            let url = data[dataIndex][10];

            return (
              <div>
                <button
                  onClick={() => {
                    this.viewTransaction(
                      id,
                      user_name,
                      employee_name,
                      receipt_amount,
                      points,
                      date_time,
                      status,
                      url
                    );
                  }}
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: '10px' }}
                >
                  Edit
                </button>
              </div>
            );
          },
        },
      },
    ];

    const data = this.state.loyaltyTransactionsArray;

    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      filter: false,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
          noMatch:
            'No loyalty transactions have been recorded for this business',
        },
      },
    };
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
            <>
              <img
                className="preloader"
                src="assets/images/logo_small.png"
                alt="image"
              />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                  <h4
                                    className="nk-block-title page-title"
                                    style={{ color: '#364a63' }}
                                  >
                                    {this.state.businessInfo.name.toString()}
                                  </h4>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                      href="#"
                                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                      data-target="pageMenu"
                                    >
                                      <em
                                        onClick={() => {
                                          this.pageMenu();
                                        }}
                                        className="icon ni ni-more-v"
                                      />
                                    </a>
                                    <div
                                      className="toggle-expand-content"
                                      data-content="pageMenu"
                                      style={{
                                        display: `${this.state.theDisplay}`,
                                      }}
                                    >
                                      <ul className="nk-block-tools g-3">
                                        {/* <li>
                                          <span>{`${this.state.points.points_threshold + " Points = $"}` + this.state.points.points_worth}</span>
                                        </li> */}
                                        <li>
                                          <button
                                            type="submit"
                                            className="btn btn-md btn-primary btn-block"
                                            onClick={() => {
                                              this.setState({
                                                definePointsModal: true,
                                              });
                                            }}
                                          >
                                            <span>Edit Loyalty Program</span>
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="example-alert mt-4"><div className="alert alert-gray alert-icon"><em className="icon ni ni-info" /> Customers earn 1 point for each dollar they spend at your establishment. Once they reach {`${this.state.points.points_threshold + " Points, they will receive $"}` + this.state.points.points_worth + " in credits, which they can use to pay for goods or services at your establishment."}</div></div>
                            </div>

                            <div data-tut="reactour__loyaltyDataTable">
                              <div className="nk-block">
                                <div className="row gy-gs">
                                  <div className="col-lg-5 col-xl-4">
                                    <div className="nk-block">
                                      <div className="nk-block-head-xs">
                                        <div className="nk-block-head-content">
                                          <h5 className="nk-block-title title">
                                            Overview
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="nk-block">
                                        <div className="card card-bordered text-light bg-light h-100">
                                          <div className="card-inner">
                                            <div className="nk-wg7">
                                              <div className="nk-wg7-stats">
                                                <div className="nk-wg7-title">
                                                  Total Transactions
                                                </div>
                                                <div
                                                  className="number-lg amount"
                                                  style={{ color: '#364a63' }}
                                                >
                                                  {this.state.totalTransactions}
                                                </div>
                                              </div>
                                              <div className="nk-wg7-stats-group">
                                                <div className="nk-wg7-stats w-50">
                                                  <div className="nk-wg7-title">
                                                    Earned
                                                  </div>
                                                  <div
                                                    className="number-lg"
                                                    style={{ color: '#364a63' }}
                                                  >
                                                    {this.state.totalEarned}
                                                  </div>
                                                </div>
                                                <div className="nk-wg7-stats w-50">
                                                  <div className="nk-wg7-title">
                                                    Redeemed
                                                  </div>
                                                  <div
                                                    className="number-lg"
                                                    style={{ color: '#364a63' }}
                                                  >
                                                    {this.state.totalRedeemed}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-7 col-xl-8">
                                    <div className="nk-block">
                                      <div className="nk-block-head-xs">
                                        <div className="nk-block-between-md g-2">
                                          <div className="nk-block-head-content">
                                            <h6 className="nk-block-title title">
                                              This Month
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row g-2">
                                        <div className="col-sm-4">
                                          <div className="card bg-light">
                                            <div className="nk-wgw sm">
                                              <div
                                                className="nk-wgw-inner"
                                              >
                                                <div className="nk-wgw-name">
                                                  <h5 className="nk-wgw-title title">
                                                    Number of customers
                                                  </h5>
                                                </div>
                                                <div className="nk-wgw-balance">
                                                  <div className="amount">
                                                    {
                                                      this.state
                                                        .customersPerMonth
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-4">
                                          <div className="card bg-light">
                                            <div className="nk-wgw sm">
                                              <div
                                                className="nk-wgw-inner"
                                              >
                                                <div className="nk-wgw-name">
                                                  <h5 className="nk-wgw-title title">
                                                    Points Earned
                                                  </h5>
                                                </div>
                                                <div className="nk-wgw-balance">
                                                  <div className="amount">
                                                    {
                                                      this.state
                                                        .pointsEarnedPerMonth
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-4">
                                          <div className="card bg-light">
                                            <div className="nk-wgw sm">
                                              <div
                                                className="nk-wgw-inner"
                                              >
                                                <div className="nk-wgw-name">
                                                  <h5 className="nk-wgw-title title">
                                                    Points Redeemed
                                                  </h5>
                                                </div>
                                                <div className="nk-wgw-balance">
                                                  <div className="amount">
                                                    {
                                                      this.state
                                                        .pointsRedeemedPerMonth
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="nk-block nk-block-md"
                                      style={{ marginTop: '0px' }}
                                    >
                                      <div className="nk-block-head-xs">
                                        <div className="nk-block-between-md g-2">
                                          <div className="nk-block-head-content">
                                            <h6 className="nk-block-title title">
                                              This Week
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row g-2">
                                        <div className="col-sm-4">
                                          <div className="card bg-light">
                                            <div className="nk-wgw sm">
                                              <div
                                                className="nk-wgw-inner"
                                              >
                                                <div className="nk-wgw-name">
                                                  <h5 className="nk-wgw-title title">
                                                    Number of customers
                                                  </h5>
                                                </div>
                                                <div className="nk-wgw-balance">
                                                  <div className="amount">
                                                    {
                                                      this.state
                                                        .customersPerWeek
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-4">
                                          <div className="card bg-light">
                                            <div className="nk-wgw sm">
                                              <div
                                                className="nk-wgw-inner"
                                              >
                                                <div className="nk-wgw-name">
                                                  <h5 className="nk-wgw-title title">
                                                    Points Earned
                                                  </h5>
                                                </div>
                                                <div className="nk-wgw-balance">
                                                  <div className="amount">
                                                    {
                                                      this.state
                                                        .pointsEarnedPerWeek
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-4">
                                          <div className="card bg-light">
                                            <div className="nk-wgw sm">
                                              <div
                                                className="nk-wgw-inner"
                                              >
                                                <div className="nk-wgw-name">
                                                  <h5 className="nk-wgw-title title">
                                                    Points Redeemed
                                                  </h5>
                                                </div>
                                                <div className="nk-wgw-balance">
                                                  <div className="amount">
                                                    {
                                                      this.state
                                                        .pointsRedeemedPerWeek
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="row mb-3">
                            <div className="col-xxl-3 col-sm-3">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">Total Transactions</h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {this.state.totalTransactions}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xxl-3 col-sm-3">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">
                                            Total Earnings
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {'$' + this.state.totalEarnings}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xxl-3 col-sm-3">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">Withdrawn</h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {'$' + this.state.withdrawn}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xxl-3 col-sm-3">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">
                                            Available Balance
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {'$' + this.state.availableBalance}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div> */}

                              <div style={{ marginTop: '20px' }}>
                                {/* <MUIDataTable
                                title={'Loyalty'}
                                data={data}
                                columns={columns}
                                options={options}
                              /> */}

                                <ThemeProvider theme={this.getMuiTheme()}>
                                  <MUIDataTable
                                    title={'Loyalty'}
                                    data={data}
                                    columns={columns}
                                    options={options}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>

                            {/* <Modal
                              visible={this.state.viewLoyaltyVisible}
                              title={this.state.viewLoyaltyTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  viewLoyaltyVisible: false,
                                });
                              }}
                            >
                              <div className="col-md-12 col-xxl-6">
                                <div className="pricing-body">
                                  <ul className="pricing-features">
                                    <li>
                                      <span className="w-50">Date</span> -{' '}
                                      <span className="ml-auto">
                                        {this.state.editLoyalty.date}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">
                                        Withdrawal Method
                                      </span>{' '}
                                      -{' '}
                                      <span className="ml-auto">
                                        {this.state.editLoyalty.withdrawalMethod
                                          .charAt(0)
                                          .toUpperCase() +
                                          this.state.editLoyalty.withdrawalMethod.slice(
                                            1
                                          )}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">Status</span> -{' '}
                                      <span className="ml-auto">
                                        {this.state.editLoyalty.status
                                          .charAt(0)
                                          .toUpperCase() +
                                          this.state.editLoyalty.status.slice(
                                            1
                                          )}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">Amount</span> -{' '}
                                      <span className="ml-auto">
                                        {'$' +
                                          this.state.editLoyalty.amount}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">
                                        Transaction ID
                                      </span>{' '}
                                      -{' '}
                                      <span className="ml-auto">
                                        {
                                          this.state.editLoyalty
                                            .transaction_id
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Modal> */}

                            <Modal
                              visible={this.state.previewReceiptVisible}
                              title="Preview Receipt"
                              width={330}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  previewReceiptVisible: false,
                                });
                              }}
                            >
                              <div
                                className="col-md-6 col-lg-3 col-xl-3 col-sm-12"
                                style={{ zIndex: '0' }}
                              >
                                <div className="preview-block">
                                  <div className="custom-control custom-checkbox image-control">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      // id={data.id}
                                    />
                                    <Image
                                      style={{
                                        objectFit: 'cover',
                                        background:
                                          'radial-gradient(black, transparent)',
                                      }}
                                      width={250}
                                      height={250}
                                      src={this.state.editLoyalty.url}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Modal>

                            {/* define points modal start */}

                            <Modal
                              visible={this.state.definePointsModal}
                              title="Edit Loyalty Program"
                              footer={null}
                              width={800}
                              onCancel={() => {
                                this.setState({
                                  definePointsModal: false,
                                });
                              }}
                            >
                              <form onSubmit={this.handleDefinePointsSubmit}>
                                <div className="card-preview">
                                  {/* <pre
                                    className="prettyprint lang-html"
                                    id="Tooltip1"
                                    style={{padding: "0.5rem", marginBottom: "20px"}}
                                  >
                                      Tooltip on top
                                  </pre> */}

                                  {/* {this.state.post_business_define_points.map(
                                    (element, i) => ( */}
                                  <div
                                    // key={i}
                                    style={{
                                      paddingBottom: '16px',
                                    }}
                                  >
                                    <div className="row">
                                      <div></div>

                                      <div className="col-md-6">
                                        <pre
                                          className="prettyprint lang-html"
                                          id="Tooltip1"
                                          style={{
                                            padding: '0.5rem',
                                            marginBottom: '20px',
                                          }}
                                        >
                                          Minimum Points Threshold
                                        </pre>
                                        <div className="form-group">
                                          <Input
                                            type="number"
                                            placeholder="Number of points"
                                            value={
                                              this.state.points.points_threshold
                                            }
                                            required
                                            onChange={(e) => {
                                              this.onChangePoints(
                                                e,
                                                'points_threshold'
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className="col-md-6"
                                        // style={{ marginTop: `${i != 0 && '8px'}` }}
                                      >
                                        <pre
                                          className="prettyprint lang-html"
                                          id="Tooltip1"
                                          style={{
                                            padding: '0.5rem',
                                            marginBottom: '20px',
                                          }}
                                        >
                                          Credit Value
                                        </pre>
                                        <div className="form-group">
                                          <Input
                                            placeholder="Points worth"
                                            value={
                                              this.state.points.points_worth
                                            }
                                            required
                                            onChange={(e) => {
                                              this.onChangePoints(
                                                e,
                                                'points_worth'
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <pre
                                          className="prettyprint lang-html"
                                          id="Tooltip1"
                                          style={{
                                            padding: '0.5rem',
                                            marginBottom: '20px',
                                          }}
                                        >
                                          $1 = 1 Point
                                        </pre> */}

                                        {this.state.points.points_threshold != "" > 0 && <pre
                                          className="prettyprint lang-html"
                                          id="Tooltip1"
                                          style={{
                                            padding: '0.5rem',
                                            marginBottom: '20px',
                                          }}
                                        >
                                          {`${this.state.points.points_threshold + " Points = $"}` + this.state.points.points_worth}
                                         {/* {this.state.points.points_threshold.length > 0 && this.state.points.points_threshold + " Points = $" + this.state.points.points_worth } */}
                                        </pre> }

                                </div>

                                <div className="row">
                                  <div className="col-lg-9"></div>
                                  <div
                                    className="col-lg-3"
                                    style={{ marginTop: '25px' }}
                                  >
                                    {!this.state.isRequesting ? (
                                      <button
                                        type="submit"
                                        className="btn btn-md btn-primary btn-block float-right"
                                      >
                                        <span>Save</span>
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        disabled
                                        className="btn btn-md btn-primary btn-block float-right"
                                      >
                                        <div
                                          className="spinner-border"
                                          role="status"
                                          style={{ margin: '-6px' }}
                                        >
                                          {' '}
                                        </div>
                                      </button>
                                    )}
                                    {/* </div> */}
                                  </div>
                                </div>
                              </form>
                            </Modal>

                            {/* define points modal start */}

                            {/* edit loyalty modal start */}

                            <Modal
                              visible={this.state.editLoyaltyVisible}
                              title={this.state.editLoyaltyTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  editLoyaltyVisible: false,
                                });
                              }}
                            >
                              <div className="col-md-12 col-xxl-6">
                                <div className="pricing-body">
                                  <ul className="pricing-features">
                                    <li>
                                      <span className="w-50">Username</span> -{' '}
                                      <span className="ml-auto">
                                        {this.state.editLoyalty.user_name}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">
                                        Employee name
                                      </span>{' '}
                                      -{' '}
                                      <span className="ml-auto">
                                        {this.state.editLoyalty.employee_name}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">
                                        Receipt amount
                                      </span>{' '}
                                      -{' '}
                                      <Input
                                        prefix="$"
                                        value={this.state.editLoyalty.receipt_amount}
                                        style={{
                                          fontSize: '14px',
                                          height: '35px',
                                          width: '120px',
                                          marginLeft: 'auto'
                                        }}
                                        onChange={(e) => {
                                          this.handleReceiptAmount(
                                            e,
                                            'receipt_amount'
                                          );
                                        }}
                                      />
                                    </li>
                                    <li>
                                      <span className="w-50">Points</span> -{' '}
                                      <Input
                                        value={this.state.editLoyalty.points}
                                        style={{
                                          fontSize: '14px',
                                          height: '35px',
                                          width: '120px',
                                          marginLeft: 'auto'
                                        }}
                                        onChange={(e) => {
                                          this.handleReceiptAmount(
                                            e,
                                            'points'
                                          );
                                        }}
                                      />
                                      {/* <span className="ml-auto">
                                        {this.state.editLoyalty.points}
                                      </span> */}
                                    </li>
                                    <li>
                                      <span className="w-50">Date/Time</span> -{' '}
                                      <span className="ml-auto">
                                        {this.state.editLoyalty.date_time}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">Status</span> -{' '}
                                      <span className="ml-auto">
                                        {this.state.editLoyalty.status
                                          .charAt(0)
                                          .toUpperCase() +
                                          this.state.editLoyalty.status.slice(
                                            1
                                          )}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="w-50">
                                        Preview receipt
                                      </span>{' '}
                                      -{' '}
                                      <span
                                        className="ml-auto"
                                        style={{
                                          color: 'blue',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            previewReceiptVisible: true,
                                          });
                                        }}
                                      >
                                        view
                                      </span>
                                    </li>

                                    {/* <li>
                                      <span className="w-50">
                                        Withdrawal Method
                                      </span>{' '}
                                      -{' '}
                                      <span className="ml-auto">
                                        {this.state.editLoyalty.withdrawalMethod
                                          .charAt(0)
                                          .toUpperCase() +
                                          this.state.editLoyalty.withdrawalMethod.slice(
                                            1
                                          )}
                                      </span>
                                    </li> */}

                                    {/* <li>
                                      <span className="w-50">
                                        Transaction ID
                                      </span>{' '}
                                      -{' '}
                                      <span className="ml-auto">
                                        {
                                          this.state.editLoyalty
                                            .transaction_id
                                        }
                                      </span>
                                    </li> */}
                                  </ul>

                                  <br />

                                  <form
                                    onSubmit={this.submiteditLoyaltyStatusForm}
                                  >
                                    <div>
                                      <span
                                      // htmlFor="status"
                                      >
                                        Edit status
                                      </span>
                                      <div className="form-group">
                                        <div className="form-control-wrap">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <select
                                                value={
                                                  this.state.editLoyalty.status
                                                }
                                                onChange={(e) => {
                                                  this.editLoyaltyStatus(e);
                                                }}
                                                className="form-control"
                                                id="default-06"
                                              >
                                                <option
                                                  selected="selected"
                                                  value=""
                                                >
                                                  Select Status
                                                </option>
                                                <option value="earned">
                                                  Earned
                                                </option>
                                                <option value="redeemed">
                                                  Redeemed
                                                </option>
                                                <option value="blocked">
                                                  Blocked
                                                </option>
                                              </select>
                                            </div>

                                            <div className="col-md-6">
                                              {!this.state.isRequesting ? (
                                                <button
                                                  type="submit"
                                                  className="btn btn-md btn-primary btn-block"
                                                >
                                                  <span>Submit</span>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  disabled
                                                  className="btn btn-lg btn-primary btn-block"
                                                >
                                                  <div
                                                    className="spinner-border"
                                                    role="status"
                                                    style={{ margin: '-6px' }}
                                                  >
                                                    {' '}
                                                  </div>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Modal>

                            {/* edit loyalty modal end */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer
                      infoForFooter={reactLocalStorage.getObject('free_trial')}
                    />
                  </div>
                </div>
              </div>
              {/* <Tour
                onRequestClose={this.closeTour}
                disableInteraction={true}
                steps={loyaltyTourConfig}
                isOpen={isTourOpen}
                className="helper"
                rounded={5}
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
              /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Loyalty;

import React from "react";
import CONSTANTS from '../misc/Constants';

class SideNav extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      homeClassName: "nk-menu-item active current-page",
      businessInformationClassName: "nk-menu-item",
      myWebsiteClassName: "nk-menu-item",
      guideClassName: "nk-menu-item",
      reviewsClassName: "nk-menu-item",
      incentiveClassName: "nk-menu-item",
      servicesClassName: "nk-menu-item",
      menuClassName: "nk-menu-item",
      eventsClassName: "nk-menu-item",
      giftCardsClassName: "nk-menu-item",
      messagingClassName: "nk-menu-item",
      earningsClassName: "nk-menu-item",
      billingClassName: "nk-menu-item",
      loyaltyClassName: "nk-menu-item",
      employeesClassName: "nk-menu-item",
      photosVideosClassName: "nk-menu-item",
      sideNavTrigger: "nk-sidebar nk-sidebar-fixed is-light"
    }
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    window.sideNavTrigger = "nk-sidebar nk-sidebar-fixed is-light";
    if(window.location.pathname.includes("/home")){
      this.setState({
        homeClassName: "nk-menu-item active current-page",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/business-information")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item active current-page",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/my-website")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item active current-page",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/guide")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item active current-page",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/reviews")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item active current-page",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/incentive")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item active current-page",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/services")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item active current-page",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/menu")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item active current-page",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/events")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item active current-page",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/gift-cards")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item active current-page",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/messaging")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item active current-page",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/earnings")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item active current-page",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/billing")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item active current-page",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/loyalty")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item active current-page",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/employees")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item active current-page",
        photosVideosClassName: "nk-menu-item"
      });
    }
    if(window.location.pathname.includes("/photos-videos")){
      this.setState({
        homeClassName: "nk-menu-item",
        businessInformationClassName: "nk-menu-item",
        myWebsiteClassName: "nk-menu-item",
        guideClassName: "nk-menu-item",
        reviewsClassName: "nk-menu-item",
        incentiveClassName: "nk-menu-item",
        servicesClassName: "nk-menu-item",
        menuClassName: "nk-menu-item",
        eventsClassName: "nk-menu-item",
        giftCardsClassName: "nk-menu-item",
        messagingClassName: "nk-menu-item",
        earningsClassName: "nk-menu-item",
        billingClassName: "nk-menu-item",
        loyaltyClassName: "nk-menu-item",
        employeesClassName: "nk-menu-item",
        photosVideosClassName: "nk-menu-item active current-page"
      });
    }
  }

  render(){
    return (

      <div
      style={{zIndex: '1'}}
      className={window.sideNavTrigger}
      data-content="sidebarMenu"
    >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <a
            href="./"
            className="logo-link nk-sidebar-logo"
          >
            <img
              className="logo-light logo-img logo-img-sm"
              src="assets/images/logo.png"
              srcSet="assets/images/logo.png 2x"
              alt="logo"
            />
            <img
              className="logo-dark logo-img"
              src="assets/images/logo.png"
              srcSet="assets/images/logo.png 2x"
              alt="logo-dark"
            />
            <img
              className="logo-small logo-img logo-img-small"
              src="assets/images/logo.png"
              srcSet="assets/images/logo.png 2x"
              alt="logo-small"
            />
          </a>
        </div>
        {/* <div className="nk-menu-trigger mr-n2">
          <a
            href="./"
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left" />
          </a>
          <a
            href="./business-information"
            className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-menu" />
          </a>
        </div> */}
      </div>
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar="init">
            <div
              className="simplebar-wrapper"
              style={{ margin: '-16px 0px -40px' }}
            >
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer" />
              </div>
              <div className="simplebar-mask">
                <div
                  className="simplebar-offset"
                  style={{ right: '0px', bottom: '0px' }}
                >
                  <div
                    className="simplebar-content-wrapper"
                    tabIndex={0}
                    role="region"
                    aria-label="scrollable content"
                    style={{
                      height: '100%',
                      overflow: 'hidden scroll',
                    }}
                  >
                    <div
                      className="simplebar-content"
                      style={{ padding: '16px 0px 40px' }}
                    >
                      <ul className="nk-menu">
                        {/* <li className={this.state.homeClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/home`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-growth-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Dashboard
                            </span>
                          </a>
                        </li> */}
                        <li className={this.state.businessInformationClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/business-information`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-bag-fill" />
                            </span>
                            <span className="nk-menu-text">Business Information</span>
                          </a>
                        </li>
                        <li className={this.state.loyaltyClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/loyalty`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-heart-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Loyalty Program
                            </span>
                          </a>
                        </li>
                        <li className={this.state.myWebsiteClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/my-website`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-monitor" />
                            </span>
                            <span className="nk-menu-text">My Website</span>
                          </a>
                        </li>
                        <li className={this.state.reviewsClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/reviews`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-star-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Reviews
                            </span>
                          </a>
                        </li>
                        <li className={this.state.photosVideosClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/photos-videos`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-img-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Gallery
                            </span>
                          </a>
                        </li>
                        <li className={this.state.incentiveClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/incentive`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-coin-alt-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Deals/Coupons
                            </span>
                          </a>
                        </li>
                        <li className={this.state.servicesClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/services`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-list-round" />
                            </span>
                            <span className="nk-menu-text">
                              Services
                            </span>
                          </a>
                        </li>
                        <li className={this.state.menuClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/menu`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-coffee-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Menu
                            </span>
                          </a>
                        </li>
                        <li className={this.state.eventsClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/events`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-ticket-plus-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Events
                            </span>
                          </a>
                        </li>
                        <li className={this.state.giftCardsClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/gift-cards`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-cards-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Gift Cards
                            </span>
                          </a>
                        </li>
                        <li className={this.state.messagingClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/messaging`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-chat-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Messaging
                            </span>
                          </a>
                        </li>
                        <li className={this.state.employeesClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/employees`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-user-add-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Employees
                            </span>
                          </a>
                        </li>
                        <li className={this.state.earningsClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/earnings`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-growth-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Earnings
                            </span>
                          </a>
                        </li>
                        <li className={this.state.billingClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/billing`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-cc-alt-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Billing
                            </span>
                          </a>
                        </li>
                        <li className={this.state.guideClassName}>
                          <a
                            href={`${CONSTANTS.APP_URL}/guide`}
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-help-fill" />
                            </span>
                            <span className="nk-menu-text">Help</span>
                          </a>
                        </li>
                        
                        {/* <li className="nk-menu-item">
                          <a
                            href="/#"
                            className="nk-menu-link"
                          >
                            <span className="nk-menu-icon">
                              <em className="icon ni ni-setting-alt-fill" />
                            </span>
                            <span className="nk-menu-text">
                              Account Settings
                            </span>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="simplebar-placeholder"
                style={{ width: 'auto', height: '585px' }}
              />
            </div>
            <div
              className="simplebar-track simplebar-horizontal"
              style={{ visibility: 'hidden' }}
            >
              <div
                className="simplebar-scrollbar"
                style={{ width: '0px', display: 'none' }}
              />
            </div>
            <div
              className="simplebar-track simplebar-vertical"
              style={{ visibility: 'visible' }}
            >
              <div
                className="simplebar-scrollbar"
                style={{
                  height: '188px',
                  display: 'block',
                  transform: 'translate3d(0px, 0px, 0px)',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    )
  }
}

export default SideNav;
import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import AuthService from "../misc/AuthService";
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { reactLocalStorage } from 'reactjs-localstorage';

class Home extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      isLoading: true,
      business_details: {
        place_id: '',
        bio: '',
        name: '',
        formatted_address: '',
        business_status: '',
        icon: '',
        icon_background_color: '',
        icon_mask_base_uri: '',
        phone_number: '',
        rating: '',
        url: '',
        user_ratings_total: '',
        vicinity: '',
        website: '',
        reference: '',
        lat: '',
        lng: '',
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    this.getBusinessDetails();
  }

  getBusinessDetails() {
    let business_details = JSON.parse(AuthService.getAuth().business);
    var old_business_details = this.state.business_details;
    old_business_details.bio = business_details.bio;
    old_business_details.name = business_details.name;
    old_business_details.rating = business_details.rating;
    old_business_details.user_ratings_total =
      business_details.user_ratings_total;
    old_business_details.phone_number = business_details.phone_number;
    old_business_details.website = business_details.website;
    this.setState({
      isLoading: false,
      business_details: old_business_details
    });
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>UnstuQ Business</title>
        </MetaTags>
        <div>
          <div className="nk-app-root bg-lighter">
            <div className="nk-main ">
              <SideNav />
              <div className="nk-wrap ">
              <HeaderNav/>

                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                              <h4
                                className="nk-block-title page-title"
                                style={{ color: '#364a63' }}
                              >
                                {this.state.business_details.name.toString()}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="row g-gs" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Footer infoForFooter={reactLocalStorage.getObject('free_trial')}/>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Home;

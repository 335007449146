import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import theme from '../../theme/black';
import { ThemeProvider } from '@mui/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import moment from 'moment';
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Tour from "reactour";
// import {servicesTourConfig} from '../includes/tourConfig';
import { Upload, Modal } from 'antd';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { BusinessPortalLoader } from '../includes/Loaders';
import { reactLocalStorage } from 'reactjs-localstorage';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DatePicker, message, Button } from 'antd';
const { RangePicker } = DatePicker;
// var Loader = require('react-loader');

// let theme = createTheme();
// theme = responsiveFontSizes(theme);

class Menu extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      nothingToShow: true,
      isRequesting: false,
      showServiceModal: false,
      previewVisible: false,
      allowEmptyStartDate: false,
      allowEmptyEndDate: false,
      deleteServiceModal: false,
      deleteServiceId: '',
      previewImage: '',
      previewTitle: '',
      theDisplay: '',
      serviceModalTitle: '',
      isTourOpen: false,
      isShowingMore: false,
      fileListt: [],
      getImageUrl: '',
      business_details: {
        name: '',
      },
      service: {
        id: '',
        fileList: []
      },
      fileList: [],
      uploading: false,
      menuItemFile: [],
      ext: '',
      menuItemFileResult: []
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getMenuItem("");
      // if(this.state.userInfo.services_tour === 0){
      //   this.openTour();
      // }
    }
  }

//   onDocumentLoadSuccess({ numPages }) {
//     // setNumPages(numPages);
//   }

  getMenuItem = (request) => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/menuitem',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let final_result = result.data.menu_item;
        if(final_result.length > 0){
          let final_resultt = final_result[0]['url'];
          console.log(final_result[0]['url']);
          if(request == "add"){
              this.setState({
                isRequesting: false,
                showServiceModal: false,
                menuItemFileResult: final_resultt
              });
              AuthService.Prompts('success', 'Updated Successfully');
              return;
          }
          this.setState({
            ...this.state,
            isLoading: false,
            menuItemFileResult: final_resultt
          });
        }
        else{
        this.setState({
          ...this.state,
          isLoading: false,
          menuItemFileResult: []
        });
      }
      })
      .catch((error) => {
      });
  };

  toggleShowMore = () => {
    this.setState(prevState => ({
        isLoading: false
    }));
  };

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //   .post(
  //     CONSTANTS.API_BASE_URL + '/business/tour/update',
  //     { column_name: "services_tour" },
  //     AuthService.getAxiosHeaders(
  //       window.sessionStorage.getItem('new_business')
  //     )
  //   )
  //   .then((response) => {
  //     let result = response.data;
  //     reactLocalStorage.remove(
  //       'user_object'
  //     );
  //     reactLocalStorage.setObject(
  //       'user_object',
  //       JSON.stringify(result.data.user_details)
  //     );
  //   })
  //   .catch((error) => {
  //   });
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  onDocumentLoadSuccess({ numPages }) {
    // setNumPages(numPages);
  }

  handleInputChange = (e) => {

    // e.target.files is an array of files
    let menuItemFileAnnex = [];

    const image = e.target.files[0];   
    const total = image.type.split("/");        

    // Read each video from the input
    e.target.files.forEach((file, index)=>{
        var reader = new FileReader();
        reader.onload = ()=>{
            // add the file to the array
            menuItemFileAnnex.push(reader.result);
        }
        reader.readAsDataURL(file);
    });

    setTimeout(
        function () {
            this.setState({
                menuItemFile: menuItemFileAnnex,
                ext: total[1]
            });
      }.bind(this),
      2000
    );

}

  addService = () => {
    let old_service = this.state.service;
    old_service.id = '';

    this.setState({
      showServiceModal: true,
      service: old_service,
      serviceModalTitle: 'Upload PDF Menu',
    });
  };

  submitServicesForm = (e) => {
    e.preventDefault();
    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/add/menuitem',
        { ext: this.state.ext, menu_item_file: this.state.menuItemFile },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        console.log(result);
        this.getMenuItem("add");
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          showServiceModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };


  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
             <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h4
                                      className="nk-block-title page-title"
                                      style={{ color: '#364a63' }}
                                    >
                                      {this.state.businessInfo.name.toString()}
                                    </h4>
                                  </div>
                                  <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em
                                          onClick={() => {
                                            this.pageMenu();
                                          }}
                                          className="icon ni ni-more-v"
                                        />
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                        style={{
                                          display: `${this.state.theDisplay}`,
                                        }}
                                      >
                                        <ul className="nk-block-tools g-3">
                                          <li data-tut="reactour__addAServiceButton">
                                            <button
                                              type="submit"
                                              className="btn btn-md btn-primary btn-block"
                                              onClick={() => {
                                                this.addService();
                                              }}
                                            >
                                              <span>Upload PDF Menu</span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            <Modal
                              visible={this.state.showServiceModal}
                              title={this.state.serviceModalTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  showServiceModal: false,
                                });
                              }}
                            >
                              <div className="form-group">
                                <div className="form-control-wrap">
                                  <form onSubmit={this.submitServicesForm}>
                                    <div>
                                    <span style={{color: "red"}}>Accepted file type: PDF</span>
                                    <p></p>
                                      <div
                                        className="row"
                                        style={{ alignItems: 'normal' }}
                                      >
                                        
                                        <div className="col-3">
                                        <input
                                            type="file"
                                            accept="images/png, images/jpeg, .pdf"
                                            onChange={this.handleInputChange}
                                        />
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{ marginTop: '12px' }}>
                                      {!this.state.isRequesting ? (
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <span>Submit</span>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          disabled
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <div
                                            className="spinner-border"
                                            role="status"
                                            style={{ margin: '-6px' }}
                                          >
                                            {' '}
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Modal>

                            {/* menu pdf preview goes here */}

                            <div className="row g-gs">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                              <div className="card card-full">
                                <div
                                  className="card-inner-group"
                                >
                                  {this.state.menuItemFileResult.length < 1 ? (
                                    <>
                                      <div
                                        style={{ backgroundColor: '#f5f6fa' }}
                                        className="nk-block-content nk-error-ld text-center"
                                      >
                                        <em
                                          className="icon ni ni-na fa-lg"
                                          style={{
                                            fontSize: '40vh',
                                            color: '#8094ae',
                                          }}
                                        ></em>
                                        <h3
                                          style={{
                                            color: '#8094ae',
                                          }}
                                          className="nk-error-title"
                                        >
                                          Upload PDF Menu
                                        </h3>
                                      </div>
                                    </>
                                  ) : (
                                    <>

                                <div
                                    className="holds-the-iframe"
                                    style={{ padding: '8px' }}
                                  >
                                    <iframe
                                        src={this.state.menuItemFileResult}
                                        frameborder="0"
                                        width="100%"
                                        height="1175"
                                    >
                                    </iframe>
                                </div>
                           
                                    </>
                                  )}

                                </div>
                              </div>
                            </div>
                          </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer
                      infoForFooter={reactLocalStorage.getObject('free_trial')}
                    />
                  </div>
                </div>
              </div>
              {/* <Tour
                onRequestClose={this.closeTour}
                disableInteraction={true}
                steps={servicesTourConfig}
                isOpen={isTourOpen}
                className="helper"
                rounded={5}
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
              /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Menu;

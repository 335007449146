import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import CONSTANTS from '../misc/Constants';
import { reactLocalStorage } from 'reactjs-localstorage';
import Iframe from 'react-iframe';
import { OnboardingLoader } from '../includes/Loaders';

class WelcomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    setTimeout(
        function () {
        this.setState({
          isLoading: false,
        });
      }.bind(this),
      2000
    );
    // window.sessionStorage.removeItem('business_information');
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <link
            rel="stylesheet"
            href="https://unstuq.com/assets/css/landingplugin.css"
          />
          <link
            rel="stylesheet"
            href="https://unstuq.com/assets/css/landingstyle.css"
          />
          <title>UnstuQ Business</title>
        </MetaTags>
        {this.state.isLoading ? (
          <>
          <img className="preloader" src="assets/images/logo_small.png" alt="image" />
          </>
        ) : (
          <div>
            <div className="content-wrapper">
              {/* /header */}
              <section id="home" className="wrapper bg-gradient-primary">
                <div className="container pt-10 pt-md-14 pb-8 text-center">
                  <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                    <div className="col-lg-7">
                      {/* <iframe src="https://www.youtube.com/watch?v=niG3YMU6jFk https://www.youtube.com/embed/-WAq-UoUWA4" frameborder="0" width="100%" height="500"></iframe> */}
                      <iframe src="https://www.youtube.com/embed/-WAq-UoUWA4" frameborder="0" width="100%" height="500"></iframe>
                      {/* <figure>
                        <img
                          className="w-auto"
                          src="./landing/assets/img/concept/concept2.png"
                          srcSet="./landing/assets/img/concept/concept2@2x.png 2x"
                          alt=""
                        />
                      </figure> */}
                    </div>
                    {/* /column */}
                    <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
                      <h1 className="display-1 mb-5 mx-md-n5 mx-lg-0">
                        Grow Your Business with Our Solutions.
                      </h1>
                        {/* <div className="d-flex flex-row mb-4">
                          <div>
                            <img
                              src="./landing/assets/img/icons/light-bulb.svg"
                              className="svg-inject icon-svg icon-svg-sm text-blue me-4"
                              alt=""
                            />
                          </div>
                          <div>
                            <h4 className="mb-1">Collect Ideas</h4>
                            <p className="mb-1">
                              Nulla vitae elit libero pharetra augue dapibus.
                            </p>
                          </div>
                        </div> */}
                        <div className="d-flex flex-row">
                          <div>
                            <span className="nk-menu-icon me-4">
                            <em className="icon ni ni-check-circle-fill" style={{fontSize: "60px"}}/>
                            </span>
                          </div>
                          <div>
                            <h4 className="mb-1">Magic Touch 1</h4>
                            <p className="mb-0">
                              Cras mattis consectetur purus sit amet.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-4">
                          <div>
                            <span className="nk-menu-icon me-4">
                            <em className="icon ni ni-check-circle-fill" style={{fontSize: "60px"}}/>
                            </span>
                          </div>
                          <div>
                            <h4 className="mb-1">Magic Touch 2</h4>
                            <p className="mb-0">
                              Cras mattis consectetur purus sit amet.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-4">
                          <div>
                            <span className="nk-menu-icon me-4">
                            <em className="icon ni ni-check-circle-fill" style={{fontSize: "60px"}}/>
                            </span>
                          </div>
                          <div>
                            <h4 className="mb-1">Magic Touch 3</h4>
                            <p className="mb-0">
                              Cras mattis consectetur purus sit amet.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-4">
                          <div>
                            <span className="nk-menu-icon me-4">
                              <em className="icon ni ni-check-circle-fill" style={{fontSize: "60px"}}/>
                              {/* <em className="icon ni ni-coin-alt-fill" style={{fontSize: "60px"}} /> */}
                            </span>
                          </div>
                          <div>
                            <h4 className="mb-1">Magic Touch 3</h4>
                            <p className="mb-0">
                              Cras mattis consectetur purus sit amet.
                            </p>
                          </div>
                        </div>
                      <span>
                        <a
                          className="btn btn-primary rounded-pill mt-4 nav-link scroll"
                          href={`${CONSTANTS.APP_URL}/onboarding`}
                        >
                          Get Started
                        </a>
                      </span>
                    </div>
                    {/* /column */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container */}
                </section>
             
              
            </div>
            {/* /.content-wrapper */}
            <div className="progress-wrap">
              <svg
                className="progress-circle svg-content"
                width="100%"
                height="100%"
                viewBox="-1 -1 102 102"
              >
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
              </svg>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default WelcomePage;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import {explore_data} from '../includes/SerpAPIPhotos';
import { BusinessPortalLoader } from '../includes/Loaders';
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Tour from "reactour";
// import {imagesTourConfig} from '../includes/tourConfig';
import { Upload, Modal, Image } from 'antd';
import { reactLocalStorage } from 'reactjs-localstorage';
import EllipsisText from 'react-ellipsis-text';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class PhotosAndVideos extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      isLoadingAws: false,
      isRequesting: false,
      edit_photos_videos: false,
      defaultChecked: false,
      isTourOpen: false,
      isShowingMore: false,
      business_photos_videos: [],
      theDisplay: '',
      delete_photos_videos_list: [],
      business_details: {
        place_id: '',
        bio: '',
        name: '',
        formatted_address: '',
        business_status: '',
        icon: '',
        icon_background_color: '',
        icon_mask_base_uri: '',
        phone_number: '',
        rating: '',
        url: '',
        user_ratings_total: '',
        vicinity: '',
        website: '',
        reference: '',
        lat: '',
        lng: '',
      },
      previewVisible: false,
      uploadPhotoVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      // if(this.state.businessInfo.photos_status == "inactive"){
      //   this.uploadAWS();
      // }
      // else{
      this.getPhotosVideos();
    // }
    }
  }

  uploadAWS(){
    this.setState({
      isLoading: false,
      isLoadingAws: true,
    });
    axios
    .get(
      CONSTANTS.API_BASE_URL + '/business/photo/videoschecker',
      AuthService.getAxiosHeaders(
        window.sessionStorage.getItem('new_business')
      )
    )
    .then((response) => {
      let result = response.data;
      reactLocalStorage.setObject(
        'user_object',
        JSON.stringify(result.data.user_details)
      );
      reactLocalStorage.setObject(
        'business_object',
        JSON.stringify(result.data.business_details)
      );
      this.setState({
        isLoading: false,
        isLoadingAws: false,
        business_photos_videos: result.data.business_photos_videos,
      });
        // this.openTour();
    })
    .catch((error) => {
      //console.log(error);
      this.setState({
        isLoading: false,
        isLoadingAws: false,
      });
    });
  }

  getPhotosVideos() {

      let business_details = JSON.parse(AuthService.getAuth().business);
      axios
      .post(CONSTANTS.API_BASE_URL + '/business/photo/videos', {
        link: business_details.photos_link,
      })
      .then((response) => {
        let result = response.data.data;

      var old_business_details = this.state.business_details;
      old_business_details.bio = business_details.bio;
      old_business_details.name = business_details.name;
      old_business_details.rating = business_details.rating;
      old_business_details.user_ratings_total =
        business_details.user_ratings_total;
      old_business_details.phone_number = business_details.phone_number;
      old_business_details.website = business_details.website;

      this.setState({
        isLoading: false,
        business_photos_videos: result.photos,
      });

      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });

    // axios
    //   .get(
    //     CONSTANTS.API_BASE_URL + '/business/photo/videos',
    //     AuthService.getAxiosHeaders(
    //       window.sessionStorage.getItem('new_business')
    //     )
    //   )
    //   .then((response) => {
    //     let result = response.data;
    //     this.setState({
    //       isLoading: false,
    //       business_photos_videos: result.data.business_photos_videos,
    //     });
    //   })
    //   .catch((error) => {
    //     this.setState({
    //       isLoading: false,
    //     });
    //   });
  }

  showHide = () => {
    if (this.state.edit_photos_videos === true) {
      // this.refs.minus.checked = false
      this.setState({
        edit_photos_videos: false,
        delete_photos_videos_list: [],
        defaultChecked: false,
      });
    } else {
      this.setState({
        edit_photos_videos: true,
      });
    }
  };

  // disableBody = target => disableBodyScroll(target);
  // enableBody = target => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //   .post(
  //     CONSTANTS.API_BASE_URL + '/business/tour/update',
  //     { column_name: "photos_tour" },
  //     AuthService.getAxiosHeaders(
  //       window.sessionStorage.getItem('new_business')
  //     )
  //   )
  //   .then((response) => {
  //     let result = response.data;
  //     reactLocalStorage.remove(
  //       'user_object'
  //     );
  //     reactLocalStorage.setObject(
  //       'user_object',
  //       JSON.stringify(result.data.user_details)
  //     );
  //   })
  //   .catch((error) => {
  //   });
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  onChangeAddToDeletedPhotosCheckbox = (e, position) => {
    //console.log('checked = ', e.target.checked);
    let { delete_photos_videos_list } = this.state;
    let tentative_array = delete_photos_videos_list;
    if (e.target.checked) {
      tentative_array.push(position);
      this.setState({
        delete_photos_videos_list: tentative_array,
      });
      //console.log(tentative_array);
    } else {
      let toRemove = position;
      let index = tentative_array.indexOf(toRemove);
      if (index > -1) {
        tentative_array.splice(index, 1);
      }
      this.setState({
        delete_photos_videos_list: tentative_array,
      });
      //console.log(tentative_array);
    }
  };

  submitDeletePhotoVideosForm = (e) => {
    this.setState({
      isRequesting: true,
      errorMessage: '',
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/photos/videos/delete',
        {
          delete_photos_videos_list: JSON.stringify(
            this.state.delete_photos_videos_list
          ),
        },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.setState({
          isRequesting: false,
          edit_photos_videos: false,
          delete_photos_videos_list: [],
          business_photos_videos: result.data,
        });
        AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  handleCancel = () => this.setState({ previewVisible: false });
  handleAddPhotoCancel = () => this.setState({ uploadPhotoVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({
      fileList: fileList,
    });
  };

  postImage = (e) => {
    e.preventDefault();
    if (this.state.fileList.length === 0) {
      AuthService.Prompts('error', 'At least one image is required');
      return;
    }

    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/photo/update',
        { file: JSON.stringify(this.state.fileList), checker: true },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.setState({
          isRequesting: false,
          uploadPhotoVisible: false,
          business_photos_videos: result.data,
        });
        AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          uploadPhotoVisible: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <Fragment>
        <MetaTags>
          <title>UnstuQ Business</title>
        </MetaTags>
        {this.state.isLoading ? (
           <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
        ) : (
          <div>
            <div className="nk-app-root bg-lighter">
              <div className="nk-main ">
                <SideNav />
                <div className="nk-wrap ">
                  <HeaderNav />

                  {this.state.isLoadingAws ? (
                    <div className="nk-content ">
                    <div className="container-fluid">
                      <div className="nk-content-inner">
                        <div className="nk-content-body">
                          <div className="nk-block-head nk-block-head-sm">

                          <>
                          <div
                            style={{ backgroundColor: '#f5f6fa' }}
                            className="nk-block-content nk-error-ld text-center"
                          >
                            <img
                              alt="example"
                              src="assets/images/images_loader.gif"
                            />
                            <h3
                              style={{
                                color: '#8094ae',
                                fontSize: 'px',
                              }}
                              className="nk-error-title"
                            >
                              Getting your images ready, please wait
                            </h3>
                          </div>
                        </>

                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                  ) : (
                  <div className="nk-content ">
                    <div className="container-fluid">
                      <div className="nk-content-inner">
                        <div className="nk-content-body">
                          <div className="nk-block-head nk-block-head-sm">

                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                  <h4
                                    className="nk-block-title page-title"
                                    style={{ color: '#364a63' }}
                                  >
                                    {this.state.business_details.name.toString()}
                                  </h4>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                      href="#"
                                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                      data-target="pageMenu"
                                    >
                                      <em
                                        onClick={() => {
                                          this.pageMenu();
                                        }}
                                        className="icon ni ni-more-v"
                                      />
                                    </a>
                                    <div
                                      className="toggle-expand-content"
                                      data-content="pageMenu"
                                      style={{
                                        display: `${this.state.theDisplay}`,
                                      }}
                                    >
                                      {/* <ul className="nk-block-tools g-3">
                                        <li data-tut="reactour__addPhotosButton">
                                          <button
                                            type="submit"
                                            className="btn btn-md btn-primary btn-block"
                                            onClick={() => {
                                              this.setState({
                                                uploadPhotoVisible: true,
                                                fileList: [],
                                                delete_photos_videos_list: [],
                                              });
                                            }}
                                          >
                                            <span>Add Images/Videos</span>
                                          </button>
                                        </li>
                                        <li
                                        data-tut="reactour__editPhotosButton"
                                        >
                                          <button
                                            type="submit"
                                            className="btn btn-md btn-primary btn-block"
                                            onClick={() => {
                                              this.showHide();
                                            }}
                                          >
                                            <span>
                                              {this.state.edit_photos_videos ===
                                              true
                                                ? 'Done'
                                                : 'Edit Images/Videos'}
                                            </span>
                                          </button>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                          {this.state.delete_photos_videos_list
                                            .length > 0 && (
                                            <form
                                              onSubmit={
                                                this.submitDeletePhotoVideosForm
                                              }
                                            >
                                              {!this.state.isRequesting ? (
                                                <button
                                                  type="submit"
                                                  className="btn btn-lg btn-danger btn-block"
                                                >
                                                  <span>Delete</span>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  disabled
                                                  className="btn btn-lg btn-danger btn-block"
                                                >
                                                  <div
                                                    className="spinner-border"
                                                    role="status"
                                                    style={{ margin: '-6px' }}
                                                  >
                                                    {' '}
                                                  </div>
                                                </button>
                                              )}
                                            </form>
                                          )}
                                        </li>
                                      </ul> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Modal
                            visible={this.state.uploadPhotoVisible}
                            title="Add Photo"
                            footer={null}
                            onCancel={this.handleAddPhotoCancel}
                          >
                            <>
                              <Upload
                                action={`${
                                  CONSTANTS.API_BASE_URL +
                                  '/business/photo/update'
                                }`}
                                listType="picture-card"
                                fileList={this.state.fileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleChange}
                              >
                                {this.state.fileList.length >= 8
                                  ? null
                                  : uploadButton}
                              </Upload>
                              <Modal
                                visible={this.state.previewVisible}
                                title={this.state.previewTitle}
                                footer={null}
                                onCancel={this.handleCancel}
                              >
                                <img
                                  alt="example"
                                  style={{ width: '100%' }}
                                  src={this.state.previewImage}
                                />
                              </Modal>
                            </>
                            <form onSubmit={this.postImage}>
                              {!this.state.isRequesting ? (
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary btn-block"
                                >
                                  <span>Submit</span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  disabled
                                  className="btn btn-lg btn-primary btn-block"
                                >
                                  <div
                                    className="spinner-border"
                                    role="status"
                                    style={{ margin: '-6px' }}
                                  >
                                    {' '}
                                  </div>
                                </button>
                              )}
                            </form>
                          </Modal>

                          {this.state.business_photos_videos.length < 1 ? (
                            <>
                              <div
                                style={{ backgroundColor: '#f5f6fa' }}
                                className="nk-block-content nk-error-ld text-center"
                              >
                                <em
                                  className="icon ni ni-na fa-lg"
                                  style={{
                                    fontSize: '40vh',
                                    color: '#8094ae',
                                  }}
                                ></em>
                                <h3
                                  style={{
                                    color: '#8094ae',
                                  }}
                                  className="nk-error-title"
                                >
                                  There are no Photos/Videos for this business
                                </h3>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row g-3">
                                {this.state.business_photos_videos.map(
                                  (data, index) => {
                                    if (this.state.edit_photos_videos) {
                                      return (
                                        <div className="col-md-6 col-lg-3 col-xl-3 col-sm-12" style={{zIndex: "0"}}>
                                          <div className="preview-block">
                                            <div className="custom-control custom-checkbox image-control">
                                              <input
                                                onChange={(e) => {
                                                  this.onChangeAddToDeletedPhotosCheckbox(
                                                    e,
                                                    data.id
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={data.id}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={data.id}
                                              >
                                                <img
                                                  style={{
                                                    objectFit: 'cover',
                                                    height: '250px',
                                                    width: '250px',
                                                    background:
                                                      'radial-gradient(black, transparent)',
                                                  }}
                                                  src={data.url}
                                                  alt=""
                                                />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="col-md-6 col-lg-3 col-xl-3 col-sm-12" style={{zIndex: "0"}}>
                                          <div className="preview-block">
                                            <div className="custom-control custom-checkbox image-control">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={data.id}
                                              />
                                              <Image
                                                style={{objectFit: 'cover', background:
                                                'radial-gradient(black, transparent)'}}
                                                width={250}
                                                height={250}
                                                src={data.image}
                                                />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                  <Footer
                    infoForFooter={reactLocalStorage.getObject('free_trial')}
                  />
                </div>
              </div>
            </div>
            {/* <Tour
              onRequestClose={this.closeTour}
              disableInteraction={true}
              steps={imagesTourConfig}
              isOpen={isTourOpen}
              className="helper"
              rounded={5}
              onAfterOpen={this.disableBody}
              onBeforeClose={this.enableBody}
            /> */}
          </div>
        )}
      </Fragment>
    );
  }
}

export default PhotosAndVideos;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BusinessPortalLoader } from '../includes/Loaders';
import { TimePicker, Modal, Select, AutoComplete, Divider, Input, Typography, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { business_information } from '../includes/DummyData';
import 'react-phone-number-input/style.css';
import EllipsisText from 'react-ellipsis-text';
import PhoneInput from 'react-phone-number-input/max';
import moment from 'moment';
import 'antd/dist/antd.css';
import 'notyf/notyf.min.css';
const format = 'h:mm a';
const { Option } = Select;

class BusinessInformation extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    window.major = this;
    window.postUserAndBusinessDetailsGlobal = this;
    window.closeClaimBusinessModalGlobal = this;
    this.handleSetupLoyaltySubmit = this.handleSetupLoyaltySubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeliveryServiceSubmit = this.handleDeliveryServiceSubmit.bind(this);
    this.handleSocialMediaHandleSubmit = this.handleSocialMediaHandleSubmit.bind(this);
    window.freeTrialDays = this;
    this.state = {
      isLoading: true,
      sundayValues: [{ opens: '', closes: '' }],
      mondayValues: [{ opens: '', closes: '' }],
      tuesdayValues: [{ opens: '', closes: '' }],
      wednesdayValues: [{ opens: '', closes: '' }],
      thursdayValues: [{ opens: '', closes: '' }],
      fridayValues: [{ opens: '', closes: '' }],
      saturdayValues: [{ opens: '', closes: '' }],
      sundayValuesPreview: [],
      mondayValuesPreview: [],
      tuesdayValuesPreview: [],
      wednesdayValuesPreview: [],
      thursdayValuesPreview: [],
      fridayValuesPreview: [],
      saturdayValuesPreview: [],
      values: [1],
      reviews_total: 0,
      businessDescriptionModal: false,
      hoursOfOperationModal: false,
      businessCategoryModal: false,
      businessWebsiteModal: false,
      businessAddressModal: false,
      businessPhoneModal: false,
      businessTagsModal: false,
      restaurantDefault: false,
      attractionDefault: false,
      hotelDefault: false,
      dineInDefault: false,
      takeoutDefault: false,
      deliveryDefault: false,
      cateringDefault: false,
      unableToClaimBusinessModal: false,
      onbaordingModal: false,
      freeTrialExpiredModal: false,
      businessDeliveryServicesModal: false,
      businessCuisineModal: false,
      user_items: [],
      business_types: [],
      business_service_options: [],
      business_cuisine_list: [],
      post_business_types: [],
      business_cuisine: [],
      business_cuisine_annex: [],
      business_cuisine_preview: [],
      manage_businesses: [],
      business_delivery_options: [],
      business_social_media_handle_list: [],
      post_business_delivery_options: [{ delivery_service: '', url: '', validate_url: false }],
      business_delivery_options_preview: [{ delivery_service: '', url: '' }],
      post_business_social_media_handle: [{ media: '', handle: '', validate_url: false }],
      business_social_media_handle_preview: [{ media: '', handle: '' }],
      postEmployeeNameHandle: [{ id: '', name: '', employee_code: '', status: 1 }],
      charLimit: 0,
      isTourOpen: false,
      isShowingMore: false,
      disableMonday: false,
      disableTuesday: false,
      disableWednesday: false,
      disableThursday: false,
      disableFriday: false,
      disableSaturday: false,
      disableSunday: false,
      checkRestaurant: false,
      checkAttraction: false,
      checkHotel: false,
      setupLoyaltyProgramModal: false,
      onbaordingModalAnotherOne: false,
      manageBusinessesModal: false,
      restaurantCheckboxStatus: '',
      newDeliveryOptionsName: '',
      deliveryOptionsLink: '',
      description: '',
      theDisplay: '',
      phone_number: '',
      website: '',
      formatted_address: '',
      businessPriceRangeModal: '',
      countryFlag: '',
      min_price: '',
      max_price: '',
      manageBusinessesID: '',
      URL: "",
      isInputValid: false,
      business_details: {
        place_id: '',
        bio: '',
        name: '',
        formatted_address: '',
        min_price: '',
        max_price: '',
        business_status: '',
        icon: '',
        icon_background_color: '',
        icon_mask_base_uri: '',
        phone_number: '',
        rating: '',
        reviews: '',
        url: '',
        user_ratings_total: '',
        vicinity: '',
        website: '',
        reference: '',
        lat: '',
        lng: '',
        loyalty_program_status: ''
      },
      filter: '',
      referred_by: '',
      userDetails: {
        sub: '',
        nickname: '',
        access_token: '',
        name: '',
        updated_at: '',
        email_verified: '',
        avatar: '',
        email: '',
      },
      freeTrialDays: 0,
      points: {
        points_threshold:100,
        points_worth: 10,
      },
      editLoyalty: {
        id: 0,
        user_name: '',
        employee_name: '',
        receipt_amount: 0,
        points: 0,
        date_time: '',
        status: '',
        url: '',
      },
    };
  }

  componentDidMount() {
    // window.initScript(window.NioApp, window.jQuery);
    window.getCurrentLocation();
    this.getCountryFlag();

    let userDetails = JSON.parse(window.sessionStorage.getItem('onboarding') != undefined ? window.sessionStorage.getItem('onboarding'):window.sessionStorage.getItem('userDetails'));
    let businessDetails = JSON.parse(
      window.sessionStorage.getItem('business_information')
    );
    let paymentInformation = JSON.parse(
      window.sessionStorage.getItem('payment_information')
    );

    if (window.sessionStorage.getItem('business_information') === null) {
      this.getUserAndBusinessDetails(
        window.sessionStorage.getItem('new_business'), ""
      );
    } else {
      if (window.sessionStorage.getItem('claim_another') != 'active') {
        let expirationDate = new Date();
        let validDays = 14;
        expirationDate.setTime(
          expirationDate.getTime() + validDays * 24 * 60 * 60 * 1000
        );
        // cookie.save('access_token', userDetails.access_token, {
        //   path: `${CONSTANTS.APP_URL}`,
        //   expires: expirationDate,
        // });
        reactLocalStorage.set('access_token', userDetails.access_token);
        reactLocalStorage.setObject('user_object', JSON.stringify(userDetails));
        this.postUserAndBusinessDetails(
          userDetails,
          businessDetails,
          paymentInformation
        );
      } else {
        this.postUserAndBusinessDetails(
          userDetails,
          businessDetails,
          paymentInformation
        );
      }
    }
  }

  toggleShowMore = () => {
    this.setState((prevState) => ({
      isShowingMore: !prevState.isShowingMore,
    }));
  };

  handleDeliveryOptions(i, e, n) {
    let { post_business_delivery_options } = this.state;
    if(n == 'delivery_service'){
      post_business_delivery_options[i][n] = e;
      this.setState({ post_business_delivery_options });
    }
    else{
      let finalValue = e.target.value;
      const isInputValid = !finalValue || this.regextValidation(finalValue);
      post_business_delivery_options[i][n] = finalValue;
      post_business_delivery_options[i]['validate_url'] = isInputValid;
      this.setState({ 
        post_business_delivery_options
      });
    }
    //console.log(this.state.post_business_delivery_options);
  }

  onChangePoints = (e, n) => {
    let { points } = this.state;
    if (n == 'points_threshold') {
      let old_points = this.state.points;
      old_points.points_threshold = e.target.value;
      this.setState({
        points: old_points,
      });
    } else {
      let old_points = this.state.points;
      old_points.points_worth = e.target.value;
      this.setState({
        points: old_points,
      });
    }
  };

  handleSocialMediaOptions(i, e, n) {
    let { post_business_social_media_handle } = this.state;
    if(n == 'media'){
      post_business_social_media_handle[i][n] = e;
      this.setState({ post_business_social_media_handle });
    }
    else{
      let finalValue = e.target.value;
      const isInputValid = !finalValue || this.regextValidation(finalValue);
      post_business_social_media_handle[i][n] = finalValue;
      post_business_social_media_handle[i]['validate_url'] = isInputValid;
      this.setState({ 
        post_business_social_media_handle
      });
    }
    //console.log(this.state.post_business_social_media_handle);
  }

  handleChangeSunday(i, e, n) {
    let { sundayValues } = this.state;

    let splitter_one = e.toString().split(' ');
    let timeString = splitter_one[4];
    let zero_one = timeString.toString().split(':');
    let zero_one_kyiv = zero_one[0] + ':' + zero_one[1];

    sundayValues[i][n] = zero_one_kyiv;
    this.setState({ sundayValues });
    //console.log(this.state.sundayValues);
  }

  handleChangeMonday(i, e, n) {
    let { mondayValues } = this.state;

    let splitter_one = e.toString().split(' ');
    let timeString = splitter_one[4];
    let zero_one = timeString.toString().split(':');
    let zero_one_kyiv = zero_one[0] + ':' + zero_one[1];

    mondayValues[i][n] = zero_one_kyiv;
    this.setState({ mondayValues });
    //console.log(this.state.mondayValues);
  }

  handleChangeTuesday(i, e, n) {
    let { tuesdayValues } = this.state;
    let splitter_one = e.toString().split(' ');
    let timeString = splitter_one[4];
    let zero_one = timeString.toString().split(':');
    let zero_one_kyiv = zero_one[0] + ':' + zero_one[1];

    tuesdayValues[i][n] = zero_one_kyiv;
    this.setState({ tuesdayValues });
    //console.log(this.state.tuesdayValues);
  }

  handleChangeWednesday(i, e, n) {
    let { wednesdayValues } = this.state;
    let splitter_one = e.toString().split(' ');
    let timeString = splitter_one[4];
    let zero_one = timeString.toString().split(':');
    let zero_one_kyiv = zero_one[0] + ':' + zero_one[1];

    wednesdayValues[i][n] = zero_one_kyiv;
    this.setState({ wednesdayValues });
    //console.log(this.state.wednesdayValues);
  }

  handleChangeThursday(i, e, n) {
    let { thursdayValues } = this.state;

    let splitter_one = e.toString().split(' ');
    let timeString = splitter_one[4];
    let zero_one = timeString.toString().split(':');
    let zero_one_kyiv = zero_one[0] + ':' + zero_one[1];

    thursdayValues[i][n] = zero_one_kyiv;
    this.setState({ thursdayValues });
    //console.log(this.state.thursdayValues);
  }

  handleChangeFriday(i, e, n) {
    let { fridayValues } = this.state;

    let splitter_one = e.toString().split(' ');
    let timeString = splitter_one[4];
    let zero_one = timeString.toString().split(':');
    let zero_one_kyiv = zero_one[0] + ':' + zero_one[1];

    fridayValues[i][n] = zero_one_kyiv;
    this.setState({ fridayValues });
    //console.log(this.state.fridayValues);
  }

  handleChangeSaturday(i, e, n) {
    let { saturdayValues } = this.state;

    let splitter_one = e.toString().split(' ');
    let timeString = splitter_one[4];
    let zero_one = timeString.toString().split(':');
    let zero_one_kyiv = zero_one[0] + ':' + zero_one[1];

    saturdayValues[i][n] = zero_one_kyiv;
    this.setState({ saturdayValues });
    //console.log(this.state.saturdayValues);
  }

  addDeliveryOptionFields() {
    this.setState({
      post_business_delivery_options: [...this.state.post_business_delivery_options, { delivery_service: '', url: '' }],
    });
  }

    addSocialMediaHandleFields() {
    this.setState({
      post_business_social_media_handle: [...this.state.post_business_social_media_handle, { media: '', handle: '' }],
    });
  }

  addSundayFormFields() {
    this.setState({
      sundayValues: [...this.state.sundayValues, { opens: '', closes: '' }],
    });
  }

  addMondayFormFields() {
    this.setState({
      mondayValues: [...this.state.mondayValues, { opens: '', closes: '' }],
    });
  }

  addTuesdayFormFields() {
    this.setState({
      tuesdayValues: [...this.state.tuesdayValues, { opens: '', closes: '' }],
    });
  }

  addWednesdayFormFields() {
    this.setState({
      wednesdayValues: [
        ...this.state.wednesdayValues,
        { opens: '', closes: '' },
      ],
    });
  }

  addThursdayFormFields() {
    this.setState({
      thursdayValues: [...this.state.thursdayValues, { opens: '', closes: '' }],
    });
  }

  addFridayFormFields() {
    this.setState({
      fridayValues: [...this.state.fridayValues, { opens: '', closes: '' }],
    });
  }

  addSaturdayFormFields() {
    this.setState({
      saturdayValues: [...this.state.saturdayValues, { opens: '', closes: '' }],
    });
  }

  removeDeliveryOptionFormFields(i) {
    let post_business_delivery_options = this.state.post_business_delivery_options;
    post_business_delivery_options.splice(i, 1);
    this.setState({ post_business_delivery_options });
  }

  removeSocialMediaHandleFormFields(i) {
    let post_business_social_media_handle = this.state.post_business_social_media_handle;
    post_business_social_media_handle.splice(i, 1);
    this.setState({ post_business_social_media_handle });
  }

  removeSundayFormFields(i) {
    let sundayValues = this.state.sundayValues;
    sundayValues.splice(i, 1);
    this.setState({ sundayValues });
  }

  removeMondayFormFields(i) {
    let mondayValues = this.state.mondayValues;
    mondayValues.splice(i, 1);
    this.setState({ mondayValues });
  }

  removeTuesdayFormFields(i) {
    let tuesdayValues = this.state.tuesdayValues;
    tuesdayValues.splice(i, 1);
    this.setState({ tuesdayValues });
  }

  removeWednesdayFormFields(i) {
    let wednesdayValues = this.state.wednesdayValues;
    wednesdayValues.splice(i, 1);
    this.setState({ wednesdayValues });
  }

  removeThursdayFormFields(i) {
    let thursdayValues = this.state.thursdayValues;
    thursdayValues.splice(i, 1);
    this.setState({ thursdayValues });
  }

  removeFridayFormFields(i) {
    let fridayValues = this.state.fridayValues;
    fridayValues.splice(i, 1);
    this.setState({ fridayValues });
  }

  removeSaturdayFormFields(i) {
    let saturdayValues = this.state.saturdayValues;
    saturdayValues.splice(i, 1);
    this.setState({ saturdayValues });
  }

  handleSocialMediaHandleSubmit(event) {
    event.preventDefault();
    for (let i = 0; i < this.state.post_business_social_media_handle.length; i++) {
      if(this.state.post_business_social_media_handle[i]['validate_url'] === false){
        AuthService.Prompts('error', 'Invalid url for ' + this.state.post_business_social_media_handle[i]['media']);
        return
      }
    }

    this.setState({
      isRequesting: true,
    });

    let all_hours = [];
    all_hours.push(this.state.post_business_social_media_handle);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/add/social/media/handle',
        { business_social_media_handle: JSON.stringify(all_hours) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getUserAndBusinessDetails(
          window.sessionStorage.getItem('new_business'), "add"
        );
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          hoursOfOperationModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  }

  handleDeliveryServiceSubmit(event) {
    event.preventDefault();
    for (let i = 0; i < this.state.post_business_delivery_options.length; i++) {
      if(this.state.post_business_delivery_options[i]['validate_url'] === false){
        AuthService.Prompts('error', 'Invalid url for ' + this.state.post_business_delivery_options[i]['delivery_service']);
        return
      }
    }

    this.setState({
      isRequesting: true,
    });

    let all_hours = [];
    all_hours.push(this.state.post_business_delivery_options);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/add/business/delivery/services',
        { business_delivery_options: JSON.stringify(all_hours) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getUserAndBusinessDetails(
          window.sessionStorage.getItem('new_business'), "add"
        );
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          hoursOfOperationModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      isRequesting: true,
    });

    let all_hours = [];
    all_hours.push(this.state.sundayValues);
    all_hours.push(this.state.mondayValues);
    all_hours.push(this.state.tuesdayValues);
    all_hours.push(this.state.wednesdayValues);
    all_hours.push(this.state.thursdayValues);
    all_hours.push(this.state.fridayValues);
    all_hours.push(this.state.saturdayValues);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/hours/operation',
        { hours_of_operation: JSON.stringify(all_hours) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getUserAndBusinessDetails(
          window.sessionStorage.getItem('new_business'), "add"
        );
        // this.setState({
        //   isRequesting: false,
        //   hoursOfOperationModal: false,
        // });
        // AuthService.Prompts('success', 'Updated Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          hoursOfOperationModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  }

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  getCountryFlag = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        this.setState({
          countryFlag: data.country_code,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  distributeData(response) {
    let result = response.data;
    reactLocalStorage.setObject(
      'user_object',
      JSON.stringify(result.data.user_details)
    );
    reactLocalStorage.setObject(
      'business_object',
      JSON.stringify(result.data.business_details)
    );

    let business_details = result.data.business_details;
    var old_business_details = this.state.business_details;
    old_business_details.bio = business_details.bio;
    old_business_details.name = business_details.name;
    old_business_details.rating = business_details.rating;
    old_business_details.user_ratings_total =
      business_details.user_ratings_total;
    old_business_details.phone_number = business_details.phone;
    old_business_details.formatted_address = business_details.address;
    old_business_details.website = business_details.website;
    old_business_details.min_price = business_details.price.min_price;
    old_business_details.max_price = business_details.price.max_price;
    old_business_details.reviews = business_details.reviews;

    let arr = [];
    arr.push(business_details.service_options);
    const re = /, ?|\n/gm;

    this.setState({
      mondayValuesPreview: business_details.hours[0].monday.split(re),
      tuesdayValuesPreview: business_details.hours[1].tuesday.split(re),
      wednesdayValuesPreview: business_details.hours[2].wednesday.split(re),
      thursdayValuesPreview: business_details.hours[3].thursday.split(re),
      fridayValuesPreview: business_details.hours[4].friday.split(re),
      saturdayValuesPreview: business_details.hours[5].saturday.split(re),
      sundayValuesPreview: business_details.hours[6].sunday.split(re),
      business_service_options: arr,
      business_delivery_options: result.data.business_delivery_options_list,
      setupLoyaltyProgramModal: business_details.loyalty_program_status === 1 ? false : true
    });

    reactLocalStorage.setObject(
      'free_trial', result.data.trial_days_left
    );
    reactLocalStorage.setObject(
      'subscription_status', business_details.subscription_status
    );

    if (business_details.subscription_status == 'inactive') {
        this.setState({ freeTrialExpiredModal: true });
    } else {
      this.setState({ freeTrialExpiredModal: false });
    }

    if(result.data.business_delivery_options.length > 0){
      for (
        let i = 0;
        i < result.data.business_delivery_options.length;
        i++
      ) {
          this.setState({
            post_business_delivery_options: result.data.business_delivery_options,
            business_delivery_options_preview: result.data.business_delivery_options,
          });
      }
    }

    if(result.data.business_social_media_handles.length > 0){
      for (
        let i = 0;
        i < result.data.business_social_media_handles.length;
        i++
      ) {
          this.setState({
            post_business_social_media_handle: result.data.business_social_media_handles,
            business_social_media_handle_preview: result.data.business_social_media_handles,
          });
      }
    }

    this.setState({
      isLoading: false,
      business_details: old_business_details,
      business_types: result.data.business_types,
      post_business_types: [],
      description: business_details.bio,
      manage_businesses: result.data.manage_businesses,
      business_social_media_handle_list: result.data.business_social_media_handle_list
    });

    //console.log(this.state.business_delivery_options);

  }

  closeClaimBusinessModal() {
    this.setState({
      onbaordingModal: false,
    });
  }

  postUserAndBusinessDetails(userDetails, businessDetails, paymentInformation) {
    this.setState({
      isLoading: true,
      // onbaordingModal: false,
    });
    console.log(userDetails);
    // let unstuq_affiliate_code = cookie.load('unstuq_affiliate_code');
    let unstuq_affiliate_code = reactLocalStorage.get('unstuq_affiliate_code');
    // if(unstuq_affiliate_code !== undefined || unstuq_affiliate_code  !== ""){
    //   oldData.referred_by = unstuq_affiliate_code;
    // }

    let cann = JSON.parse(reactLocalStorage.getObject('user_object'));
    axios
      .post(CONSTANTS.API_BASE_URL + '/user/business/details', {
        user_email:
          window.sessionStorage.getItem('claim_another') === 'active'
            ? cann.email
            : '',
        user_details: JSON.stringify(userDetails),
        business_details: JSON.stringify(businessDetails),
        // payment_details: JSON.stringify(paymentInformation),
        referred_by: unstuq_affiliate_code,
      })
      .then((response) => {
        window.sessionStorage.removeItem('onboarding');
        window.sessionStorage.removeItem('business_information');
        window.sessionStorage.removeItem('claim_another');
        reactLocalStorage.remove('unstuq_affiliate_code');
        this.distributeData(response);
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
        AuthService.Prompts('error', 'Unable to claim Business');
      });
  }

  onChangeDays = (e, category) => {
    let { post_business_types } = this.state;
    if (e.target.checked && category == 'attraction') {
      // this.setState({
      //   sundayDefault: true
      // });
      post_business_types.push('attraction');
      //console.log(this.state.post_business_types);
    } else {
      if (category == 'attraction') {
        // this.setState({
        //   sundayDefault: false
        // });
        let toRemove = 'attraction';
        let index = post_business_types.indexOf(toRemove);
        if (index > -1) {
          this.state.post_business_types.splice(index, 1);
        }
        //console.log(this.state.post_business_types);
      }
    }
  }

  regextValidation = URL => {
    const regex = new RegExp('https://([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(URL);
  };

  getUserAndBusinessDetails(business_id, request) {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/information',
        AuthService.getAxiosHeaders(business_id)
      )
      .then((response) => {
        this.distributeData(response);
        if(request == "add"){
          this.setState({
            isRequesting: false,
            hoursOfOperationModal: false,
            businessDescriptionModal: false,
            businessDeliveryServicesModal: false,
            socialMediaHandlesModal: false,
          });
          AuthService.Prompts('success', 'Updated Successfully');
        }
        else{
            this.setState({
              isLoading: true,
              manageBusinessesModal: false,
            });
            this.distributeData(response);
        }
      })
      .catch((error) => {
        //console.log(error);
        this.setState({
          isLoading: false,
        });
        // window.location = '/not-found';
      });
  }

  handleReceiptAmount(e, n) {
    if (n.toString() === 'receipt_amount') {
      let old_editLoyalty = this.state.editLoyalty;
      old_editLoyalty.receipt_amount = e.target.value;
      this.setState({
        editLoyalty: old_editLoyalty,
      });
    }
    if (n.toString() === 'points') {
      let old_editLoyalty = this.state.editLoyalty;
      old_editLoyalty.points = e.target.value;
      this.setState({
        editLoyalty: old_editLoyalty,
      });
    }
  }

  handleSetupLoyaltySubmit(event) {
    event.preventDefault();
    this.setState({
      isRequesting: true,
    });

    let all_hours = [];
    all_hours.push(this.state.postEmployeeNameHandle);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/setup/loyalty/proogram',
        { 
          points: JSON.stringify(this.state.points),
          employeeNameFinal: JSON.stringify(all_hours),
        },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.success();
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          showServiceModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  }

  handleEmployeeNameOptions(i, e, n) {
    let { postEmployeeNameHandle } = this.state;
    if(n == 'name'){
      postEmployeeNameHandle[i][n] = e.target.value;
      this.setState({ postEmployeeNameHandle });
    }
  }

  addEmployeeNameFields() {
    this.setState({
      postEmployeeNameHandle: [...this.state.postEmployeeNameHandle, { id: '', name: '', employee_code: '', status: 1 }],
    });
  }

  removeEmployeeNameFields(i) {
    let postEmployeeNameHandle = this.state.postEmployeeNameHandle;
    postEmployeeNameHandle.splice(i, 1);
    this.setState({ postEmployeeNameHandle });
  }

  onChangeStatus = (i, e, v) => {
    let { postEmployeeNameHandle } = this.state;
    if (e.target.checked && v == 'status') {
      postEmployeeNameHandle[i][v] = 1;
      this.setState({ postEmployeeNameHandle });
    } else {
      if (v == 'status') {
        postEmployeeNameHandle[i][v] = 0;
        this.setState({ postEmployeeNameHandle });
      }
    }
  };

  handleBusinessDetails = (event, name) => {
    if (name.toString() === 'description') {
      let value = event.target.value;
      let max = 500;
      if (event.which < 0x20) {
        return;
      }
      if (value.length == max) {
        event.preventDefault();
      } else if (value.length > max) {
        // Maximum exceeded
        value = value.substring(0, max);
      }
      let old_business_details = this.state.business_details;
      old_business_details.bio = value;
      this.setState({
        business_details: old_business_details,
      });
    }
    if (name.toString() === 'phone_number') {
      let old_business_details = this.state.business_details;
      old_business_details.phone_number = event;
      this.setState({
        business_details: old_business_details,
      });
    }
    if (name.toString() === 'address') {
      let old_business_details = this.state.business_details;
      old_business_details.formatted_address = event.target.value;
      this.setState({
        business_details: old_business_details,
      });
    }
    if (name.toString() === 'website') {
      let old_business_details = this.state.business_details;
      old_business_details.website = event.target.value;
      this.setState({
        business_details: old_business_details,
      });
    }
    if (name.toString() === 'min') {
      let old_business_details = this.state.business_details;
      old_business_details.min_price = event.target.value;
      this.setState({
        giftCard: old_business_details,
      });
    }
    if (name.toString() === 'max') {
      let old_business_details = this.state.business_details;
      old_business_details.max_price = event.target.value;
      this.setState({
        giftCard: old_business_details,
      });
    }
    if (name.toString() === 'search_tags') {
      this.setState({
        filter: event.target.value,
      });
    }
  };

  success = () => {
    Modal.success({
      content: 'You have successfully setup your loyalty program. Your business should now appear in the UnstuQ App as a Premium Business. \n We told you it would be easy. Click OK to be redirected to our help page to learn more about our platform.',
      onOk() {
        window.location.replace(`${CONSTANTS.APP_URL}/guide`)
      },});
  };

  submitBusinessDetailsForm = (e) => {
    this.setState({
      isRequesting: true,
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/details/update',
        { business_details: JSON.stringify(this.state.business_details) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getUserAndBusinessDetails(
          window.sessionStorage.getItem('new_business'), "add"
        );
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          businessDescriptionModal: false,
          businessWebsiteModal: false,
          businessPhoneModal: false,
          businessTagsModal: false,
          businessPriceRangeModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  submitBusinessCuisineForm = (e) => {
    e.preventDefault();
    this.setState({
      isRequesting: true,
    });
    //console.log(this.state.business_cuisine);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/add/cuisine',
        { business_cuisine: JSON.stringify(this.state.business_cuisine) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getUserAndBusinessDetails(
          window.sessionStorage.getItem('new_business'), "add"
        );
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          businessTagsModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  submitBusinessTypesForm = (e) => {
    e.preventDefault();
    if (this.state.post_business_types.length < 1) {
      AuthService.Prompts('error', 'At least one category required');
      return;
    }
    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/types/update',
        { business_types: JSON.stringify(this.state.post_business_types) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getUserAndBusinessDetails(
          window.sessionStorage.getItem('new_business'), "add"
        );
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          businessCategoryModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  submitHoursOfOperationForm = (e) => {
    e.preventDefault();

    this.setState({
      isRequesting: true,
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/hours/operation',
        { hours_of_operation: JSON.stringify(this.state.hours_of_operation) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getUserAndBusinessDetails(
          window.sessionStorage.getItem('new_business'), "add"
        );
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          hoursOfOperationModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  onChangeTypesCheckbox = (e, name) => {
    //console.log('checked = ', e.target.checked);
  };

  onChangeCuisine = (e) => {
    //console.log(`checked = ${e}`);
    let { business_cuisine_annex } = this.state;
    if (business_cuisine_annex.indexOf(e) == -1) {
      business_cuisine_annex.push(e);
    } else {
      // let toRemove = e;
      let indexx = business_cuisine_annex.indexOf(e);
      if (indexx > -1) {
        business_cuisine_annex.splice(indexx, 1);
      }
    }
    this.setState({
      business_cuisine: business_cuisine_annex.at(-1),
    });
    //console.log(this.state.business_cuisine);
  };

  onNameChange = (event) => {
    this.setState({
      newDeliveryOptionsName: event.target.value,
    });
  };

  deliveryOptionsLink = (event) => {
    this.setState({
      deliveryOptionsLink: event.target.value,
    });
  };

  addNewDeliveryOption = (e) => {
    e.preventDefault();
    if(this.state.newDeliveryOptionsName == ''){
      AuthService.Prompts('error', 'Item is required');
      return
    }
    let control = [];
    let { business_delivery_options } = this.state;
    // business_delivery_options.unshift(this.state.newDeliveryOptionsName);
    // control = [...this.state.business_delivery_options, {tag: this.state.newDeliveryOptionsName}];
    this.setState({
      business_delivery_options: [{tag: this.state.newDeliveryOptionsName}].concat(...this.state.business_delivery_options),
    });
    AuthService.Prompts('success', 'Item added to list');
    //console.log(this.state.business_delivery_options);
  };

  onChangeRestaurantCategory = (e) => {
    //console.log(`checked = ${e.target.checked}`);
    let { post_business_types } = this.state;
    if (e.target.checked) {
      post_business_types.push('restaurant');
      //console.log(this.state.post_business_types);
    } else {
      let toRemove = 'restaurant';
      let index = post_business_types.indexOf(toRemove);
      if (index > -1) {
        this.state.post_business_types.splice(index, 1);
      }
      //console.log(this.state.post_business_types);
    }
  };

  onChangeAttractionCategory = (e) => {
    //console.log(`checked = ${e.target.checked}`);
    let { post_business_types } = this.state;
    if (e.target.checked) {
      post_business_types.push('attraction');
      //console.log(this.state.post_business_types);
    } else {
      let toRemove = 'attraction';
      let index = post_business_types.indexOf(toRemove);
      if (index > -1) {
        this.state.post_business_types.splice(index, 1);
      }
      //console.log(this.state.post_business_types);
    }
  };

  onChangeHotelCategory = (e) => {
    //console.log(`checked = ${e.target.checked}`);
    let { post_business_types } = this.state;
    if (e.target.checked) {
      post_business_types.push('hotel');
      //console.log(this.state.post_business_types);
    } else {
      let toRemove = 'hotel';
      let index = post_business_types.indexOf(toRemove);
      if (index > -1) {
        this.state.post_business_types.splice(index, 1);
      }
      //console.log(this.state.post_business_types);
    }
  };

  onChangeHoursOfOperationCheckbox = (i, e, name) => {
    //console.log('checked = ', e.target.checked);

    if (e.target.checked && name == 'sunday') {
      let sundayValues = this.state.sundayValues;
      // sundayValues.splice(1);
      sundayValues[i]['opens'] = '';
      sundayValues[i]['closes'] = '';
      this.setState({
        checked: e.target.checked,
        disableSunday: true,
        sundayValues,
      });
    } else {
      if (name == 'sunday') {
        this.setState({
          disableSunday: false,
        });
      }
    }

    //console.log(this.state.sundayValues);

    if (e.target.checked && name == 'monday') {
      let mondayValues = this.state.mondayValues;
      // mondayValues.splice(1);
      mondayValues[i]['opens'] = '';
      mondayValues[i]['closes'] = '';
      this.setState({
        checked: e.target.checked,
        disableMonday: true,
        mondayValues,
      });
    } else {
      if (name == 'monday') {
        this.setState({
          disableMonday: false,
        });
      }
    }

    if (e.target.checked && name == 'tuesday') {
      let tuesdayValues = this.state.tuesdayValues;
      // tuesdayValues.splice(1);
      tuesdayValues[i]['opens'] = '';
      tuesdayValues[i]['closes'] = '';
      this.setState({
        checked: e.target.checked,
        disableTuesday: true,
        tuesdayValues,
      });
    } else {
      if (name == 'tuesday') {
        this.setState({
          disableTuesday: false,
        });
      }
    }

    if (e.target.checked && name == 'wednesday') {
      let wednesdayValues = this.state.wednesdayValues;
      // wednesdayValues.splice(1);
      wednesdayValues[i]['opens'] = '';
      wednesdayValues[i]['closes'] = '';
      this.setState({
        checked: e.target.checked,
        disableWednesday: true,
        wednesdayValues,
      });
    } else {
      if (name == 'wednesday') {
        this.setState({
          disableWednesday: false,
        });
      }
    }

    if (e.target.checked && name == 'thursday') {
      let thursdayValues = this.state.thursdayValues;
      // thursdayValues.splice(1);
      thursdayValues[i]['opens'] = '';
      thursdayValues[i]['closes'] = '';
      this.setState({
        checked: e.target.checked,
        disableThursday: true,
        thursdayValues,
      });
    } else {
      if (name == 'thursday') {
        this.setState({
          disableThursday: false,
        });
      }
    }

    if (e.target.checked && name == 'friday') {
      let fridayValues = this.state.fridayValues;
      // fridayValues.splice(1);
      fridayValues[i]['opens'] = '';
      fridayValues[i]['closes'] = '';
      this.setState({
        checked: e.target.checked,
        disableFriday: true,
        fridayValues,
      });
    } else {
      if (name == 'friday') {
        this.setState({
          disableFriday: false,
        });
      }
    }

    if (e.target.checked && name == 'saturday') {
      let saturdayValues = this.state.saturdayValues;
      // saturdayValues.splice(1);
      saturdayValues[i]['opens'] = '';
      saturdayValues[i]['closes'] = '';
      this.setState({
        checked: e.target.checked,
        disableSaturday: true,
        saturdayValues,
      });
    } else {
      if (name == 'saturday') {
        this.setState({
          disableSaturday: false,
        });
      }
    }
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = '#5cb7b7';

    return (
      <Fragment>
        <MetaTags>
          <title>UnstuQ Business</title>
        </MetaTags>
        {this.state.isLoading ? (
          <>
            <img
              className="preloader"
              src="assets/images/logo_small.png"
              alt="image"
            />
          </>
        ) : (
          <div>
            <div className="nk-app-root bg-lighter">
              <div className="nk-main ">
                <SideNav />
                <div className="nk-wrap ">
                  <HeaderNav />

                  <div className="nk-content ">
                    <div className="container-fluid">
                      <div className="nk-content-inner">
                        <div className="nk-content-body">
                          <div className="row g-gs">
                            <div className="col-md-12">
                              <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h4
                                      className="nk-block-title page-title"
                                      style={{ color: '#364a63' }}
                                    >
                                      Welcome to UnstuQ Business
                                    </h4>
                                  </div>
                                  <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em
                                          onClick={() => {
                                            this.pageMenu();
                                          }}
                                          className="icon ni ni-more-v"
                                        />
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                        style={{
                                          display: `${this.state.theDisplay}`,
                                        }}
                                      >
                                        <ul className="nk-block-tools g-3">
                                          {this.state.manage_businesses.length >
                                            1 && (
                                            <li
                                            // data-tut="reactour__manageBusinessesButton"
                                            >
                                              <button
                                                type="submit"
                                                className="btn btn-md btn-primary btn-block"
                                                onClick={() => {
                                                  this.setState({
                                                    manageBusinessesModal: true,
                                                    onbaordingModalAnotherOne: true,
                                                  });
                                                  // window.sessionStorage.setItem(
                                                  //   'claim_another',
                                                  //   'active'
                                                  // );
                                                }}
                                              >
                                                <span>Manage Businesses</span>
                                              </button>
                                            </li>
                                          )}
                                          <li
                                            data-tut="reactour__claimAnotherBusinessesButton"
                                            // className="nk-block-tools-opt"
                                          >
                                            <button
                                              type="submit"
                                              className="btn btn-md btn-primary btn-block"
                                              onClick={() => {
                                                window.open(
                                                  `https://business.google.com`,
                                                  '_blank'
                                                );
                                              }}
                                            >
                                              <span>Edit Business Info</span>
                                            </button>
                                          </li>
                                          <li
                                            data-tut="reactour__claimAnotherBusinessesButton"
                                            // className="nk-block-tools-opt"
                                          >
                                            <button
                                              type="submit"
                                              className="btn btn-md btn-primary btn-block"
                                              onClick={() => {
                                                // this.setState({
                                                //   manageBusinessesModal: true,
                                                //   onbaordingModalAnotherOne: true,
                                                // });
                                                window.location.replace(
                                                  `${CONSTANTS.APP_URL}/onboarding`
                                                );
                                                window.sessionStorage.setItem(
                                                  'claim_another',
                                                  'active'
                                                );
                                              }}
                                            >
                                              <span>
                                                Claim Another Business
                                              </span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row g-gs">
                            <div className="col-md-8 col-lg-8 col-sm-12">
                              {/* <div
                                className="card"
                                data-tut="reactour__manageDescription"
                              >
                                <div className="card-inner-group">
                                  
                                  <div className="card-inner card-inner-md">
                                    <div>
                                      <div className="user-info">
                                       
                                      </div>
                                    </div>
                                  </div>

                                  
                                </div>
                              </div> */}

                              <div className="card card-bordered">
                                <div className="card-inner">
                                  <h4 className="card-title mb-1">
                                    {this.state.business_details.name.toString()}
                                  </h4>
                                  {this.state.business_details.min_price !==
                                    undefined && (
                                    <span
                                      className="badge badge-dim badge-sm badge-pill badge-outline-primary"
                                      style={{
                                        color: '#007aff',
                                        backgroundColor: '#ffffff',
                                        borderColor: '#007aff',
                                      }}
                                    >
                                      {`${
                                        '$' +
                                        this.state.business_details.min_price +
                                        ' - ' +
                                        '$' +
                                        this.state.business_details.max_price +
                                        ' / PERSON'
                                      }`}
                                    </span>
                                  )}
                                  <div className="d-flex justify-content-between align-items-end">
                                    <ul className="pt-2 gy-1">
                                      <li>
                                        <em className="icon ni ni-map-pin" />
                                        <span>
                                          {
                                            this.state.business_details
                                              .formatted_address
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <em className="icon ni ni-call" />
                                        <span>
                                          {
                                            this.state.business_details
                                              .phone_number
                                          }
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <ul className="rating">
                                    {parseInt(
                                      this.state.business_details.rating
                                    ) === 0 && (
                                      <>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                      </>
                                    )}
                                    {parseInt(
                                      this.state.business_details.rating
                                    ) === 1 && (
                                      <>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                      </>
                                    )}
                                    {parseInt(
                                      this.state.business_details.rating
                                    ) === 2 && (
                                      <>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                      </>
                                    )}
                                    {parseInt(
                                      this.state.business_details.rating
                                    ) === 3 && (
                                      <>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                      </>
                                    )}
                                    {parseInt(
                                      this.state.business_details.rating
                                    ) === 4 && (
                                      <>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star" />
                                        </li>
                                      </>
                                    )}
                                    {parseInt(
                                      this.state.business_details.rating
                                    ) === 5 && (
                                      <>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                        <li>
                                          <em className="icon ni ni-star-fill" />
                                        </li>
                                      </>
                                    )}
                                    <li style={{ color: '#000000' }}>
                                      {'(' +
                                        this.state.business_details.reviews +
                                        ' reviews)'}
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div
                                className="card"
                                data-tut="reactour__manageDescription"
                              >
                                <div className="card-inner-group">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <h6 className="title">Description</h6>
                                      </div>
                                      {/* <div
                                        className="card-tools"
                                        onClick={() => {
                                          this.setState({
                                            businessDescriptionModal: true,
                                          });
                                        }}
                                        style={{
                                          color: '#017afe',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        Manage Description
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="card-inner card-inner-md">
                                    <div>
                                      <div className="user-info">
                                        {this.state.description === '' ? (
                                          <>
                                            <div
                                              className="preview-icon-box"
                                              style={{
                                                padding: '0px',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                              }}
                                            >
                                              <div className="preview-icon-wrap">
                                                <em
                                                  className="icon ni ni-na fa-lg"
                                                  style={{
                                                    fontSize: '49px',
                                                    color: '#8094ae',
                                                  }}
                                                ></em>
                                              </div>
                                              <span
                                                className="preview-icon-name"
                                                style={{ fontSize: '14px' }}
                                              >
                                                You do not have a Description
                                                for this business.{' '}
                                                <span
                                                  onClick={() => {
                                                    // this.setState({
                                                    //   businessDescriptionModal: true,
                                                    // });
                                                    window.open(
                                                      `https://business.google.com`,
                                                      '_blank'
                                                    );
                                                  }}
                                                  style={{
                                                    color: '#017afe',
                                                    cursor: 'pointer',
                                                  }}
                                                >
                                                  Add Description
                                                </span>
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          <span
                                            className="sub-text"
                                            style={{ fontSize: '14px' }}
                                          >
                                            {this.state.description}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="card"
                                data-tut="reactour__manageWebsite"
                              >
                                <div className="card-inner-group">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <h6 className="title">
                                          Delivery Services
                                        </h6>
                                      </div>
                                      <div
                                        className="card-tools"
                                        onClick={() => {
                                          this.setState({
                                            businessDeliveryServicesModal: true,
                                          });
                                        }}
                                        style={{
                                          color: '#017afe',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        Manage Delivery Services
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-inner card-inner-md">
                                    <div
                                      className="user-card"
                                      style={{ display: 'inherit' }}
                                    >
                                      <div className="user-info">
                                        {this.state
                                          .business_delivery_options_preview[0][
                                          'url'
                                        ] == '' ? (
                                          <>
                                            <div
                                              className="preview-icon-box"
                                              style={{
                                                padding: '0px',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                              }}
                                            >
                                              <div className="preview-icon-wrap">
                                                <em
                                                  className="icon ni ni-na fa-lg"
                                                  style={{
                                                    fontSize: '49px',
                                                    color: '#8094ae',
                                                  }}
                                                ></em>
                                              </div>
                                              <span
                                                className="preview-icon-name"
                                                style={{ fontSize: '14px' }}
                                              >
                                                You do not have any Delivery
                                                Services for this business.{' '}
                                                <br />
                                                <span
                                                  onClick={() => {
                                                    this.setState({
                                                      businessDeliveryServicesModal: true,
                                                    });
                                                  }}
                                                  style={{
                                                    color: '#017afe',
                                                    cursor: 'pointer',
                                                  }}
                                                >
                                                  Add Delivery Service
                                                </span>
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="form-group">
                                            <ul class="custom-control-group">
                                              {this.state.business_delivery_options_preview.map(
                                                (data, index) => {
                                                  return (
                                                    <>
                                                      {this.state
                                                        .business_delivery_options_preview
                                                        .length > 0 && (
                                                        <li>
                                                          <div class="custom-control custom-checkbox custom-control-pro no-control">
                                                            <input
                                                              style={{
                                                                cursor: 'none',
                                                              }}
                                                              type="text"
                                                              class="custom-control-input"
                                                              disabled
                                                            />
                                                            <label class="custom-control-label bg-transparent">
                                                              {/* <em className="icon ni ni-mobile"></em> */}
                                                              <span>
                                                                {
                                                                  data.delivery_service
                                                                }
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </li>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="card"
                                data-tut="reactour__manageWebsite"
                              >
                                <div className="card-inner-group">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <h6 className="title">
                                          Social Media Handles
                                        </h6>
                                      </div>
                                      <div
                                        className="card-tools"
                                        onClick={() => {
                                          this.setState({
                                            socialMediaHandlesModal: true,
                                          });
                                        }}
                                        style={{
                                          color: '#017afe',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        Manage Social Media Handles
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-inner card-inner-md">
                                    <div
                                      className="user-card"
                                      style={{ display: 'inherit' }}
                                    >
                                      <div className="user-info">
                                        {this.state
                                          .business_social_media_handle_preview[0][
                                          'handle'
                                        ] == '' ? (
                                          <>
                                            <div
                                              className="preview-icon-box"
                                              style={{
                                                padding: '0px',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                              }}
                                            >
                                              <div className="preview-icon-wrap">
                                                <em
                                                  className="icon ni ni-na fa-lg"
                                                  style={{
                                                    fontSize: '49px',
                                                    color: '#8094ae',
                                                  }}
                                                ></em>
                                              </div>
                                              <span
                                                className="preview-icon-name"
                                                style={{ fontSize: '14px' }}
                                              >
                                                You have not added any social
                                                media handle for this business.{' '}
                                                <br />
                                                <span
                                                  onClick={() => {
                                                    this.setState({
                                                      socialMediaHandlesModal: true,
                                                    });
                                                  }}
                                                  style={{
                                                    color: '#017afe',
                                                    cursor: 'pointer',
                                                  }}
                                                >
                                                  Add Social Media Handle
                                                </span>
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="form-group">
                                            <ul class="custom-control-group">
                                              {this.state.business_social_media_handle_preview.map(
                                                (data, index) => {
                                                  return (
                                                    <>
                                                      {this.state
                                                        .business_social_media_handle_preview
                                                        .length > 0 && (
                                                        <li>
                                                          <div class="custom-control custom-checkbox custom-control-pro no-control">
                                                            <input
                                                              style={{
                                                                cursor: 'none',
                                                              }}
                                                              type="text"
                                                              class="custom-control-input"
                                                              disabled
                                                            />
                                                            <label class="custom-control-label bg-transparent">
                                                              {/* <em className="icon ni ni-mobile"></em> */}
                                                              <span>
                                                                {data.media}
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </li>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-sm-12">
                              <div
                                className="card"
                                data-tut="reactour__manageHoursOfOperation"
                              >
                                <div className="card-inner border-bottom">
                                  <div className="card-title-group">
                                    <div className="card-title">
                                      <h6 className="title">
                                        Hours of Operation
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-inner">
                                  <div className="timeline">
                                    <ul
                                      className="timeline-list"
                                      style={{
                                        height: '300px',
                                        overflow: 'scroll',
                                      }}
                                    >
                                      <li className="timeline-item">
                                        <div className="timeline-status bg-primary" />
                                        <div
                                          className="timeline-date"
                                          style={{ width: '100px' }}
                                        >
                                          Sunday{' '}
                                          <em className="icon ni ni-alarm-alt" />
                                        </div>
                                        <div className="timeline">
                                          <ul className="timeline-list">
                                            {this.state.sundayValuesPreview.map(
                                              (element, i) => (
                                                <div key={i}>
                                                  <li
                                                    className="timeline-item"
                                                    style={{
                                                      marginTop: `${
                                                        i != 0 && '8px'
                                                      }`,
                                                    }}
                                                  >
                                                    <div className="timeline-data">
                                                      <div className="timeline-des">
                                                        <span className="time">
                                                          {element === ''
                                                            ? 'Closed'
                                                            : element}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </li>

                                      <li className="timeline-item">
                                        <div className="timeline-status bg-primary" />
                                        <div
                                          className="timeline-date"
                                          style={{ width: '100px' }}
                                        >
                                          Monday{' '}
                                          <em className="icon ni ni-alarm-alt" />
                                        </div>
                                        <div className="timeline">
                                          <ul className="timeline-list">
                                            {this.state.mondayValuesPreview.map(
                                              (element, i) => (
                                                <div key={i}>
                                                  <li
                                                    className="timeline-item"
                                                    style={{
                                                      marginTop: `${
                                                        i != 0 && '8px'
                                                      }`,
                                                    }}
                                                  >
                                                    <div className="timeline-data">
                                                      <div className="timeline-des">
                                                        <span className="time">
                                                          {element === ''
                                                            ? 'Closed'
                                                            : element}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </li>

                                      <li className="timeline-item">
                                        <div className="timeline-status bg-primary" />
                                        <div
                                          className="timeline-date"
                                          style={{ width: '100px' }}
                                        >
                                          Tuesday{' '}
                                          <em className="icon ni ni-alarm-alt" />
                                        </div>
                                        <div className="timeline">
                                          <ul className="timeline-list">
                                            {this.state.tuesdayValuesPreview.map(
                                              (element, i) => (
                                                <div key={i}>
                                                  <li
                                                    className="timeline-item"
                                                    style={{
                                                      marginTop: `${
                                                        i != 0 && '8px'
                                                      }`,
                                                    }}
                                                  >
                                                    <div className="timeline-data">
                                                      <div className="timeline-des">
                                                        <span className="time">
                                                          {element === ''
                                                            ? 'Closed'
                                                            : element}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </li>

                                      <li className="timeline-item">
                                        <div className="timeline-status bg-primary" />
                                        <div
                                          className="timeline-date"
                                          style={{ width: '100px' }}
                                        >
                                          Wednesday{' '}
                                          <em className="icon ni ni-alarm-alt" />
                                        </div>
                                        <div className="timeline">
                                          <ul className="timeline-list">
                                            {this.state.wednesdayValuesPreview.map(
                                              (element, i) => (
                                                <div key={i}>
                                                  <li
                                                    className="timeline-item"
                                                    style={{
                                                      marginTop: `${
                                                        i != 0 && '8px'
                                                      }`,
                                                    }}
                                                  >
                                                    <div className="timeline-data">
                                                      <div className="timeline-des">
                                                        <span className="time">
                                                          {element === ''
                                                            ? 'Closed'
                                                            : element}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </li>

                                      <li className="timeline-item">
                                        <div className="timeline-status bg-primary" />
                                        <div
                                          className="timeline-date"
                                          style={{ width: '100px' }}
                                        >
                                          Thursday{' '}
                                          <em className="icon ni ni-alarm-alt" />
                                        </div>
                                        <div className="timeline">
                                          <ul className="timeline-list">
                                            {this.state.thursdayValuesPreview.map(
                                              (element, i) => (
                                                <div key={i}>
                                                  <li
                                                    className="timeline-item"
                                                    style={{
                                                      marginTop: `${
                                                        i != 0 && '8px'
                                                      }`,
                                                    }}
                                                  >
                                                    <div className="timeline-data">
                                                      <div className="timeline-des">
                                                        <span className="time">
                                                          {element === ''
                                                            ? 'Closed'
                                                            : element}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </li>

                                      <li className="timeline-item">
                                        <div className="timeline-status bg-primary" />
                                        <div
                                          className="timeline-date"
                                          style={{ width: '100px' }}
                                        >
                                          Friday{' '}
                                          <em className="icon ni ni-alarm-alt" />
                                        </div>
                                        <div className="timeline">
                                          <ul className="timeline-list">
                                            {this.state.fridayValuesPreview.map(
                                              (element, i) => (
                                                <div key={i}>
                                                  <li
                                                    className="timeline-item"
                                                    style={{
                                                      marginTop: `${
                                                        i != 0 && '8px'
                                                      }`,
                                                    }}
                                                  >
                                                    <div className="timeline-data">
                                                      <div className="timeline-des">
                                                        <span className="time">
                                                          {element === ''
                                                            ? 'Closed'
                                                            : element}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </li>

                                      <li className="timeline-item">
                                        <div className="timeline-status bg-primary" />
                                        <div
                                          className="timeline-date"
                                          style={{ width: '100px' }}
                                        >
                                          Saturday{' '}
                                          <em className="icon ni ni-alarm-alt" />
                                        </div>
                                        <div className="timeline">
                                          <ul className="timeline-list">
                                            {this.state.saturdayValuesPreview.map(
                                              (element, i) => (
                                                <div key={i}>
                                                  <li
                                                    className="timeline-item"
                                                    style={{
                                                      marginTop: `${
                                                        i != 0 && '8px'
                                                      }`,
                                                    }}
                                                  >
                                                    <div className="timeline-data">
                                                      <div className="timeline-des">
                                                        <span className="time">
                                                          {element === ''
                                                            ? 'Closed'
                                                            : element}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="card"
                                data-tut="reactour__manageServiceOptions"
                              >
                                <div className="card-inner-group">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <h6 className="title">
                                          Service Options
                                        </h6>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card-inner card-inner-md">
                                    <div className="user-card">
                                      {this.state.business_service_options
                                        .length < 1 ? (
                                        <>
                                          <div
                                            className="preview-icon-box"
                                            style={{
                                              padding: '0px',
                                              marginLeft: 'auto',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            <div className="preview-icon-wrap">
                                              <em
                                                className="icon ni ni-na fa-lg"
                                                style={{
                                                  fontSize: '49px',
                                                  color: '#8094ae',
                                                }}
                                              ></em>
                                            </div>
                                            <span
                                              className="preview-icon-name"
                                              style={{ fontSize: '14px' }}
                                            >
                                              You do not have any service option
                                              for this business.{' '}
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="form-group">
                                          <ul class="custom-control-group">
                                            {this.state.business_service_options.map(
                                              (data, index) => {
                                                return (
                                                  <>
                                                    {data.dine_in === true && (
                                                      <li>
                                                        <div class="custom-control custom-checkbox custom-control-pro no-control">
                                                          <input
                                                            style={{
                                                              cursor: 'none',
                                                            }}
                                                            type="text"
                                                            class="custom-control-input"
                                                            disabled
                                                          />
                                                          <label class="custom-control-label bg-transparent">
                                                            <span>Dine in</span>
                                                          </label>
                                                        </div>
                                                      </li>
                                                    )}
                                                    {data.takeout === true && (
                                                      <li>
                                                        <div class="custom-control custom-checkbox custom-control-pro no-control">
                                                          <input
                                                            style={{
                                                              cursor: 'default',
                                                            }}
                                                            type="text"
                                                            class="custom-control-input"
                                                            disabled
                                                          />
                                                          <label class="custom-control-label bg-transparent">
                                                            <span>Takeout</span>
                                                          </label>
                                                        </div>
                                                      </li>
                                                    )}
                                                    {data.curbside_pickup ===
                                                      true && (
                                                      <li>
                                                        <div class="custom-control custom-checkbox custom-control-pro no-control">
                                                          <input
                                                            style={{
                                                              cursor: 'default',
                                                            }}
                                                            type="text"
                                                            class="custom-control-input"
                                                            disabled
                                                          />
                                                          <label class="custom-control-label bg-transparent">
                                                            <span>
                                                              Curbside Pickup
                                                            </span>
                                                          </label>
                                                        </div>
                                                      </li>
                                                    )}
                                                    {data.delivery === true && (
                                                      <li>
                                                        <div class="custom-control custom-checkbox custom-control-pro no-control">
                                                          <input
                                                            style={{
                                                              cursor: 'default',
                                                            }}
                                                            type="text"
                                                            class="custom-control-input"
                                                            disabled
                                                          />
                                                          <label class="custom-control-label bg-transparent">
                                                            <span>
                                                              Delivery
                                                            </span>
                                                          </label>
                                                        </div>
                                                      </li>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* define points modal start */}

                  <Modal
                    visible={this.state.setupLoyaltyProgramModal}
                    title="Let's Set Up Your Loyalty Program"
                    footer={null}
                    width={800}
                    maskClosable={false}
                    closable={false}
                    onCancel={() => {
                      this.setState({
                        setupLoyaltyProgramModal: false,
                      });
                    }}
                  >
                    <form onSubmit={this.handleSetupLoyaltySubmit}>
                      <div className="card-preview">
                        <div
                          style={{
                            paddingBottom: '16px',
                          }}
                        >
                          <div className="row">
                            <div></div>

                            <div className="example-alert"
                              style={{
                                padding: '0.5rem',
                                marginBottom: '20px',
                                marginTop: '-10px',
                                width: '99%'
                              }}
                              >
                                <div className="alert alert-gray alert-icon"
                                style={{
                                  marginLeft: '0rem',
                                  paddingLeft: '1rem'
                                }}
                                >
                                  <p style={{text: '0rem',
                                  fontSize: "larger",
                                  fontWeight: 700
                                  }}>Welcome to UnstuQ Business.</p>
                                  <p>To complete the setup of your loyalty program, please update the form below and click the Save button.</p>
                                </div>
                              </div>

                            <div className="col-md-6">
                              <pre
                                // className="prettyprint lang-html"
                                id="Tooltip1"
                                style={{
                                  // padding: '0.5rem',
                                  marginBottom: '10px',
                                }}
                              >
                                Minimum Points Threshold
                              </pre>
                              <div className="form-group">
                                <Input
                                  type="number"
                                  placeholder="Number of points"
                                  value={this.state.points.points_threshold}
                                  required
                                  onChange={(e) => {
                                    this.onChangePoints(e, 'points_threshold');
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <pre
                                // className="prettyprint lang-html"
                                id="Tooltip1"
                                style={{
                                  // padding: '0.5rem',
                                  marginBottom: '10px',
                                }}
                              >
                                Credit Value
                              </pre>
                              <div className="form-group">
                                <Input
                                  placeholder="Points worth"
                                  value={this.state.points.points_worth}
                                  required
                                  onChange={(e) => {
                                    this.onChangePoints(e, 'points_worth');
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.points.points_threshold != '' > 0 && (
                          <pre
                            className="prettyprint lang-html"
                            id="Tooltip1"
                            style={{
                              padding: '0.5rem',
                              marginBottom: '20px',
                              display: 'none'
                            }}
                          >
                            {`${
                              this.state.points.points_threshold + ' Points = $'
                            }` + this.state.points.points_worth}
                          </pre>
                        )}

                        <div className="example-alert"
                        style={{
                          marginBottom: '20px',
                        }}
                        >
                          <div className="alert alert-gray alert-icon"
                          style={{paddingLeft: '1rem'}}
                          >
                            Customers earn <strong>1 point</strong> for each dollar they spend at your
                            establishment. Once they reach{' '}
                            <strong>{`${this.state.points.points_threshold +' Points'}`}</strong>
                            , they will receive
                            <strong>{`${' $' + this.state.points.points_worth +' in Credits'}`}</strong>
                            , which they can use to pay for goods or services at your establishment.
                          </div>
                        </div>

                        <pre
                          id="Tooltip1"
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          Add Employees
                        </pre>
                        <div className="example-alert"
                        style={{
                          marginBottom: '20px',
                        }}
                        >
                          <div className="alert alert-gray alert-icon"
                          style={{paddingLeft: '1rem'}}
                          >
                            Each employee will be assigned a 4 digit code
                            which they will provide to customers to earn or redeem
                            points/credits.  
                          </div>
                        </div>
                        {this.state.postEmployeeNameHandle.map((element, i) => (
                          <div
                            key={i}
                            style={{
                              paddingBottom: '16px',
                            }}
                          >
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group">
                                  <Input
                                    placeholder="Employee Name"
                                    value={element.name}
                                    required
                                    onChange={(e) =>
                                      this.handleEmployeeNameOptions(
                                        i,
                                        e,
                                        'name'
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="mt-1">
                                  <div className="custom-control custom-switch checked">
                                    <input
                                      defaultChecked={element.status}
                                      type="checkbox"
                                      // value={element.status}
                                      onChange={(e) => {
                                        this.onChangeStatus(i, e, 'status');
                                      }}
                                      className="custom-control-input"
                                      id={i}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={i}
                                    >
                                      Active
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {i > 0 && (
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: '5px' }}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={this.removeEmployeeNameFields.bind(
                                        this,
                                        i
                                      )}
                                      className="icon ni ni-cross-circle-fill"
                                    />
                                  </span>
                                </div>
                              )}

                              {i === 0 && (
                                <div
                                  style={{
                                    marginTop: '10px',
                                    color: 'blue',
                                    cursor: 'pointer',
                                  }}
                                  className="col-md-2"
                                >
                                  <p
                                    onClick={this.addEmployeeNameFields.bind(
                                      this
                                    )}
                                  >
                                    Add more
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3" style={{ marginTop: '25px' }}>
                          {!this.state.isRequesting ? (
                            <button
                              type="submit"
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <span>Save</span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <div
                                className="spinner-border"
                                role="status"
                                style={{ margin: '-6px' }}
                              >
                                {' '}
                              </div>
                            </button>
                          )}
                          {/* </div> */}
                        </div>
                      </div>
                    </form>
                  </Modal>

                  {/* define points modal start */}

                  {/* business description modal start */}

                  <Modal
                    visible={this.state.businessDescriptionModal}
                    title="Manage Business Description"
                    footer={null}
                    //   footer={<div className="card-inner card-inner-md bg-light">
                    //   <div className="card-note">
                    //     <em className="icon ni ni-info-fill" />
                    //     <span>
                    //       Give your customers up-to-date information about
                    //       your business.
                    //     </span>
                    //   </div>
                    // </div>}
                    onCancel={() => {
                      this.setState({
                        businessDescriptionModal: false,
                      });
                    }}
                  >
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="cf-default-textarea"
                      >
                        Business Description
                        <span style={{ position: 'absolute', right: '0px' }}>
                          {this.state.charLimit +
                            this.state.business_details.bio.length}
                          /500
                        </span>
                      </label>

                      <div className="form-control-wrap">
                        <form onSubmit={this.submitBusinessDetailsForm}>
                          <textarea
                            className="form-control form-control-sm"
                            placeholder="Add Business Description"
                            spellCheck="false"
                            required
                            defaultValue={''}
                            style={{ fontSize: '14px' }}
                            value={this.state.business_details.bio}
                            onChange={(e) => {
                              this.handleBusinessDetails(e, 'description');
                            }}
                          />
                          <div style={{ marginTop: '12px' }}>
                            {!this.state.isRequesting ? (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <span>Submit</span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{ margin: '-6px' }}
                                >
                                  {' '}
                                </div>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Modal>

                  {/* business description modal end */}

                  {/* delivery service modal start */}

                  <Modal
                    visible={this.state.businessDeliveryServicesModal}
                    title="Manage Delivery Services"
                    footer={null}
                    width={800}
                    onCancel={() => {
                      this.setState({
                        businessDeliveryServicesModal: false,
                      });
                    }}
                  >
                    <form onSubmit={this.handleDeliveryServiceSubmit}>
                      <div
                        className="card-preview"
                        // style={{ overflow: 'scroll', height: '380px' }}
                      >
                        {this.state.post_business_delivery_options.map(
                          (element, i) => (
                            <div
                              key={i}
                              style={{
                                // borderBottom: '1px solid #f0f0f0',
                                paddingBottom: '16px',
                              }}
                            >
                              <div className="row">
                                <div></div>

                                <div
                                  className="col-md-5"
                                  // style={{ marginTop: `${i != 0 && '8px'}` }}
                                >
                                  <div className="form-group">
                                    <Select
                                      required
                                      style={{ width: 300 }}
                                      onChange={(e) =>
                                        this.handleDeliveryOptions(
                                          i,
                                          e,
                                          'delivery_service'
                                        )
                                      }
                                      value={element.delivery_service}
                                      placeholder="Select/add delivery service"
                                      dropdownRender={(menu) => (
                                        <>
                                          {menu}
                                          <Divider
                                            style={{ margin: '8px 0' }}
                                          />
                                          <Space
                                            align="center"
                                            style={{ padding: '0 8px 4px' }}
                                          >
                                            <Input
                                              placeholder="Please enter item"
                                              value={
                                                this.state
                                                  .newDeliveryOptionsName
                                              }
                                              onChange={(e) => {
                                                this.onNameChange(e);
                                              }}
                                            />
                                            <Typography.Link
                                              onClick={(e) => {
                                                this.addNewDeliveryOption(e);
                                              }}
                                              style={{ whiteSpace: 'nowrap' }}
                                            >
                                              <PlusOutlined /> Add item
                                            </Typography.Link>
                                          </Space>
                                        </>
                                      )}
                                    >
                                      {this.state.business_delivery_options.map(
                                        (item) => (
                                          <Option key={item.tag}>
                                            {item.tag}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </div>
                                </div>

                                <div
                                  className="col-md-5"
                                  // style={{ marginTop: `${i != 0 && '8px'}` }}
                                >
                                  <div className="form-group">
                                    <Input
                                      placeholder="https://"
                                      value={element.url}
                                      required
                                      // onChange={(e) => {this.deliveryOptionsLink(e);}}
                                      onChange={(e) =>
                                        this.handleDeliveryOptions(i, e, 'url')
                                      }
                                    />
                                  </div>
                                </div>

                                {i > 0 && (
                                  <div
                                    className="col-md-2"
                                    style={{ marginTop: '5px' }}
                                  >
                                    <span className="nk-menu-icon">
                                      <em
                                        style={{
                                          color: 'red',
                                          cursor: 'pointer',
                                        }}
                                        onClick={this.removeDeliveryOptionFormFields.bind(
                                          this,
                                          i
                                        )}
                                        className="icon ni ni-cross-circle-fill"
                                      />
                                    </span>
                                  </div>
                                )}

                                {i === 0 && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                    className="col-md-2"
                                  >
                                    <p
                                      onClick={this.addDeliveryOptionFields.bind(
                                        this
                                      )}
                                    >
                                      Add more
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3" style={{ marginTop: '25px' }}>
                          {!this.state.isRequesting ? (
                            <button
                              type="submit"
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <span>Submit</span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <div
                                className="spinner-border"
                                role="status"
                                style={{ margin: '-6px' }}
                              >
                                {' '}
                              </div>
                            </button>
                          )}
                          {/* </div> */}
                        </div>
                      </div>
                    </form>
                  </Modal>

                  {/* hours of operation modal start */}

                  <Modal
                    visible={this.state.socialMediaHandlesModal}
                    title="Manage Social Media Handles"
                    footer={null}
                    width={800}
                    onCancel={() => {
                      this.setState({
                        socialMediaHandlesModal: false,
                      });
                    }}
                  >
                    <form onSubmit={this.submitEmployeeForm}>
                      <div className="card-preview">
                        {this.state.post_business_social_media_handle.map(
                          (element, i) => (
                            <div
                              key={i}
                              style={{
                                paddingBottom: '16px',
                              }}
                            >
                              <div className="row">
                                <div></div>

                                <div className="col-md-5">
                                  <div className="form-group">
                                    <Select
                                      required
                                      style={{ width: 300 }}
                                      onChange={(e) =>
                                        this.handleSocialMediaOptions(
                                          i,
                                          e,
                                          'media'
                                        )
                                      }
                                      value={element.media}
                                      placeholder="Select/add delivery service"
                                      dropdownRender={(menu) => <>{menu}</>}
                                    >
                                      {this.state.business_social_media_handle_list.map(
                                        (item) => (
                                          <Option key={item.tag}>
                                            {item.tag}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </div>
                                </div>

                                <div className="col-md-5">
                                  <div className="form-group">
                                    <Input
                                      placeholder="https://"
                                      value={element.handle}
                                      required
                                      onChange={(e) =>
                                        this.handleSocialMediaOptions(
                                          i,
                                          e,
                                          'handle'
                                        )
                                      }
                                    />
                                  </div>
                                </div>

                                {i > 0 && (
                                  <div
                                    className="col-md-2"
                                    style={{ marginTop: '5px' }}
                                  >
                                    <span className="nk-menu-icon">
                                      <em
                                        style={{
                                          color: 'red',
                                          cursor: 'pointer',
                                        }}
                                        onClick={this.removeSocialMediaHandleFormFields.bind(
                                          this,
                                          i
                                        )}
                                        className="icon ni ni-cross-circle-fill"
                                      />
                                    </span>
                                  </div>
                                )}

                                {i === 0 && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                    className="col-md-2"
                                  >
                                    <p
                                      onClick={this.addSocialMediaHandleFields.bind(
                                        this
                                      )}
                                    >
                                      Add more
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3" style={{ marginTop: '25px' }}>
                          {!this.state.isRequesting ? (
                            <button
                              type="submit"
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <span>Submit</span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <div
                                className="spinner-border"
                                role="status"
                                style={{ margin: '-6px' }}
                              >
                                {' '}
                              </div>
                            </button>
                          )}
                          {/* </div> */}
                        </div>
                      </div>
                    </form>
                  </Modal>

                  {/* hours of operation modal start */}

                  <Modal
                    visible={this.state.hoursOfOperationModal}
                    title="Manage Hours Of Operation"
                    footer={null}
                    width={800}
                    onCancel={() => {
                      this.setState({
                        hoursOfOperationModal: false,
                      });
                    }}
                  >
                    <form onSubmit={this.handleSubmit}>
                      <div
                        className="card-preview"
                        style={{ overflow: 'scroll', height: '380px' }}
                      >
                        {this.state.sundayValues.map((element, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: '1px solid #f0f0f0',
                              paddingBottom: '16px',
                            }}
                          >
                            <div className="row">
                              <div></div>
                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="cf-default-textarea"
                                >
                                  {i === 0 && 'Sunday'}
                                </label>
                              </div>

                              {/* <ul className="preview-btn-list"> */}

                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                {i === 0 && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      onChange={(e) =>
                                        this.onChangeHoursOfOperationCheckbox(
                                          i,
                                          e,
                                          'sunday'
                                        )
                                      }
                                      checked={this.state.disableSunday}
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="sunday"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="sunday"
                                    >
                                      Closed
                                    </label>
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeSunday(i, e, 'opens')
                                    }
                                    disabled={this.state.disableSunday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.opens != '' &&
                                        moment(element.opens, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Opens'}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeSunday(i, e, 'closes')
                                    }
                                    disabled={this.state.disableSunday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.closes != '' &&
                                        moment(element.closes, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Closes'}
                                  />
                                </div>
                              </div>

                              {i > 0 && (
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: '1rem' }}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={this.removeSundayFormFields.bind(
                                        this,
                                        i
                                      )}
                                      className="icon ni ni-cross-circle-fill"
                                    />
                                    {/* &nbsp; Remove */}
                                  </span>
                                </div>
                              )}

                              {i === 0 && this.state.disableSunday != true && (
                                <div
                                  style={{
                                    marginTop: '10px',
                                    color: 'blue',
                                    cursor: 'pointer',
                                  }}
                                  className="col-md-2"
                                >
                                  <p
                                    onClick={this.addSundayFormFields.bind(
                                      this
                                    )}
                                  >
                                    Add Hours
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                        {this.state.mondayValues.map((element, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: '1px solid #f0f0f0',
                              paddingBottom: '16px',
                            }}
                          >
                            <div className="row" style={{ marginTop: '8px' }}>
                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="cf-default-textarea"
                                >
                                  {i === 0 && 'Monday'}
                                </label>
                              </div>

                              {/* <ul className="preview-btn-list"> */}

                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                {i === 0 && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      onChange={(e) =>
                                        this.onChangeHoursOfOperationCheckbox(
                                          i,
                                          e,
                                          'monday'
                                        )
                                      }
                                      checked={this.state.disableMonday}
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="monday"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="monday"
                                    >
                                      Closed
                                    </label>
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeMonday(i, e, 'opens')
                                    }
                                    disabled={this.state.disableMonday}
                                    format={format}
                                    use12Hours
                                    // defaultValue={
                                    //   this.state.hours_of_operation_viewer
                                    //     .monday_opens != '' &&
                                    //   moment(
                                    //     this.state.hours_of_operation_viewer
                                    //       .monday_opens,
                                    //     'HH:mm'
                                    //   )
                                    // }
                                    value={
                                      (element.opens != '' &&
                                        moment(element.opens, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Opens'}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeMonday(i, e, 'closes')
                                    }
                                    disabled={this.state.disableMonday}
                                    format={format}
                                    use12Hours
                                    // defaultValue={
                                    //   this.state.hours_of_operation_viewer
                                    //     .monday_opens != '' &&
                                    //   moment(
                                    //     this.state.hours_of_operation_viewer
                                    //       .monday_opens,
                                    //     'HH:mm'
                                    //   )
                                    // }
                                    value={
                                      (element.closes != '' &&
                                        moment(element.closes, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Closes'}
                                  />
                                </div>
                              </div>

                              {i > 0 && (
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: '1rem' }}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={this.removeMondayFormFields.bind(
                                        this,
                                        i
                                      )}
                                      className="icon ni ni-cross-circle-fill"
                                    />
                                    {/* &nbsp; Remove */}
                                  </span>
                                </div>
                              )}

                              {i === 0 && this.state.disableMonday != true && (
                                <div
                                  style={{
                                    marginTop: '10px',
                                    color: 'blue',
                                    cursor: 'pointer',
                                  }}
                                  className="col-md-2"
                                >
                                  <p
                                    onClick={this.addMondayFormFields.bind(
                                      this
                                    )}
                                  >
                                    Add Hours
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                        {this.state.tuesdayValues.map((element, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: '1px solid #f0f0f0',
                              paddingBottom: '16px',
                            }}
                          >
                            <div className="row" style={{ marginTop: '8px' }}>
                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="cf-default-textarea"
                                >
                                  {i === 0 && 'Tuesday'}
                                </label>
                              </div>

                              {/* <ul className="preview-btn-list"> */}

                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                {i === 0 && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      onChange={(e) =>
                                        this.onChangeHoursOfOperationCheckbox(
                                          i,
                                          e,
                                          'tuesday'
                                        )
                                      }
                                      checked={this.state.disableTuesday}
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="tuesday"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="tuesday"
                                    >
                                      Closed
                                    </label>
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeTuesday(i, e, 'opens')
                                    }
                                    disabled={this.state.disableTuesday}
                                    format={format}
                                    use12Hours
                                    // defaultValue={
                                    //   this.state.hours_of_operation_viewer
                                    //     .monday_opens != '' &&
                                    //   moment(
                                    //     this.state.hours_of_operation_viewer
                                    //       .monday_opens,
                                    //     'HH:mm'
                                    //   )
                                    // }
                                    value={
                                      (element.opens != '' &&
                                        moment(element.opens, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Opens'}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeTuesday(i, e, 'closes')
                                    }
                                    disabled={this.state.disableTuesday}
                                    format={format}
                                    use12Hours
                                    // defaultValue={
                                    //   this.state.hours_of_operation_viewer
                                    //     .monday_opens != '' &&
                                    //   moment(
                                    //     this.state.hours_of_operation_viewer
                                    //       .monday_opens,
                                    //     'HH:mm'
                                    //   )
                                    // }
                                    value={
                                      (element.closes != '' &&
                                        moment(element.closes, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Closes'}
                                  />
                                </div>
                              </div>

                              {i > 0 && (
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: '1rem' }}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={this.removeTuesdayFormFields.bind(
                                        this,
                                        i
                                      )}
                                      className="icon ni ni-cross-circle-fill"
                                    />
                                    {/* &nbsp; Remove */}
                                  </span>
                                </div>
                              )}

                              {i === 0 && this.state.disableTuesday != true && (
                                <div
                                  style={{
                                    marginTop: '10px',
                                    color: 'blue',
                                    cursor: 'pointer',
                                  }}
                                  className="col-md-2"
                                >
                                  <p
                                    onClick={this.addTuesdayFormFields.bind(
                                      this
                                    )}
                                  >
                                    Add Hours
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                        {this.state.wednesdayValues.map((element, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: '1px solid #f0f0f0',
                              paddingBottom: '16px',
                            }}
                          >
                            <div className="row" style={{ marginTop: '8px' }}>
                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="cf-default-textarea"
                                >
                                  {i === 0 && 'Wednesday'}
                                </label>
                              </div>

                              {/* <ul className="preview-btn-list"> */}

                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                {i === 0 && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      onChange={(e) =>
                                        this.onChangeHoursOfOperationCheckbox(
                                          i,
                                          e,
                                          'wednesday'
                                        )
                                      }
                                      checked={this.state.disableWednesday}
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="wednesday"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="wednesday"
                                    >
                                      Closed
                                    </label>
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeWednesday(i, e, 'opens')
                                    }
                                    disabled={this.state.disableWednesday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.opens != '' &&
                                        moment(element.opens, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Opens'}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeWednesday(i, e, 'closes')
                                    }
                                    disabled={this.state.disableWednesday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.closes != '' &&
                                        moment(element.closes, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Closes'}
                                  />
                                </div>
                              </div>

                              {i > 0 && (
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: '1rem' }}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={this.removeWednesdayFormFields.bind(
                                        this,
                                        i
                                      )}
                                      className="icon ni ni-cross-circle-fill"
                                    />
                                    {/* &nbsp; Remove */}
                                  </span>
                                </div>
                              )}

                              {i === 0 &&
                                this.state.disableWednesday != true && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                    className="col-md-2"
                                  >
                                    <p
                                      onClick={this.addWednesdayFormFields.bind(
                                        this
                                      )}
                                    >
                                      Add Hours
                                    </p>
                                  </div>
                                )}
                            </div>
                          </div>
                        ))}

                        {this.state.thursdayValues.map((element, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: '1px solid #f0f0f0',
                              paddingBottom: '16px',
                            }}
                          >
                            <div className="row" style={{ marginTop: '8px' }}>
                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="cf-default-textarea"
                                >
                                  {i === 0 && 'Thursday'}
                                </label>
                              </div>

                              {/* <ul className="preview-btn-list"> */}

                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                {i === 0 && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      onChange={(e) =>
                                        this.onChangeHoursOfOperationCheckbox(
                                          i,
                                          e,
                                          'thursday'
                                        )
                                      }
                                      checked={this.state.disableThursday}
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="thursday"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="thursday"
                                    >
                                      Closed
                                    </label>
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeThursday(i, e, 'opens')
                                    }
                                    disabled={this.state.disableThursday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.opens != '' &&
                                        moment(element.opens, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Opens'}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeThursday(i, e, 'closes')
                                    }
                                    disabled={this.state.disableThursday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.closes != '' &&
                                        moment(element.closes, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Closes'}
                                  />
                                </div>
                              </div>

                              {i > 0 && (
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: '1rem' }}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={this.removeThursdayFormFields.bind(
                                        this,
                                        i
                                      )}
                                      className="icon ni ni-cross-circle-fill"
                                    />
                                    {/* &nbsp; Remove */}
                                  </span>
                                </div>
                              )}

                              {i === 0 &&
                                this.state.disableThursday != true && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                    className="col-md-2"
                                  >
                                    <p
                                      onClick={this.addThursdayFormFields.bind(
                                        this
                                      )}
                                    >
                                      Add Hours
                                    </p>
                                  </div>
                                )}
                            </div>
                          </div>
                        ))}

                        {this.state.fridayValues.map((element, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: '1px solid #f0f0f0',
                              paddingBottom: '16px',
                            }}
                          >
                            <div className="row" style={{ marginTop: '8px' }}>
                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="cf-default-textarea"
                                >
                                  {i === 0 && 'Friday'}
                                </label>
                              </div>

                              {/* <ul className="preview-btn-list"> */}

                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                {i === 0 && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      onChange={(e) =>
                                        this.onChangeHoursOfOperationCheckbox(
                                          i,
                                          e,
                                          'friday'
                                        )
                                      }
                                      checked={this.state.disableFriday}
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="friday"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="friday"
                                    >
                                      Closed
                                    </label>
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeFriday(i, e, 'opens')
                                    }
                                    disabled={this.state.disableFriday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.opens != '' &&
                                        moment(element.opens, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Opens'}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeFriday(i, e, 'closes')
                                    }
                                    disabled={this.state.disableFriday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.closes != '' &&
                                        moment(element.closes, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Closes'}
                                  />
                                </div>
                              </div>

                              {i > 0 && (
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: '1rem' }}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={this.removeFridayFormFields.bind(
                                        this,
                                        i
                                      )}
                                      className="icon ni ni-cross-circle-fill"
                                    />
                                    {/* &nbsp; Remove */}
                                  </span>
                                </div>
                              )}

                              {i === 0 && this.state.disableFriday != true && (
                                <div
                                  style={{
                                    marginTop: '10px',
                                    color: 'blue',
                                    cursor: 'pointer',
                                  }}
                                  className="col-md-2"
                                >
                                  <p
                                    onClick={this.addFridayFormFields.bind(
                                      this
                                    )}
                                  >
                                    Add Hours
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                        {this.state.saturdayValues.map((element, i) => (
                          <div key={i}>
                            <div className="row" style={{ marginTop: '8px' }}>
                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="cf-default-textarea"
                                >
                                  {i === 0 && 'Saturday'}
                                </label>
                              </div>

                              {/* <ul className="preview-btn-list"> */}

                              <div
                                className="col-md-2"
                                style={{ marginTop: '8px' }}
                              >
                                {i === 0 && (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      onChange={(e) =>
                                        this.onChangeHoursOfOperationCheckbox(
                                          i,
                                          e,
                                          'saturday'
                                        )
                                      }
                                      checked={this.state.disableSaturday}
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="saturday"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="saturday"
                                    >
                                      Closed
                                    </label>
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeSaturday(i, e, 'opens')
                                    }
                                    disabled={this.state.disableSaturday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.opens != '' &&
                                        moment(element.opens, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Opens'}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-3"
                                style={{ marginTop: `${i != 0 && '8px'}` }}
                              >
                                <div className="form-control-wrap">
                                  <TimePicker
                                    onChange={(e) =>
                                      this.handleChangeSaturday(i, e, 'closes')
                                    }
                                    disabled={this.state.disableSaturday}
                                    format={format}
                                    use12Hours
                                    value={
                                      (element.closes != '' &&
                                        moment(element.closes, 'HH:mm')) ||
                                      ''
                                    }
                                    size="large"
                                    placeholder={'Closes'}
                                  />
                                </div>
                              </div>

                              {i > 0 && (
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: '1rem' }}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={this.removeSaturdayFormFields.bind(
                                        this,
                                        i
                                      )}
                                      className="icon ni ni-cross-circle-fill"
                                    />
                                    {/* &nbsp; Remove */}
                                  </span>
                                </div>
                              )}

                              {i === 0 &&
                                this.state.disableSaturday != true && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                    className="col-md-2"
                                  >
                                    <p
                                      onClick={this.addSaturdayFormFields.bind(
                                        this
                                      )}
                                    >
                                      Add Hours
                                    </p>
                                  </div>
                                )}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3" style={{ marginTop: '25px' }}>
                          {/* <button className="btn btn-secondary float-right">Button</button> */}
                          {/* <div style={{ marginTop: '12px', float: "right" }}> */}
                          {!this.state.isRequesting ? (
                            <button
                              type="submit"
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <span>Submit</span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <div
                                className="spinner-border"
                                role="status"
                                style={{ margin: '-6px' }}
                              >
                                {' '}
                              </div>
                            </button>
                          )}
                          {/* </div> */}
                        </div>
                      </div>
                    </form>
                  </Modal>

                  {/* hours of operation modal end */}

                  {/* business phone modal start */}

                  <Modal
                    visible={this.state.businessPhoneModal}
                    title="Manage Phone"
                    footer={null}
                    onCancel={() => {
                      this.setState({
                        businessPhoneModal: false,
                      });
                    }}
                  >
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="cf-default-textarea"
                      >
                        Phone
                      </label>
                      <div className="form-control-wrap">
                        <form onSubmit={this.submitBusinessDetailsForm}>
                          <PhoneInput
                            class="form-control"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry={this.state.countryFlag}
                            style={{ fontSize: '14px', height: '35px' }}
                            value={this.state.business_details.phone_number}
                            onChange={(e) => {
                              this.handleBusinessDetails(e, 'phone_number');
                            }}
                            // error={this.state.regForm.phoneNumber ? (isValidPhoneNumber(this.state.regForm.phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                          />
                          <div style={{ marginTop: '12px' }}>
                            {!this.state.isRequesting ? (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <span>Submit</span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{ margin: '-6px' }}
                                >
                                  {' '}
                                </div>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Modal>

                  {/* business phone modal end */}

                  {/* business website modal start */}

                  <Modal
                    visible={this.state.businessWebsiteModal}
                    title="Manage Website"
                    footer={null}
                    onCancel={() => {
                      this.setState({
                        businessWebsiteModal: false,
                      });
                    }}
                  >
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="cf-default-textarea"
                      >
                        Website
                      </label>

                      <div className="form-control-wrap">
                        <form onSubmit={this.submitBusinessDetailsForm}>
                          <input
                            className="form-control form-control-sm"
                            placeholder="Add Business Description"
                            spellCheck="false"
                            required
                            defaultValue={''}
                            style={{ fontSize: '14px', height: '35px' }}
                            value={this.state.business_details.website}
                            onChange={(e) => {
                              this.handleBusinessDetails(e, 'website');
                            }}
                          />
                          <div style={{ marginTop: '12px' }}>
                            {!this.state.isRequesting ? (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <span>Submit</span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{ margin: '-6px' }}
                                >
                                  {' '}
                                </div>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Modal>

                  {/* business website modal end */}

                  {/* business address modal start */}

                  <Modal
                    visible={this.state.businessAddressModal}
                    title="Manage Address"
                    footer={null}
                    onCancel={() => {
                      this.setState({
                        businessAddressModal: false,
                      });
                    }}
                  >
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="cf-default-textarea"
                      >
                        Address
                      </label>

                      <div className="form-control-wrap">
                        <form onSubmit={this.submitBusinessDetailsForm}>
                          <input
                            className="form-control form-control-sm"
                            placeholder="Add Business Address"
                            spellCheck="false"
                            required
                            defaultValue={''}
                            style={{ fontSize: '14px', height: '35px' }}
                            value={
                              this.state.business_details.formatted_address
                            }
                            onChange={(e) => {
                              this.handleBusinessDetails(e, 'address');
                            }}
                          />
                          <div style={{ marginTop: '12px' }}>
                            {!this.state.isRequesting ? (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <span>Submit</span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{ margin: '-6px' }}
                                >
                                  {' '}
                                </div>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Modal>

                  {/* business address modal end */}

                  {/* business price range modal start */}

                  <Modal
                    visible={this.state.businessPriceRangeModal}
                    title="Manage Price Range"
                    footer={null}
                    onCancel={() => {
                      this.setState({
                        businessPriceRangeModal: false,
                      });
                    }}
                  >
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <form onSubmit={this.submitBusinessDetailsForm}>
                          <div className="row g-gs">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="fv-phone"
                                >
                                  Minimum price per person
                                </label>
                                <div className="form-control-wrap">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="fv-phone"
                                      >
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      value={
                                        this.state.business_details.min_price
                                      }
                                      onChange={(e) => {
                                        this.handleBusinessDetails(e, 'min');
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="fv-phone"
                                >
                                  Maximum price per person
                                </label>
                                <div className="form-control-wrap">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="fv-phone"
                                      >
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                      value={
                                        this.state.business_details.max_price
                                      }
                                      onChange={(e) => {
                                        this.handleBusinessDetails(e, 'max');
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ marginTop: '12px' }}>
                            {!this.state.isRequesting ? (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <span>Submit</span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{ margin: '-6px' }}
                                >
                                  {' '}
                                </div>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Modal>

                  {/* business price range modal end */}

                  {/* business category modal start */}

                  <Modal
                    visible={this.state.businessCategoryModal}
                    title="Manage Category"
                    footer={null}
                    onCancel={() => {
                      this.setState({
                        businessCategoryModal: false,
                      });
                    }}
                  >
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="cf-default-textarea"
                      >
                        Business Category
                      </label>

                      <div className="form-control-wrap">
                        <form onSubmit={this.submitBusinessTypesForm}>
                          <div className="form-group">
                            <ul class="custom-control-group">
                              <li>
                                <div class="custom-control custom-checkbox custom-control-pro no-control checked">
                                  <input
                                    // defaultChecked={
                                    //   this.state.business_types.some(
                                    //     (v) => v.index_two == 'restaurant'
                                    //   ) && true
                                    // }
                                    defaultChecked={
                                      this.state.restaurantDefault === true
                                        ? true
                                        : false
                                    }
                                    onChange={this.onChangeRestaurantCategory}
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="restaurant"
                                  />
                                  <label
                                    class="custom-control-label bg-transparent"
                                    htmlFor="restaurant"
                                  >
                                    <em className="icon ni ni-mobile"></em>
                                    <span>Restaurant</span>
                                  </label>
                                </div>
                              </li>

                              <li>
                                <div class="custom-control custom-checkbox custom-control-pro no-control checked">
                                  <input
                                    onChange={this.onChangeAttractionCategory}
                                    defaultChecked={
                                      this.state.attractionDefault === true
                                        ? true
                                        : false
                                    }
                                    // checked={
                                    //   this.state.business_types ===
                                    //   true
                                    //     ? true
                                    //     : false
                                    // }
                                    // disabled={false}
                                    // onChange={(e) => {
                                    //   this.onChangeDays(
                                    //     e,
                                    //     'attraction'
                                    //   );
                                    // }}
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="attraction"
                                  />
                                  <label
                                    class="custom-control-label bg-transparent"
                                    for="attraction"
                                  >
                                    <em className="icon ni ni-browser"></em>
                                    <span>Attraction</span>
                                  </label>
                                </div>
                              </li>

                              <li>
                                <div class="custom-control custom-checkbox custom-control-pro no-control checked">
                                  <input
                                    onChange={this.onChangeHotelCategory}
                                    // defaultChecked={
                                    //   this.state.business_types.some(
                                    //     (v) => v.index_two == 'hotel'
                                    //   ) && true
                                    // }
                                    defaultChecked={
                                      this.state.hotelDefault === true
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="hotel"
                                  />
                                  <label
                                    class="custom-control-label bg-transparent"
                                    for="hotel"
                                  >
                                    <em className="icon ni ni-building-fill"></em>
                                    <span>Hotel</span>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div style={{ marginTop: '12px' }}>
                            {!this.state.isRequesting ? (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <span>Submit</span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled
                                className="btn btn-lg btn-primary btn-block"
                              >
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{ margin: '-6px' }}
                                >
                                  {' '}
                                </div>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Modal>

                  {/* business category modal end */}

                  {/* onbaording modal start */}

                  <Modal
                    visible={this.state.onbaordingModal}
                    title="Claim Your Business"
                    footer={null}
                    closable={this.state.onbaordingModalAnotherOne}
                    width="80%"
                    onCancel={() => {
                      this.setState({
                        onbaordingModal: false,
                      });
                    }}
                  >
                    <Fragment>
                      <MetaTags>
                        <link
                          rel="stylesheet"
                          href={`${CONSTANTS.APP_URL}/onboarding/css/main.css`}
                        />
                        <title>UnstuQ Business</title>
                      </MetaTags>
                      {/* {this.state.isLoading ? (
                        <OnboardingLoader></OnboardingLoader>
                      ) : ( */}
                      <div>
                        <div className="content-wrapper">
                          <div className="s006">
                            <form>
                              <fieldset>
                                <legend
                                  // onClick={() => {
                                  //   this.redirect();
                                  // }}
                                  style={{ marginBottom: '10px' }}
                                >
                                  Manage your free listing
                                </legend>
                                <div className="inner-form">
                                  <div className="input-field">
                                    <button
                                      className="btn-search"
                                      type="button"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                      </svg>
                                    </button>
                                    <input
                                      onChange={() => {
                                        window.initAutocomplete();
                                      }}
                                      id="search-box-input"
                                      role="combobox"
                                      type="text"
                                      placeholder="Enter the address of your business"
                                    />
                                  </div>
                                </div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* )} */}
                    </Fragment>
                  </Modal>

                  {/* onbaording modal end */}

                  {/* claim another business modal start */}

                  <Modal
                    visible={this.state.manageBusinessesModal}
                    title="Manage Businesses"
                    footer={null}
                    // width={1000}
                    onCancel={() => {
                      this.setState({
                        manageBusinessesModal: false,
                      });
                    }}
                  >
                    <div>
                      {/* <label className="form-group mb-1">
                          <span>
                            Claim Another Business
                          </span>
                        </label>
                        <button
                          type="submit"
                          className="btn btn-md btn-primary btn-block mb-4"
                          onClick={() => {
                            this.setState({
                              onbaordingModal: true,
                              onbaordingModalAnotherOne: true,
                              manageBusinessesModal: false
                            });
                            window.getCurrentLocation();
                            window.sessionStorage.setItem(
                              'claim_another',
                              'active'
                            );
                          }}
                        >
                          <span>Claim Business</span>
                        </button> */}
                      <label className="form-group mb-1">
                        <span>Switch between businesses</span>
                      </label>
                      <br />

                      <AutoComplete
                        style={{ width: '100%' }}
                        options={this.state.manage_businesses}
                        placeholder="Search for businesses"
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onSelect={(value, option) => {
                          window.sessionStorage.setItem(
                            'new_business',
                            option.id
                          );
                          this.getUserAndBusinessDetails(
                            window.sessionStorage.getItem('new_business'),
                            ''
                          );
                        }}
                      />
                      {/* <div className="form-group">
                        <div className="form-icon form-icon-left">
                              <em className="icon ni ni-search" />
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search for businesses"
                              value={filter}
                              onChange={(e) => {
                                this.handleBusinessDetails(e, 'search_tags');
                              }}
                            />
                          </div> */}
                      {/* <iframe src="https://castbox.fm/app/castbox/player/id3344270?v=8.22.11&autoplay=0" frameborder="0" width="100%" height="500"></iframe> */}
                    </div>
                  </Modal>

                  {/* claim another business modal end */}

                  {/* subscription expired modal start */}

                  <Modal
                    visible={this.state.freeTrialExpiredModal}
                    title="Subscription Expired"
                    footer={null}
                    maskClosable={false}
                    closable={false}
                    // width={1000}
                    // onCancel={() => {
                    //   this.setState({
                    //     freeTrialExpiredModal: false,
                    //   });
                    // }}
                  >
                    <div>
                      <div className="row">
                        <div className="col-md-8">
                          <label className="form-group mb-1">
                            <span>
                              {this.state.business_details.name} does not have
                              an active subscription.
                            </span>
                          </label>
                        </div>
                        <br />

                        <div className="col-md-4">
                          <a href={`${CONSTANTS.APP_URL}/billing`}>
                            <button
                              type="submit"
                              className="btn btn-md btn-primary btn-block mb-4"
                            >
                              <span>Subscribe</span>
                            </button>
                          </a>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-md-8">
                          <label className="form-group mb-1">
                            <span>
                              You can access the Loyalty page without an active
                              subscription
                            </span>
                          </label>
                        </div>
                        <br />

                        <div className="col-md-4">
                          <a href={`${CONSTANTS.APP_URL}/loyalty`}>
                            <button
                              type="submit"
                              className="btn btn-md btn-primary btn-block mb-4"
                            >
                              <span>Loyalty Page</span>
                            </button>
                          </a>
                        </div>
                      </div>

                      {this.state.manage_businesses.length > 1 && (
                        <>
                          <br />
                          <label className="form-group mb-1">
                            <span>Switch to a different business</span>
                          </label>
                          <br />

                          <AutoComplete
                            style={{ width: '100%' }}
                            options={this.state.manage_businesses}
                            placeholder="Search for businesses"
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={(value, option) => {
                              window.sessionStorage.setItem(
                                'new_business',
                                option.id
                              );
                              this.getUserAndBusinessDetails(
                                window.sessionStorage.getItem('new_business'),
                                ''
                              );
                            }}
                          />
                        </>
                      )}

                      {/* <label className="form-group mb-1 mt-4">
                          <span>
                            Claim Another Business
                          </span>
                        </label> */}
                      <button
                        type="submit"
                        className="btn btn-md btn-primary btn-block  mb-1 mt-2"
                        onClick={() => {
                          // this.setState({
                          //   onbaordingModal: true,
                          //   onbaordingModalAnotherOne: true,
                          //   manageBusinessesModal: false
                          // });
                          window.location.replace(
                            `${CONSTANTS.APP_URL}/onboarding`
                          );
                          window.sessionStorage.setItem(
                            'claim_another',
                            'active'
                          );
                        }}
                      >
                        <span>Claim Another Business</span>
                      </button>
                    </div>
                  </Modal>

                  {/* subscription expired modal end */}

                  <Footer
                    infoForFooter={reactLocalStorage.getObject('free_trial')}
                  />
                </div>
              </div>
            </div>

            {/* <Tour
              onRequestClose={this.closeTour}
              disableInteraction={true}
              steps={businessInformationTourConfig}
              isOpen={isTourOpen}
              className="helper"
              rounded={5}
              onAfterOpen={this.disableBody}
              onBeforeClose={this.enableBody}
            /> */}
          </div>
        )}
      </Fragment>
    );
  }
}

export default BusinessInformation;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from "../misc/AuthService";
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import {explore_data} from '../includes/SerpAPIPhotos';
import { Image, Carousel } from 'antd';
import Iframe from 'react-iframe';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BusinessPortalLoader } from '../includes/Loaders';

class Reviews extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      allReviewsModal: false,
      business_reviews: [],
      business_details: {
        place_id: '',
        bio: '',
        name: '',
        formatted_address: '',
        business_status: '',
        icon: '',
        icon_background_color: '',
        icon_mask_base_uri: '',
        phone_number: '',
        rating: '',
        url: '',
        user_ratings_total: '',
        vicinity: '',
        website: '',
        reference: '',
        lat: '',
        lng: '',
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getReviews();
    }
  }

  getReviews() {
    let business_details = JSON.parse(AuthService.getAuth().business);

    axios
    .post(CONSTANTS.API_BASE_URL + '/business/reviews', {
      link: business_details.reviews_link,
    })
    .then((response) => {
      let result = response.data.data;

      let old_business_details = this.state.business_details;
      old_business_details.bio = business_details.bio;
      old_business_details.name = business_details.name;
      old_business_details.rating = business_details.rating;
      old_business_details.user_ratings_total = business_details.reviews;
      old_business_details.phone_number = business_details.phone_number;
      old_business_details.website = business_details.website;


      for (let i = 0; i < result.reviews.length; i++) {
        if ('images' in result.reviews[i]) {
        }
        else{
          result.reviews[i]['images'] = [];
        }
      }

      this.setState({
        isLoading: false,
        business_reviews: result.reviews,
      });
      
    })
    .catch((error) => {
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {

    const contentStyle = {
      height: '160px',
      color: '#fff',
      lineHeight: '160px',
      textAlign: 'center',
      background: '#364d79',
    };

    return (
      <Fragment>
        <MetaTags>
          <title>UnstuQ Business</title>
        </MetaTags>
        {this.state.isLoading ? (
           <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
        ) : (
          <div>
            <div className="nk-app-root bg-lighter">
              <div className="nk-main ">
                <SideNav />
                <div className="nk-wrap ">
                  <HeaderNav />

                  <div className="nk-content ">
                    <div className="container-fluid">
                      <div className="nk-content-inner">
                        <div className="nk-content-body">
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h4
                                  className="nk-block-title page-title"
                                  style={{ color: '#364a63' }}
                                >
                                  {this.state.business_details.name.toString()}
                                </h4>
                              </div>
                            </div>
                          </div>

                          <div className="nk-block">
                            <div className="row g-gs">
                              <div className="col-md-6 col-lg-6 col-sm-12">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">
                                            Overall Google Rating
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">
                                            {this.state.business_details.rating}</div>
                                          <div className="nk-ecwg6-ck">
                                            <div className="chartjs-size-monitor">
                                              <div className="chartjs-size-monitor-expand">
                                                <div className=""/>
                                              </div>
                                              <div className="chartjs-size-monitor-shrink">
                                                <div className=""/>
                                              </div>
                                            </div>
                                            <canvas
                                              className="ecommerce-line-chart-s3 chartjs-render-monitor"
                                              id="todayOrders"
                                              style={{
                                                display: 'block',
                                                height: '40px',
                                                width: '100px',
                                              }}
                                              width={200}
                                              height={80}
                                            />
                                          </div>
                                        </div>
                                        <div className="info">
                                        <div className="kanban-title-board">
                                            <div className="kanban-title-content">
                                              <ul
                                                className="rating"
                                                style={{ color: '#017afe' }}
                                              >
                                                {parseInt(
                                                  this.state.business_details
                                                    .rating
                                                ) === 0 && (
                                                  <>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                  </>
                                                )}
                                                {parseInt(
                                                  this.state.business_details
                                                    .rating
                                                ) === 1 && (
                                                  <>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                  </>
                                                )}
                                                {parseInt(
                                                  this.state.business_details
                                                    .rating
                                                ) === 2 && (
                                                  <>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                  </>
                                                )}
                                                {parseInt(
                                                  this.state.business_details
                                                    .rating
                                                ) === 3 && (
                                                  <>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                  </>
                                                )}
                                                {parseInt(
                                                  this.state.business_details
                                                    .rating
                                                ) === 4 && (
                                                  <>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star" />
                                                    </li>
                                                  </>
                                                )}
                                                {parseInt(
                                                  this.state.business_details
                                                    .rating
                                                ) === 5 && (
                                                  <>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                    <li>
                                                      <em className="icon ni ni-star-fill" />
                                                    </li>
                                                  </>
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                          {/* <span className="change up text-danger">
                                            <em className="icon ni ni-arrow-long-up" />
                                            4.63%
                                          </span>
                                          <span> vs. last week</span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-sm-12">
                                <div className="card">
                                  <div className="nk-ecwg nk-ecwg6">
                                    <div className="card-inner">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h6 className="title">
                                            Total Ratings Received
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="data">
                                        <div className="data-group">
                                          <div className="amount">{String(
                                                        this.state
                                                          .business_details
                                                          .user_ratings_total
                                                      ).replace(
                                                        /(.)(?=(\d{3})+$)/g,
                                                        '$1,'
                                                      )}</div>
                                          <div className="nk-ecwg6-ck">
                                            <div className="chartjs-size-monitor">
                                              <div className="chartjs-size-monitor-expand">
                                                <div className=""/>
                                              </div>
                                              <div className="chartjs-size-monitor-shrink">
                                                <div className=""/>
                                              </div>
                                            </div>
                                            <canvas
                                              className="ecommerce-line-chart-s3 chartjs-render-monitor"
                                              id="todayRevenue"
                                              style={{
                                                display: 'block',
                                                height: '40px',
                                                width: '100px',
                                              }}
                                              width={200}
                                              height={80}
                                            />
                                          </div>
                                        </div>
                                        <div className="info">
                                        <a
                                          target="_blank"
                                          href={`https://search.google.com/local/reviews?placeid=${this.state.businessInfo.place_id}`}
                                        >
                                          <h6
                                            className="title"
                                            style={{
                                              color: '#017afe',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            Reply to Reviews 
                                          </h6>
                                        </a>
                                          {/* <span className="change down text-danger">
                                            <em className="icon ni ni-arrow-long-down" />
                                            2.34%
                                          </span>
                                          <span> vs. last week</span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row g-gs">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                              <div className="card card-full">
                                <div
                                  className="card-inner-group"
                                  //   style={{
                                  //     maxHeight: '380px',
                                  //     overflow: 'scroll',
                                  //   }}
                                >
                                  {this.state.business_reviews.length < 1 ? (
                                    <>
                                      <div
                                        style={{ backgroundColor: '#f5f6fa' }}
                                        className="nk-block-content nk-error-ld text-center"
                                      >
                                        <em
                                          className="icon ni ni-na fa-lg"
                                          style={{
                                            fontSize: '40vh',
                                            color: '#8094ae',
                                          }}
                                        ></em>
                                        <h3
                                          style={{
                                            color: '#8094ae',
                                          }}
                                          className="nk-error-title"
                                        >
                                          There are no reviews for this business
                                        </h3>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {/* <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              Reviews (
                                              {
                                                this.state.business_reviews
                                                  .length
                                              }
                                              )
                                            </h6>
                                          </div>
                                        </div>
                                      </div> */}

                                      <div className="card-inner">
                                        <div className="card-title-group">
                                          <div className="card-title">
                                            <h6 className="title">
                                              {this.state.business_reviews
                                                .length +
                                                ' Most Relevant Reviews'}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>

                                      {this.state.business_reviews.map(
                                        (data, index) => {
                                          return (
                                            <div className="card-inner card-inner-md">
                                              <div className="user-card">
                                                <div className="user-avatar bg-primary-dim">
                                                  {data.user.thumbnail ===
                                                  undefined ? (
                                                    <em className="icon ni ni-user-alt" />
                                                  ) : (
                                                    <img
                                                      src={
                                                        data.user.thumbnail
                                                      }
                                                      alt=""
                                                    />
                                                  )}
                                                  {/* {data.profile_photo_url === '' ? (
                                                  <span>AB</span>
                                                ) : (
                                                  <img
                                                    src={ data.profile_photo_url }
                                                    alt=""
                                                  />
                                                )} */}
                                                </div>
                                                <div className="user-info">
                                                  <span className="lead-text">
                                                    {data.user.name}&nbsp; •
                                                    &nbsp;
                                                    <span className="lead-text">
                                                      <ul
                                                        className="rating"
                                                        style={{
                                                          color: '#017afe',
                                                        }}
                                                      >
                                                        {parseInt(
                                                          data.rating
                                                        ) === 0 && (
                                                          <>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                          </>
                                                        )}
                                                        {parseInt(
                                                          data.rating
                                                        ) === 1 && (
                                                          <>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                          </>
                                                        )}
                                                        {parseInt(
                                                          data.rating
                                                        ) === 2 && (
                                                          <>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                          </>
                                                        )}
                                                        {parseInt(
                                                          data.rating
                                                        ) === 3 && (
                                                          <>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                          </>
                                                        )}
                                                        {parseInt(
                                                          data.rating
                                                        ) === 4 && (
                                                          <>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star" />
                                                            </li>
                                                          </>
                                                        )}
                                                        {parseInt(
                                                          data.rating
                                                        ) === 5 && (
                                                          <>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                            <li>
                                                              <em className="icon ni ni-star-fill" />
                                                            </li>
                                                          </>
                                                        )}
                                                      </ul>
                                                    </span>
                                                  </span>
                                                  <span className="sub-text">
                                                    {data.snippet}
                                                  </span>
                                                  <br/>

                                                  {this.state.business_reviews[index]['images'].map(
                                                  (d, i) => {
                                                      return (
                                                        // <div style={{marginRight: "12px", display: "flex"}}>
                                                        <Image
                                                          style={{marginRight: "12px"}}
                                                          width={100}
                                                          src={d}
                                                        />

                                                        // <Carousel effect="fade">
                                                        //   <Image
                                                        //   width={100}
                                                        //   src={d}
                                                        // />
                                                        // </Carousel>

                                                        // <div
                                                        //   className="ant-image"
                                                        //   style={{
                                                        //     width: '100px',
                                                        //     marginRight: "10px"
                                                        //   }}
                                                        // >
                                                        //   <img
                                                        //     className="ant-image-img"
                                                        //     src={d}
                                                        //     style={{
                                                        //       marginRight:
                                                        //         '12px',
                                                        //     }}
                                                        //   />
                                                        //   <div className="ant-image-mask">
                                                        //     <div className="ant-image-mask-info">
                                                        //       <span
                                                        //         role="img"
                                                        //         aria-label="eye"
                                                        //         className="anticon anticon-eye"
                                                        //       >
                                                        //         <svg
                                                        //           viewBox="64 64 896 896"
                                                        //           focusable="false"
                                                        //           data-icon="eye"
                                                        //           width="1em"
                                                        //           height="1em"
                                                        //           fill="currentColor"
                                                        //           aria-hidden="true"
                                                        //         >
                                                        //           <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z" />
                                                        //         </svg>
                                                        //       </span>
                                                        //       Preview
                                                        //     </div>
                                                        //   </div>
                                                        // </div>

                                                        // </div>
                                                      );
                                                    }
                                                  )} 

                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  )}

                                  {/* manage reviews modal start */}

                                  {/* <Modal
                                    visible={this.state.allReviewsModal}
                                    title="Manage Reviews"
                                    footer={null}
                                    // width={1000}
                                    onCancel={() => {
                                      this.setState({
                                        allReviewsModal: false,
                                      });
                                    }}
                                  >
                                    <div>
                                      <iframe src={`https://search.google.com/local/reviews?placeid=${this.state.businessInfo.place_id}`} frameborder="0" width="100%" height="500"></iframe>
                                    </div>
                                  </Modal> */}

                                  {/* manage reviews modal end */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Footer
                    infoForFooter={reactLocalStorage.getObject('free_trial')}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Reviews;

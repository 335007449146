import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import moment from 'moment';
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Tour from "reactour";
// import {eventsTourConfig} from '../includes/tourConfig';
import theme from '../../theme/black';
import { Upload, Modal } from 'antd';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BusinessPortalLoader } from '../includes/Loaders';
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { DatePicker, Switch } from 'antd';
const { RangePicker } = DatePicker;
// var Loader = require('react-loader');

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class Events extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      nothingToShow: true,
      isRequesting: false,
      showEventModal: false,
      previewVisible: false,
      allowEmptyStartDate: false,
      allowEmptyEndDate: false,
      deleteEventModal: false,
      isTourOpen: false,
      isShowingMore: false,
      allDaysDefault: false,
      sundayDefault: false,
      mondayDefault: false,
      tuesdayDefault: false,
      wednesdayDefault: false,
      thursdayDefault: false,
      fridayDefault: false,
      saturdayDefault: false,
      sundayDisabled: true,
      mondayDisabled: true,
      tuesdayDisabled: true,
      wednesdayDisabled: true,
      thursdayDisabled: true,
      fridayDisabled: true,
      saturdayDisabled: true,
      allDaysDisabled: true,
      deleteEventId: '',
      deleteEventTitle: '',
      previewImage: '',
      previewTitle: '',
      eventModalTitle: '',
      status: 0,
      fileListt: [],
      tryThis: [],
      getImageUrl: '',
      eventsArray: [],
      business_details: {
        name: '',
      },
      event: {
        id: '',
        title: '',
        description: '',
        fileList: [],
        finalRepeatList: [],
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        status: 0,
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getEvents("");
      // if(this.state.userInfo.events_tour === 0){
      //   this.openTour();
      // }
    }
  }

  // disableBody = target => disableBodyScroll(target);
  // enableBody = target => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //   .post(
  //     CONSTANTS.API_BASE_URL + '/business/tour/update',
  //     { column_name: "events_tour" },
  //     AuthService.getAxiosHeaders(
  //       window.sessionStorage.getItem('new_business')
  //     )
  //   )
  //   .then((response) => {
  //     let result = response.data;
  //     reactLocalStorage.remove(
  //       'user_object'
  //     );
  //     reactLocalStorage.setObject(
  //       'user_object',
  //       JSON.stringify(result.data.user_details)
  //     );
  //   })
  //   .catch((error) => {
  //   });
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  getMuiTheme = () => createTheme({
    components: {
        MUIDataTableHeadCell: {
          styleOverrides:{
          fixedHeader: {
            zIndex: "0"
          }
        }
        },
        MUIDataTableSelectCell: {
          styleOverrides:{
          headerCell: {
            zIndex: "0"
          }
        }
        },
    }
})

  getEvents = (request) => {
    const format1 = "ll hh:mm a"
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/events',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let business_events = result.data.business_events;
        let business_events_recurring =
        result.data.business_events_recurring;
        let theArray = [];
        let UsersArray = [];

        for (let i = 0; i < business_events.length; i++) {
          let the_id = business_events[i].id;
          let title = business_events[i].title;
          let description = business_events[i].description;
          let image = business_events[i].image;
          let image_content = business_events[i].image_content;
          let start_date = business_events[i].start_date;
          let start_time = business_events[i].start_time;
          let end_date = business_events[i].end_date;
          let end_time = business_events[i].end_time;
          let status = business_events[i].status;

          const start_date_converted = new Date(start_date + ' ' + start_time)
            .toString()
            .split(' ');
          const start_time_converted = start_date_converted[4].split(':');

          const end_date_converted = new Date(end_date + ' ' + end_time)
            .toString()
            .split(' ');
          const end_time_converted = start_date_converted[4].split(':');

          let UserArray = [];
          //   UserArray.push(image);
          UserArray.push(title);
          UserArray.push(description);
          UserArray.push(moment(start_date + ' ' + start_time).format(format1));
          // UserArray.push(
          //   start_date_converted[0] +
          //     ' ' +
          //     start_date_converted[1] +
          //     ' ' +
          //     start_date_converted[2] +
          //     ' ' +
          //     start_date_converted[3] +
          //     ' ' +
          //     start_time_converted[0] +
          //     ':' +
          //     start_time_converted[1]
          // );
          UserArray.push(moment(end_date + ' ' + end_time).format(format1));
          // UserArray.push(
          //   end_date_converted[0] +
          //     ' ' +
          //     end_date_converted[1] +
          //     ' ' +
          //     end_date_converted[2] +
          //     ' ' +
          //     end_date_converted[3] +
          //     ' ' +
          //     end_time_converted[0] +
          //     ':' +
          //     end_time_converted[1]
          // );
          UserArray.push(start_date);
          UserArray.push(start_time);
          UserArray.push(end_date);
          UserArray.push(end_time);
          UserArray.push('');
          UserArray.push(the_id);
          UserArray.push(image_content);
          UserArray.push(image);
          UserArray.push(status);
          UserArray.push(business_events_recurring);

          UsersArray.push(UserArray);
        }
        if(request == "add"){
          this.setState({
            eventsArray: UsersArray,
            isRequesting: false,
            showEventModal: false,
          });
          AuthService.Prompts('success', 'Updated Successfully');
        }
        if(request == "delete"){
          this.setState({
            eventsArray: UsersArray,
            isRequesting: false,
            deleteEventModal: false,
          });
          AuthService.Prompts('success', 'Deleted Successfully');
        }
        this.setState({
          ...this.state,
          isLoading: false,
          eventsArray: UsersArray,
          // isRequesting: false,
          // showEventModal: false,
          // deleteEventModal: false,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  onCheckAllDays = (e, day) => {
    if (e.target.checked) {
      this.controlRepeat(0, 'checked');
      this.controlRepeat(1, 'checked');
      this.controlRepeat(2, 'checked');
      this.controlRepeat(3, 'checked');
      this.controlRepeat(4, 'checked');
      this.controlRepeat(5, 'checked');
      this.controlRepeat(6, 'checked');

      this.setState({
        allDaysDefault: true,
        sundayDisabled: true,
        mondayDisabled: true,
        tuesdayDisabled: true,
        wednesdayDisabled: true,
        thursdayDisabled: true,
        fridayDisabled: true,
        saturdayDisabled: true,

        sundayDefault: false,
        mondayDefault: false,
        tuesdayDefault: false,
        wednesdayDefault: false,
        thursdayDefault: false,
        fridayDefault: false,
        saturdayDefault: false,
      });
    } else {
      this.controlRepeat(0, 'unchecked');
      this.controlRepeat(1, 'unchecked');
      this.controlRepeat(2, 'unchecked');
      this.controlRepeat(3, 'unchecked');
      this.controlRepeat(4, 'unchecked');
      this.controlRepeat(5, 'unchecked');
      this.controlRepeat(6, 'unchecked');

      this.setState({
        allDaysDefault: false,
        sundayDisabled: false,
        mondayDisabled: false,
        tuesdayDisabled: false,
        wednesdayDisabled: false,
        thursdayDisabled: false,
        fridayDisabled: false,
        saturdayDisabled: false,
      });
    }
  };

  controlRepeat = (the_day, status) => {
    let startM = moment(this.state.event.start_date),
      endM = moment(this.state.event.end_date),
      dayM = the_day;
    let { fileListt } = this.state;

    if (status == 'checked') {
      var current = startM.clone();
      while (current.day(7 + dayM).isSameOrBefore(endM)) {
        // while (current.day(1 + dayM).isSameOrBefore(endM)) {
        // fileListt.push(current.clone().format('YYYY-MM-DD'));
        fileListt.push(the_day);
      }
    } else {
      var current = startM.clone();
      while (current.day(7 + dayM).isSameOrBefore(endM)) {
        // while (current.day(1 + dayM).isSameOrBefore(endM)) {
        // let index = fileListt.indexOf(current.clone().format('YYYY-MM-DD'));
        let index = fileListt.indexOf(the_day);
        if (index > -1) {
          fileListt.splice(index, 1);
        }
      }
    }
    //console.log(this.state.fileListt);
  };

  onChangeDays = (e, day) => {
    //console.log(`checked = ${e.target.checked}`);

    // if (e.target.checked) {
    //   this.setState({
    //     allDaysDisabled: true
    //   });
    // }
    if (e.target.checked && day == 'sunday') {
      this.controlRepeat(0, 'checked');
      this.setState({
        sundayDefault: true
      });
    } else {
      if (day == 'sunday') {
        this.controlRepeat(0, 'unchecked');
        this.setState({
          sundayDefault: false
        });
      }
    }
    if (e.target.checked && day == 'monday') {
      this.controlRepeat(1, 'checked');
      this.setState({
        mondayDefault: true
      });
    } else {
      if (day == 'monday') {
        this.controlRepeat(1, 'unchecked');
        this.setState({
          mondayDefault: false
        });
      }
    }
    if (e.target.checked && day == 'tuesday') {
      this.controlRepeat(2, 'checked');
      this.setState({
        tuesdayDefault: true
      });
    } else {
      if (day == 'tuesday') {
        this.controlRepeat(2, 'unchecked');
        this.setState({
          tuesdayDefault: false
        });
      }
    }
    if (e.target.checked && day == 'wednesday') {
      this.controlRepeat(3, 'checked');
      this.setState({
        wednesdayDefault: true
      });
    } else {
      if (day == 'wednesday') {
        this.controlRepeat(3, 'unchecked');
        this.setState({
          wednesdayDefault: false
        });
      }
    }
    if (e.target.checked && day == 'thursday') {
      this.controlRepeat(4, 'checked');
      this.setState({
        thursdayDefault: true
      });
    } else {
      if (day == 'thursday') {
        this.controlRepeat(4, 'unchecked');
        this.setState({
          thursdayDefault: false
        });
      }
    }
    if (e.target.checked && day == 'friday') {
      this.controlRepeat(5, 'checked');
      this.setState({
        fridayDefault: true
      });
    } else {
      if (day == 'friday') {
        this.controlRepeat(5, 'unchecked');
        this.setState({
          fridayDefault: false
        });
      }
    }
    if (e.target.checked && day == 'saturday') {
      this.controlRepeat(6, 'checked');
      this.setState({
        saturdayDefault: true
      });
    } else {
      if (day == 'saturday') {
        this.controlRepeat(6, 'unchecked');
        this.setState({
          saturdayDefault: false
        });
      }
    }
  };

  deleteEvent = (the_id, title) => {
    this.setState({
      deleteEventModal: true,
      deleteEventId: the_id,
      deleteEventTitle: title,
    });
  };

  editEvent = (
    dataIndex,
    the_id,
    title,
    description,
    image_content,
    start_date,
    start_time,
    end_date,
    end_time,
    recurr,
    image,
    status
  ) => {
    let theArray = [];
    this.state.fileListt.length = 0;
    let { fileListt } = this.state;

    let old_file = [];
    if (image != '') {
      old_file = [
        {
          action: 'edit',
          url: image,
        },
      ];
    }

    this.setState({
      sundayDefault: false,
      mondayDefault: false,
      tuesdayDefault: false,
      wednesdayDefault: false,
      thursdayDefault: false,
      fridayDefault: false,
      saturdayDefault: false,
      allDaysDefault: false,
      allDaysDisabled: false,
      sundayDisabled: false,
      mondayDisabled: false,
      tuesdayDisabled: false,
      wednesdayDisabled: false,
      thursdayDisabled: false,
      fridayDisabled: false,
      saturdayDisabled: false,
      incentiveModalTitle: 'Edit Deal',
    });

    for (let i = 0; i < recurr[dataIndex].length; i++) {
      fileListt.push(recurr[dataIndex][i].day);

      if (recurr[dataIndex][i].day == 0) {
        // this.controlRepeat(0, 'checked');
        this.setState({
          sundayDefault: true,
        });
      }
      if (recurr[dataIndex][i].day == 1) {
        // this.controlRepeat(1, 'checked');
        this.setState({
          mondayDefault: true,
        });
      }
      if (recurr[dataIndex][i].day == 2) {
        // this.controlRepeat(2, 'checked');
        this.setState({
          tuesdayDefault: true,
        });
      }
      if (recurr[dataIndex][i].day == 3) {
        // this.controlRepeat(3, 'checked');
        this.setState({
          wednesdayDefault: true,
        });
      }
      if (recurr[dataIndex][i].day == 4) {
        // this.controlRepeat(4, 'checked');
        this.setState({
          thursdayDefault: true,
        });
      }
      if (recurr[dataIndex][i].day == 5) {
        // this.controlRepeat(5, 'checked');
        this.setState({
          fridayDefault: true,
        });
      }
      if (recurr[dataIndex][i].day == 6) {
        // this.controlRepeat(6, 'checked');
        this.setState({
          saturdayDefault: true,
        });
      }
    }

      if(recurr[dataIndex].length == 7){
        this.setState({
          allDaysDefault: true,
          sundayDefault: false,
          mondayDefault: false,
          tuesdayDefault: false,
          wednesdayDefault: false,
          thursdayDefault: false,
          fridayDefault: false,
          saturdayDefault: false,
        });
    }

    let old_event = this.state.event;
    old_event.id = the_id;
    old_event.title = title;
    old_event.description = description;
    old_event.fileList = old_file;
    old_event.start_date = start_date;
    old_event.start_time = start_time;
    old_event.end_date = end_date;
    old_event.end_time = end_time;
    old_event.status = status;
    this.setState({
      event: old_event,
      status: status,
      showEventModal: true,
      eventModalTitle: 'Edit Event',
    });
  };

  deleteEventRequest = (e) => {
    this.setState({
      isRequesting: true,
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/delete/event',
        { event_id: JSON.stringify(this.state.deleteEventId) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.getEvents("delete");
        // this.setState({
        //   isRequesting: false,
        //   deleteEventModal: false,
        // });
        // AuthService.Prompts('success', 'Deleted Successfully');
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          deleteEventModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  onChangeStatus = (e, day) => {
    if (e.target.checked && day == 'status') {
      let old_event = this.state.event;
      old_event.status = 1;
      this.setState({
        service: old_event,
      });
    } else {
      if (day == 'status') {
        let old_event = this.state.event;
        old_event.status = 0;
        this.setState({
          service: old_event,
        });
      }
    }
  };

  addEvent = () => {
    let generateStartDate = moment().format();
    let generateEndDate = moment().add(5, 'days').format();
    let old_event = this.state.event;
    old_event.id = '';
    old_event.title = '';
    old_event.description = '';
    old_event.fileList = [];
    old_event.start_date = [];
    old_event.end_date = [];
    old_event.status = 1;

    this.setState({
      showEventModal: true,
      event: old_event,
      allowEmptyStartDate: true,
      allowEmptyEndDate: true,
      eventModalTitle: 'Add an Event',

      allDaysDefault: false,
      sundayDefault: false,
      mondayDefault: false,
      tuesdayDefault: false,
      wednesdayDefault: false,
      thursdayDefault: false,
      fridayDefault: false,
      saturdayDefault: false,

      allDaysDisabled: true,
      sundayDisabled: true,
      mondayDisabled: true,
      tuesdayDisabled: true,
      wednesdayDisabled: true,
      thursdayDisabled: true,
      fridayDisabled: true,
      saturdayDisabled: true,
    });
  };

  // startEnd = (value, dateString) => {
  //   let { fileListt, tryThis } = this.state;
  //   let startM = moment(dateString[0].split(' ')[0]),
  //     endM = moment(dateString[1].split(' ')[0]),
  //     dayM = 1;

  // let result = [];
  // var detailedResult = [];
  //   var current = startM.clone();

  //   while (current.day(7 + dayM).isSameOrBefore(endM)) {
  //     //   result.push(current.clone());
  //     //   detailedResult.push(current.clone().format('LLLL'));
  //     fileListt.push(current.clone().format('YYYY-MM-DD'));
  //   }

  //   let old_fileList = this.state.event;
  //   old_fileList.start_date = dateString[0].split(' ')[0];
  //   old_fileList.start_time = dateString[0].split(' ')[1];
  //   old_fileList.end_date = dateString[1].split(' ')[0];
  //   old_fileList.end_time = dateString[1].split(' ')[1];
  //   tryThis.push(dayM);
  //   this.setState({
  //     event: old_fileList,
  //     mondayDefault: true,
  //     sundayDisabled: false,
  //     tuesdayDisabled: false,
  //     wednesdayDisabled: false,
  //     thursdayDisabled: false,
  //     fridayDisabled: false,
  //     saturdayDisabled: false,
  //   });
  //console.log(this.state.fileListt);
  //console.log(this.state.tryThis);
  // };

  startEnd = (value, dateString) => {

    let old_fileList = this.state.event;
    old_fileList.start_date = dateString[0].split(' ')[0];
    old_fileList.start_time = dateString[0].split(' ')[1];
    old_fileList.end_date = dateString[1].split(' ')[0];
    old_fileList.end_time = dateString[1].split(' ')[1];
    // tryThis.push(dayM);
    this.setState({
      event: old_fileList,
      mondayDefault: false,
      allDaysDisabled: false,
      sundayDisabled: false,
      mondayDisabled: false,
      tuesdayDisabled: false,
      wednesdayDisabled: false,
      thursdayDisabled: false,
      fridayDisabled: false,
      saturdayDisabled: false,
    });

  };

  controlRepeat = (the_day, status) => {
    // let startM = moment(this.state.event.start_date),
    //   endM = moment(this.state.event.end_date),
    //   dayM = the_day;
    // let { fileListt } = this.state;

    // if (status == 'checked') {
    //   var current = startM.clone();
    //   while (current.day(7 + dayM).isSameOrBefore(endM)) {
    //     fileListt.push(current.clone().format('YYYY-MM-DD'));
    //   }
    // } else {
    //   var current = startM.clone();
    //   while (current.day(7 + dayM).isSameOrBefore(endM)) {
    //     let index = fileListt.indexOf(current.clone().format('YYYY-MM-DD'));
    //     if (index > -1) {
    //       fileListt.splice(index, 1);
    //     }
    //   }
    // }
    //console.log(this.state.fileListt);

    let startM = moment(this.state.event.start_date),
    endM = moment(this.state.event.end_date),
    dayM = the_day;
    let { fileListt } = this.state;

    if (status == 'checked') {
      var current = startM.clone();
      while (current.day(7 + dayM).isSameOrBefore(endM)) {
        // while (current.day(1 + dayM).isSameOrBefore(endM)) {
        // fileListt.push(current.clone().format('YYYY-MM-DD'));
        fileListt.push(the_day);
      }
    } else {
      var current = startM.clone();
      while (current.day(7 + dayM).isSameOrBefore(endM)) {
        // while (current.day(1 + dayM).isSameOrBefore(endM)) {
        // let index = fileListt.indexOf(current.clone().format('YYYY-MM-DD'));
        let index = fileListt.indexOf(the_day);
        if (index > -1) {
          fileListt.splice(index, 1);
        }
      }
    }
    //console.log(this.state.fileListt);
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleAddEvent = (event, name) => {
    if (name.toString() === 'title') {
      let old_fileList = this.state.event;
      old_fileList.title = event.target.value;
      this.setState({
        event: old_fileList,
      });
    }
    if (name.toString() === 'description') {
      let old_fileList = this.state.event;
      old_fileList.description = event.target.value;
      this.setState({
        event: old_fileList,
      });
    }
  };

  handleChange = ({ fileList }) => {
    let old_fileList = this.state.event;
    old_fileList.fileList = [];
    old_fileList.fileList = fileList;
    this.setState({
      event: old_fileList,
    });
    //console.log(this.state.event.fileList);

    // for (let i = 0; i < this.state.fileList.length; i++) {
    //   if(this.state.fileList[i].status.toString() === 'uploading' && this.state.fileList[i].size > 11000){
    //console.log(this.state.fileList[i].size);
    //    alert('File too large');
    //    return;
    //   }
    // }
  };

  submitEventsForm = (e) => {
    e.preventDefault();

    if (this.state.event.start_time == '') {
      AuthService.Prompts('error', 'Start and End date required');
      return;
    }

    this.setState({
      isRequesting: true,
    });

    if (this.state.event.fileList.length != 0) {
      //console.log(Object.keys(this.state.event.fileList[0]).length);
      if (Object.keys(this.state.event.fileList[0]).length === 3) {
        let old_event = this.state.event;
        old_event.fileList = [];
        this.setState({
          event: old_event,
        });
      }
    }
    let data = this.state.fileListt;
    data = this.state.fileListt.filter(function (element) {
      return element !== undefined;
    });

    let old_event = this.state.event;
    old_event.finalRepeatList = [...new Set(data)];
    this.setState({
      event: old_event,
    });

    //console.log(this.state.event.finalRepeatList);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/event',
        { events: JSON.stringify(this.state.event) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.getEvents("add");
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          showEventModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";

    const columns = [
      {
        name: 'Image',
        options: {
          print: true,
          filter: true,
          sort: false,
          filterType: 'multiselect',
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][11];
            if (value == '' || value === undefined || value === null) {
              return <div className="user-toggle">
              <div className="sm" style={{width: "75px"}}>
                <img src='../assets/images/image.png' alt="" />
              </div>
            </div>;
            } else {
              return <div className="user-toggle">
              <div className="sm" style={{width: "75px"}}>
                <img src={value} alt="" />
              </div>
            </div>;
            }
          },
        },
      },
      {
        // label: "Name",
        name: 'Title',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][0];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Start Date/Time',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][2];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'End Date/Time',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][3];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Status',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][12];
            if (value === 1) {
              return (
                <li style={{ padding: '0px' }} className="preview-item">
                  <span
                    style={{ fontWeight: '700' }}
                    className="badge badge-success"
                  >
                    Active
                  </span>
                </li>
              );
            } else {
              return (
                <li style={{ padding: '0px' }} className="preview-item">
                  <span
                    style={{ fontWeight: '700' }}
                    className="badge badge-danger"
                  >
                    Inactive
                  </span>
                </li>
              );
            }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let title = data[dataIndex][0];
            let description = data[dataIndex][1];
            let start_date = data[dataIndex][4];
            let start_time = data[dataIndex][5];
            let end_date = data[dataIndex][6];
            let end_time = data[dataIndex][7];
            let the_id = data[dataIndex][9];
            let image_content = data[dataIndex][10];
            let image = data[dataIndex][11];
            let status = data[dataIndex][12];
            let recurr = data[dataIndex][13];
            return (
              <div>
                <button
                  onClick={() => {
                    this.editEvent(
                      dataIndex,
                      the_id,
                      title,
                      description,
                      image_content,
                      start_date,
                      start_time,
                      end_date,
                      end_time,
                      recurr,
                      image,
                      status
                    );
                  }}
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: '10px' }}
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    this.deleteEvent(the_id, title);
                  }}
                  className="btn btn-danger btn-sm"
                >
                  Delete
                </button>
              </div>
            );
          },
        },
      },
    ];

    const data = this.state.eventsArray;

    const options = {
      filterType: 'dropdown',
      responsive: 'stacked',
      filter: false,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
            noMatch: 'No events have been added for this business'
        },
      },
    };
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div>Add Image</div>
      </div>
    );
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
             <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">

                              <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h4
                                      className="nk-block-title page-title"
                                      style={{ color: '#364a63' }}
                                    >
                                      {this.state.businessInfo.name.toString()}
                                    </h4>
                                  </div>
                                  <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em
                                          onClick={() => {
                                            this.pageMenu();
                                          }}
                                          className="icon ni ni-more-v"
                                        />
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                        style={{
                                          display: `${this.state.theDisplay}`,
                                        }}
                                      >
                                        <ul className="nk-block-tools g-3">
                                          <li data-tut="reactour__addEventButton">
                                            <button
                                              type="submit"
                                              className="btn btn-md btn-primary btn-block"
                                              onClick={() => {
                                                this.addEvent();
                                              }}
                                            >
                                              <span>Add an Event</span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-md-3">
                                <header
                                  className="kanban-board-header"
                                  style={{ borderTop: '0px' }}
                                >
                                  <div className="kanban-title-board" style={{justifyContent: 'flex-start'}}>
                                    <div className="kanban-title-content">
                                      <div className="drodown">
                                        <a
                                          href="/#"
                                          className="dropdown-toggle btn btn-sm btn-icon btn-trigger mr-n1"
                                          data-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <em className="icon ni ni-happy-fill" />
                                        </a>
                                      </div>
                                    </div>
                                    <div className="kanban-title-content ml-2">
                                      <h6
                                        className="title"
                                        onClick={() => {
                                          this.addEvent();
                                        }}
                                        style={{
                                          color: '#017afe',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        Add Event
                                      </h6>
                                    </div>
                                  </div>
                                </header>
                              </div>

                            </div> */}

                            <Modal
                              visible={this.state.showEventModal}
                              title={this.state.eventModalTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                  showEventModal: false,
                                });
                              }}
                            >
                              <div className="form-group">
                                <div className="form-control-wrap">
                                  <form onSubmit={this.submitEventsForm}>
                                    <div>
                                      <div
                                        className="row"
                                        style={{ alignItems: 'normal' }}
                                      >
                                        <div className="col-3">
                                          <Upload
                                            action={`${
                                              CONSTANTS.API_BASE_URL +
                                              '/business/photo/update'
                                            }`}
                                            listType="picture-card"
                                            fileList={this.state.event.fileList}
                                            onPreview={this.handlePreview}
                                            onChange={this.handleChange}
                                          >
                                            {this.state.event.fileList
                                              .length === 1
                                              ? null
                                              : uploadButton}
                                          </Upload>
                                          <Modal
                                            visible={this.state.previewVisible}
                                            title={this.state.previewTitle}
                                            footer={null}
                                            onCancel={() => {
                                              this.setState({
                                                previewVisible: false,
                                              });
                                            }}
                                          >
                                            <img
                                              alt="example"
                                              style={{ width: '100%' }}
                                              src={this.state.previewImage}
                                            />
                                          </Modal>
                                        </div>
                                        <div className="col-9">
                                          <div className="project-info">
                                            <input
                                              className="form-control form-control-sm"
                                              placeholder="Event Title"
                                              spellCheck="false"
                                              required
                                              style={{
                                                fontSize: '14px',
                                                height: '35px',
                                              }}
                                              value={this.state.event.title}
                                              onChange={(e) => {
                                                this.handleAddEvent(e, 'title');
                                              }}
                                            />
                                            <textarea
                                              className="form-control form-control-sm mt-2"
                                              placeholder="Event Description"
                                              spellCheck="false"
                                              required
                                              rows="2"
                                              style={{
                                                fontSize: '14px',
                                                minHeight: 'auto',
                                              }}
                                              value={
                                                this.state.event.description
                                              }
                                              onChange={(e) => {
                                                this.handleAddEvent(
                                                  e,
                                                  'description'
                                                );
                                              }}
                                            />
                                            <div className="mt-2">
                                              <RangePicker
                                                showTime={{ format: 'HH:mm a' }}
                                                size="medium"
                                                format="YYYY-MM-DD HH:mm a"
                                                onChange={this.startEnd}
                                                
                                                // allowEmpty={this.state.allowEmptyStartDate, this.state.allowEmptyEndDate}
                                                value={
                                                  this.state.event.start_date !=
                                                    '' && [
                                                    moment(
                                                      this.state.event
                                                        .start_date +
                                                        ' ' +
                                                        this.state.event
                                                          .start_time
                                                    ),
                                                    moment(
                                                      this.state.event
                                                        .end_date +
                                                        ' ' +
                                                        this.state.event
                                                          .end_time
                                                    ),
                                                  ]
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* <div className="form-group mt-3">
                                            <div className="form-control-wrap">
                                                <div className="col-md-3 col-sm-6"> */}
                                          <div className="preview-block mt-2">
                                            <div className="custom-control custom-switch checked">
                                              <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                  this.onChangeStatus(
                                                    e,
                                                    'status'
                                                  );
                                                }}
                                                className="custom-control-input"
                                                checked={
                                                  this.state.event.status === 1
                                                    ? true
                                                    : false
                                                }
                                                // defaultChecked={
                                                //   this.state.event.status === 1
                                                //     ? true
                                                //     : false
                                                // }
                                                id="status"
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="status"
                                              >
                                                Status of Event
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        {/* </div>
                                        </div>
                                        </div> */}
                                      </div>

                                      <div className="form-group mt-2">
                                        <label>
                                          <span>Effective On</span>
                                        </label>
                                        <ul className="custom-control-group">
                                          <li>
                                            <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                              {/* <div className="custom-control custom-checkbox"> */}
                                              <input
                                                checked={
                                                  this.state.allDaysDefault ===
                                                  true
                                                    ? true
                                                    : false
                                                }
                                                disabled={
                                                  this.state.allDaysDisabled
                                                }
                                                onChange={(e) => {
                                                  this.onCheckAllDays(
                                                    e,
                                                    'allDays'
                                                  );
                                                }}
                                                // checked={true}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="allDays"
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="allDays"
                                              >
                                                Everyday
                                              </label>
                                            </div>
                                          </li>
                                          <li>

                                            <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                                              <input
                                                checked={
                                                  this.state.sundayDefault ===
                                                  true
                                                    ? true
                                                    : false
                                                }
                                                disabled={
                                                  this.state.sundayDisabled
                                                }
                                                onChange={(e) => {
                                                  this.onChangeDays(
                                                    e,
                                                    'sunday'
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="sunday"
                                              />
                                              <label
                                                className="custom-control-label bg-transparent"
                                                htmlFor="sunday"
                                              >
                                                <span>Sunday</span>
                                              </label>
                                            </div>

                                          </li>

                                          <li>
                                            <div className="custom-control custom-checkbox custom-control-pro no-control">
                                              <input
                                                disabled={
                                                  this.state.mondayDisabled
                                                }
                                                checked={
                                                  this.state.mondayDefault &&
                                                  true
                                                }
                                                onChange={(e) => {
                                                  this.onChangeDays(
                                                    e,
                                                    'monday'
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="monday"
                                              />
                                              <label
                                                className="custom-control-label bg-transparent"
                                                htmlFor="monday"
                                              >
                                                <span>Monday</span>
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className="custom-control custom-checkbox custom-control-pro no-control">
                                              <input
                                                disabled={
                                                  this.state.tuesdayDisabled
                                                }
                                                checked={
                                                  this.state.tuesdayDefault &&
                                                  true
                                                }
                                                onChange={(e) => {
                                                  this.onChangeDays(
                                                    e,
                                                    'tuesday'
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="tuesday"
                                              />
                                              <label
                                                className="custom-control-label bg-transparent"
                                                htmlFor="tuesday"
                                              >
                                                <span>Tuesday</span>
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className="custom-control custom-checkbox custom-control-pro no-control">
                                              <input
                                                disabled={
                                                  this.state.wednesdayDisabled
                                                }
                                                checked={
                                                  this.state.wednesdayDefault &&
                                                  true
                                                }
                                                onChange={(e) => {
                                                  this.onChangeDays(
                                                    e,
                                                    'wednesday'
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="wednesday"
                                              />
                                              <label
                                                className="custom-control-label bg-transparent"
                                                htmlFor="wednesday"
                                              >
                                                <span>Wednesday</span>
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className="custom-control custom-checkbox custom-control-pro no-control">
                                              <input
                                                disabled={
                                                  this.state.thursdayDisabled
                                                }
                                                checked={
                                                  this.state.thursdayDefault &&
                                                  true
                                                }
                                                onChange={(e) => {
                                                  this.onChangeDays(
                                                    e,
                                                    'thursday'
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="thursday"
                                              />
                                              <label
                                                className="custom-control-label bg-transparent"
                                                htmlFor="thursday"
                                              >
                                                <span>Thursday</span>
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className="custom-control custom-checkbox custom-control-pro no-control">
                                              <input
                                                disabled={
                                                  this.state.fridayDisabled
                                                }
                                                checked={
                                                  this.state.fridayDefault &&
                                                  true
                                                }
                                                onChange={(e) => {
                                                  this.onChangeDays(
                                                    e,
                                                    'friday'
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="friday"
                                              />
                                              <label
                                                className="custom-control-label bg-transparent"
                                                htmlFor="friday"
                                              >
                                                <span>Friday</span>
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className="custom-control custom-checkbox custom-control-pro no-control">
                                              <input
                                                disabled={
                                                  this.state.saturdayDisabled
                                                }
                                                checked={
                                                  this.state.saturdayDefault &&
                                                  true
                                                }
                                                onChange={(e) => {
                                                  this.onChangeDays(
                                                    e,
                                                    'saturday'
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="saturday"
                                              />
                                              <label
                                                className="custom-control-label bg-transparent"
                                                htmlFor="saturday"
                                              >
                                                <span>Saturday</span>
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      
                                    </div>

                                    <div style={{ marginTop: '12px' }}>
                                      {!this.state.isRequesting ? (
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <span>Submit</span>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          disabled
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <div
                                            className="spinner-border"
                                            role="status"
                                            style={{ margin: '-6px' }}
                                          >
                                            {' '}
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Modal>

                            <Modal
                              title="Delete Event"
                              visible={this.state.deleteEventModal}
                              footer={null}
                              // onOk={() => {
                              //   this.deleteEventRequest();
                              // }}
                              // confirmLoading={confirmLoading}
                              onCancel={() => {
                                this.setState({
                                  deleteEventModal: false,
                                });
                              }}
                            >

                              <form onSubmit={this.deleteEventRequest}>
                              <p>
                                Delete{' '}
                                {`${'"' + this.state.deleteEventTitle + '"'}`}{' '}
                                Event
                              </p>

                              <div style={{ marginTop: '12px' }}>
                                  {!this.state.isRequesting ? (
                                    <button
                                      type="submit"
                                      className="btn btn-md btn-primary btn-block"
                                    >
                                      <span>Submit</span>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="btn btn-lg btn-primary btn-block"
                                    >
                                      <div
                                        className="spinner-border"
                                        role="status"
                                        style={{ margin: '-6px' }}
                                      >
                                        {' '}
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </form>
                            </Modal>

                            <div data-tut="reactour__eventsDataTable">
                            {/* <MUIDataTable
                              title={'Events'}
                              data={data}
                              columns={columns}
                              options={options}
                            /> */}

                                <ThemeProvider theme={this.getMuiTheme()}>
                                  <MUIDataTable title={'Events'} data={data} columns={columns} options={options} />
                                </ThemeProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer
                      infoForFooter={reactLocalStorage.getObject('free_trial')}
                    />
                  </div>
                </div>
              </div>
              {/* <Tour
                onRequestClose={this.closeTour}
                disableInteraction={true}
                steps={eventsTourConfig}
                isOpen={isTourOpen}
                className="helper"
                rounded={5}
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
              /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Events;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import ReactPlayer from 'react-player'
import { Upload, Modal, Image } from 'antd';
import { reactLocalStorage } from 'reactjs-localstorage';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class Guide extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      isLoadingAws: false,
      isRequesting: false,
      edit_photos_videos: false,
      defaultChecked: false,
      isTourOpen: false,
      isShowingMore: false,
      business_photos_videos: [],
      previewVideoAssetModal: false,
      theDisplay: '',
      delete_photos_videos_list: [],
      business_details: {
        place_id: '',
        bio: '',
        name: '',
        formatted_address: '',
        business_status: '',
        icon: '',
        icon_background_color: '',
        icon_mask_base_uri: '',
        phone_number: '',
        rating: '',
        url: '',
        user_ratings_total: '',
        vicinity: '',
        website: '',
        reference: '',
        lat: '',
        lng: '',
      },
      previewVisible: false,
      uploadPhotoVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      videoAssetTitle: '',
      videoAssetLink: ''
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getPhotosVideos();
    }
  }

  getPhotosVideos() {
      let business_details = JSON.parse(AuthService.getAuth().business);
      axios
      .post(CONSTANTS.API_BASE_URL + '/get/video/assets', {})
      .then((response) => {
      let result = response.data.data;
      let old_business_details = this.state.business_details;
      old_business_details.name = 'Help and Tutorial Videos';

      console.log(result);
      this.setState({
        isLoading: false,
        business_details: old_business_details,
        business_photos_videos: result,
      });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  onChangeAddToDeletedPhotosCheckbox = (e, position) => {
    let { delete_photos_videos_list } = this.state;
    let tentative_array = delete_photos_videos_list;
    if (e.target.checked) {
      tentative_array.push(position);
      this.setState({
        delete_photos_videos_list: tentative_array,
      });
    } else {
      let toRemove = position;
      let index = tentative_array.indexOf(toRemove);
      if (index > -1) {
        tentative_array.splice(index, 1);
      }
      this.setState({
        delete_photos_videos_list: tentative_array,
      });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });
  handleAddPhotoCancel = () => this.setState({ uploadPhotoVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({
      fileList: fileList,
    });
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <Fragment>
        <MetaTags>
          <title>UnstuQ Business</title>
        </MetaTags>
        {this.state.isLoading ? (
           <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
        ) : (
          <div>
            <div className="nk-app-root bg-lighter">
              <div className="nk-main ">
                <SideNav />
                <div className="nk-wrap ">
                  <HeaderNav />

                  {this.state.isLoadingAws ? (
                    <div className="nk-content ">
                    <div className="container-fluid">
                      <div className="nk-content-inner">
                        <div className="nk-content-body">
                          <div className="nk-block-head nk-block-head-sm">

                          <>
                          <div
                            style={{ backgroundColor: '#f5f6fa' }}
                            className="nk-block-content nk-error-ld text-center"
                          >
                            <img
                              alt="example"
                              src="assets/images/images_loader.gif"
                            />
                            <h3
                              style={{
                                color: '#8094ae',
                                fontSize: 'px',
                              }}
                              className="nk-error-title"
                            >
                              Getting your images ready, please wait
                            </h3>
                          </div>
                        </>

                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                  ) : (
                  <div className="nk-content ">
                    <div className="container-fluid">
                      <div className="nk-content-inner">
                        <div className="nk-content-body">
                          <div className="nk-block-head nk-block-head-sm">

                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                  <h4
                                    className="nk-block-title page-title"
                                    style={{ color: '#364a63' }}
                                  >
                                    {this.state.business_details.name.toString()}
                                  </h4>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                      href="#"
                                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                      data-target="pageMenu"
                                    >
                                      <em
                                        onClick={() => {
                                          this.pageMenu();
                                        }}
                                        className="icon ni ni-more-v"
                                      />
                                    </a>
                                    <div
                                      className="toggle-expand-content"
                                      data-content="pageMenu"
                                      style={{
                                        display: `${this.state.theDisplay}`,
                                      }}
                                    >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.business_photos_videos.length < 1 ? (
                            <>
                              <div
                                style={{ backgroundColor: '#f5f6fa' }}
                                className="nk-block-content nk-error-ld text-center"
                              >
                                <em
                                  className="icon ni ni-na fa-lg"
                                  style={{
                                    fontSize: '40vh',
                                    color: '#8094ae',
                                  }}
                                ></em>
                                <h3
                                  style={{
                                    color: '#8094ae',
                                  }}
                                  className="nk-error-title"
                                >
                                  There are no Photos/Videos for this business
                                </h3>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row g-3">
                                {this.state.business_photos_videos.map(
                                  (data, index) => {
                                    if (this.state.edit_photos_videos) {
                                      return (
                                        <div className="col-md-6 col-lg-3 col-xl-3 col-sm-12" style={{zIndex: "0"}}>
                                          <div className="preview-block">
                                            <div className="custom-control custom-checkbox image-control">
                                              <input
                                                onChange={(e) => {
                                                  this.onChangeAddToDeletedPhotosCheckbox(
                                                    e,
                                                    data.id
                                                  );
                                                }}
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={data.id}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={data.id}
                                              >
                                                <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col-md-6 col-lg-3 col-xl-3 col-sm-12"
                                          style={{ zIndex: '0' }}
                                        >
                                          <div className="preview-block">
                                            <div className="custom-control custom-checkbox image-control">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={data.id}
                                              />

                                                <div className="gallery card">
                                                    <img
                                                      style={{cursor: 'pointer'}}
                                                      onClick={() => {
                                                        this.setState({
                                                          previewVideoAssetModal: true,
                                                          videoAssetTitle: data.title,
                                                          videoAssetLink: data.url,
                                                        });
                                                      }}
                                                      className="w-100 rounded-top"
                                                      src={"http://img.youtube.com/vi/"+data.video_id+"/0.jpg"}
                                                      alt=""
                                                    />
                                                  <div className="gallery-body card-inner align-center justify-between flex-wrap g-2">
                                                    <div className="user-card" style={{padding: '0rem'}}>
                                                      <div className="user-info">
                                                        <span className="lead-text">
                                                          {data.title}
                                                        </span>
                                                        <span className="sub-text">
                                                          {data.description}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  )}


                  <Modal
                    visible={this.state.previewVideoAssetModal}
                    title={this.state.videoAssetTitle}
                    footer={null}
                    width={800}
                    onCancel={() => {
                      this.setState({
                        previewVideoAssetModal: false,
                      });
                    }}
                  >
                    <ReactPlayer
                      width={752}
                      height={423}
                      url={this.state.videoAssetLink}
                    />
                  </Modal>

                  <Footer
                    infoForFooter={reactLocalStorage.getObject('free_trial')}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Guide;

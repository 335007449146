import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {  
        "type":"light",
        "primary":{
            "main":"#1c1c1c",
            "light":"rgb(71, 145, 219)",
            "dark":"#414b54",
            "contrastText":"#fff"
        },
        "secondary":{
            "main":"rgb(64, 120, 187)",
            "light":"rgb(227, 51, 113)",
            "dark":"rgb(154, 0, 54)",
            "contrastText":"#fff"
        },
        "error":{
            "light":"#e57373",
            "main":"#f44336",
            "dark":"#d32f2f",
            "contrastText":"#fff"
        },
        "warning":{
            "light":"#ffb74d",
            "main":"#ff9800",
            "dark":"#f57c00",
            "contrastText":"rgba(0, 0, 0, 0.87)"
        },
        "info":{
            "light":"#64b5f6",
            "main":"#2196f3",
            "dark":"#1976d2",
            "contrastText":"#fff"
        },
        "success":{
            "main":"#F8D147",
            "light":"rgb(71, 145, 219)",
            "dark":"#355b92",
            "contrastText":"#fff"
        },
        "grey":{
            "50":"#fafafa",
            "100":"#f5f5f5",
            "200":"#eeeeee",
            "300":"#e0e0e0",
            "400":"#bdbdbd",
            "500":"#9e9e9e",
            "600":"#757575",
            "700":"#616161",
            "800":"#424242",
            "900":"#212121",
            "A100":"#d5d5d5",
            "A200":"#aaaaaa",
            "A400":"#303030",
            "A700":"#616161"
        },
        "contrastThreshold":3,
        "tonalOffset":0.2,
        "text":{
            "primary":"rgba(0, 0, 0, 0.87)",
            "secondary":"rgba(0, 0, 0, 0.54)",
            "disabled":"rgba(0, 0, 0, 0.38)",
            "hint":"rgba(0, 0, 0, 0.38)"
        },
        "divider":"rgba(0, 0, 0, 0.12)",
        "background":{
            "paper":"#fff",
            "default":"#fff",
            "level2":"#f5f5f5",
            "level1":"#fff"
        },
        "action":{
            "active":"rgba(0, 0, 0, 0.54)",
            "hover":"rgba(0, 0, 0, 0.04)",
            "hoverOpacity":0.04,
            "selected":"rgba(0, 0, 0, 0.08)",
            "selectedOpacity":0.08,
            "disabled":"rgba(0, 0, 0, 0.26)",
            "disabledBackground":"rgba(0, 0, 0, 0.12)",
            "disabledOpacity":0.38,
            "focus":"rgba(0, 0, 0, 0.12)",
            "focusOpacity":0.12,
            "activatedOpacity":0.12
        }
    },
    // props: {
    //     // Name of the component ⚛️
    //     MuiButtonBase: {
    //       // The default props to change
    //       disableRipple: true, // No more ripple, on the whole application 💣!
    //     },
    //   }
});

export default theme;
import React, { Fragment } from 'react';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import moment from 'moment';

class Footer extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <Fragment>
        <div className="nk-footer">
          <div className="container-fluid">
            <div className="nk-footer-wrap">
              <div className="nk-footer-copyright">
                {' '}
                © 2022{' '}
                <a href="https://unstuq.com" target="_blank" rel="noreferrer">
                  UnstuQ Business
                </a>
              </div>
            </div>

            {this.props.infoForFooter > 0 &&
              <div className="pmo-lv pmo-dark active" style={{width: "fit-content", zIndex: "999"}}>
                <a
                  className="pmo-wrap"
                  href={`${CONSTANTS.APP_URL}/billing`}
                >
                  <div className="pmo-text text-white">
                    Free Trial Expires In {this.props.infoForFooter} Day(s) <em className="ni ni-arrow-long-right" />
                  </div>
                </a>
              </div>
            }

          </div>
        </div>
      </Fragment>
    );
  }
}

export default Footer;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import theme from '../../theme/black';
import { ThemeProvider } from '@mui/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import moment from 'moment';
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Tour from "reactour";
// import {servicesTourConfig} from '../includes/tourConfig';
import { Upload, Modal, Input } from 'antd';
import SideNav from '../includes/SideNav';
import HeaderNav from '../includes/HeaderNav';
import Footer from '../includes/Footer';
import EllipsisText from 'react-ellipsis-text';
import { BusinessPortalLoader } from '../includes/Loaders';
import { reactLocalStorage } from 'reactjs-localstorage';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { DatePicker, message } from 'antd';
const { RangePicker } = DatePicker;
// var Loader = require('react-loader');

// let theme = createTheme();
// theme = responsiveFontSizes(theme);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class Employees extends React.Component {
  constructor(props) {
    AuthService.checkProtected();
    super(props);
    this.handleEmployeeNameSubmit = this.handleEmployeeNameSubmit.bind(this);
    this.state = {
      userInfo: JSON.parse(AuthService.getAuth().user),
      businessInfo: JSON.parse(AuthService.getAuth().business),
      isLoading: true,
      nothingToShow: true,
      isRequesting: false,
      showServiceModal: false,
      previewVisible: false,
      allowEmptyStartDate: false,
      allowEmptyEndDate: false,
      deleteEmployeeModal: false,
      giftCardVisible: false,
      showGiftCard: false,
      loadGiftCardSwitch: false,
      showAddmore: true,
      deleteServiceId: '',
      giftCardTitle: '',
      deleteEmployeeTitle: '',
      previewImage: '',
      previewTitle: '',
      theDisplay: '',
      employeeModalTitle: '',
      isTourOpen: false,
      isShowingMore: false,
      fileListt: [],
      getImageUrl: '',
      recurringList: [],
      employeesArray: [],
      detailedRecurringList: [],
      postEmployeeNameHandle: [{ id: '', name: '', employee_code: '', status: 1 }],
      business_details: {
        name: '',
      },
      employee: {
        id: '',
        name: '',
        employee_code: '',
        status: 0,
      },
    };
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    if(reactLocalStorage.getObject('subscription_status') === 'inactive'){
      window.location.replace(
        `${CONSTANTS.APP_URL}/business-information`
      );
    }
    else{
      this.getEmployees("");
      // if(this.state.userInfo.employee_tour === 0){
      //   this.openTour();
      // }
    }
  }

  handleEmployeeNameOptions(i, e, n) {
    let { postEmployeeNameHandle } = this.state;
    if(n == 'name'){
      postEmployeeNameHandle[i][n] = e.target.value;
      this.setState({ postEmployeeNameHandle });
    }
  }

  addEmployeeNameFields() {
    this.setState({
      postEmployeeNameHandle: [...this.state.postEmployeeNameHandle, { id: '', name: '', employee_code: '', status: 1 }],
    });
  }

  removeEmployeeNameFields(i) {
    let postEmployeeNameHandle = this.state.postEmployeeNameHandle;
    postEmployeeNameHandle.splice(i, 1);
    this.setState({ postEmployeeNameHandle });
  }

  handleEmployeeNameSubmit(event) {
    event.preventDefault();
    this.setState({
      isRequesting: true,
    });

    let all_hours = [];
    all_hours.push(this.state.postEmployeeNameHandle);

    console.log(all_hours);

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/add/employee',
        { employeeNameFinal: JSON.stringify(all_hours) },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        this.getEmployees("add");
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          showServiceModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  }

  getEmployees = (request) => {
    axios
      .get(
        CONSTANTS.API_BASE_URL + '/business/get/employees',
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        let business_services = result.data.business_employees;
        let UsersArray = [];

        for (let i = 0; i < business_services.length; i++) {
          let the_id = business_services[i].id;
          let name = business_services[i].employee_name;
          let employee_code = business_services[i].employee_code;
          let status = business_services[i].status;

          let UserArray = [];
          UserArray.push(the_id);
          UserArray.push(name);
          UserArray.push(employee_code);
          UserArray.push(status);

          UsersArray.push(UserArray);

          this.setState({
            postEmployeeNameHandle: [...this.state.postEmployeeNameHandle, {
              id: business_services[i].id,
              name: business_services[i].employee_name,
              employee_code: business_services[i].employee_code,
              status: business_services[i].status }],
          });
        }
        if(request == "add"){
          this.setState({
            employeesArray: UsersArray,
            isRequesting: false,
            showEmployeeModal: false,
          });
          AuthService.Prompts('success', 'Updated Successfully');
        }
        if(request == "delete"){
          this.setState({
            employeesArray: UsersArray,
            isRequesting: false,
            deleteEmployeeModal: false,
          });
          AuthService.Prompts('success', 'Deleted Successfully');
        }
        this.setState({
          ...this.state,
          employeesArray: UsersArray,
          isLoading: false,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  getMuiTheme = () => createTheme({
    components: {
        MUIDataTableHeadCell: {
          styleOverrides:{
          fixedHeader: {
            zIndex: "0"
          }
        }
        },
        MUIDataTableSelectCell: {
          styleOverrides:{
          headerCell: {
            zIndex: "0"
          }
        }
        },
    }
})

  // disableBody = target => disableBodyScroll(target);
  // enableBody = target => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  // closeTour = () => {
  //   this.setState({ isTourOpen: false });
  //   axios
  //   .post(
  //     CONSTANTS.API_BASE_URL + '/business/tour/update',
  //     { column_name: "employee_tour" },
  //     AuthService.getAxiosHeaders(
  //       window.sessionStorage.getItem('new_business')
  //     )
  //   )
  //   .then((response) => {
  //     let result = response.data;
  //     reactLocalStorage.remove(
  //       'user_object'
  //     );
  //     reactLocalStorage.setObject(
  //       'user_object',
  //       JSON.stringify(result.data.user_details)
  //     );
  //   })
  //   .catch((error) => {
  //   });
  // };

  // openTour = () => {
  //   this.setState({ isTourOpen: true });
  // };

  pageMenu = () => {
    if (this.state.theActive == '') {
      this.setState({
        theActive: 'active',
        theDisplay: 'block',
      });
    } else {
      this.setState({
        theActive: '',
        theDisplay: '',
      });
    }
  };

  deleteEmployee = (the_id, name) => {
    // this.setState({
    //   deleteEmployeeModal: true,
    //   deleteEmployeeId: the_id,
    //   deleteEmployeeTitle: name,
    // });

    let old_employee = this.state.employee;
    old_employee.id = the_id;
    old_employee.name = name;
    this.setState({
      employee: old_employee,
      deleteEmployeeModal: true,
    });

  };

  editEmployee = (
    the_id,
    name,
    code,
    status
  ) => {
    // this.setState({
    //   employeeModalTitle: 'Edit Service',
    // });

    // let old_employee = this.state.employee;
    // old_employee.id = the_id;
    // old_employee.name = name;
    // old_employee.status = status;
    // this.setState({
    //   service: old_employee,
    //   showEmployeeModal: true,
    // });
    this.setState({
      employeeModalTitle: 'Edit Service',
      showEmployeeModal: true,
      showAddmore: false,
      // postEmployeeNameHandle: [{ id: '', name: '', employee_code: '', status: 1 }],
      postEmployeeNameHandle: [{
        id: the_id,
        name: name,
        employee_code: code,
        status: status,
        // employee_code: business_services[i].employee_code,
        // status: business_services[i].status 
      }],
    });
  };

  onChangeStatus = (i, e, v) => {
    let { postEmployeeNameHandle } = this.state;
    if (e.target.checked && v == 'status') {
      // let old_employee = this.state.employee;
      // old_employee.status = 1;
      // this.setState({
      //   service: old_employee,
      // });
      postEmployeeNameHandle[i][v] = 1;
      this.setState({ postEmployeeNameHandle });
    } else {
      if (v == 'status') {
        // let old_employee = this.state.employee;
        // old_employee.status = 0;
        // this.setState({
        //   service: old_employee,
        // });
        postEmployeeNameHandle[i][v] = 0;
        this.setState({ postEmployeeNameHandle });
      }
    }
  };

  deleteEmployeeRequest = (e) => {
    this.setState({
      isRequesting: true,
    });
    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + '/business/delete/employee',
        { employee_id: this.state.employee.id },
        AuthService.getAxiosHeaders(
          window.sessionStorage.getItem('new_business')
        )
      )
      .then((response) => {
        let result = response.data;
        this.getEmployees("delete");
      })
      .catch((error) => {
        this.setState({
          isRequesting: false,
          deleteEmployeeModal: false,
        });
        AuthService.Prompts('error', 'Unable to reach server');
      });
  };

  addEmployee = () => {
    // let old_employee = this.state.employee;
    // old_employee.id = '';
    // old_employee.name = '';
    // old_employee.status = 1;

    // this.setState({
    //   showEmployeeModal: true,
    //   employee: old_employee,
    //   employeeModalTitle: 'Add an Employee',
    // });

    this.setState({
      showEmployeeModal: true,
      showAddmore: true,
      postEmployeeNameHandle: [{ id: '', name: '', employee_code: '', status: 1 }],
      employeeModalTitle: 'Add an Employee',
    });
  };

  // submitEmployeeForm = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     isRequesting: true,
  //   });

  //   axios
  //     .post(
  //       CONSTANTS.API_BASE_URL + '/business/add/employee',
  //       { employees: JSON.stringify(this.state.employee) },
  //       AuthService.getAxiosHeaders(
  //         window.sessionStorage.getItem('new_business')
  //       )
  //     )
  //     .then((response) => {
  //       let result = response.data;
  //       this.getEmployees("add");
  //       // this.setState({
  //       //   isRequesting: false,
  //       //   showServiceModal: false,
  //       // });
  //       // AuthService.Prompts('success', 'Updated Successfully');
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         isRequesting: false,
  //         showServiceModal: false,
  //       });
  //       AuthService.Prompts('error', 'Unable to reach server');
  //     });
  // };

  onChangeGiftCardStatus = (e) => {
    //console.log(`switch to ${e}`);
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";

    const columns = [
      {
        // label: "Name",
        name: 'Name',
        options: {
          print: true,
          filter: true,
          sort: false,
          filterType: 'multiselect',
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][1];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Employee ID',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][2];
            if (value == '' || value === undefined || value === null) {
              return 'N/A';
            } else {
              return value;
            }
          },
        },
      },
      {
        name: 'Status',
        options: {
          print: true,
          filter: true,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let value = data[dataIndex][3];
            if (value === 1) {
              return (
                <li style={{ padding: '0px' }} className="preview-item">
                  <span
                    style={{ fontWeight: '700' }}
                    className="badge badge-success"
                  >
                    Active
                  </span>
                </li>
              );
            } else {
              return (
                <li style={{ padding: '0px' }} className="preview-item">
                  <span
                    style={{ fontWeight: '700' }}
                    className="badge badge-danger"
                  >
                    Inactive
                  </span>
                </li>
              );
            }
          },
        },
      },
      {
        name: 'Action',
        options: {
          print: false,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            let the_id = data[dataIndex][0];
            let name = data[dataIndex][1];
            let employee_code = data[dataIndex][2];
            let status = data[dataIndex][3];
            return (
              <div>
                <button
                  onClick={() => {
                    this.editEmployee(
                      the_id,
                      name,
                      employee_code,
                      status
                    );
                  }}
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: '10px' }}
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    this.deleteEmployee(the_id, name);
                  }}
                  className="btn btn-danger btn-sm"
                >
                  Delete
                </button>
              </div>
            );
          },
        },
      },
    ];

    const data = this.state.employeesArray;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      // resizableColumns: true,
      selectableRows: false,
      elevation: 0,
      textLabels: {
        body: {
            noMatch: "No employess have been added to this business"
        },
      },
    };
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div>Add Image</div>
      </div>
    );
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <MetaTags>
            <title>UnstuQ Business</title>
          </MetaTags>
          {this.state.isLoading ? (
             <>
              <img className="preloader" src="assets/images/logo_small.png" alt="image" />
            </>
          ) : (
            <div>
              <div className="nk-app-root bg-lighter">
                <div className="nk-main ">
                  <SideNav />
                  <div className="nk-wrap ">
                    <HeaderNav />

                    <div className="nk-content ">
                      <div className="container-fluid">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">

                              <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h4
                                      className="nk-block-title page-title"
                                      style={{ color: '#364a63' }}
                                    >
                                      {this.state.businessInfo.name.toString()}
                                    </h4>
                                  </div>
                                  <div className="nk-block-head-content">
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em
                                          onClick={() => {
                                            this.pageMenu();
                                          }}
                                          className="icon ni ni-more-v"
                                        />
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                        style={{
                                          display: `${this.state.theDisplay}`,
                                        }}
                                      >
                                        <ul className="nk-block-tools g-3">
                                          <li data-tut="reactour__addAServiceButton">
                                            <button
                                              type="submit"
                                              className="btn btn-md btn-primary btn-block"
                                              onClick={() => {
                                                this.addEmployee();
                                              }}
                                            >
                                              <span>Add an Employee</span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* add employees old start */}

                            {/* <Modal
                              visible={this.state.showEmployeeModal}
                              title={this.state.employeeModalTitle}
                              footer={null}
                              onCancel={() => {
                                this.setState({
                                    showEmployeeModal: false,
                                });
                              }}
                            >
                              <div className="form-group">
                                <div className="form-control-wrap">
                                  <form onSubmit={this.submitEmployeeForm}>
                                    <div>
                                      <div
                                        className="row"
                                        style={{ alignItems: 'normal' }}
                                      >
                                        <div className="col-8">
                                          <div className="project-info">
                                            <input
                                              className="form-control form-control-sm"
                                              placeholder="Employee Name"
                                              spellCheck="false"
                                              required
                                              style={{
                                                fontSize: '14px',
                                                height: '35px',
                                              }}
                                              value={this.state.employee.name}
                                              onChange={(e) => {
                                                this.handleAddEmployee(
                                                  e,
                                                  'name'
                                                );
                                              }}
                                            />
                                            </div>
                                            </div>

                                              <div className="col-md-4">
                                                <div className="mt-1">
                                                        <div className="custom-control custom-switch checked">
                                                          <input
                                                          defaultChecked={true}
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                              this.onChangeStatus(
                                                                e,
                                                                'status'
                                                              );
                                                            }}
                                                            className="custom-control-input"
                                                            id="status"
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor="status"
                                                          >
                                                            Active
                                                          </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                    <div style={{ marginTop: '12px' }}>
                                      {!this.state.isRequesting ? (
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <span>Submit</span>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          disabled
                                          className="btn btn-lg btn-primary btn-block"
                                        >
                                          <div
                                            className="spinner-border"
                                            role="status"
                                            style={{ margin: '-6px' }}
                                          >
                                            {' '}
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Modal> */}

                            {/* add employees old end */}

                  <Modal
                    visible={this.state.showEmployeeModal}
                    title={this.state.employeeModalTitle}
                    footer={null}
                    width={800}
                    onCancel={() => {
                      this.setState({
                        showEmployeeModal: false,
                      });
                    }}
                  >
                    <form onSubmit={this.handleEmployeeNameSubmit}>
                      <div className="card-preview">
                        {this.state.postEmployeeNameHandle.map(
                          (element, i) => (
                            <div
                              key={i}
                              style={{
                                paddingBottom: '16px',
                              }}
                            >
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="form-group">
                                    <Input
                                      placeholder="Employee Name"
                                      value={element.name}
                                      required
                                      onChange={(e) =>
                                        this.handleEmployeeNameOptions(
                                          i,
                                          e,
                                          'name'
                                        )
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  <div className="mt-1">
                                          <div className="custom-control custom-switch checked">
                                            <input
                                              defaultChecked={element.status}
                                              type="checkbox"
                                              // value={element.status}
                                              onChange={(e) => {
                                                this.onChangeStatus(
                                                  i,
                                                  e,
                                                  'status'
                                                );
                                              }}
                                              className="custom-control-input"
                                              id={i}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={i}
                                            >
                                              Active
                                            </label>
                                        </div>
                                      </div>
                                    </div>

                                {i > 0 && (
                                  <div
                                    className="col-md-2"
                                    style={{ marginTop: '5px' }}
                                  >
                                    <span className="nk-menu-icon">
                                      <em
                                        style={{
                                          color: 'red',
                                          cursor: 'pointer',
                                        }}
                                        onClick={this.removeEmployeeNameFields.bind(
                                          this,
                                          i
                                        )}
                                        className="icon ni ni-cross-circle-fill"
                                      />
                                    </span>
                                  </div>
                                )}

                              
                                {i === 0 && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                    className="col-md-2"
                                  >
                                    {this.state.showAddmore &&
                                    <p
                                      onClick={this.addEmployeeNameFields.bind(
                                        this
                                      )}
                                    >
                                      Add more
                                    </p>}
                                  </div>
                                )}
                                </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3" style={{ marginTop: '25px' }}>
                          {!this.state.isRequesting ? (
                            <button
                              type="submit"
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <span>Submit</span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn-md btn-primary btn-block float-right"
                            >
                              <div
                                className="spinner-border"
                                role="status"
                                style={{ margin: '-6px' }}
                              >
                                {' '}
                              </div>
                            </button>
                          )}
                          {/* </div> */}
                        </div>
                      </div>
                    </form>
                  </Modal>


                            <Modal
                              title="Delete Employee"
                              visible={this.state.deleteEmployeeModal}
                              footer={null}
                              // onOk={() => {
                              //   this.deleteEmployeeRequest();
                              // }}
                              // confirmLoading={confirmLoading}
                              onCancel={() => {
                                this.setState({
                                  deleteEmployeeModal: false,
                                });
                              }}
                            >

                            <form onSubmit={this.deleteEmployeeRequest}>
                              <p>
                                Delete{' '}
                                Employee{' '}
                                {`${'"' + this.state.employee.name + '"'}`}{' '}
                              </p>

                              <div style={{ marginTop: '12px' }}>
                                  {!this.state.isRequesting ? (
                                    <button
                                      type="submit"
                                      className="btn btn-md btn-primary btn-block"
                                    >
                                      <span>Submit</span>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="btn btn-lg btn-primary btn-block"
                                    >
                                      <div
                                        className="spinner-border"
                                        role="status"
                                        style={{ margin: '-6px' }}
                                      >
                                        {' '}
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </form>
                            </Modal>

                            <div data-tut="reactour__serviceDataTable">
                            {/* <MUIDataTable
                              title={'Services'}
                              data={data}
                              columns={columns}
                              options={options}
                            /> */}

                                <ThemeProvider theme={this.getMuiTheme()}>
                                  <MUIDataTable title={'Employees'} data={data} columns={columns} options={options} />
                                </ThemeProvider>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer
                      infoForFooter={reactLocalStorage.getObject('free_trial')}
                    />
                  </div>
                </div>
              </div>
              {/* <Tour
                onRequestClose={this.closeTour}
                disableInteraction={true}
                steps={servicesTourConfig}
                isOpen={isTourOpen}
                className="helper"
                rounded={5}
                onAfterOpen={this.disableBody}
                onBeforeClose={this.enableBody}
              /> */}
            </div>
          )}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Employees;

import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import { SettingUpLoader } from '../includes/Loaders';

class SettingUp extends React.Component {
  verifyAuthzeroCode() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      axios
        .post(CONSTANTS.API_BASE_URL + '/verify/authzero/code', {
          code: code,
          redirect_uri: `${CONSTANTS.APP_URL}`,
        })
        .then((response) => {
          let result = response.data;
          window.sessionStorage.setItem(
            'userDetails',
            JSON.stringify(result.data)
          );
          let expirationDate = new Date();
          let validDays = 14;
          expirationDate.setTime(
            expirationDate.getTime() + validDays * 24 * 60 * 60 * 1000
          );
          if (result.data.option === 'redirect') {
            // cookie.set('access_token', result.data.access_token, {
            //   path: `${CONSTANTS.APP_URL}`,
            //   expires: expirationDate,
            // });
            reactLocalStorage.set('access_token', result.data.access_token);
            reactLocalStorage.setObject(
              'business_object',
              JSON.stringify(result.data.business_details)
            );
            window.location.replace(
              `${CONSTANTS.APP_URL}/business-information`
            );
            return;
          }
          else
          {
            window.sessionStorage.setItem('onboarding_status', 'true');
            window.sessionStorage.setItem(
              'onboarding',
              JSON.stringify(result.data)
            );
            // cookie.save('access_token', result.access_token, {
            //   path: `${CONSTANTS.APP_URL}`,
            //   expires: expirationDate,
            // });
            reactLocalStorage.set('access_token', result.access_token);
            window.location.replace(`${CONSTANTS.APP_URL}/onboarding`);
            // window.location.replace(`${CONSTANTS.APP_URL}/welcome-page`);
          }
        })
        .catch((error) => {
          //console.log(error);
          // window.location = '/not-found';
        });
    } else {
      // window.location = '/not-found';
    }
  }

  componentDidMount() {
    window.initScript(window.NioApp, window.jQuery);
    // const script = document.createElement('script');
    // const scriptt = document.createElement('script');
    // const scripttt = document.createElement('script');

    // script.src = `${CONSTANTS.APP_URL}/assets/js/stripe-one.js`;
    // script.src = `${CONSTANTS.APP_URL}/assets/js/stripe-two.js`;
    // script.src = `${CONSTANTS.APP_URL}/assets/js/stripe-three.js`;

    // script.async = true;
    // scriptt.async = true;
    // scripttt.async = true;

    // document.body.appendChild(script);
    // document.body.appendChild(scriptt);
    // document.body.appendChild(scripttt);

    if (window.sessionStorage.getItem('onboarding') != null) {
      window.location.replace(`${CONSTANTS.APP_URL}/business-information`);
    } else {
      this.verifyAuthzeroCode();
    }
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          {/* <link
            rel="stylesheet"
            href={`${CONSTANTS.APP_URL}/assets/css/stripe.css`}
          /> */}
          <title>UnstuQ Business</title>
        </MetaTags>
        {/* <SettingUpLoader></SettingUpLoader> */}

        <>
          <img className="preloader" src="assets/images/logo_small.png" alt="image" />
          </>
          
        {/* <div
          style={{ backgroundColor: 'white' }}
          className="App-Container is-noBackground flex-container justify-content-center"
        >
          <div className="App App--multiItem">
            <div className="App-Overview">
              <div
                className="OrderDetails-footer"
                style={{ marginBottom: '230px' }}
              >
                <header>
                  <div className="Header-Content flex-container justify-content-space-between align-items-stretch">
                    <div className="Header-business flex-item width-grow flex-container align-items-center"></div>
                    <div className="Header-actions flex-item width-fixed">
                      <button
                        className="Button Header-viewDetailsButton Button--link Button--sm"
                        type="button"
                      >
                        <div className="flex-container justify-content-flex-end align-items-center">
                          <svg
                            className="InlineSVG Icon Button-Icon Button-Icon--right Icon--sm Icon--square"
                            focusable="false"
                            viewBox="0 0 12 12"
                          >
                            <path
                              d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z"
                              fillRule="evenodd"
                            />
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                </header>
                <div className="App-productSummaryContainer">
                  <div className="ProductSummary"></div>
                  <section className="OrderDetails App-Overview-OrderDetails my6 is-overflowing">
                    <div>
                      <div className="LOADING-checkoutOverview">
                        <div class="LOADING-header">
                          <div class="LOADING-backArrow"></div>
                          <div class="LOADING-logo LOADING-loads"></div>
                        </div>
                        <div className="LOADING-productSummary">
                          <div className="LOADING-productTitle LOADING-loads" />
                          <div className="LOADING-productPrice LOADING-loads" />
                          <div className="LOADING-productImageContainer">
                            <div className="LOADING-productImage LOADING-loads" />
                          </div>
                          <div className="LOADING-lineItem">
                            <div className="LOADING-lineItem-image LOADING-loads" />
                            <div className="LOADING-lineItem-lines">
                              <div className="LOADING-lineItem-line LOADING-loads" />
                              <div className="LOADING-lineItem-line LOADING-loads" />
                            </div>
                          </div>
                          <div className="LOADING-lineItem">
                            <div className="LOADING-lineItem-image LOADING-loads" />
                            <div className="LOADING-lineItem-lines">
                              <div className="LOADING-lineItem-line LOADING-loads" />
                              <div className="LOADING-lineItem-line LOADING-loads" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div className="App-Payment">
              <div className="CheckoutPaymentForm">
                <div
                  className="PaymentFormFixedHeightContainer flex-container direction-column"
                  style={{ height: '529px' }}
                >
                  <div style={{ height: '460.883px' }}>
                    <div>
                      <div style={{ opacity: 1 }}>
                        <div>
                          <div>
                            <div>
                              <div className="LOADING-checkoutPayment">
                                <div className="LOADING-paymentTitle LOADING-loads" />
                                <div className="LOADING-payment-oneRow LOADING-mb38 LOADING-loads" />
                                <div className="LOADING-payment-twoRow LOADING-mb38 LOADING-loads" />
                                <div className="LOADING-payment-oneRow LOADING-mb38 LOADING-loads" />
                                <div className="LOADING-payment-twoRow LOADING-loads" />
                                <div className="LOADING-button LOADING-loads" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Fragment>
    );
  }
}

export default SettingUp;
